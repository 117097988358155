import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        invoices: []
    },

    actions:{
        fetchAll({ commit }, { sequence, buyer, seller, date }){
            return window.axios.get('/files/invoices', {
                params:{
                    sequence, buyer, seller, date
                }
            })
            .then((response) => {
                commit(types.STORE_INVOICES, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/invoices/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }){
            return window.axios.post('/files/invoices', { isManualSequence, manualSequence, sequence, currency, buyer, date, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry })
            .then((response) => {
                return response.data;
            });
        },

        update({ commit }, { id, isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }){
            return window.axios.put(`/files/invoices/${id}`, { isManualSequence, manualSequence, sequence, currency, buyer, date, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry })
            .then((response) => {
                return id;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/invoices/${id}`);
        }
    },

    mutations:{
        [types.STORE_INVOICES](state, invoices){
            state.invoices = invoices;
        }
    },

    getters:{
        getInvoiceById: state => id => state.invoices.find(c => c.id === id),
    }
}