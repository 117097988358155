<template>
    <v-form @submit.prevent="submit(false)" ref="form" id="invoice" :disabled="viewerOnly">
        <div class="d-flex justify-space-between align-center mb-1">
            <!-- title and number -->
            <v-row class="align-center ma-0 mb-1">
                <!-- title -->
                <v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
                    <h1 class="text-h5 primary--text">{{title}}</h1>

                    <!-- back button -->
                    <v-btn
                    	v-if="$vuetify.breakpoint.smAndDown"
                    	:to="{ name:'invoices' }"
                    	:disabled="submitLoading"
                    	elevation="1"
                    	small
                    	class="px-6"
                    >
                        {{$t('back')}}
                    </v-btn>
                </v-col>

                <!-- num -->
                <v-col lg="2" md="3" sm="6" cols="12" class="py-0">
                    <!-- :label="$t('forms.number')" -->
                    <v-text-field
                        v-model="num"
                        :loading="loading || editLoading"
                        :rules="rules.required"
                        :append-outer-icon="isAdmin ? 'mdi-pencil' : null"
                        @click:append-outer="editSequence"
                        dense
                        hide-details
                        :readonly="!isManual"
                        :class="{ 'v-input--is-disabled': !isManual }"
                    ></v-text-field>
                </v-col>

                <!-- date -->
                <v-col md="3" sm="6" cols="12">
                    <bee-date-input
                        v-model="date"
                        changeable-with-arrows
                        :label="$t('forms.date')"
                        prepend-icon="mdi-calendar"
                        :input-format-order="dateFormat"
                        :user-menu-picker="true"
                        dense
                        hide-details="auto"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        :rules="rules.required"
                    ></bee-date-input>
                </v-col>
            </v-row>

            <!-- back button -->
            <v-btn
            	v-if="$vuetify.breakpoint.mdAndUp"
            	:to="{ name:'invoices' }"
            	:disabled="submitLoading"
            	elevation="1"
            	small
            	class="px-6"
            >
                {{$t('back')}}
            </v-btn>
        </div>

        <!-- BASIC INFORMATION -->
        <h2 class="text-body-1 primary--text mb-3">{{$t('sections.basic-information')}}</h2>

        <v-row class="justify-start mb-0">
            <!-- seller data -->
            <v-col sm="6" cols="12">
                <v-row>
                    <!-- seller -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="seller"
                            :label="$t('forms.seller')"
                            disabled
                            dense
                            hide-details="auto"
                            :rules="rules.required"
                        ></v-text-field>
                    </v-col>

                    <!-- reference contract -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="referenceContract"
                            dense
                            hide-details="auto"
                            :label="$t('forms.reference-contract')"
                        ></v-text-field>
                    </v-col>

                    <!-- currency -->
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="currency"
                            :items="company.currencies"
                            :label="$t('forms.currency')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="loading || submitLoading || editLoading"
                            :rules="rules.required"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>

            <!-- buyer data -->
            <v-col sm="6" cols="12">
                <v-row>
                    <!-- Buyer -->
                    <v-col cols="12">
                        <v-combobox
                            v-model="buyer"
                            :label="$t('forms.buyer')"
                            :items="customers"
                            item-text="name"
                            item-value="name"
                            :return-object="false"
                            dense
                            hide-details="auto"
                            :loading="loading"
                            :disabled="loading || submitLoading"
                            @change="findCustomserData"
                            :rules="rules.required"
                        ></v-combobox>
                    </v-col>
                    
                    <!-- buyer data -->
                    <v-col cols="12" class="pb-md-0">
                        <v-textarea
                            v-model="buyerData"
                            :label="$t('forms.buyer-data')"
                            rows="4"
                            :hint="$t('forms.buyer-data-will-show-if-you-select-the-buyer')"
                            persistent-hint
                            no-resize
                            outlined
                            dense
                            hide-details="auto"
                            :loading="editLoading || buyerDataLoading"
                            :disabled="loading || submitLoading || buyerDataLoading"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-col>

                    <!-- consignee -->
                    <v-col cols="12">
                        <v-autocomplete-server
                            v-model="consignee"
                            input-type="textarea"
                            rows="1"
                            auto-grow
                            dense
                            :label="$t('forms.consignee')"
                            :input-field-enum="inputEnums.consignee"
                            hide-details="auto"
                            outlined
                        ></v-autocomplete-server>
                    </v-col>

                    <!-- multiline notes -->
                    <v-col cols="12" class="pb-md-0">
                        <v-textarea
                            v-model="multilineNotes"
                            rows="2"
                            no-resize
                            dense
                            hide-details
                            outlined
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                            :label="$t('forms.multiline-notes')"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- BANK ACCOUNT DATA -->
        <!-- <h2 class="text-h6 mb-2">{{$t('sections.bank-account-data')}}</h2> -->
        <v-checkbox
            :label="$t('bank.show-bank-account-information-in-the-report-file')"
            v-model="showBankAccount"
            :disabled="loading || submitLoading"
            hide-details
            dense
            class="ma-0 pa-0 mb-5"
        />
       <v-row v-if="showBankAccount" class="mb-5">
            <!-- identifier -->
            <v-col md="4" sm="6" cols="12">
                <v-autocomplete
                    v-model="identifier"
                    :label="$t('bank.identifier')"
                    :items="companyBankIdentifiers"
                    item-text="identifier"
                    item-value="identifier"
                    :return-object="false"
                    @change="changeAccountIdentifier"
                    dense
                    hide-details="auto"
                    :loading="loading"
                    :disabled="loading || submitLoading"
                ></v-autocomplete>
            </v-col>

            <!-- Account Number -->
            <v-col md="4" sm="6" cols="12">
                <v-autocomplete
                    v-model="accountNumber"
                    :label="$t('bank.account-number')"
                    :items="companyBankAccounts"
                    item-text="accountNumber"
                    item-value="accountNumber"
                    @change="changeAccountNumber"
                    dense
                    hide-details="auto"
                    :loading="loading"
                    :disabled="loading || submitLoading"
                ></v-autocomplete>
            </v-col>

            <!-- Bank name -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                    v-model="bankName"
                    :label="$t('bank.bank-name')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- Bank country -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                    v-model="bankCountry"
                    :label="$t('bank.bank-country')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- address -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                    v-model="address"
                    :label="$t('bank.address')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- Swift Code -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                    v-model="swiftCode"
                    :label="$t('bank.swift-code')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                ></v-text-field>
            </v-col>
            
            <!-- IBan -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                    v-model="iban"
                    :label="$t('bank.iban')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                ></v-text-field>
            </v-col>
            
            <!-- Branch -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                    v-model="branch"
                    :label="$t('bank.branch')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- Intermediary bank -->
            <v-col md="4" sm="6" cols="12">
                <v-textarea
                    v-model="intermediaryBank"
                    rows="1"
                    auto-grow
                    dense
                    outlined
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                    :label="$t('forms.intermediary-bank')"
                    hide-details="auto"
                ></v-textarea>
            </v-col>
        </v-row>

        <!-- ITEM TRANSACTIONS -->
        <h2 class="text-body-1 primary--text mb-3">{{$t('sections.item-transactions')}}</h2>

        <!-- table data -->
        <v-form v-if="!viewerOnly" @submit.prevent="saveEntry" ref="formTable" class="mb-3">
            <v-row>
                <!-- items -->
                <v-col md="3" sm="4" cols="12">
                    <v-autocomplete
                        v-model="itemName"
                        :items="menuItems"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :menu-props="{contentClass:'text-center'}"
                        :label="$t('headers.item')"
                        @change="fetchItem"
                        @blur="resetValidationOnblur"
                        :rules="rules.required"
                        dense
                        hide-details="auto"
                        :loading="loading"
                        :disabled="loading || submitLoading"
                        ref="matreialInput"
                    >
                        <template
                            v-slot:append-item
                            v-if="!showAllItems && buyer && menuItems.length !== items.length"
                        >
                            <v-divider/>
                            <v-list-item dense ripple @click="showAllItems = true">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title class="text-body-2">
                                        {{$t('show-all')}}
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <!-- units -->
                <v-col md="2" sm="4" cols="12">
                    <v-autocomplete
                        v-model="itemUnit"
                        :items="units"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :menu-props="{contentClass:'text-center'}"
                        :label="$t('headers.unit')"
                        @change="findPrice"
                        @blur="resetValidationOnblur"
                        :rules="rules.required"
                        dense
                        hide-details="auto"
                        :loading="loading || itemLoading"
                        :disabled="loading || submitLoading || itemLoading"
                    ></v-autocomplete>
                </v-col>

                <!-- quantity -->
                <v-col md="2" sm="4" cols="12">
                    <v-currency-text-field
                    v-model.number="quantity"
                    :label="$t('headers.quantity')"
                    type="number"
                    min="0"
                    @blur="resetValidationOnblur"
                    dense
                    :disabled="submitLoading || editLoading"
                    hide-details="auto"
                    ></v-currency-text-field>
                </v-col>

                <!-- itemPrice -->
                <v-col md="2" sm="4" cols="12">
                    <v-currency-text-field
                    v-model.number="itemPrice"
                    :label="$t('headers.price')"
                    type="number"
                    min="0"
                    @blur="resetValidationOnblur"
                    :disabled="submitLoading || editLoading"
                    dense
                    hide-details="auto"
                    ></v-currency-text-field>
                </v-col>
                
                <!-- add button -->
                <v-col sm="1" cols="6" class="d-flex justify-center align-center">
                    <v-btn text small type="submit" :disabled="submitLoading || editLoading">
                        <v-icon>
                            {{ 
                                toEdit ? 'mdi-checkbox-marked-circle-outline' : 'mdi-plus-circle-outline'
                            }}
                        </v-icon>
                    </v-btn>
                </v-col>

                <!-- reset button -->
                <v-col sm="1" cols="6" class="d-flex justify-center align-center">
                    <v-btn @click="resetProductInputs" text small class="align-self-center" elevation="1">
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <v-data-table
            :headers="headers"
            :items="productEntries"
            hide-default-footer
            disable-pagination
            disable-sort
            :loading="editLoading"
            :no-data-text="$t('no-data-text')"
            :no-results-text="$t('no-results-text')"
            :loading-text="$t('loading-text')"
            :footer-props="{ 
                'items-per-page-all-text': $t('items-per-page-all-text'),
                'items-per-page-text': $t('items-per-page-text'),
            }"
            class="elevation-1 mb-3"
            dense
        >
            <!-- quantity -->
            <template v-slot:item.data.quantity="{item}">
                {{ item.data.quantity | currency}}
            </template>

            <!-- price -->
            <template v-slot:item.data.itemPrice="{item}">
                {{ item.data.itemPrice || '-' | currency}}
            </template>

            <!-- totalPrice -->
            <template v-slot:item.totalPrice="{item}">
                {{ item.data.itemPrice * item.data.quantity | currency}}
            </template>
            
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <tooltip text="Edit item">
                    <v-btn text small :disabled="submitLoading || editLoading" @click="fillToEdit(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>
                
                <tooltip text="Remove item" v-if="!toEdit">
                    <v-btn text small :disabled="submitLoading || editLoading" @click="removeProduct(item.id)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </v-data-table>

        <!-- Prices -->
        <v-row class="align-center">
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

            <!-- total -->
            <v-col md="2" sm="3" cols="12">
                <v-text-field
                :value="$options.filters.currency(total)"
                :label="$t('forms.total')"
                outlined
                dense
                hide-details
                readonly
                disabled
                ></v-text-field>
            </v-col>

            <span class="text-h6" v-if="$vuetify.breakpoint.smAndUp">+</span>
            <!-- tax -->
            <v-col md="2" sm="3" cols="12">
                <v-currency-text-field
                v-model.number="tax"
                :label="$t('forms.tax')"
                outlined
                type="number"
                min="0"
                :prefix="fileSettings.taxFixed ? null : '%'"
                dense
                hide-details
                :loading="loading || editLoading"
                :disabled="submitLoading || editLoading"
                ></v-currency-text-field>
            </v-col>

            <span class="text-h6" v-if="$vuetify.breakpoint.smAndUp">-</span>

            <!-- advance payment -->
            <v-col md="2" sm="3" cols="12">
                <v-currency-text-field
                    v-model.number="advancePayment"
                    outlined
                    type="number"
                    min="0"
                    dense
                    :required="false"
                    hide-details
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                    :label="$t('forms.advance-payment')"
                ></v-currency-text-field>
            </v-col>
            
            <span class="text-h6" v-if="$vuetify.breakpoint.smAndUp">=</span>

            <!-- final Price -->
            <v-col md="2" sm="3" cols="12">
                <v-text-field
                    :value="$options.filters.currency(balance)"
                    :label="$t('forms.final-price')"
                    outlined
                    dense
                    hide-details
                    readonly
                    disabled
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- more data -->
        <h2 class="text-body-1 primary--text mb-3">{{$t('sections.more-information')}}</h2>
        <v-row class="mb-5">
            <!-- Destination -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                v-model="destination"
                dense
                hide-details="auto"
                :label="$t('forms.destination')"
                ></v-text-field>
            </v-col>

            <!-- Shipping -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                v-model="shipping"
                dense
                hide-details="auto"
                :label="$t('forms.shipping')"
                ></v-text-field>
            </v-col>

            <!-- Shipping Marks -->
            <v-col md="4" sm="6" cols="12">
                <v-autocomplete-server
                    v-model="shippingMarks"
                    :label="$t('forms.shipping-marks')"
                    dense
                    hide-details="auto"
                    :input-field-enum="inputEnums.shippingMarks"
                ></v-autocomplete-server>
            </v-col>

            <!-- packing -->
            <v-col md="4" sm="6" cols="12">
                <v-autocomplete-server
                    v-model="packing"
                    dense
                    hide-details="auto"
                    :label="$t('forms.packing')"
                    :input-field-enum="inputEnums.packing"
                ></v-autocomplete-server>
            </v-col>

            <!-- Payment Terms -->
            <v-col md="4" sm="6" cols="12">
                <v-text-field
                v-model="paymentTerms"
                dense
                hide-details="auto"
                :label="$t('forms.payment-terms')"
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- actions -->
        <div class="d-flex justify-end" v-if="!viewerOnly">
            <v-btn :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
                {{$t('save')}}
            </v-btn>
            <v-btn @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
                {{$t('save-and-export-as-PDF')}}
            </v-btn>
            <v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
                <v-icon class="me-1">mdi-eye-outline</v-icon>
                {{$t('preview')}}
            </v-btn>
        </div>
        
        <!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`invoice:${$route.params.companyId}`); draftSnackbar = false"
        />
        
        <!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </v-form>
</template>

<script>
import Tooltip from '../../../components/Tooltip'
import rules from '../../../validation rules'
import { mapState, mapGetters } from 'vuex'
import { BASE_API_URL } from '../../../constants'
import fileTypes from '../../../enums/file-types';
import { setBuyerDataLabels, getBuyerName, orderItemsDueCostumer, getCostumerItems } from '../../../helpers'

export default {
    data: () => ({
        // helpers
        title: null,
        loading: false,
        submitLoading: false,
        editLoading: false,
        itemLoading: false,
        buyerDataLoading: false,
        previewLoading: false,
        rules,

        draft: null,
        isDraftUsed: null,
        draftSnackbar: false,

        snackbar: false,
        message: null,

        showAllItems: false,
        buyerItems: [],

        rowId: 0,
        toEdit: false,
        editEntryId: null,

        inputEnums: {
            consignee: 0,
            shippingMarks: 1,
            packing: 2,
        },

        /* DATA */
        // top data
        num: null,
        sequence: null,
        isManual: false,
        date: null,
        seller: null,
        buyer: null,
        currency: null,
        buyerData: null,

        // Bank account data
        showBankAccount: false,
        identifier: null,
        accountNumber: null,
        bankName: null,
        bankCountry: null,
        address: null,
        swiftCode: null,
        iban: null,
        branch: null,

        // items table data
        itemName: null,
        itemUnit: null,
        itemUnits: [],
        quantity: null,
        itemPrice: null,

        productEntries: [],

        tax: null,
        advancePayment: null,

        // more information
        paymentTerms: null,
        shippingMarks: null,
        shipping: null,
        destination: null,
        packing: null,
        referenceContract: null,
        consignee: null,
        intermediaryBank: null,
        multilineNotes: null,
    }),

    components:{
        Tooltip
    },

    watch: {
        draftFieldsWatcher(){
            if (!this.$route.query.id){
                this.$localDrafts.save(`invoice:${this.$route.params.companyId}`, {
                    date: this.date,
                    buyer: this.buyer,
                    currency: this.currency,
                    buyerData: this.buyerData,
                    showBankAccount: this.showBankAccount,
                    identifier: this.identifier,
                    accountNumber: this.accountNumber,
                    bankName: this.bankName,
                    bankCountry: this.bankCountry,
                    address: this.address,
                    swiftCode: this.swiftCode,
                    iban: this.iban,
                    branch: this.branch,
                    productEntries: this.productEntries,
                    advancePayment: this.advancePayment,
                    paymentTerms: this.paymentTerms,
                    shippingMarks: this.shippingMarks,
                    shipping: this.shipping,
                    destination: this.destination,
                    packing: this.packing,
                    referenceContract: this.referenceContract,
                    consignee: this.consignee,
                    intermediaryBank: this.intermediaryBank,
                    multilineNotes: this.multilineNotes,
                })
            }
        }
    },

    methods:{
        editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },

        // Bank account data functions
        changeAccountNumber(){
            var backAccount = this.companyBankAccounts.find(c => c.accountNumber === this.accountNumber);
            this.identifier = backAccount.identifier;
            this.bankName = backAccount.bankName;
            this.bankCountry = backAccount.country;
            this.address = backAccount.address;
            this.swiftCode = backAccount.swiftCode;
            this.iban = backAccount.iban;
            this.branch = backAccount.branch;
            this.intermediaryBank = backAccount.intermediaryBank;
        },
        changeAccountIdentifier(){
            var backAccount = this.companyBankAccounts.find(c => c.identifier === this.identifier);
            this.accountNumber = backAccount.accountNumber;
            this.bankName = backAccount.bankName;
            this.bankCountry = backAccount.country;
            this.address = backAccount.address;
            this.swiftCode = backAccount.swiftCode;
            this.iban = backAccount.iban;
            this.branch = backAccount.branch;
            this.intermediaryBank = backAccount.intermediaryBank;
        },

        // unit and table fuctions
        resetProductInputs(){
            this.itemName = null;
            this.itemUnit = null;
            this.quantity = null;
            this.itemPrice = null;
            this.$refs.formTable.resetValidation();
        },

        resetValidationOnblur(){
            if (this.productEntries.length > 0){
                setTimeout(() => {
                    this.$refs.formTable.resetValidation();
                }, 100)
            }
        },
        
        addProduct(){
            if (this.$refs.formTable.validate()){
                // add data
                this.productEntries.push({
                    id: this.rowId,
                    data: {
                        itemName: this.itemName,
                        itemUnit: this.itemUnit,
                        quantity: this.quantity,
                        itemPrice: this.itemPrice,
                    }
                });

                // reset inputs
                this.resetProductInputs();
                this.rowId++;
            }
        },

        fillToEdit(entryId){
            this.toEdit = true;
            this.editEntryId = entryId;

            // fill data
            var entryData = this.productEntries.find(c => c.id === entryId);
            this.itemName = entryData.data.itemName;
            this.itemUnit = entryData.data.itemUnit;
            this.itemPrice = entryData.data.itemPrice;
            this.quantity = entryData.data.quantity;

            this.fetchItem();
        },

        editProduct(){
            // edit data
            var entryData = this.productEntries.find(c => c.id === this.editEntryId);
            entryData.data.itemUnit = this.itemUnit;
            entryData.data.itemPrice = this.itemPrice;
            entryData.data.itemPrice = this.itemPrice;
            entryData.data.quantity = this.quantity;
            this.toEdit = false;

            // reset
            this.resetProductInputs();
        },

        removeProduct(index){
            this.productEntries.splice(index, 1);
            this.productEntries.forEach(product => {
                if (product.id > index){
                    product.id--;
                }
            });
            this.rowId--;
        },

        saveEntry(){
            if (this.editEntryId === null) this.addProduct();
            else this.editProduct();
            this.$refs.matreialInput.focus();
        },

        fetchItem(){
            if (this.itemName){
                this.itemLoading = true;
                this.$store.dispatch('items/fetchById', {
                    id: this.getItemByName(this.itemName).id
                })
                .then((data) => {
                    this.itemUnits = data.units;
                })
                .finally(() => {
                    // setting defualt unit and finding its price
                    var defaultUnit = this.itemUnits.find(c => c.isDefault);
                    if (defaultUnit){
                        this.itemUnit = this.getUnitById(defaultUnit.unitId).name;
                        this.findPrice();
                    }

                    this.itemLoading = false;
                })
            }
        },

        findPrice(){
            if (this.itemUnit){
                var selectedUnitId = this.getUnitByName(this.itemUnit).id;
                var unit = this.itemUnits.find(c => c.unitId === selectedUnitId);
                if (unit){
                    this.itemPrice = unit.price === 0 ? null : unit.price;
                }
            }
        },

        // resetAll
        resetAll(){
            this.rowId = 0;
            this.toEdit = false;
            this.editEntryId = null;

            // top data
            this.num = null;
            this.date = null;
            this.seller = null;
            this.buyer = null;
            this.currency = null;
            this.buyerData = null;

            // Bank account data
            this.showBankAccount = false;
            this.accountNumber = null;
            this.bankName = null;
            this.bankCountry = null;
            this.address = null;
            this.swiftCode = null;
            this.iban = null;
            this.branch = null;

            // items table data
            this.itemName = null;
            this.itemUnit = null;
            this.quantity = null;
            this.itemPrice = null;

            this.productEntries = [];

            this.tax = null;

            // more information
            this.paymentTerms = null;
            this.shippingMarks = null;
            this.consignee = null;
            this.advancePayment = null;
            this.intermediaryBank = null;
            this.multilineNotes = null;

            if (!this.num){
                this.$refs.form.resetValidation();
            }
        },

        // submit
        submit(exportPdf){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                // to create invoice

                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
                this.$store.dispatch('sequences/reserve', {
                    sequence: this.isManual ? this.num : this.sequence,
                    fileType: fileTypes.invoice,
                    isManual: this.isManual,
                    overrideCollision: true
                }).then(() => {
                    this.$store.dispatch(`invoices/${actionName}`, {
                        id: this.$route.query.id,
                        isManualSequence: this.isManual,
                        manualSequence: this.num,
                        sequence: this.sequence,
                        currency: this.currency,
                        date: this.date,
                        buyer: this.buyerData,
                        seller: this.seller,
                        paymentTerms: this.paymentTerms,
                        shippingMarks: this.shippingMarks,
                        shipping: this.shipping,
                        destination: this.destination,
                        packing: this.packing,
                        referenceContract: this.referenceContract,
                        consignee: this.consignee,
                        intermediaryBank: this.intermediaryBank,
                        multilineNotes: this.multilineNotes,
                        showBankAccount: this.showBankAccount,
                        bankName: this.bankName,
                        bankCountry: this.bankCountry,
                        address: this.address,
                        accountNumber: this.accountNumber,
                        swiftCode: this.swiftCode,
                        iban: this.iban,
                        branch: this.branch,
                        tax: this.tax || 0,
                        advancePayment: this.advancePayment || 0,
                        transactions: this.productEntries.map(c => c.data)
                    })
                    .then((id) => {
                        this.message = this.$t('messages.proforma-invoice-has-been-successfully-saved');
                        if (exportPdf) {
                            this.report(id);
                        }
                        if (this.isDraftUsed){
                            this.$localDrafts.remove(`invoice:${this.$route.params.companyId}`);
                        }
                        this.resetAll();
                        this.$router.push({ name: 'invoices' })
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.submitLoading = false;
                        this.snackbar = true;
                    })
                }).catch((e) => {
                    this.message = e.response.data.message;
                });
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
        },

        preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/invoices', {
                    companyId: this.$route.params.companyId,
                    isManualSequence: this.isManual,
                    manualSequence: this.num,
                    sequence: this.sequence,
                    currency: this.currency,
                    date: this.date,
                    buyer: this.buyerData,
                    seller: this.seller,
                    paymentTerms: this.paymentTerms,
                    shippingMarks: this.shippingMarks,
                    shipping: this.shipping,
                    destination: this.destination,
                    packing: this.packing,
                    referenceContract: this.referenceContract,
                    consignee: this.consignee,
                    intermediaryBank: this.intermediaryBank,
                    multilineNotes: this.multilineNotes,
                    showBankAccount: this.showBankAccount,
                    bankName: this.bankName,
                    bankCountry: this.bankCountry,
                    address: this.address,
                    accountNumber: this.accountNumber,
                    swiftCode: this.swiftCode,
                    iban: this.iban,
                    branch: this.branch,
                    tax: this.tax || 0,
                    advancePayment: this.advancePayment || 0,
                    transactions: this.productEntries.map(c => c.data)
                })
                .then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
            }
        },

        // fill all field if edit
        fillAllFieldsToEdit(data, fillSequence = true){
            if (fillSequence) { this.num = data.sequence; }
            this.currency = data.currency;
            this.date = window.moment(data.date).format('YYYY-MM-DD');
            this.buyer = getBuyerName(data.buyer);
            this.buyerData = data.buyer;
            this.paymentTerms = data.paymentTerms;
            this.shippingMarks = data.shippingMarks;
            this.shipping = data.shipping;
            this.destination = data.destination;
            this.packing = data.packing;
            this.referenceContract = data.referenceContract;
            this.consignee = data.consignee;
            this.intermediaryBank = data.intermediaryBank;
            this.multilineNotes = data.multilineNotes;
            this.showBankAccount = data.showBankAccount;
            this.bankName = data.bankName;
            this.bankCountry = data.bankCountry;
            this.address = data.address;
            this.accountNumber = data.accountNumber;
            this.swiftCode = data.swiftCode;
            this.iban = data.iban;
            this.branch = data.branch;
            this.tax = data.tax !== 0 ? data.tax : null;
            this.advancePayment = data.advancePayment !== 0 ? data.advancePayment : null;
            data.transactions.forEach((transaction) => {
                this.productEntries.push({
                    id: this.rowId,
                    data: {
                        itemName: transaction.itemName,
                        itemUnit: transaction.itemUnit,
                        quantity: transaction.quantity,
                        itemPrice: transaction.itemPrice,
                    }
                });
                this.rowId++;
            })
        },

        // fetch Data
        fetchData(){
            return Promise.all([
                this.$store.dispatch('companies/fetchById', { id: this.$route.params.companyId }),
                this.$store.dispatch('customers/fetchAll'),
                this.$store.dispatch('items/fetchAll'),
                this.$store.dispatch('units/fetchAll'),
            ])
        },

        // fetch sequence
        fetchSequence() {
            return this.$store.dispatch('sequences/fetch', { fileType: fileTypes.invoice })
                .then(response => {
                    this.num = response.value;
                    this.sequence = response;
                })
        },

        // find customer data
        findCustomserData(){
            let buyer = this.getCustomerByName(this.buyer);
            this.showAllItems = false;
            if (buyer){
                this.buyerDataLoading = true;
                this.$store.dispatch('customers/fetchById', { id: buyer.id })
                .then((data) => {
                    buyer = data;
                    this.buyerItems = data.items;
                    this.buyerData = setBuyerDataLabels(buyer);
                })
                .finally(() => {
                    this.buyerDataLoading = false;
                })
            }
            else {
                this.buyerData = `${this.buyer || '-'}\n-\n-\n-`;
            }
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/invoices/${id}`);
        },
        
        // fill data from draft
        fillDataFromDraft(){
            this.date = this.draft.date;
            this.buyer = this.draft.buyer;
            this.currency = this.draft.currency;
            this.buyerData = this.draft.buyerData;
            this.showBankAccount = this.draft.showBankAccount;
            this.identifier = this.draft.identifier;
            this.accountNumber = this.draft.accountNumber;
            this.bankName = this.draft.bankName;
            this.bankCountry = this.draft.bankCountry;
            this.address = this.draft.address;
            this.swiftCode = this.draft.swiftCode;
            this.iban = this.draft.iban;
            this.branch = this.draft.branch;
            this.advancePayment = this.draft.advancePayment;
            this.paymentTerms = this.draft.paymentTerms;
            this.shippingMarks = this.draft.shippingMarks;
            this.shipping = this.draft.shipping;
            this.destination = this.draft.destination;
            this.packing = this.draft.packing;
            this.referenceContract = this.draft.referenceContract;
            this.consignee = this.draft.consignee;
            this.intermediaryBank = this.draft.intermediaryBank;
            this.multilineNotes = this.draft.multilineNotes;
            this.productEntries = this.draft.productEntries;
            this.rowId = this.draft.productEntries.length;
        }
    },
    
    computed:{
        ...mapState({
            company: state => state.companies.company,
            customers: state => state.customers.customers,
            items: state => state.items.items,
            units: state => state.units.units,
            fileSettings: state => state.fileSettings.fileSettings,
            isAdmin: state => state.users.currentUser.isAdmin,
            viewerOnly: state => state.users.viewerOnly,
        }),

        ...mapGetters({
            getItemByName: 'items/getItemByName',
            getUnitByName: 'units/getUnitByName',
            getUnitById: 'units/getUnitById',
            getCompanyById: 'companies/getCompanyById',
            getCustomerByName: 'customers/getCustomerByName',
        }),

        dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

        headers() {
            const headers = [
                { text: this.$t('headers.item'), value:'data.itemName', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.unit'), value:'data.itemUnit', align:'center' },
                { text: this.$t('headers.quantity'), value:'data.quantity', align:'center' },
                { text: this.$t('headers.price'), value:'data.itemPrice', align:'center' },
                { text: this.$t('headers.total'), value:'totalPrice', align:'center' },
                { text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
        },

        companyBankAccounts(){
            return this.company.bankAccounts;
        },

        companyBankIdentifiers(){
            return this.company.bankAccounts.filter(c => c.identifier);
        },

        menuItems() {
            if (this.buyer && this.showAllItems) {
                return orderItemsDueCostumer(this.buyerItems)
            } else if (this.buyer) {
                const costomerItems = getCostumerItems(this.buyerItems)
                return costomerItems.length > 0 ? costomerItems : this.items;
            } else {
                return this.items
            }
        },

        total(){
            var total = 0;
            this.productEntries.forEach(entry => {
                total += (entry.data.itemPrice * entry.data.quantity)
            })
            return total;
        },

        balance(){
            if (this.fileSettings.taxFixed){
                return this.total + this.tax - this.advancePayment;
            }
            else {
                return this.total + (this.total * (this.tax / 100)) - this.advancePayment;
            }
        },

        draftFieldsWatcher(){
            return (`${this.date} | ${this.buyer} | ${this.currency} | ${this.buyerData} | ${this.showBankAccount} | ${this.identifier} | ${this.accountNumber} | ${this.bankName} | ${this.bankCountry} | ${this.address} | ${this.swiftCode} | ${this.iban} | ${this.branch} | ${this.productEntries} | ${this.advancePayment} | ${this.paymentTerms} | ${this.shippingMarks} | ${this.shipping} | ${this.destination} | ${this.packing} | ${this.referenceContract} | ${this.consignee} | ${this.intermediaryBank} | ${this.multilineNotes} | ${this.toEdit}
            `)
        }
    },

    mounted(){
        this.loading = true;
        this.seller = this.getCompanyById(this.$route.params.companyId).name;
        if (this.$route.query.id){
            this.editLoading = true;

            if (this.$route.query.viewer) {
                this.title = this.$t('title.preview') + this.$t('Invoices.proforma-invoice');
            }
            else if (this.$route.query.duplicate) {
                this.title = this.$t('title.duplicate') + this.$t('Invoices.proforma-invoice');
            }
            else {
                this.title = this.$t('title.edit') + this.$t('Invoices.proforma-invoice');
            }

            Promise.all([
                this.$store.dispatch('invoices/fetchById', { id: this.$route.query.id })
                .then((data) => {
                    // if it duplicate mode don't fill sequence
                    if (this.$route.query.duplicate) {
                        this.fillAllFieldsToEdit(data, false);
                    }
                    else {
                        this.fillAllFieldsToEdit(data);
                    }
                }),
                this.fetchData()
                .then(() => {
                    // if it duplicate mode create new sequence and fill it
                    if (this.$route.query.duplicate) {
                        this.fetchSequence();
                    }
                }),
            ])
            .finally(() => {
                this.loading = false;
                this.editLoading = false;
            });
        }
        else {
            this.title = this.$t('title.add') + this.$t('Invoices.proforma-invoice');
            this.fetchData()
            .finally(() => {
                this.fetchSequence()
                    .finally(() => {
                        this.tax = this.fileSettings.taxAmount !== 0 ? this.fileSettings.taxAmount : null;
                        this.loading = false;
                    });
                // get draft and show snackbar
                this.draft = this.$localDrafts.get(`invoice:${this.$route.params.companyId}`);
                if (this.draft){
                    setTimeout(() => {
                        this.draftSnackbar = true;
                    }, 500)
                }
            });
        }
    }
}
</script>

<style lang="scss">
#invoice{
    .v-label--is-disabled, input[disabled="disabled"]{
        color: rgba(43, 40, 40, 0.65);
    }
    input[disabled="disabled"]{
        font-weight: 700;
    }
}
</style>