import * as types from '../mutation-types'

export default {
    namespaced: true,
    
    state: {
        containers: []
    },

    actions: {
        fetchAll({ commit }){
            return window.axios.get('/companyFileSettings/containers')
            .then((response) => {
                commit(types.STORE_CONTAINERS, response.data);
                return response.data;
            })
        },

        create({ commit }, { file }){
            const formData = new FormData();
            formData.append('file', file);
            return window.axios.post('/companyFileSettings/containers', formData);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/companyFileSettings/containers/${id}`)
        },
    },

    mutations: {
        [types.STORE_CONTAINERS](state, containers) {
            state.containers = containers
        }
    },

    getters: {
        getContainerById: state => id => state.containers.find(c => c.id === id)
    }
}