<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 primary--text">{{$t('Invoices.proforma-invoices')}}</h1>
            
            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="filter">
                <span class="text-body-1">
                    {{$t('are-you-sure-you-want-to')}} 
                    <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                    {{$t('Invoices.the-proforma-invoice')}}
                </span>
            </delete-dialog>

            <!-- Add an Proforma Invoice -->
            <v-btn
              v-if="!viewerOnly"
              text
              small
              class="lightPrimary white--text"
              :to="{
                name:'invoice',
                params:{companyId: $route.params.companyId},
              }"
            >
                {{$t('Invoices.add-a-proforma-invoice')}}
            </v-btn>
        </div>

        <!-- filter -->
        <v-form class="my-2" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1">
                    {{$t('filter')}}
                </v-col>

                <!-- num -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-text-field
                        v-model="num"
                        :label="$t('headers.number')"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- Buyer -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-combobox
                        v-model="buyer"
                        :label="$t('headers.buyer')"
                        :items="customers"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-combobox>
                </v-col>

                <!-- Seller -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-combobox
                        v-model="seller"
                        :label="$t('headers.seller')"
                        :items="customers"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-combobox>
                </v-col>

                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date ? moment(date) : null"
                                :label="$t('headers.date')"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate.save(date)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        width="80"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        icon
                        small
                        @click="fetchInvoices();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : invoices"
          :items-per-page="20"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-options': [10, 20, 40, 100, -1],
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          :loading="loading"
          disable-sort
          dense
          class="elevation-2"
        >
            <!-- sequence -->
            <template v-slot:item.sequence="{item}">
                {{item.sequence ? item.sequence : 'N/A'}}
            </template>

            <!-- seller -->
            <template v-slot:item.seller>
                {{getCompanyById($route.params.companyId).name}}
            </template>

            <!-- buyer -->
            <template v-slot:item.buyer="{item}">
                {{item.buyer ? getBuyerName(item.buyer) : 'N/A'}}
            </template>

            <!-- tax -->
            <template v-slot:item.tax="{item}">
                {{item.tax !== 0 ? item.tax : '-'}}
            </template>

            <!-- total -->
            <template v-slot:item.total="{item}">
                {{ item.total | currency }}
            </template>

            <!-- total with tax -->
            <template v-slot:item.totalWithTax="{item}">
                {{ item.totalWithTax | currency }}
            </template>

            <!-- date -->
            <template v-slot:item.date="{item}">
                {{moment(item.date)}}
            </template>

            <!-- payment terms -->
            <template v-slot:item.paymentTerms="{item}">
                {{
                    item.paymentTerms
                    ? item.paymentTerms.length > 100 ? item.paymentTerms.slice(0, 100) + '...' : item.paymentTerms
                    : 'N/A'
                }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('tooltips.download-as-pdf')">
                        <v-btn text small @click="report(item.id)">
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <template v-if="viewerOnly">
                        <tooltip :text="$t('tooltips.preview')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'invoice',
                                    params:{companyId: $route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>

                    <template v-else>
                        <tooltip :text="$t('tooltips.duplicate')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'invoice',
                                    params:{companyId: $route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-content-duplicate
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip :text="$t('tooltips.edit')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'invoice',
                                    params:{companyId: $route.params.companyId},
                                    query:{id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip :text="$t('tooltips.delete')">
                            <v-btn @click="$refs.DeleteDialog.activeDialog(item.id, 'invoices')" text small>
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import { mapState, mapGetters } from 'vuex';
import { BASE_API_URL } from '../../constants';
import { getBuyerName } from '../../helpers'

export default {
    data: () => ({
        // filter data
        num: null,
        buyer: null,
        seller: null,
        date: null,

        // helpers
        loading: true,
        filterLoading: true,
        isFiltered: false,
    }),

    methods:{
        // filter funciton
        clearFilter(){
            this.num = null;
            this.buyer = null;
            this.seller = null;
            this.date = null;

            this.isFiltered = false;
        },
        
        filter(){
            this.loading = true;
            this.$store.dispatch('invoices/fetchAll', {
                sequence: this.num,
                buyer: this.buyer,
                seller: this.seller,
                date: this.date
            })
            .finally(() => {
                this.loading = false;
            })
        },

        fetchInvoices(){
            this.clearFilter();
            this.filter();
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/invoices/${id}`);
        },

        // table funcitons
        moment(date){
            return window.moment(date).format(this.fileSettings.dateFormat ? this.fileSettings.dateFormat.toUpperCase() : 'DD-MM-YYYY');
        },
        getBuyerName
    },

    computed:{
        isAllFieldsEmpty(){
            return this.num === null && this.buyer === null && this.seller === null && this.date === null
        },

        ...mapState({
            customers: state => state.customers.customers,
            invoices: state => state.invoices.invoices,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),

        headers() {
            const headers = [
                { text:this.$t('headers.number'), value:'sequence', class:'primary--text', cellClass:'primary--text', width: 100 },
                { text:this.$t('headers.date'), value:'date', class: 'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                // { text:this.$t('headers.seller'), value:'seller', width: 120 },
                { text:this.$t('headers.buyer'), value:'buyer', width: 220 },
                // { text:this.$t('headers.tax'), value:'tax' },
                { text:this.$t('headers.total'), value:'total' },
                { text:this.$t('headers.balance'), value:'totalWithTax' },
                { text:this.$t('headers.payment-terms'), value:'paymentTerms', width: 180 },
                { text:this.$t('headers.actions'), value:'actions', width: 150 },
            ]

            // set shared props
            for (let i = 0; i < headers.length; i++) {
                headers[i].align = 'center';
                headers[i].class = headers[i].class ? `${headers[i].class} px-2` : 'px-2';
                headers[i].cellClass = headers[i].cellClass ? `${headers[i].cellClass} px-2` : 'px-2';
            }

            return headers;
        },
    },

    mounted(){
        Promise.all([
            this.$store.dispatch('customers/fetchAll'),
            this.$store.dispatch('invoices/fetchAll', { sequence: null, buyer: null, seller: null, date: null })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    components:{
        DeleteDialog,
        Tooltip
    }
}
</script>

<style>

</style>