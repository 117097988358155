<template>
    <div>
        <v-dialog
          v-model="dialog"
          width="700"
          persistent
          ref="dialog"
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text justify-center py-2">
                        <v-spacer/>
                        <span class="text-h6">{{title}}</span>
                        <v-spacer/>
                        <v-btn text small light fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text class="pt-2 pb-1">
                        <v-row class="ma-0">
                            <!-- material name -->
                            <v-col sm='6' cols="12">
                                <v-text-field
                                	v-model="name"
                                	:label="$t('Materials.dialog.items-of-materials-name')"
                                	dense
                                	hide-details="auto"
                                	:loading="dataLoading"
                                	:disabled="dataLoading"
                                	:rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- descreption -->
                            <v-col sm='6' cols="12">
                                <v-text-field
                                	v-model="descreption"
                                	:label="$t('Materials.dialog.description')"
                                    dense
                                	hide-details="auto"
                                	:loading="dataLoading"
                                	:disabled="dataLoading"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <h3 class="primary--text text-body-1 ms-3 mb-2">
                            {{$t('Materials.dialog.packaging-units-for-item')}}
                        </h3>

                        <!-- form unit entry -->
                        <v-form
                            @submit.prevent="saveEntry"
                            ref="formTable"
                        >
                            <v-row class="ma-0">
                                <!-- unit -->
                                <v-col sm="3" cols="6">
                                    <v-autocomplete
                                        v-model="unitId"
                                        :items="selectedUnites"
                                        item-text="name"
                                        item-value="id"
                                        ref="unitField"
                                        :return-object="false"
                                        :menu-props="{contentClass:'text-center'}"
                                        :label="$t('Materials.dialog.unit')"
                                        :rules="rules.required"
                                        :loading="unitsLoading"
                                        dense
                                        hide-details="auto"
                                        :disabled="unitsLoading"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- price -->
                                <v-col sm="3" cols="6">
                                    <v-currency-text-field
                                        v-model.number="price"
                                        :label="$t('Materials.dialog.price')"
                                        dense
                                        hide-details="auto"
                                        :required="false"
                                    ></v-currency-text-field>
                                </v-col>

                                <!-- default unit -->
                                <v-col sm="3">
                                    <v-checkbox
                                    	v-model="isDefault"
                                    	:label="$t('Materials.dialog.default-unit')"
                                        hide-details="auto"
                                        class="ma-0 text-start"
                                    ></v-checkbox>
                                </v-col>
                                
                                <!-- add button -->
                                <v-btn
                                    text
                                    small
                                    class="align-self-center"
                                    type="submit"
                                >
                                    <v-icon>
                                        {{ 
                                            toEdit ? 'mdi-checkbox-marked-circle-outline' : 'mdi-plus-circle-outline'
                                        }}
                                    </v-icon>
                                </v-btn>

                                <!-- reset button -->
                                <v-btn
                                    @click="restInputs"
                                    text
                                    small
                                    class="align-self-center"
                                    elevation="1"
                                >
                                    {{$t('reset')}}
                                </v-btn>
                            </v-row>
                        </v-form>

                        <!-- table -->
                        <v-data-table
                            :headers="headers"
                            :items="dataLoading? []: unitsEntries"
                            hide-default-footer
                            disable-sort
                            :no-data-text="$t('no-data-text')"
                            :no-results-text="$t('no-results-text')"
                            :loading-text="$t('loading-text')"
                            :footer-props="{ 
                                'items-per-page-all-text': $t('items-per-page-all-text'),
                                'items-per-page-text': $t('items-per-page-text'),
                            }"
                            :loading="dataLoading"
                            dense
                            class="elevation-2 mx-3"
                        >
                            <!-- unit -->
                            <template v-slot:item.unit="{item}">
                                {{ units.find(c => c.id === item.unitId).name }}
                            </template>

                            <!-- price -->
                            <template v-slot:item.price="{item}">
                                {{ item.price || '-' }}
                            </template>

                            <!-- isDefault -->
                            <template v-slot:item.isDefault="{item}">
                                <v-icon>
                                    {{ item.isDefault ? 'mdi-check-circle-outline' : '' }}
                                </v-icon>
                            </template>
                            
                            <!-- actions -->
                            <template v-slot:item.actions="{item}">
                                <tooltip :text="$t('tooltips.edit')">
                                    <v-btn text small @click="fillToEdit(item.id)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </tooltip>
                                
                                <tooltip :text="$t('tooltips.delete')" v-if="!toEdit">
                                    <v-btn text small @click="removeUnit(item.id)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            :loading="loading"
                            :disabled="loading"
                            type="submit"
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            @click="dialog = false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import rules from '../validation rules'
import Tooltip from '../components/Tooltip'
import { mapState, mapGetters } from 'vuex';
export default {
    data: () => ({
        // helpers
        id: null,
        title: null,
        dialog: false,
        snackbar: false,
        message: null,
        
        loading: false,
        dataLoading: false,
        unitsLoading: true,
        
        rowId: 0,
        toEdit: false,
        editId: null,

        selectedUnites:[],

        rules,

        // data
        name: null,
        descreption: null,
        unitId: null,
        price: null,
        isDefault: false,
        unitsEntries: [],
    }),

    components:{
        Tooltip
    },

    methods:{
        resetData(){
            if (this.name) {
                this.$refs.form.resetValidation();
            }
            this.name = null;
            this.descreption = null;
            this.unitId = null;
            this.price = null;
            this.isDefault = false;
            this.unitsEntries = [];

            this.selectedUnites.forEach(c => {
                c.disabled = false;
            });
            
            this.id = null;
            this.rowId = 0;
            this.toEdit = false;
        },

        // unit and table fuctions
        restInputs(){
            this.unitId = null;
            this.price = null;
            this.isDefault = false;
            this.$refs.formTable.resetValidation();
        },

        resetUnitInputs(){
            this.restInputs();

            // reset helpers
            this.toEdit = false;
            this.editId = null;

            this.$refs.formTable.resetValidation();
        },
        
        setOtherToNonDfault(){
            this.unitsEntries.forEach((entry) => {
                if (entry.isDefault){
                    entry.isDefault = false;
                }
            });
        },

        needDisableSelectedUnits(unitId, state){
            // active or disable selected units.
            this.selectedUnites.find(c => c.id === unitId).disabled = state;
        },

        addUnit(){
            if (this.$refs.formTable.validate()){
                // if the new one is default set other to non-default
                if (this.isDefault) {
                    this.setOtherToNonDfault();
                }

                // add data
                this.unitsEntries.push({
                    id: this.rowId,
                    unitId: this.unitId,
                    price: (this.price || 0),
                    isDefault: this.isDefault
                });

                // disable selected units.
                this.needDisableSelectedUnits(this.unitId, true);

                // reset inputs
                this.resetUnitInputs();
                this.rowId++;
            }
        },

        fillToEdit(entryId){
            this.toEdit = true;
            this.editId = entryId;

            // fill data
            var entryData = this.unitsEntries.find(c => c.id === entryId);
            this.unitId = entryData.unitId;
            this.price = entryData.price;
            this.isDefault = entryData.isDefault;

            // active selected units.
            this.needDisableSelectedUnits(this.unitId, false);
        },

        editUnit(){
            // if the new one is default set other to non-default
            if (this.isDefault) {
                this.setOtherToNonDfault();
            }
                
            // edit data
            var entryData = this.unitsEntries.find(c => c.id === this.editId);
            entryData.unitId = this.unitId;
            entryData.price = (this.price || 0);
            entryData.isDefault = this.isDefault;

            // disable selected units.
            this.needDisableSelectedUnits(this.unitId, true);

            // reset
            this.resetUnitInputs();
        },

        removeUnit(entryId){
            // active removed unit
            var unitId = this.unitsEntries.find(c => c.id === entryId).unitId;
            this.needDisableSelectedUnits(unitId, false);

            // remove unit from entries
            this.unitsEntries.splice(entryId, 1);
            this.unitsEntries.forEach((entry) => {
                if (entry.id > entryId){
                    entry.id--;
                }
            });
            this.rowId--;
        },

        saveEntry(){
            if (this.editId === null) this.addUnit();
            else this.editUnit();
        },

        // dialog fuctions
        fillData(materialId){
            this.dataLoading = true;
            this.$store.dispatch('items/fetchById', { id: materialId })
            .then((item) => {
                this.name = item.name;
                this.descreption = item.description;
                item.units.forEach((unit) => {
                    this.unitsEntries.push({
                        id: this.rowId++,
                        unitId: unit.unitId,
                        price: unit.price,
                        isDefault: unit.isDefault
                    });

                    // disable selected units.
                    this.needDisableSelectedUnits(unit.unitId, true);
                })
            })
            .finally(() => {
                this.dataLoading = false;
            })
        },
        
        addMaterial(){
            this.resetData();
            this.title = this.$t('Materials.dialog.add-an-item');
            this.dialog = true;
        },

        editMaterial(materialId){
            this.resetData();
            this.id = materialId;
            this.title = this.$t('Materials.dialog.edit-an-existing-item');
            this.fillData(materialId);
            this.dialog = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                var units = [];
                this.unitsEntries.forEach(entry => {
                    units.push({
                        unitId: entry.unitId,
                        price: entry.price,
                        isDefault: entry.isDefault
                    })
                })
                
                // create item
                if (!this.id){
                    this.$store.dispatch('items/create', {
                        name: this.name,
                        description: this.descreption,
                        units
                    })
                    .then(() => {
                        this.message = this.$t('messages.item-has-been-successfully-added');
                        this.$emit('on-save');
                        this.dialog = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.snackbar = true;
                    })
                }

                // update item
                else {
                    this.$store.dispatch('items/update', {
                        id: this.id,
                        name: this.name,
                        description: this.descreption,
                        units
                    })
                    .then(() => {
                        this.message = this.$t('messages.items-has-been-successfully-edited');
                        this.$emit('on-save');
                        this.dialog = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.snackbar = true;
                    })
                }
            }
        }
    },

    watch: {
        'dialog' () {
            if (this.$refs.unitField) {
                this.$refs.unitField.resetValidation();
            }
        }
    },

    computed:{
        ...mapState({
            units: state => state.units.units
        }),

        ...mapGetters({
            getItemById: 'items/getItemById'
        }),

        headers() {
            return [
                { text:this.$t('headers.unit'), value:'unit', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text:this.$t('headers.price'), value:'price', align:'center' },
                { text:this.$t('headers.default-unit'), value:'isDefault', align:'center' },
                { text:this.$t('headers.actions'), value:'actions', align:'center' },
            ]
        },
    },
    
    mounted(){
        this.unitsLoading = true;
        this.$store.dispatch('units/fetchAll')
        .then((data) => {
            this.selectedUnites = this.units;
        })
        .finally(() => {
            this.unitsLoading = false;
        })
    }
}
</script>

<style>

</style>