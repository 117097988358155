<template>
    <div>
        <v-dialog
          v-model="dialog"
          width="400"
          ref="dialog"
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text justify-center py-2">
                        <v-spacer/>
                        <span class="text-h6">{{title}}</span>
                        <v-spacer/>
                        <v-btn text small light fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text class="py-0">
                        <v-container>
                            <v-row>
                                <!-- unit name -->
                                <v-col cols="12">
                                    <v-text-field
                                      v-model="name"
                                      :label="$t('PackagingUnits.dialog.unit-name')"
                                      :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            :loading="loading"
                            :disabled="loading"
                            type="submit"
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            @click="dialog = false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import rules from '../validation rules'
import { mapState, mapGetters } from 'vuex';
export default {
    data: () => ({
        // helpers
        id: null,
        dialog: false,
        snackbar: false,
        message: null,
        title: null,
        loading: false,
        rules,

        // data
        name: null
    }),

    methods:{
        restData(){
            if (this.name){
                this.$refs.form.resetValidation();
            }
            this.id = null;
            this.name = null;
        },

        addUnit(){
            this.restData();
            this.title = this.$t('PackagingUnits.dialog.add-a-unit');
            this.dialog = true;
        },

        editUnit(unitId){
            this.id = unitId;
            this.name = this.getUnitById(unitId).name;
            this.title = this.$t('PackagingUnits.dialog.edit-an-existing-unit')
            this.dialog = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                
                // create unit
                if (!this.id){
                    this.$store.dispatch('units/create', { name: this.name })
                    .then(() => {
                        this.message = this.$t('messages.unit-has-been-successfully-added');
                        this.$emit('on-save');
                        this.dialog = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.snackbar = true;
                    })
                }

                // update unit
                else {
                    this.$store.dispatch('units/update', { id: this.id, name: this.name })
                    .then(() => {
                        this.message = this.$t('messages.unit-has-been-successfully-edited');
                        this.$emit('on-save');
                        this.dialog = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.snackbar = true;
                    })
                }
            }
        }
    },

    computed:{
        ...mapState({
            units: state => state.units.units
        }),

        ...mapGetters({
            getUnitById: 'units/getUnitById'
        })
    }
}
</script>

<style>

</style>