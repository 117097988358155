var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-5"},[_c('h1',{staticClass:"text-h5 primary--text"},[_vm._v(_vm._s(_vm.$t('Materials.items-and-materials')))]),_c('material-dialog',{ref:"MaterialDialog",on:{"on-save":_vm.fetchItems}}),_c('delete-dialog',{ref:"DeleteDialog",on:{"on-delete":_vm.fetchItems}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('are-you-sure-you-want-to'))+" "),_c('span',{staticClass:"text-uppercase error--text"},[_vm._v(_vm._s(_vm.$t('delete')))]),_vm._v(" "+_vm._s(_vm.$t('Materials.the-item'))+" ")])]),_c('div',[_c('v-btn',{staticClass:"lightPrimary white--text me-2",attrs:{"text":"","small":"","to":{
                name:'packaging-units',
                params:{companyId: _vm.$route.params.companyId},
              }}},[_vm._v(" "+_vm._s(_vm.$t('Materials.manage-packaging-units'))+" ")]),_c('v-btn',{staticClass:"lightPrimary white--text",attrs:{"text":"","small":""},on:{"click":_vm.addMaterial}},[_vm._v(" "+_vm._s(_vm.$t('Materials.add-an-item'))+" ")])],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.items,"items-per-page":20,"no-data-text":_vm.$t('no-data-text'),"no-results-text":_vm.$t('no-results-text'),"loading-text":_vm.$t('loading-text'),"footer-props":{
        'items-per-page-options': [10, 20, 40, 100, -1],
        'items-per-page-all-text': _vm.$t('items-per-page-all-text'),
        'items-per-page-text': _vm.$t('items-per-page-text'),
        showCurrentPage:true
      },"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref ){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.description ? item.description.length > 100 ? item.description.slice(0, 100) + '...' : item.description : 'N/A')+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.edit')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.MaterialDialog.editMaterial(item.id)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.delete')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.DeleteDialog.activeDialog(item.id, 'items')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1)]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }