export default {
    companies: 'الشركات',
    
    'add-a-company': 'إضافة شركة',
    'edit-a-company': 'تعديل شركة',

    'the-company': 'الشركة؟',

    'company-dialog': {
        'company-name': 'اسم الشركة',
        'tax-number': 'الرقم الضريبي',
        'manager-name': 'اسم المدير',
        'financial-manager-name': 'اسم المدير المالي',
        'company-logo': 'شعار الشركة',
        'digital-signature': 'التوقيع الالكتروني',
        'company-files': 'ملفات الشركة',
        'files': 'الملفات',
        'select-all': 'تحديد الكل',
        'currencies': 'العملات',
        'currency': 'العملة',
        'phone-numbers': 'أرقام الهاتف',
        'phone-number': 'رقم الهاتف',
        'addresses': 'العناوين',
        'address': 'العنوان',
    },

    'financial-account-dailog': {
        'financial-accounts': 'الحسابات المالية',
        'account-details': 'تفاصيل الحساب',
        'the-account': 'الحساب؟',
    }
}