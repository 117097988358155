<template>
    <v-form @submit.prevent="submit(false)" ref="form" id="contract" :disabled="viewerOnly">
        <div class="d-flex justify-space-between align-center mb-3">
            <v-row class="align-center ma-0">
                <!-- title -->
                <v-col sm="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
                    <h1 class="text-h5 primary--text">{{title}}</h1>

                    <!-- back button -->
                    <v-btn
                        v-if="$vuetify.breakpoint.xs"
                        :to="{ name: 'packing-lists' }"
                        :disabled="submitLoading"
                        elevation="1"
                        small
                        class="px-6"
                    >
                        {{$t('back')}}
                    </v-btn>
                </v-col>

                <!-- date -->
                <v-col sm="4" cols="12">
                    <bee-date-input
                        v-model="date"
                        changeable-with-arrows
                        :label="$t('forms.date')"
                        prepend-icon="mdi-calendar"
                        :input-format-order="dateFormat"
                        :user-menu-picker="true"
                        dense
                        hide-details="auto"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        :rules="rules.required"
                    ></bee-date-input>
                </v-col>
            </v-row>

            <!-- back button -->
            <v-btn
            	v-if="$vuetify.breakpoint.smAndUp"
            	:to="{ name: 'packing-lists' }"
            	:disabled="submitLoading"
            	elevation="1"
            	small
            	class="px-6"
            >
                {{$t('back')}}
            </v-btn>
        </div>

        <!-- BASIC INFORMATION -->
        <h2 class="text-body-1 primary--text mb-3">{{$t('sections.basic-information')}}</h2>

        <!-- top data -->
        <v-row class="justify-center mb-3">
            <v-col md="6" cols="12">
                <v-row>
                    <!-- REF. CONTARCT No. -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="refContractNo"
                            :label="$t('forms.ref-contract-no')"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="loading || submitLoading || editLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- REF. INVOICE No. -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="refInvoiceNo"
                            :label="$t('forms.ref-invoice-no')"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="loading || submitLoading || editLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- description of goods -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="descriptionOfGoods"
                            :label="$t('forms.goods-description')"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="loading || submitLoading || editLoading"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>

            <!-- buyer -->
            <v-col md="6" cols="12">
                <v-row>
                    <!-- Buyer -->
                    <v-col cols="12">
                        <v-combobox
                            v-model="buyer"
                            :label="$t('forms.buyer')"
                            :items="customers"
                            item-text="name"
                            item-value="name"
                            :return-object="false"
                            dense
                            hide-details="auto"
                            :loading="loading"
                            :disabled="loading || submitLoading"
                            @change="findCustomserData"
                            :rules="rules.required"
                        ></v-combobox>
                    </v-col>

                    <!-- buyer data -->
                    <v-col cols="12" class="py-0">
                        <v-textarea
                            v-model="buyerData"
                            :label="$t('forms.buyer-data')"
                            rows="4"
                            :hint="$t('forms.buyer-data-will-show-if-you-select-the-buyer')"
                            persistent-hint
                            no-resize
                            outlined
                            dense
                            hide-details="auto"
                            :loading="editLoading || buyerDataLoading"
                            :disabled="loading || submitLoading || buyerDataLoading"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- ITEMS -->
        <h2 class="text-body-1 primary--text mb-3">{{$t('sections.items')}}</h2>

        <!-- table data -->
        <v-form v-if="!viewerOnly" @submit.prevent="saveEntry" ref="formTable" class="mb-3">
            <v-row class="justify-md-end">
                <!-- quantity -->
                <v-col md="2" sm="4" cols="12">
                    <v-currency-text-field
                        v-model.number="quantity"
                        type="number"
                        min='0'
                        :label="$t('headers.quantity')"
                        :disabled="loading || submitLoading"
                        dense
                        hide-details="auto"
                        ref="quantity"
                        :rules="rules.requiredAsNumber"
                        @blur="resetValidationOnblur"
                    ></v-currency-text-field>
                </v-col>

                <!-- units -->
                <v-col md="2" sm="4" cols="12">
                    <v-autocomplete
                        v-model="unit"
                        :items="units"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :menu-props="{contentClass:'text-center'}"
                        :label="$t('headers.unit')"
                        dense
                        hide-details="auto"
                        :loading="loading || itemLoading"
                        :disabled="loading || submitLoading || itemLoading"
                    ></v-autocomplete>
                </v-col>

                <!-- description -->
                <v-col md="2" sm="4" cols="12">
                    <v-autocomplete
                        v-model="description"
                        :items="menuItems"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :menu-props="{contentClass:'text-center'}"
                        :label="$t('headers.quality')"
                        dense
                        hide-details="auto"
                        :disabled="loading || submitLoading"
                        ref="matreialInput"
                    >
                        <template
                            v-slot:append-item
                            v-if="!showAllItems && buyer && menuItems.length !== items.length"
                        >
                            <v-divider/>
                            <v-list-item dense ripple @click="showAllItems = true">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title class="text-body-2">
                                        {{$t('show-all')}}
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <!-- net weight -->
                <v-col md="2" sm="4" cols="12">
                    <v-currency-text-field
                        :label="$t('forms.net-weight-kgs')"
                        v-model.number="netWeight"
                        type="number"
                        min='0'
                        dense
                        hide-details="auto"
                        :disabled="loading || submitLoading || itemLoading"
                        :rules="rules.requiredAsNumber"
                        @blur="resetValidationOnblur"
                    ></v-currency-text-field>
                </v-col>

                <!-- gross weight -->
                <v-col md="2" sm="4" cols="12">
                    <v-currency-text-field
                        :label="$t('forms.gross-weight-kgs')"
                        v-model.number="grossWeight"
                        type="number"
                        min='0'
                        dense
                        hide-details="auto"
                        :disabled="loading || submitLoading || itemLoading"
                        :rules="rules.requiredAsNumber"
                        @blur="resetValidationOnblur"
                    ></v-currency-text-field>
                </v-col>

                <v-col md="2" sm="4" cols="12" class="d-flex justify-center align-center pt-0">
                    <!-- add button -->
                    <v-btn
                        text
                        small
                        type="submit"
                        class="me-3"
                        :disabled="submitLoading || editLoading"
                    >
                        <v-icon>
                            {{ 
                                toEdit ? 'mdi-checkbox-marked-circle-outline' : 'mdi-plus-circle-outline'
                            }}
                        </v-icon>
                    </v-btn>

                    <!-- reset button -->
                    <v-btn @click="resetItemInputs" text small class="align-self-center" elevation="1">
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <v-data-table
            :headers="headers"
            :items="itemEntries"
            hide-default-footer
            disable-sort
            disable-pagination
            :loading="editLoading"
            :no-data-text="$t('no-data-text')"
            :no-results-text="$t('no-results-text')"
            :loading-text="$t('loading-text')"
            :footer-props="{ 
                'items-per-page-all-text': $t('items-per-page-all-text'),
                'items-per-page-text': $t('items-per-page-text'),
            }"
            dense
            class="elevation-1 mb-6"
        >
            <!-- quantity -->
            <template v-slot:item.data.quantity="{item}">
                {{ item.data.quantity ? item.data.quantity : '-' | currency }}
            </template>

            <!-- unit -->
            <template v-slot:item.data.unit="{item}">
                {{ item.data.unit ? item.data.unit : '-' }}
            </template>

            <!-- netWeight -->
            <template v-slot:item.data.netWeight="{item}">
                {{ item.data.netWeight ? item.data.netWeight : '-' | currency }}
            </template>

            <!-- grossWeight -->
            <template v-slot:item.data.grossWeight="{item}">
                {{ item.data.grossWeight ? item.data.grossWeight : '-' | currency }}
            </template>

            <!-- description -->
            <template v-slot:item.data.description="{item}">
                {{ item.data.description ? item.data.description : '-' }}
            </template>
            
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <tooltip text="Edit item">
                    <v-btn text small :disabled="submitLoading || editLoading" @click="fillToEdit(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>
                
                <tooltip text="Remove item" v-if="!toEdit">
                    <v-btn text small :disabled="submitLoading || editLoading" @click="removeItem(item.id)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </v-data-table>

        <!-- unit note -->
        <v-row class="mb-3">
            <v-col sm="4" cols="12" class="pt-0">
                <v-textarea
                    v-model="unitNote"
                    :label="$t('forms.unit-notes')"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                    rows="2"
                    no-resize
                    dense
                    hide-details="auto"
                    outlined
                    auto-grow
                ></v-textarea>
            </v-col>
        </v-row>

        <!-- MORE INFORMATION -->
        <h2 class="text-body-1 primary--text mb-3">More information</h2>

        <v-row class="mb-3">
            <!-- packing -->
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model="packing"
                    :label="$t('forms.packing')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- packing date -->
            <v-col md="4" sm="6" cols="12">
                <bee-date-input
                    v-model="packingDate"
                    changeable-with-arrows
                    :menu-picker-years-over-max="10"
                    :label="$t('forms.packing-date')"
                    prepend-icon="mdi-calendar"
                    :input-format-order="dateFormat"
                    input-type="month"
                    :user-menu-picker="true"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading || loading"
                ></bee-date-input>
            </v-col>

            <!-- expiry date -->
            <v-col md="4" sm="6" cols="12">
                <bee-date-input
                    v-model="expiryDate"
                    changeable-with-arrows
                    :menu-picker-years-over-max="10"
                    :label="$t('forms.expiry-date')"
                    prepend-icon="mdi-calendar"
                    :input-format-order="dateFormat"
                    input-type="month"
                    :user-menu-picker="true"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading || loading"
                ></bee-date-input>
            </v-col>

            <!-- port of loading -->
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model="loadingPort"
                    :label="$t('forms.port-of-loading')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- port of destination -->
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model="dischargePort"
                    :label="$t('forms.port-of-destination')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- sales no. -->
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model="containerNumber"
                    :label="$t('forms.container-number')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- sales no. -->
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model="sealesNo"
                    :label="$t('forms.seal-number')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- bill of lading number -->
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model="billOfLadingNumber"
                    :label="$t('forms.bill-of-loading-number')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- shipping marks -->
            <v-col sm="4" cols="12">
                <v-autocomplete-server
                    v-model="shippingMarks"
                    :label="$t('forms.shipping-marks')"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                    :input-field-enum="inputEnums.shippingMarks"
                ></v-autocomplete-server>
            </v-col>

            <!-- note -->
            <v-col cols="12">
                <v-textarea
                    v-model="note"
                    :label="$t('forms.notes')"
                    auto-grow
                    no-resize
                    rows="2"
                    dense
                    hide-details="auto"
                    outlined
                    :loading="editLoading"
                    :disabled="loading || submitLoading || editLoading"
                ></v-textarea>
            </v-col>
        </v-row>

        <!-- actions -->
        <div class="d-flex justify-end" v-if="!viewerOnly">
            <v-btn :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
                {{$t('save')}}
            </v-btn>
            <v-btn @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
                {{$t('save-and-export-as-PDF')}}
            </v-btn>
            <v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
                <v-icon class="me-1">mdi-eye-outline</v-icon>
                {{$t('preview')}}
            </v-btn>
        </div>

        <!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`packing-list:${$route.params.companyId}`); draftSnackbar = false"
        />
        
        <!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </v-form>
</template>

<script>
import rules from '../../../validation rules'
import Tooltip from '../../../components/Tooltip'
import { mapState, mapGetters } from 'vuex'
import fileTypes from '../../../enums/file-types';
import { findMonthDate, findFullDate, setBuyerDataLabels, getBuyerName, orderItemsDueCostumer, getCostumerItems } from '../../../helpers'
import containerSizes from '../../../enums/container-sizes'
import { BASE_API_URL } from '../../../constants'

export default {
    data: () => ({
        // helpers
        title: null,
        loading: false,
        submitLoading: false,
        editLoading: false,
        itemLoading: false,
        buyerDataLoading: false,
        previewLoading: false,
        rules,

        containerSizes,

        rowId: 0,
        toEdit: false,
        editEntryId: null,

        draft: null,
        isDraftUsed: false,
        draftSnackbar: false,

        snackbar: false,
        message: null,

        showAllItems: false,
        buyerItems: [],

        inputEnums: {
            shippingMarks: 0
        },

        /* DATA */
        // top data
        refContractNo: null,
        refInvoiceNo: null,
        date: null,
        buyer: null,
        buyerData: null,
        descriptionOfGoods: null,
        
        packing: null,
        packingDate: null,
        expiryDate: null,
        loadingPort: null,
        dischargePort: null,
        containerNumber: null,
        sealesNo: null,
        billOfLadingNumber: null,
        shippingMarks: null,
        note: null,

        // items table data
        quantity: null,
        description: null,
        unit: null,
        netWeight: null,
        grossWeight: null,

        itemEntries: [],
        unitNote: null
    }),

    components:{
        Tooltip
    },

    watch: {        
        // expiryDateMenu (val) {
        //     val && setTimeout(() => (this.expiryDateActivePicker = 'YEAR'));
            // setTimeout(() => {
            //     const pickers = document.querySelectorAll('.v-date-picker-years');
            //     pickers.forEach(picker => {
            //         pickers.querySelector('.active.primary--text').
            //     })
            //     pickers[pickers.length - 1].scrollTo({ top: (7 * 38), behavior: 'smooth' });
            // }, 500)
        // },

        draftFieldsWatcher() {
            if (!this.$route.query.id) {
                this.$localDrafts.save(`packing-list:${this.$route.params.companyId}`, {
                    refContractNo: this.refContractNo,
                    refInvoiceNo: this.refInvoiceNo,
                    date: this.date,
                    buyer: this.buyer,
                    buyerData: this.buyerData,
                    descriptionOfGoods: this.descriptionOfGoods,
                    packing: this.packing,
                    packingDate: this.packingDate,
                    expiryDate: this.expiryDate,
                    loadingPort: this.loadingPort,
                    dischargePort: this.dischargePort,
                    containerNumber: this.containerNumber,
                    sealesNo: this.sealesNo,
                    billOfLadingNumber: this.billOfLadingNumber,
                    shippingMarks: this.shippingMarks,
                    note: this.note,
                    itemEntries: this.itemEntries,
                    unitNote: this.unitNote,
                })
            }
        }
    },

    methods:{
        editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },

        // unit and table fuctions
        resetItemInputs(){
            this.quantity = null;
            this.description = null;
            this.unit = null;
            this.netWeight = null;
            this.grossWeight = null;
            this.$refs.formTable.resetValidation();
        },
        
        addItem(){
            if (this.$refs.formTable.validate()){
                // add data
                this.itemEntries.push({
                    id: this.rowId,
                    data: {
                        quantity: this.quantity,
                        description: this.description,
                        unit: this.unit,
                        netWeight: this.netWeight,
                        grossWeight: this.grossWeight,
                    }
                });

                // reset inputs
                this.resetItemInputs();
                this.rowId++;
            }
        },

        fillToEdit(entryId){
            this.toEdit = true;
            this.editEntryId = entryId;

            // fill data
            var entryData = this.itemEntries.find(c => c.id === entryId);
            this.quantity = entryData.data.quantity;
            this.description = entryData.data.description;
            this.unit = entryData.data.unit;
            this.netWeight = entryData.data.netWeight;
            this.grossWeight = entryData.data.grossWeight;
        },

        editItem(){
            // edit data
            var entryData = this.itemEntries.find(c => c.id === this.editEntryId);
            entryData.data.quantity = this.quantity;
            entryData.data.description = this.description;
            entryData.data.unit = this.unit;
            entryData.data.netWeight = this.netWeight;
            entryData.data.grossWeight = this.grossWeight;
            this.editEntryId = null;
            this.toEdit = false;

            // reset
            this.resetItemInputs();
        },

        removeItem(index){
            this.itemEntries.splice(index, 1);
            this.itemEntries.forEach(product => {
                if (product.id > index){
                    product.id--;
                }
            });
            this.rowId--;
        },

        saveEntry(){
            if (this.editEntryId === null) this.addItem();
            else this.editItem();
            this.$refs.quantity.focus();
        },

        // resetAll
        resetAll(){
            this.rowId = 0;
            this.toEdit = false;
            this.editEntryId = null;

            // top data
            this.refContractNo = null;
            this.refInvoiceNo = null;
            this.date = null;
            this.buyer = null;
            this.buyerData = null;
            this.descriptionOfGoods = null;
                    
            this.packing = null;
            this.packingDate = null;
            this.expiryDate = null;
            this.loadingPort = null;
            this.dischargePort = null;
            this.containerNumber = null;
            this.sealesNo = null;
            this.billOfLadingNumber = null;
            this.shippingMarks = null;
            this.note = null;

            // items table data
            this.quantity = null;
            this.description = null;
            this.unit = null;
            this.netWeight = null;
            this.grossWeight = null;

            this.itemEntries = [];
            this.unitNote = null;
        },

        // submit
        submit(exportPdf){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
                this.$store.dispatch(`packingLists/${actionName}`, {
                    id: this.$route.query.id,
                    referenceContract: this.refContractNo,
                    referenceInvoice: this.refInvoiceNo,
                    descriptionOfGoods: this.descriptionOfGoods,
                    date: this.date,
                    to: this.buyerData,
                    sealesNo: this.sealesNo,
                    loadingPort: this.loadingPort,
                    dischargePort: this.dischargePort,
                    shippingMarks: this.shippingMarks,
                    packingDate: this.packingDate ? this.findFullDate(this.packingDate) : null,
                    expiryDate: this.expiryDate ? this.findFullDate(this.expiryDate) : null,
                    packing: this.packing,
                    items: this.itemEntries.map(c => c.data),
                    unitNote: this.unitNote,
                    containerNumber: this.containerNumber,
                    billOfLadingNumber: this.billOfLadingNumber,
                    note: this.note
                })
                .then((id) => {
                    const fileId = actionName === 'create' ? id.data : this.$route.query.id;
                        this.message = this.$t('messages.packing-list-has-been-successfully-saved');
                    if (exportPdf) {
                        this.report(fileId);
                    }
                    if (this.isDraftUsed){
                        this.$localDrafts.remove(`packing-list:${this.$route.params.companyId}`)
                    }
                    this.resetAll();
                    this.$router.push({ name: 'packing-lists' })
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                })
                .finally(() => {
                    this.submitLoading = false;
                    this.snackbar = true;
                })
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
        },

        preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/packingLists', {
                    companyId: this.$route.params.companyId,
                    referenceContract: this.refContractNo,
                    referenceInvoice: this.refInvoiceNo,
                    descriptionOfGoods: this.descriptionOfGoods,
                    date: this.date,
                    to: this.buyerData,
                    sealesNo: this.sealesNo,
                    loadingPort: this.loadingPort,
                    dischargePort: this.dischargePort,
                    shippingMarks: this.shippingMarks,
                    packingDate: this.packingDate ? this.findFullDate(this.packingDate) : null,
                    expiryDate: this.expiryDate ? this.findFullDate(this.expiryDate) : null,
                    packing: this.packing,
                    items: this.itemEntries.map(c => c.data),
                    unitNote: this.unitNote,
                    containerNumber: this.containerNumber,
                    billOfLadingNumber: this.billOfLadingNumber,
                    note: this.note
                })
                .then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank');
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
            }
        },

        // fill all field if edit
        fillAllFieldsToEdit(data){
            this.refContractNo = data.referenceContract;
            this.refInvoiceNo = data.referenceInvoice;
            this.date = data.date ? window.moment(data.date).format('YYYY-MM-DD') : null;
            this.buyer = getBuyerName(data.to);
            this.buyerData = data.to;
            this.descriptionOfGoods = data.descriptionOfGoods;
            this.packing = data.packing
            this.packingDate = data.packingDate ? this.findMonthDate(data.packingDate) : null;
            this.expiryDate = data.expiryDate ? this.findMonthDate(data.expiryDate) : null;
            this.loadingPort = data.loadingPort;
            this.dischargePort = data.dischargePort;
            this.containerNumber = data.containerNumber;
            this.sealesNo = data.sealesNo;
            this.billOfLadingNumber = data.billOfLadingNumber;
            this.shippingMarks = data.shippingMarks;
            this.note = data.note;
            data.items.forEach((item) => {
                this.itemEntries.push({
                    id: this.rowId,
                    data: {
                        quantity: item.quantity,
                        description: item.description,
                        unit: item.unit,
                        netWeight: item.netWeight,
                        grossWeight: item.grossWeight,
                    }
                });
                this.rowId++;
            });
            this.unitNote = data.unitNote;
        },

        // fetch Data
        fetchData(){
            return Promise.all([
                this.$store.dispatch('items/fetchAll'),
                this.$store.dispatch('customers/fetchAll'),
                this.$store.dispatch('units/fetchAll')
            ])
        },

        // find customer data
        findCustomserData(){
            let buyer = this.getCustomerByName(this.buyer);
            this.showAllItems = false;
            if (buyer){
                this.buyerDataLoading = true;
                this.$store.dispatch('customers/fetchById', { id: buyer.id })
                .then((data) => {
                    buyer = data;
                    this.buyerItems = data.items;
                    this.buyerData = setBuyerDataLabels(buyer);
                })
                .finally(() => {
                    this.buyerDataLoading = false;
                })
            }
            else {
                this.buyerData = `${this.buyer || '-'}\n-\n-\n-`;
            }
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/packingLists/${id}`);
        },

        // fill data from draft
        fillDataFromDraft(){
            this.refContractNo = this.draft.refContractNo;
            this.refInvoiceNo = this.draft.refInvoiceNo;
            this.date = this.draft.date;
            this.buyer = this.draft.buyer;
            this.buyerData = this.draft.buyerData;
            this.descriptionOfGoods = this.draft.descriptionOfGoods;
            this.packing = this.draft.packing;
            this.packingDate = this.draft.packingDate;
            this.expiryDate = this.draft.expiryDate;
            this.loadingPort = this.draft.loadingPort;
            this.dischargePort = this.draft.dischargePort;
            this.containerNumber = this.draft.containerNumber;
            this.sealesNo = this.draft.sealesNo;
            this.billOfLadingNumber = this.draft.billOfLadingNumber;
            this.shippingMarks = this.draft.shippingMarks;
            this.note = this.draft.note;
            this.unitNote = this.draft.unitNote;
            this.itemEntries = this.draft.itemEntries;
            this.rowId = this.draft.itemEntries.length;
        },

        // helpers
        resetValidationOnblur(){
            if (this.itemEntries.length > 0){
                this.$nextTick(() => {
                    this.$refs.formTable.resetValidation();
                })
            }
        },
        findMonthDate,
        findFullDate
    },
    
    computed:{
        ...mapState({
            customers: state => state.customers.customers,
            units: state => state.units.units,
            items: state => state.items.items,
            fileSettings: state => state.fileSettings.fileSettings,
            viewerOnly: state => state.users.viewerOnly,
        }),

        ...mapGetters({
            getUnitByName: 'units/getUnitByName',
            getUnitById: 'units/getUnitById',
            getCustomerByName: 'customers/getCustomerByName',
        }),

        dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

        menuItems() {
            if (this.buyer && this.showAllItems) {
                return orderItemsDueCostumer(this.buyerItems)
            } else if (this.buyer) {
                const costomerItems = getCostumerItems(this.buyerItems)
                return costomerItems.length > 0 ? costomerItems : this.items;
            } else {
                return this.items
            }
        },

        headers() {
            const headers = [
                { text: this.$t('headers.quantity'), value:'data.quantity', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.unit') + '*', value:'data.unit', align:'center' },
                { text: this.$t('headers.quality'), value:'data.description', align:'center' },
                { text: this.$t('headers.kgs-net'), value:'data.netWeight', align:'center' },
                { text: this.$t('headers.kgs-gross'), value:'data.grossWeight', align:'center' },
                { text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
        },

        draftFieldsWatcher(){
            return (`${this.toEdit} | ${this.refContractNo} | ${this.refInvoiceNo} | ${this.date} | ${this.buyer} | ${this.buyerData} | ${this.descriptionOfGoods} | ${this.packing} | ${this.packingDate} | ${this.expiryDate} | ${this.loadingPort} | ${this.dischargePort} | ${this.containerNumber} | ${this.sealesNo} | ${this.billOfLadingNumber} | ${this.shippingMarks} | ${this.note} | ${this.itemEntries} | ${this.unitNote}`)
        }
    },

    mounted(){
        this.loading = true;
        if (this.$route.query.id){
            if (this.$route.query.viewer) {
                this.title = this.$t('title.preview') + this.$t('PackingLists.packing-list');
            }
            else if (this.$route.query.duplicate) {
                this.title = this.$t('title.duplicate') + this.$t('PackingLists.packing-list');
            }
            else {
                this.title = this.$t('title.edit') + this.$t('PackingLists.packing-list');
            }

            this.editLoading = true;
            Promise.all([
                this.$store.dispatch('packingLists/fetchById', { id: this.$route.query.id })
                .then((data) => {
                    this.fillAllFieldsToEdit(data);
                }),
                this.fetchData()
            ])
            .finally(() => {
                this.loading = false;
                this.editLoading = false;
            });
        }
        else {
            this.title = this.$t('title.add') + this.$t('PackingLists.packing-list');

            this.fetchData()
            .finally(() => {
                this.$store.dispatch('sequences/fetch', { fileType: fileTypes.packingList })
                    .then(response => {
                        this.num = response.value;
                        this.sequence = response;
                    }).finally(() => {
                        this.loading = false;
                    });
                // get draft and show snackbar
                this.draft = this.$localDrafts.get(`packing-list:${this.$route.params.companyId}`);
                if (this.draft){
                    setTimeout(() => {
                        this.draftSnackbar = true;
                    }, 500)
                }
            });
        }
    }
}
</script>

<style lang="scss">
#contract{
    .v-label--is-disabled, input[disabled="disabled"]{
        color: rgba(43, 40, 40, 0.65);
    }
    input[disabled="disabled"]{
        font-weight: 700;
    }
}
</style>