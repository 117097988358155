import * as types from '../mutation-types'

export default {
    namespaced: true,
    
    state:{
        productReleaseOrders: []
    },

    actions:{
        fetchAll({ commit }, { sequence, fromDate, toDate }){
            return window.axios.get('/files/productReleaseOrders', {
                params: { sequence, fromDate, toDate }
            })
            .then((response) => {
                commit(types.STORE_PRODUCT_RELEASE_ORDERS, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/productReleaseOrders/${id}`)
            .then((response) => {
                return response.data;
            })
        },
        
        create({ commit }, { isManualSequence, sequence, manualSequence, items, date, referenceContract, warehouseNo, packingOrderNo, portCompany, tariffSystemId, destination, contract, booking, shipName, containerNo, shippingDate, voyage, stampNo, pilotName, truckPlate, licenseNo, platformPlate, goodsDescription, boxMark, packingDate, expiryDate, observers, dispatchedBy, transportedBy, receivedBy }){
            return window.axios.post('/files/productReleaseOrders', {
                isManualSequence, sequence, manualSequence, items, currency: 'Q', date, referenceContract, warehouseNo, packingOrderNo, portCompany, tariffSystemId, destination, contract, booking, shipName, containerNo, shippingDate, voyage, stampNo, pilotName, truckPlate, licenseNo, platformPlate, goodsDescription, boxMark, packingDate, expiryDate, observers, dispatchedBy, transportedBy, receivedBy
            })
        },
        
        update({ commit }, { id, isManualSequence, sequence, manualSequence, items, date, referenceContract, warehouseNo, packingOrderNo, portCompany, tariffSystemId, destination, contract, booking, shipName, containerNo, shippingDate, voyage, stampNo, pilotName, truckPlate, licenseNo, platformPlate, goodsDescription, boxMark, packingDate, expiryDate, observers, dispatchedBy, transportedBy, receivedBy }){
            return window.axios.put(`/files/productReleaseOrders/${id}`, {
                isManualSequence, sequence, manualSequence, items, currency: 'Q', date, referenceContract, warehouseNo, packingOrderNo, portCompany, tariffSystemId, destination, contract, booking, shipName, containerNo, shippingDate, voyage, stampNo, pilotName, truckPlate, licenseNo, platformPlate, goodsDescription, boxMark, packingDate, expiryDate, observers, dispatchedBy, transportedBy, receivedBy
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/productReleaseOrders/${id}`);
        }
    },

    mutations:{
        [types.STORE_PRODUCT_RELEASE_ORDERS](state, productReleaseOrders){
            state.productReleaseOrders = productReleaseOrders;
        }
    },

    getters:{
        getProductReleaseOrdersById: state => id => state.productReleaseOrders.find(c => c.id === id)
    }
}