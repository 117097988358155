<template>
	<div>
		<v-form @submit.prevent="submit(false)" ref="form" id="invoice" :disabled="viewerOnly">
			<!-- entry dialog -->
			<v-dialog
				v-model="dialog"
				width="800"
				ref="dialog"
				@click:outside="resetItemInputs"
			>
				<v-form @submit.prevent="toEdit ? editEntry() : addEntry()" >
					<v-card>
						<!-- dialog title -->
						<v-card-title class="primary white--text justify-center py-3">
							<v-spacer/>
							<span class="text-h6 ms-3">
								{{ !toEdit ? $t('add') : $t('edit') }} {{$t('item')}}
							</span>
							<v-spacer/>
							<v-btn text small fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
						</v-card-title>

						<!-- dialog content -->
						<v-card-text>
							<v-container>
								<v-row>
									<!-- code -->
									<v-col md="3" sm="6" cols="12">
										<v-text-field
											v-model="code"
											ref="codeInput"
											:label="$t('headers.code')"
											hide-details
										></v-text-field>
									</v-col>
									
									<!-- packainges numbers -->
									<v-col md="3" sm="6" cols="12">
										<v-currency-text-field
											v-model.number="numberOfPackages"
											:label="$t('headers.number-of-packages')"
											type="number"
											min="0"
											hide-details
										></v-currency-text-field>
									</v-col>

									<!-- units -->
									<v-col md="3" sm="6" cols="12">
										<v-autocomplete
											v-model="unit"
											:items="units"
											item-text="name"
											item-value="name"
											:return-object="false"
											:menu-props="{contentClass:'text-center'}"
											:label="$t('headers.unit')"
											hide-details
										></v-autocomplete>
									</v-col>

									<!-- weight per package -->
									<v-col md="3" sm="6" cols="12">
										<v-currency-text-field
											v-model.number="weightPerPackage"
											:label="$t('headers.weight-per-package')"
											type="number"
											min="0"
											hide-details
										></v-currency-text-field>
									</v-col>

									<!-- net weight -->
									<v-col md="3" sm="6" cols="12">
										<v-currency-text-field
											v-model.number="netWeight"
											:label="$t('headers.net-weight')"
											type="number"
											min="0"
											hide-details
										></v-currency-text-field>
									</v-col>

									<!-- gross weight -->
									<v-col md="3" sm="6" cols="12">
										<v-currency-text-field
											v-model.number="grossWeight"
											:label="$t('headers.gross-weight')"
											type="number"
											min="0"
											hide-details
										></v-currency-text-field>
									</v-col>

									<!-- entry description -->
									<v-col md="6" sm="6" cols="12">
										<v-text-field
											v-model="entryDescription"
											:label="$t('headers.description')"
											hide-details
										></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary lighten-2"
								class="px-6"
								text
								type="submit"
							>
								{{ !toEdit ? $t('add') : $t('edit') }}
							</v-btn>
							<v-btn
								class="px-6"
								text
								@click="dialog = false; resetItemInputs();"
							>
								{{$t('cancel')}}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-dialog>

			<div class="d-flex justify-space-between align-center mb-1">
				<v-row class="align-center ma-0">
					<!-- title -->
					<v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
						<h1 class="text-h5 primary--text">{{title}}</h1>
						<v-btn
							v-if="$vuetify.breakpoint.smAndDown"
							:to="{ name:'health-certificates' }"
							:disabled="submitLoading"
							elevation="1"
							small
							class="px-6"
						>
							{{$t('back')}}
						</v-btn>
					</v-col>

					<!-- date -->
					<v-col md="3" sm="6" cols="12">
						<bee-date-input
							v-model="date"
							changeable-with-arrows
							:label="$t('forms.date')"
							prepend-icon="mdi-calendar"
							:input-format-order="dateFormat"
							:user-menu-picker="true"
							:loading="editLoading"
							:disabled="submitLoading || editLoading"
							:rules="rules.required"
							dense
							hide-details="auto"
						></bee-date-input>
					</v-col>
				</v-row>

				<!-- back button -->
				<v-btn
					v-if="$vuetify.breakpoint.mdAndUp"
					:to="{ name:'health-certificates' }"
					:disabled="submitLoading"
					elevation="1"
					small
					class="px-6"
				>
					{{$t('back')}}
				</v-btn>
			</div>

			<!-- BASIC INFORMATION -->
			<h2 class="text-body-1 primary--text mb-3">{{$t('sections.basic-information')}}</h2>
			<v-row class="align-center mb-5">
				<!-- exporter -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="exporter"
						:label="$t('forms.exporter')"
						disabled
						:rules="rules.required"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- invoice number -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="invoiceNumber"
						:label="$t('forms.invoice-no')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- contract number -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="contractNumber"
						:label="$t('forms.contract-no')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- place of origin -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="placeOfOrigin"
						:label="$t('forms.place-of-origin')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>
				
				<!-- port of loading -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="portOfLoading"
						:label="$t('forms.port-of-loading')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- port of destination -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="portOfDestination"
						:label="$t('forms.port-of-destination')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- consignee -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="consignee"
						:label="$t('forms.consignee')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- multiline-notes -->
				<v-col md="4" sm="6" cols="12">
					<v-textarea
						v-model="multiNotes"
						:label="$t('forms.consignee-details')"
						rows="1"
						auto-grow
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-textarea>
				</v-col>

				<!-- cargo description -->
				<v-col md="12" sm="6" cols="12">
					<v-autocomplete-server
						v-model="cargoDescription"
						:label="$t('forms.cargo-description')"
						input-type="textarea"
						rows="2"
						auto-grow
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
						outlined
						:input-field-enum="inputEnums.cargoDescription"
					></v-autocomplete-server>
				</v-col>
			</v-row>
			
			<!-- ITEMS -->
			<h2 class="text-body-1 primary--text mb-3">{{$t('sections.items')}}</h2>

			<!-- table -->
			<v-data-table
				:headers="headers"
				:items="itemEntries"
				disable-pagination
				disable-sort
				:loading="editLoading"
				:no-data-text="$t('no-data-text')"
				:no-results-text="$t('no-results-text')"
				:loading-text="$t('loading-text')"
				:footer-props="{ 
					'items-per-page-all-text': $t('items-per-page-all-text'),
					'items-per-page-text': $t('items-per-page-text'),
				}"
				dense
				class="elevation-1 mb-3"
			>
				<!-- code -->
				<template v-slot:item.data.code="{item}">
					{{(item.data.code || '-')}}
				</template>

				<!-- numberOfPackages -->
				<template v-slot:item.data.numberOfPackages="{item}">
					{{(item.data.numberOfPackages || '-') | currency}}
				</template>

				<!-- unit -->
				<template v-slot:item.data.unit="{item}">
					{{(item.data.unit || '-')}}
				</template>

				<!-- weightPerPackage -->
				<template v-slot:item.data.weightPerPackage="{item}">
					{{(item.data.weightPerPackage || '-') | currency}}
				</template>

				<!-- description -->
				<template v-slot:item.data.description="{item}">
					{{(item.data.description || '-')}}
				</template>

				<!-- netWeight -->
				<template v-slot:item.data.netWeight="{item}">
					{{(item.data.netWeight || '-') | currency}}
				</template>

				<!-- grossWeight -->
				<template v-slot:item.data.grossWeight="{item}">
					{{(item.data.grossWeight || '-') | currency}}
				</template>
				
				<!-- actions -->
				<template v-slot:item.actions="{item}">
					<tooltip text="Edit item">
						<v-btn text small :disabled="submitLoading || editLoading"
						hide-details="auto" @click="fillToEdit(item.id)">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</tooltip>
					
					<tooltip text="Remove item">
						<v-btn text small :disabled="submitLoading || editLoading"
						hide-details="auto" @click="removeEntry(item.id)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</template>

				<!-- add button -->
				<template v-slot:footer.prepend v-if="!viewerOnly">
					<v-btn
						text
						@click="dialog = true; toEdit = false;"
						:loading="loading || itemLoading"
						:disabled="loading || submitLoading || itemLoading"
					>
						<v-icon class="me-3">mdi-plus-circle-outline</v-icon>
						{{$t('add') + ' ' + $t('item')}}
					</v-btn>
				</template>

				<!-- footer -->
				<template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
					{{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
				</template>
			</v-data-table>

			<!-- totals -->
			<v-row class="mt-1">
				<v-spacer></v-spacer>
				<v-col lg="2" md="3" sm="4" cols="12" class="py-sm-0 py-1">
					<v-text-field
						outlined
						disabled
						hide-details
						dense
						:label="$t('headers.total-net-weight')"
						:value="
							itemEntries.length > 0
							? itemEntries.map(c => c.data.netWeight).reduce((a, b) => a + b)
							: 0"
					></v-text-field>
				</v-col>
				<v-col lg="2" md="3" sm="4" cols="12" class="py-sm-0 py-1">
					<v-text-field
						outlined
						disabled
						hide-details
						dense
						:label="$t('headers.total-gross-weight')"
						:value="
							itemEntries.length > 0
							? itemEntries.map(c => c.data.grossWeight).reduce((a, b) => a + b)
							: 0"
					></v-text-field>
				</v-col>
			</v-row>

			<v-row class="mb-3">
				<!-- container number -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="containerNumber"
						:label="$t('forms.container-number')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- seal number -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="sealNumber"
						:label="$t('forms.seal-number')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- bill of loading number -->
				<v-col md="4" sm="6" cols="12">
					<v-text-field
						v-model="billOfLoadingNumber"
						:label="$t('forms.bill-of-loading-number')"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
					></v-text-field>
				</v-col>

				<!-- description -->
				<v-col cols="12">
					<v-textarea
						v-model="description"
						:label="$t('forms.description')"
						rows="2"
						auto-grow
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
						dense
						hide-details="auto"
						outlined
					></v-textarea>
				</v-col>
			</v-row>

			<!-- actions -->
			<div class="d-flex justify-end" v-if="!viewerOnly">
				<v-btn :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
					{{$t('save')}}
				</v-btn>
				<v-btn @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
					{{$t('save-and-export-as-PDF')}}
				</v-btn>
				<v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
					<v-icon class="me-1">mdi-eye-outline</v-icon>
					{{$t('preview')}}
				</v-btn>
			</div>
		</v-form>

		<!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`health-certificate:${$route.params.companyId}`); draftSnackbar = false; date = moment().format('YYYY-MM-DD')"
        />

		<!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
import Tooltip from '../../../components/Tooltip'
import rules from '../../../validation rules'
import { BASE_API_URL } from '../../../constants';
import fileTypes from '../../../enums/file-types';
import packingTypes from '../../../enums/packing-types';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
export default {
	data: () => ({
		// helpers
        title: null,
        loading: false,
        submitLoading: false,
        editLoading: false,
        itemLoading: false,
        previewLoading: false,
        rules,

        draft: null,
        isDraftUsed: null,
        draftSnackbar: false,

        snackbar: false,
		message: null,
		
		dialog: null,
        rowId: 0,
        toEdit: false,
		editEntryId: null,
		
		units: packingTypes,

		inputEnums: {
			cargoDescription: 0
		},
		
		/* DATA */
        date: null,
        exporter: null,
        invoiceNumber: null,
		contractNumber: null,
		placeOfOrigin: null,
		portOfLoading: null,
		portOfDestination: null,
		consignee: null,
		
		itemEntries: [],
		
		containerNumber: null,
		sealNumber: null,
		description: null,
		billOfLoadingNumber: null,
		multiNotes: null,
		cargoDescription: null,

		/* table inputs */
		code: null,
		numberOfPackages: null,
		unit: null,
		weightPerPackage: null,
		entryDescription: null,
		netWeight: null,
		grossWeight: null,
	}),

	watch: {
		draftFieldsWatcher() {
			if (!this.$route.query.id){
                this.$localDrafts.save(`health-certificate:${this.$route.params.companyId}`, {
                    date: this.date,
					exporter: this.exporter,
					invoiceNumber: this.invoiceNumber,
					contractNumber: this.contractNumber,
					placeOfOrigin: this.placeOfOrigin,
					portOfLoading: this.portOfLoading,
					portOfDestination: this.portOfDestination,
					consignee: this.consignee,
					itemEntries: this.itemEntries,
					containerNumber: this.containerNumber,
					sealNumber: this.sealNumber,
					description: this.description,
					billOfLoadingNumber: this.billOfLoadingNumber,
					multiNotes: this.multiNotes,
					cargoDescription: this.cargoDescription,
                })
            }
		}
	},

	methods: {
		// submit
        submit(exportPdf){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
				this.$store.dispatch(`healthCertificates/${actionName}`, {
					id: this.$route.query.id,
					date: this.date,
					invoiceNumber: this.invoiceNumber,
					contractNumber: this.contractNumber,
					exporter: this.exporter,
					consignee: this.consignee,
					multiNotes: this.multiNotes,
					cargoDescription: this.cargoDescription,
					placeOfOrigin: this.placeOfOrigin,
					placeOfLoading: this.portOfLoading,
					placeOfDestination: this.portOfDestination,
					description: this.description,
					containerNumber: this.containerNumber,
					sealNumber: this.sealNumber,
					billOfLadingNumber: this.billOfLoadingNumber,
					items: this.itemEntries.map(c => c.data),
				})
				.then((id) => {
					this.message = this.$t('messages.health-certificate-has-been-successfully-saved');
					if (exportPdf) {
						this.report(id);
					}
					if (this.isDraftUsed){
						this.$localDrafts.remove(`health-certificate:${this.$route.params.companyId}`);
					}
					this.$router.push({ name: 'health-certificates' })
				})
				.catch((e) => {
					this.message = e.response.data.message;
				})
				.finally(() => {
					this.submitLoading = false;
					this.snackbar = true;
				})
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
		},

		preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/healthCertificate', {
					companyId: this.$route.params.companyId,
					date: this.date,
					invoiceNumber: this.invoiceNumber,
					contractNumber: this.contractNumber,
					exporter: this.exporter,
					consignee: this.consignee,
					multiNotes: this.multiNotes,
					cargoDescription: this.cargoDescription,
					placeOfOrigin: this.placeOfOrigin,
					placeOfLoading: this.portOfLoading,
					placeOfDestination: this.portOfDestination,
					description: this.description,
					containerNumber: this.containerNumber,
					sealNumber: this.sealNumber,
					billOfLadingNumber: this.billOfLoadingNumber,
					items: this.itemEntries.map(c => c.data),
				})
				.then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
				.catch((e) => {
                    this.message = e.response.data.message;
					this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
			}
		},
		
		fillAllFieldsToEdit(data){
			this.date = moment(data.date).format('YYYY-MM-DD');
			this.invoiceNumber = data.invoiceNumber;
			this.contractNumber = data.contractNumber;
			this.exporter = data.exporter;
			this.consignee = data.consignee;
			this.multiNotes = data.multiNotes;
			this.cargoDescription = data.cargoDescription;
			this.placeOfOrigin = data.placeOfOrigin;
			this.portOfLoading = data.placeOfLoading;
			this.portOfDestination = data.placeOfDestination;
			this.description = data.description;
			this.containerNumber = data.containerNumber;
			this.sealNumber = data.sealNumber;
			this.billOfLoadingNumber = data.billOfLadingNumber;
			
            data.items.forEach((item) => {
                this.itemEntries.push({
                    id: this.rowId,
					data: {
						code: item.code,
						numberOfPackages: item.numberOfPackages,
						unit: item.unit,
						weightPerPackage: item.weightPerPackage,
						description: item.description,
						netWeight: item.netWeight,
						grossWeight: item.grossWeight,
					}
                });
                this.rowId++;
            })
        },

		// table functions
		addEntry(){
			// add data
			this.itemEntries.push({
				id: this.rowId,
				data: {
					code: this.code,
					numberOfPackages: this.numberOfPackages,
					unit: this.unit,
					weightPerPackage: this.weightPerPackage,
					description: this.entryDescription,
					netWeight: this.netWeight,
					grossWeight: this.grossWeight,
				}
			});

			// reset inputs
			this.$refs.codeInput.focus();
			this.resetItemInputs();
			this.rowId++;
        },

        fillToEdit(entryId){
            this.toEdit = true;
			this.editEntryId = entryId;
			this.dialog = true;

            // fill data
            var entryData = this.itemEntries.find(c => c.id === entryId);
			this.code = entryData.data.code;
			this.numberOfPackages = entryData.data.numberOfPackages;
			this.unit = entryData.data.unit;
			this.weightPerPackage = entryData.data.weightPerPackage;
			this.entryDescription = entryData.data.description;
			this.netWeight = entryData.data.netWeight;
			this.grossWeight = entryData.data.grossWeight;
        },

        editEntry(){
            // edit data
			var entryData = this.itemEntries.find(c => c.id === this.editEntryId);
			entryData.data.code = this.code;
			entryData.data.numberOfPackages = this.numberOfPackages;
			entryData.data.unit = this.unit;
			entryData.data.weightPerPackage = this.weightPerPackage;
			entryData.data.description = this.entryDescription;
			entryData.data.netWeight = this.netWeight;
			entryData.data.grossWeight = this.grossWeight;
			
            this.toEdit = false;
            this.dialog = false;

            // reset
            this.resetItemInputs();
        },

        removeEntry(index){
            this.itemEntries.splice(index, 1);
            this.itemEntries.forEach(product => {
                if (product.id > index){
                    product.id--;
                }
            });
            this.rowId--;
        },
		
		resetItemInputs() {
			this.toEdit = false;

			this.code = null;
			this.numberOfPackages = null;
			this.unit = null;
			this.weightPerPackage = null;
			this.entryDescription = null;
			this.netWeight = null;
			this.grossWeight = null;
		},

		// report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/file/${id}?fileType=${fileTypes.healthCertificate}`);
		},
		
		// fill data from draft
        fillDataFromDraft() {
			this.date = this.draft.date;
			this.exporter = this.draft.exporter;
			this.invoiceNumber = this.draft.invoiceNumber;
			this.contractNumber = this.draft.contractNumber;
			this.placeOfOrigin = this.draft.placeOfOrigin;
			this.portOfLoading = this.draft.portOfLoading;
			this.portOfDestination = this.draft.portOfDestination;
			this.consignee = this.draft.consignee;
			this.containerNumber = this.draft.containerNumber;
			this.sealNumber = this.draft.sealNumber;
			this.description = this.draft.description;
			this.billOfLoadingNumber = this.draft.billOfLoadingNumber;
			this.cargoDescription = this.draft.cargoDescription;
			
			this.itemEntries = this.draft.itemEntries;
            this.rowId = this.draft.itemEntries.length;
		},
		
		// helpers
		moment,
	},

	computed: {
		...mapState({
			viewerOnly: state => state.users.viewerOnly,
			fileSettings: state => state.fileSettings.fileSettings,
		}),
		...mapGetters({
			getCompanyById: 'companies/getCompanyById'
		}),

		dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

		headers() {
            const headers = [
                { text: this.$t('headers.code'), value:'data.code', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.number-of-packages'), value:'data.numberOfPackages', align:'center' },
                { text: this.$t('headers.unit'), value:'data.unit', align:'center' },
                { text: this.$t('headers.weight-per-package'), value:'data.weightPerPackage', align:'center' },
                { text: this.$t('headers.description'), value:'data.description', align:'center' },
                { text: this.$t('headers.net-weight'), value:'data.netWeight', align:'center' },
                { text: this.$t('headers.gross-weight'), value:'data.grossWeight', align:'center' },
                { text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
		},
		
		draftFieldsWatcher(){
			return (`${this.toEdit} | ${this.invoiceNumber} | ${this.contractNumber} | ${this.placeOfOrigin} | ${this.portOfLoading} | ${this.portOfDestination} | ${this.consignee} | ${this.itemEntries} | ${this.containerNumber} | ${this.sealNumber} | ${this.description} | ${this.billOfLoadingNumber} | ${this.multiNotes} | ${this.cargoDescription}`)
        }
	},

	mounted() {
		this.loading = true;
        this.exporter = this.getCompanyById(this.$route.params.companyId).name;
        if (this.$route.query.id){
            this.editLoading = true;

            if (this.$route.query.viewer) {
                this.title = this.$t('title.preview') + this.$t('HealthCertificates.health-certificate');
            }
            else if (this.$route.query.duplicate) {
                this.title = this.$t('title.duplicate') + this.$t('HealthCertificates.health-certificate');
            }
            else {
                this.title = this.$t('title.edit') + this.$t('HealthCertificates.health-certificate');
            }

            Promise.all([
                this.$store.dispatch('healthCertificates/fetchById', { id: this.$route.query.id })
                .then((data) => {
					this.fillAllFieldsToEdit(data);
                })
            ])
            .finally(() => {
                this.loading = false;
                this.editLoading = false;
            });
        }
        else {
            this.title = this.$t('title.add') + this.$t('HealthCertificates.health-certificate');
			this.loading = false;
			// get draft and show snackbar
			this.draft = this.$localDrafts.get(`health-certificate:${this.$route.params.companyId}`);
			if (this.draft){
				setTimeout(() => {
					this.draftSnackbar = true;
				}, 500)
			}
			else {
				this.date = moment().format('YYYY-MM-DD');
			}
        }
	},

	components: {
		Tooltip
	}
}
</script>

<style>

</style>