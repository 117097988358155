var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('h1',{staticClass:"text-h5 primary--text"},[_vm._v(_vm._s(_vm.$t('SalesContracts.sales-contracts')))]),_c('v-spacer'),_c('delete-dialog',{ref:"DeleteDialog",on:{"on-delete":_vm.filter}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('are-you-sure-you-want-to'))+" "),_c('span',{staticClass:"text-uppercase error--text"},[_vm._v("DELETE")]),_vm._v(" "+_vm._s(_vm.$t('SalesContracts.the-sales-contract'))+" ")])]),(!_vm.viewerOnly)?_c('v-btn',{staticClass:"lightPrimary white--text",attrs:{"text":"","small":"","to":{
            name:'sales-contract',
            params:{companyId: _vm.$route.params.companyId},
          }}},[_vm._v(" "+_vm._s(_vm.$t('SalesContracts.add-a-sales-contract'))+" ")]):_vm._e()],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.filter()}}},[_c('v-row',{staticClass:"align-center py-2 ma-0"},[_c('v-col',{staticClass:"text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('filter'))+" ")]),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.number'),"filled":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.num),callback:function ($$v) {_vm.num=$$v},expression:"num"}})],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"md":"3","sm":"4","cols":"12"}},[_c('v-combobox',{attrs:{"label":_vm.$t('headers.buyer'),"items":_vm.customers,"item-text":"name","item-value":"name","return-object":false,"loading":_vm.filterLoading,"disabled":_vm.filterLoading,"filled":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.buyer),callback:function ($$v) {_vm.buyer=$$v},expression:"buyer"}})],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"md":"3","sm":"4","cols":"12"}},[_c('v-combobox',{attrs:{"label":_vm.$t('headers.seller'),"items":_vm.customers,"item-text":"name","item-value":"name","return-object":false,"loading":_vm.filterLoading,"disabled":_vm.filterLoading,"filled":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.seller),callback:function ($$v) {_vm.seller=$$v},expression:"seller"}})],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-menu',{ref:"menuOfDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.date ? _vm.moment(_vm.date) : null,"label":_vm.$t('headers.date'),"prepend-icon":"mdi-calendar","dense":"","filled":"","outlined":"","readonly":"","hide-details":"","disabled":_vm.loading}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":function($event){return _vm.$refs.menuOfDate.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"success","width":"80","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"type":"submit"},on:{"click":function($event){_vm.isFiltered = true}}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")]),(_vm.isFiltered)?_c('v-btn',{attrs:{"icon":"","small":"","disabled":!_vm.isFiltered || _vm.loading},on:{"click":function($event){return _vm.fetchSalesContracts();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.salesContracts,"items-per-page":20,"no-data-text":_vm.$t('no-data-text'),"no-results-text":_vm.$t('no-results-text'),"loading-text":_vm.$t('loading-text'),"footer-props":{
        'items-per-page-options': [10, 20, 40, 100, -1],
        'items-per-page-all-text': _vm.$t('items-per-page-all-text'),
        'items-per-page-text': _vm.$t('items-per-page-text'),
        showCurrentPage:true
      },"disable-sort":"","loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.sequence",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.sequence ? item.sequence : 'N/A')+" ")]}},{key:"item.seller",fn:function(){return [_vm._v(" "+_vm._s(_vm.getCompanyById(_vm.$route.params.companyId).name)+" ")]},proxy:true},{key:"item.buyer",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.buyer ? _vm.getBuyerName(item.buyer) : 'N/A')+" ")]}},{key:"item.tax",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.tax !== 0 ? item.tax : '-')+" ")]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date))+" ")]}},{key:"item.total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.totalWithTax",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalWithTax))+" ")]}},{key:"item.paymentTerms",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentTerms ? item.paymentTerms.length > 100 ? item.paymentTerms.slice(0, 100) + '...' : item.paymentTerms : 'N/A')+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.download-as-pdf')}},[_c('v-btn',{attrs:{"text":"","small":"","target":"_blank"},on:{"click":function($event){return _vm.report(item.id)}}},[_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1)],1),(_vm.viewerOnly)?[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.preview')}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                name:'sales-contract',
                                params:{companyId: _vm.$route.params.companyId},
                                query:{viewer: 1, id:item.id}
                            }}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)]:[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.duplicate')}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                name:'sales-contract',
                                params:{companyId: _vm.$route.params.companyId},
                                query:{duplicate: 1, id:item.id}
                            }}},[_c('v-icon',[_vm._v(" mdi-content-duplicate ")])],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.edit')}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                name:'sales-contract',
                                params:{companyId: _vm.$route.params.companyId},
                                query:{id:item.id}
                            }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.delete')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.DeleteDialog.activeDialog(item.id, 'salesContracts')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]],2)]}},{key:"footer.page-text",fn:function(ref){
                            var pageStart = ref.pageStart;
                            var pageStop = ref.pageStop;
                            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }