export default {
    'audit-log' : 'Registro de cambios',
    'of-$': 'de {username}',
    'last-login': 'Ultima conexión',
    'action-name': {
        create: 'Crear', 
        modify: 'Editar', 
        delete: 'Eliminar', 
        login: 'Entrar al sistema',
        'login-to-company': 'Conectarse a Compañía',
    }
}