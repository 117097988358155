<template>
    <div>
        <!-- add user -->
        <div class="d-flex flex-wrap justify-space-between">
            <h1 class="text-h5 primary--text mb-5">{{$t('Users.users')}}</h1>

            <div>
                <!-- add button -->
                <v-btn text small class="lightPrimary white--text" :disabled="loading" @click="activeDialog()">
                    {{$t('Users.add-a-user')}}
                </v-btn>
                
                <!-- back button -->
                <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    elevation="1"
                    small
                    class="ms-4 px-6"
                    @click="backToLastCompanyContext"
                >
                    {{$t('back')}}
                </v-btn>
            </div>
        </div>

        <!-- delete-dialog is decleared here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="fetchUsers">
            <span class="text-body-1">
                {{$t('are-you-sure-you-want-to')}}
                <span class="error--text">{{$t('delete')}}</span> 
                {{$t('Users.the-user')}}
            </span>
        </delete-dialog>

        <!-- add/edit dialog -->
        <v-dialog v-model="dialog" width="900" persistent>
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary py-2 white--text justify-center">
                        <v-spacer/>
                        <span class="text-h6">
                            {{isEditUser ? this.$t('Users.edit-user') : this.$t('Users.add-user')}}
                        </span>
                        <v-spacer/>
                        <v-btn text small light fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text class="pt-2 pb-0">
                        <v-container>
                            <v-row>
                                <!-- username -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="user.username"
                                        :label="$t('Users.username')"
                                        :loading="dialogLoading"
                                        :disabled="dialogLoading"
                                        dense
                                        :rules="!isEditUser ? rules.required : []"
                                    ></v-text-field>
                                </v-col>

                                <!-- password -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="user.password"
                                        :label="$t('Users.password')"
                                        type="password"
                                        :loading="dialogLoading"
                                        :disabled="dialogLoading"
                                        :hint="isEditUser ? $t('Users.leave-it-empty-if-you-do-not-want-to-change-password') : null"
                                        persistent-hint
                                        dense
                                        :rules="!isEditUser ? [rules.required, rules.password].flat() : []"
                                    ></v-text-field>
                                </v-col>

                                <!-- confirmPassword -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="user.confirmPassword"
                                        :label="$t('Users.confirm-password')"
                                        type="password"
                                        :loading="dialogLoading"
                                        :disabled="dialogLoading"
                                        dense
                                        :rules="
                                            !isEditUser || user.password
                                            ? [value => (value && value === user.password) || this.$t('Users.not-matched-password')]
                                            : []"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!-- permissions -->
                            <template v-if="isEditUser">
                                <h2 class="text-h5 my-3 primary--text">Permissions</h2>
                                <div v-if="permissions.length > 0">
                                    <v-tabs height="40" v-model="tab" grow centered center-active>
                                        <v-tab
                                            v-for="premission in permissions"
                                            :key="premission.id"
                                            class="grey lighten-3"
                                        >
                                            {{ companies.find(c => c.id === premission.companyId).name }}
                                        </v-tab>
                                
                                        <v-tab-item v-for="n in permissions.length" :key="n">
                                            <v-card color="basil" flat>
                                                <v-card-text>
                                                    <v-radio-group
                                                        v-model="permissions[tab].viewerOnly"
                                                    	:disabled="dialogLoading"
                                                        row
                                                        hide-details
                                                        class="mt-0 mb-4"
                                                    >
                                                        <span class="text-body-1 primary--text me-3">
                                                            {{$t('Users.user-permissions')}}:
                                                        </span>
                                                        <v-radio
                                                        	v-for="item in [
                                                                {label: $t('Users.read-only'), value: true},
                                                                {label: $t('Users.read-and-write'), value: false}
                                                            ]"
                                                            :key="item.label"
                                                            :label="item.label"
                                                            :value="item.value"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                    <v-row class="justify-ceter align-center">
                                                        <!-- Sales contract -->
                                                        <v-col
                                                            v-for="fileType in permissions[tab].fileTypes"
                                                            :key="fileType.id"
                                                            sm="4"
                                                            cols="6"
                                                            class="py-1"
                                                        >
                                                            <v-checkbox
                                                                v-model="fileType.value"
                                                                :label="fileType.label"
                                                                :true-value="fileType.id"
                                                                :false-value="null"
                                                                :disabled="dialogLoading"
                                                                hide-details
                                                                class="ma-0"
                                                            ></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>                         
                                    </v-tabs>
                                </div>
                            </template>
                        </v-container>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            :loading="dialogSubmit"
                            :disabled="dialogSubmit || dialogLoading"
                            type="submit"
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            :disabled="dialogSubmit"
                            @click="dialog = false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : users.filter(c => !c.deletedAt)"
          :items-per-page="15"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{ 
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
          }"
          disable-sort
          :loading="loading"
          dense
          class="elevation-2"
        >
            <!-- user type -->
            <template v-slot:item.userType="{ item }">
                {{ userTypes.find(c => c.id === item.userType).type }}
            </template>

            <!-- last login -->
            <template v-slot:item.lastLogin="{ item }">
                {{
                    item.lastLogin
                        ? moment(item.lastLogin)
                            .format('DD-MM-YYYY, hh:mm A')
                            .replace(/AM|PM/, (matched) => $i18n.locale === 'ar' ? ({AM: 'ص', PM: 'م'})[matched] : matched)
                        : 'N/A'
                }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{ item }">
                <!-- edit -->
                <tooltip :text="$t('edit')">
                    <v-btn
                        text
                        small
                        :disabled="item.userType === 0"
                        @click="activeDialog(item.id)"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>

                <!-- user auditlog -->
                <tooltip :text="$t('tooltips.user-records')">
                    <v-btn
                        text
                        small
                        :disabled="item.userType === 0"
                        :to="{ name: 'audit-log', params: { userId: item.id } }"
                    >
                        <v-icon>mdi-update</v-icon>
                    </v-btn>
                </tooltip>

                <!-- delete -->
                <tooltip :text="$t('delete')">
                    <v-btn
                        text
                        small
                        :disabled="item.userType === 0"
                        @click="$refs.DeleteDialog.activeDialog(item.id, 'users')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>

        <!-- snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip.vue'
import userTypes from '../../enums/user-types'
import fileTypes from '../../enums/file-types'
import moment from 'moment'
import rules from '../../validation rules'
import { mapState, mapGetters } from 'vuex'
import { backToLastCompanyContext } from '../../helpers'

export default {
    name: 'Users',

    data: () => ({
        userTypes,
        rules,
        loading: false,

        tab: 0,
        
        permissions: [],

        // dialog
        dialog: false,
        dialogTitle: null,
        dialogLoading: false,
        dialogSubmit: false,
        isEditUser: false,
        userId: null,

        user: {
            username: null,
            password: null,
            confirmPassword: null,
        },
        
        // snackbar
        snackbar: false,
        message: null,
    }),

    components:{
        DeleteDialog,
        Tooltip,
    },

    computed:{
        ...mapState({
            users: state => state.users.users,
            companies: state => state.companies.companies
        }),

        ...mapGetters({
            getUserById: 'users/getUserById'
        }),

        headers() {
            return [
                { 
                    text: this.$t('headers.username'), value: 'username', align: 'center', class: 'primary--text', cellClass: 'primary--text', width: '25%'
                },
                { text: this.$t('headers.user-type'), value: 'userType', align: 'center', width: '25%' },
                { text: this.$t('headers.last-login'), value: 'lastLogin', align: 'center', width: '25%' },
                { text: this.$t('headers.actions'), value: 'actions', align: 'center', width: '25%' },
            ]
        }
    },

    watch:{
        dialog(){
            if (this.dialog === false){
                this.user.username = null;
                this.user.password = null;
                this.user.confirmPassword = null;

                this.$refs.form.resetValidation();

                this.permissions.forEach(permission => {
                    permission.fileTypes.forEach(fileType => {
                        fileType.value = null;
                    });
                    permission.viewerOnly = false;
                })

                this.tab = 0;
                this.isEditUser = false;
                this.userId = null;
            }
        }
    },

    methods: {
        initPermissions(){
            this.companies.forEach((company, index) => {
                this.permissions.push({
                    id: index,
                    companyId: company.id,
                    fileTypes: [
                        { label: this.$t('file-types.sales-contract'), value: null, id: fileTypes.salesContract },
                        { label: this.$t('file-types.proforma-invoice'), value: null, id: fileTypes.invoice },
                        { label: this.$t('file-types.commercial-invoice'), value: null, id: fileTypes.commercialInvoice },
                        { label: this.$t('file-types.packing-list'), value: null, id: fileTypes.packingList },
                        { label: this.$t('file-types.shipping-advise'), value: null, id: fileTypes.shippingAdvise },
                        { label: 'Ordenes de empaque', value: null, id: fileTypes.packingOrder },
                        { label: 'Ordenes de salida', value: null, id: fileTypes.productReleaseOrder },
                        { label: this.$t('file-types.fumigation-certificate'), value: null, id: fileTypes.fumigationCertificate },
                        { label: this.$t('file-types.health-certificate'), value: null, id: fileTypes.healthCertificate },
                        { label: this.$t('file-types.weight-and-quality-certificate'), value: null, id: fileTypes.weightAndQualityCertificate },
                        { label: 'Despacho a Puerto', value: null, id: fileTypes.portDispatch },
                    ],
                    viewerOnly: false
                })
            })
        },

        // dialog fuctions
        submit(){
            if (this.$refs.form.validate()){
                const userAction = this.isEditUser ? 'update' : 'create';
                const permissionAction = 'updateUserPermissions';

                this.dialogSubmit = true;

                const finalPermissions = [];
                this.permissions.forEach((permission) => {
                    finalPermissions.push({
                        companyId: permission.companyId,
                        fileTypes: permission.fileTypes.map(fileType => fileType.value).filter(c => c !== null),
                        viewerOnly: permission.viewerOnly
                    })
                })

                this.$store.dispatch(`users/${userAction}`, {
                    id: this.userId,
                    username: this.user.username,
                    password: this.user.password,
                    passwordConfirmation: this.user.confirmPassword
                })
                .then(() => {
                    this.message = this.$t('messages.user-is-created');
                    if (this.userId && this.isEditUser) {
                        this.$store.dispatch('users/updateUserPermissions', {
                            id: this.userId,
                            permissions: finalPermissions
                        })
                        .then(() => {
                            this.dialog = false;
                        })
                        .finally(() => {
                            this.dialogSubmit = false
                        });
                        this.message = this.$t('messages.user-is-updated')
                    }
                })
                .then(() => {
                    this.dialog = false;
                    this.fetchUsers();
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                })
                .finally(() => {
                    this.snackbar = true;
                    this.dialogSubmit = false;
                });
            }
        },

        activeDialog(userId){
            if (userId){
                this.userId = userId;
                this.isEditUser = true;
                this.dialogLoading = true;
                this.$store.dispatch('users/fetchUserPermissions', { id: userId })
                .then((data) => {
                    if (data.companyPermissions.length > 0){
                        data.companyPermissions.forEach(dataPermission => {
                            if (dataPermission.companyId){
                                this.permissions.find(c => c.companyId === dataPermission.companyId)
                                .fileTypes.forEach(fileType => {
                                    if (dataPermission.fileTypes.includes(fileType.id)){
                                        fileType.value = fileType.id;
                                    }
                                })
                                this.permissions.find(c => c.companyId === dataPermission.companyId)
                                .viewerOnly = dataPermission.viewerOnly;
                            }
                        })
                    }
                })
                .finally(() => {
                    this.dialogLoading = false;
                })
                this.user.username = this.getUserById(this.userId).username;
            }
            this.dialog = true;
        },

        // fetch users
        fetchUsers(){
            this.loading = true;
            return this.$store.dispatch('users/fetchAll')
            .finally(() => {
                this.loading = false;
            });
        },

        // helpers
        moment,
        backToLastCompanyContext
    },

    created(){
        this.loading = true;
        Promise.all([
            this.fetchUsers(),
            this.$store.dispatch('companies/fetchAll')
        ])
        .then(() => {
            this.initPermissions();
        })
        .finally(() => {
            this.loading = false;
        })
    }
}
</script>

<style>

</style>