<template>
    <div>
        <div class="d-flex justify-space-between align-center mb-5">
            <h1 class="text-h5 primary--text">{{$t('CustomersAndSuppliers.customers-and-Suppliers')}}</h1>
            
            <!-- client-dialog is decleared here to use it as ref -->
            <client-dialog ref="ClientDialog" @on-save="fetchCustomers"></client-dialog>

            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="fetchCustomers">
                <span class="text-body-1">
                    {{$t('are-you-sure-you-want-to')}}
                    <span class="text-uppercase error--text">
                        {{$t('delete')}}
                    </span>
                    {{$t('CustomersAndSuppliers.the-customer')}}
                </span>
            </delete-dialog>

            <v-btn text small class="lightPrimary white--text" @click="addClient">
                {{$t('CustomersAndSuppliers.add-a-customer')}}
            </v-btn>
        </div>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : customers.filter(c => c.deletedAt === null)"
          :items-per-page="20"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{ 
            'items-per-page-options': [10, 20, 40, 100, -1],
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          :loading="loading"
          dense
          class="elevation-2"
        >
            <!-- taxNumber -->
            <template v-slot:item.taxNumber="{item}">
                {{item.taxNumber ? item.taxNumber : 'N/A' }}
            </template>

            <!-- boxNumber -->
            <template v-slot:item.boxNumber="{item}">
                {{item.boxNumber ? item.boxNumber : 'N/A' }}
            </template>

            <!-- address -->
            <template v-slot:item.address="{item}">
                {{item.address ? item.address : 'N/A' }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('tooltips.edit')">
                        <v-btn text small @click="editClient(item.id)">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip :text="$t('tooltips.delete')">
                        <v-btn text small @click="$refs.DeleteDialog.activeDialog(item.id, 'customers')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ClientDialog from '../../components/ClientDialog'
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import { mapState } from 'vuex';
export default {
    data: () => ({
        // helpers
        loading: true,
    }),

    components:{
        ClientDialog,
        DeleteDialog,
        Tooltip
    },

    methods:{
        // dialog functions 
        addClient(){
            this.$refs.ClientDialog.addClient();
        },
        editClient(customerId){
            this.$refs.ClientDialog.editClient(customerId);
        },
        
        fetchCustomers(){
            this.loading = true;
            Promise.all([
                this.$store.dispatch('items/fetchAll'),
                this.$store.dispatch('customers/fetchAll')
            ])
            .finally(() => {
                this.loading = false;
            })
        }
    },

    computed:{
        ...mapState({
            customers: state => state.customers.customers,
            items: state => state.items.items,
        }),

        headers() {
            return [
                { text: this.$t('headers.name'), value: 'name', align:'start', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.tax-no'), value: 'taxNumber', align:'start', },
                { text: this.$t('headers.box-no'), value: 'boxNumber', align:'start', },
                { text: this.$t('headers.address'), value: 'address', align:'start', },
                { text: this.$t('headers.actions'), value: 'actions', align:'center', sortable: true },
            ]
        }
    },

    mounted(){
        this.fetchCustomers();
    }
}
</script>

<style>

</style>