export default {
    'select-company': 'Select company',
    'switch-company': 'Switch company',
    'language': 'Language',
    'settings': 'Settings',
    'manage-companies': 'Manage companies',
    'manage-users': 'Manage users',
    'change-password': 'Change password',
    'manage-currencies': 'Manage currencies',
    'view-templates': 'View templates',
    'signout': 'Sign out',
    'available-in-next-version': 'Available in next version',

    dialog: {
        'change-password': 'Change password',
        'old-password': 'Old password',
        'new-password': 'New password',
        'confirm-new-password': 'Confirm new password',
    }
}