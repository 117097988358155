<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 primary--text">Ordenes de empaque</h1>

            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="filter">
                <span class="text-body-1">
                    Are you sure you want to <span class="error--text">DELETE</span> the Packing Order?
                </span>
            </delete-dialog>

            <!-- Add an Proforma Invoice -->
            <v-btn
              v-if="!viewerOnly"
              text
              small
              class="lightPrimary white--text"
              :to="{
                name:'packing-order',
                params:{companyId: $route.params.companyId},
              }"
            >
                Agregar orden de empaque
            </v-btn>
        </div>

        <!-- filter -->
        <v-form class="my-2" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1">
                    Filter
                </v-col>

                <!-- Contrato -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-text-field
                        v-model="referenceContract"
                        :disabled="filterLoading"
                        label="Número"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- Cliente -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-combobox
                        v-model="customer"
                        label="Cliente"
                        :items="customers"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-combobox>
                </v-col>

                <!-- Consignatario -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-combobox
                        v-model="consignee"
                        label="Consignatario"
                        :items="customers"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-combobox>
                </v-col>

                <!-- Contenedor No. -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-text-field
                        v-model="containerNo"
                        label="Contenedor No."
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date ? moment(date) : null"
                                label="Partir de la fecha"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate.save(date)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Fecha de exportacion -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="exportDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="exportDate ? moment(exportDate) : null"
                                label="Hasta la fecha"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="exportDate"
                            :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.exportDateMenu.save(exportDate)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        width="80"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        Search
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        icon
                        small
                        @click="fetchShippingAdvices();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : packingOrders"
          :items-per-page="20"
          :footer-props="{ 'items-per-page-options': [10, 20, 40, 100, -1], showCurrentPage:true}"
          :loading="loading"
          disable-sort
          dense
          class="elevation-2"
        >
            <!-- referenceContract -->
            <template v-slot:item.referenceContract="{item}">
                {{item.referenceContract ? item.referenceContract : 'N/A'}}
            </template>

            <!-- customer -->
            <template v-slot:item.customer="{item}">
                {{item.customer ? item.customer : 'N/A'}}
            </template>

            <!-- consignee -->
            <template v-slot:item.consignee="{item}">
                {{item.consignee ? item.consignee : 'N/A'}}
            </template>

            <!-- date -->
            <template v-slot:item.date="{item}">
                {{item.date ? moment(item.date) : 'N/A'}}
            </template>

            <!-- exportDate -->
            <template v-slot:item.exportDate="{item}">
                {{item.exportDate ? moment(item.exportDate) : 'N/A'}}
            </template>
            
            <!-- totalNetWeight -->
            <template v-slot:item.totalNetWeight="{item}">
                {{item.totalNetWeight ? item.totalNetWeight : 'N/A' | currency }}
            </template>
            
            <!-- totalUnits -->
            <template v-slot:item.totalUnits="{item}">
                {{item.totalUnits ? item.totalUnits : 'N/A' | currency }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Descargar Como Pdf">
                        <v-btn text small @click="report(item.id)">
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <template v-if="viewerOnly">
                        <tooltip text="Avance">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'packing-order',
                                    params:{companyId: $route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>

                    <template v-else>
                        <tooltip text="Duplicar">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'packing-order',
                                    params:{companyId: $route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-content-duplicate
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Editar">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'packing-order',
                                    params:{companyId: $route.params.companyId},
                                    query:{id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Eliminar">
                            <v-btn @click="$refs.DeleteDialog.activeDialog(item.id, 'packingOrders')" text small>
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} of {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import { mapState, mapGetters } from 'vuex';
import { BASE_API_URL } from '../../constants';
export default {
    data: () => ({
        // filter data
        referenceContract: null,
        customer: null,
        consignee: null,
        containerNo: null,
        date: null,
        exportDate: null,

        // helpers
        loading: true,
        filterLoading: true,
        isFiltered: false,
    }),

    methods:{
        // filter funciton
        clearFilter(){
            this.referenceContract = null;
            this.customer = null;
            this.consignee = null;
            this.containerNo = null;
            this.date = null;
            this.exportDate = null;

            this.isFiltered = false;
        },
        
        filter(){
            this.loading = true;
            this.$store.dispatch('packingOrders/fetchAll', {
                sequence: this.referenceContract,
                fromDate: this.date,
                toDate: this.exportDate
            })
            .finally(() => {
                this.loading = false;
            })
        },

        fetchShippingAdvices(){
            this.clearFilter();
            this.filter();
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/PackingOrders/${id}`);
        },

        // table funcitons
        moment(date){
            return window.moment(date).format(this.fileSettings.dateFormat ? this.fileSettings.dateFormat.toUpperCase() : 'DD-MM-YYYY');
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                this.referenceContract === null &&
                this.customer === null &&
                this.consignee === null &&
                this.containerNo === null &&
                this.date === null &&
                this.exportDate === null
            )
        },

        ...mapState({
            customers: state => state.customers.customers,
            packingOrders: state => state.packingOrders.packingOrders,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),

        headers() {
            const headers = [
                { text:'Número', value:'sequence', class:'primary--text', cellClass:'primary--text', width: 100 },
                { text:'Fecha', value:'date', class:'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                { text:'Contrato', value:'referenceContract' },
                { text:'Cliente', value:'customer' },
                { text:'Consignatario', value:'consignee' },
                { text:'Fecha de exportacion', value:'exportDate', class:'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                { text:'Total en KGs', value:'totalNetWeight' },
                { text:'Total de sacos', value:'totalUnits' },
                { text:'Actions', value:'actions', width: 150 },
            ];

            // set shared props
            for (let i = 0; i < headers.length; i++) {
                headers[i].align = 'center';
                headers[i].class = headers[i].class ? `${headers[i].class} px-2` : 'px-2';
                headers[i].cellClass = headers[i].cellClass ? `${headers[i].cellClass} px-2` : 'px-2';
            }

            return headers;
        },
    },

    mounted(){
        Promise.all([
            this.$store.dispatch('customers/fetchAll'),
            this.$store.dispatch('packingOrders/fetchAll', { sequence: null, fromDate: null, toDate: null })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    components:{
        DeleteDialog,
        Tooltip
    }
}
</script>

<style>

</style>