import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        healths: []
    },

    actions:{
        fetchAll({ commit }, { date }){
            return window.axios.get('/files/health', {
                params:{
                    date
                }
            })
            .then((response) => {
                commit(types.STORE_HEALTH, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/health/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.post('/files/health', { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items })
            .then((response) => {
                return response.data;
            });
        },

        update({ commit }, { id, date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.put(`/files/health/${id}`, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items })
            .then((response) => {
                return id;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/health/${id}`);
        }
    },

    mutations:{
        [types.STORE_HEALTH](state, healths){
            state.healths = healths;
        }
    },

    getters:{
        gethealthById: state => id => state.healths.find(c => c.id === id),
    }
}