import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import companies from './modules/companies'
import bankAccounts from './modules/bank-accounts'
import units from './modules/units'
import customers from './modules/customers'
import items from './modules/items'
import fileSettings from './modules/file-settings'
import fiscalYears from './modules/fiscal-years'
import templates from './modules/templates'
import containers from './modules/containers'
import sequences from './modules/sequences'
import salesContracts from './modules/sales-contracts'
import invoices from './modules/invoices'
import commercialInvoices from './modules/commercial-invoices'
import packingLists from './modules/packing-lists'
import shippingAdvises from './modules/shipping-advises'
import packingOrders from './modules/packing-orders'
import productReleaseOrders from './modules/product-release-orders'
import fumigationCertificates from './modules/fumigation-certificates'
import healthCertificates from './modules/health-certificates'
import weightAndQualityCertificates from './modules/weight-and-quality-certificates'
import portDispatches from './modules/port-dispatches'
import users from './modules/users'
import reportsPreview from './modules/reports-preview'
import technicalAndFinancialOffers from './modules/technical-and-financial-offers'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        companies,
        bankAccounts,
        units,
        customers,
        items,
        fileSettings,
        fiscalYears,
        templates,
        containers,
        sequences,
        salesContracts,
        invoices,
        commercialInvoices,
        packingLists,
        shippingAdvises,
        packingOrders,
        productReleaseOrders,
        fumigationCertificates,
        healthCertificates,
        weightAndQualityCertificates,
        portDispatches,
        users,
        reportsPreview,
        technicalAndFinancialOffers
    }
})