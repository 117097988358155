<template>
    <div class="loader">
        <v-progress-circular
            indeterminate
            color="primary"
            width="6"
            size="45"
        ></v-progress-circular>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.loader{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 90vh;
}
</style>