export default {
    'management': 'الإدارة',
    'documents': 'المستندات',
    'files-settings': 'إعدادات الملفات',
    'customers-and-suppliers': 'الزبائن والموردون',
    'items-and-materials': 'المواد والأصناف',
    'sales-contracts': 'عقود البيع',
    'proforma-invoices': 'الفواتير الأولية ',
    'commercial-invoices': 'الفواتير التجارية ',
    'packing-lists': 'قوائم التعبئة',
    'shipping-advises': 'إشعارات الشحن',
    'technical-and-financial-offers': 'العروض المالية والفنية'
}