<template>
    <v-layout class="login" align-center justify-center>
        <v-flex xs12 sm8 md4>
            <div class="text-center">
                <img src="/img/acacia.png" class="logo my-4" />
            </div>
            <v-form @submit.prevent="login" ref="form">
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{$t('Login.sign-in')}}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-text-field
                          prepend-icon="mdi-account"
                          name="login"
                          :label="$t('Login.username')"
                          type="text"
                          v-model="username"
                          :disabled="loading"
                          :rules="rules.required"
                          required
                        ></v-text-field>
                        <v-text-field
                          id="Password"
                          prepend-icon="mdi-lock"
                          name="password"
                          :label="$t('Login.password')"
                          type="password"
                          v-model="password"
                          :disabled="loading"
                          :rules="rules.required"
                          required
                        ></v-text-field>
                    </v-card-text>

                    <v-card-actions>

                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          type="submit"
                          :disabled="loading"
                          :loading="loading"
                        >
                            {{$t('Login.sign-in')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
            <v-alert
              :value="error != null"
              v-if="!loading && error"
              type="error"
              class="mt-3"
              transition="scale-transition"
            >{{ error }}</v-alert>
        </v-flex>
    </v-layout>
</template>

<script>
import rules from '../../validation rules'
export default {
    data: () => ({
        username: null,
        password: null,
        loading: false,

        error: null,
        
        rules
    }),

    methods: {
        login() {
            if (this.$refs.form.validate()){
                this.loading = true;
                this.$store.dispatch('auth/login', {
                    username: this.username,
                    password: this.password
                })
                .then(() => {
                    this.$router.replace({ name: 'home' });
                    this.$store.dispatch('users/fetchSelfPermissions');
                })
                .catch((e) => {
                    this.error = e.response.data.message;
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        }
    },
}
</script>

<style lang="scss">
.login{
    height: 90vh;
    
    .logo{
        max-width: 45%;
        min-width: 150px;
    }
}
</style>