if (!('local-drafts' in localStorage)) {
    localStorage.setItem('local-drafts', JSON.stringify({}));
}

export function save(draftName, data) {
    const localDrafts = JSON.parse(localStorage['local-drafts']);
    const userId = JSON.parse(localStorage.auth).id;
    if (!(userId in localDrafts)){
        localDrafts[userId] = {}
    }

    localDrafts[userId][draftName] = data;
    localStorage.setItem('local-drafts', JSON.stringify(localDrafts))
}

export function get(draftName) {
    const localDrafts = JSON.parse(localStorage['local-drafts']);
    const userId = JSON.parse(localStorage.auth).id;
    if (!(userId in localDrafts) || !(draftName in localDrafts[userId])){
        return null
    }
    return localDrafts[userId][draftName]
}

export function remove(draftName) {
    const localDrafts = JSON.parse(localStorage['local-drafts']);
    const userId = JSON.parse(localStorage.auth).id;

    if (!(userId in localDrafts)) {
        return -1;
    }
    else {
        delete localDrafts[userId][draftName]
    }
    if (Object.keys(localDrafts[userId]).length === 0) {
        delete localDrafts[userId];
    }
    localStorage.setItem('local-drafts', JSON.stringify(localDrafts))
}

export default { save, get, remove };