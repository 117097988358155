<template>
    <div>
        <h1 class="text-h5 primary--text mb-4">{{$t('FilesSetting.files-settings')}}</h1>
		
		<!-- serials & calculations & imag -->
		<v-row>
			<!-- serials -->
			<v-col md="6" cols="12">
				<v-layout wrap justify-space-between align-center>
					<v-flex md8>
						<h2 class="text-h6">{{$t('FilesSetting.serial-sequences-for-system-files')}}</h2>
					</v-flex>
					<v-flex md4>
						<v-text-field
							v-model.number="paddedDigitsCount"
							:disabled="loading"
							dense
							hide-details="auto"
							type="number"
							min="0"
							:label="$t('FilesSetting.dynamic-part-length')"
							:hint="$t('FilesSetting.dynamic-part-will-be-left-padded-with-zeroes')"
							class="mt-3 mb-0"
						></v-text-field>
					</v-flex>
				</v-layout>
			
				<three-part-sequence
					v-if="shownFilesConfigs.invoices && isCompanyHasFile(fileTypes.invoice)"
					:label="$t('FilesSetting.proforma-invoices-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="invoicesSequence"
					class="mt-3"
				></three-part-sequence>
			
				<three-part-sequence
					v-if="shownFilesConfigs.salesContracts && isCompanyHasFile(fileTypes.salesContract)"
					:label="$t('FilesSetting.sales-contracts-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="salesContractsSequence"
					class="mt-3"
				></three-part-sequence>
			
				<three-part-sequence
					v-if="shownFilesConfigs.commercialInvoices && isCompanyHasFile(fileTypes.commercialInvoice)"
					:label="$t('FilesSetting.commercial-invoices-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="commercialInvoicesSequence"
					class="mt-3"
				></three-part-sequence>
			
				<three-part-sequence
					v-if="shownFilesConfigs.packingOrders && isCompanyHasFile(fileTypes.packingOrder)"
					:label="$t('FilesSetting.orden-de-empaque-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="packingOrdersSequence"
					class="mt-3"
				></three-part-sequence>
			
				<three-part-sequence
					v-if="shownFilesConfigs.productReleaseOrders && isCompanyHasFile(fileTypes.productReleaseOrder)"
					:label="$t('FilesSetting.orden-de-salida-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="productReleaseOrdersSequence"
					class="mt-3"
				></three-part-sequence>

				<three-part-sequence
					v-if="shownFilesConfigs.portDispatches && isCompanyHasFile(fileTypes.portDispatch)"
					:label="$t('FilesSetting.despacho-a-puerto-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="portDispatchesSequence"
					class="mt-3"
				></three-part-sequence>
				
				<three-part-sequence
					v-if="shownFilesConfigs.technicalAndFinancialOffers && isCompanyHasFile(fileTypes.technicalAndFinancialOffer)"
					:label="$t('FilesSetting.technical-and-financial-offers-sequence-beginning')" 
					:disabled="loading"
					:loading="loading"
					v-model="technicalAndFinancialOffersSequence"
					class="mt-3"
				></three-part-sequence>

				<!-- fiscal year -->
				<div class="d-flex flex-wrap align-center mt-3 mb-2">
					<h2 class="text-h6">{{$t('FilesSetting.current-fiscal-year')}}</h2>
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
					<v-btn
						v-if="0"
						text
						color="primary"
						:loading="filtersLoading"
						:disabled="filtersLoading"
						@click="filtersDialog = true"
					>
						manage periods
					</v-btn>
					<tooltip v-else :text="$t('tooltips.manage-fiscal-years')">
						<v-btn
							text
							color="primary"
							:loading="filtersLoading"
							:disabled="filtersLoading"
							@click="filtersDialog = true"
						>
							<v-icon>mdi-timetable</v-icon>
						</v-btn>
					</tooltip>
				</div>
				<v-layout wrap align-center v-if="filterData">
					<v-flex lg3 md4 sm3 xs12 class="pe-2">
						<span class="text-body-1 primary--text">{{filterData.name}}</span>
					</v-flex>
					<v-flex lg6 md8 sm6 xs12 class="text-body-1">
						{{moment(filterData.startDate).format(dateFormat)}}
						<span class="primary--text mx-2">{{$t('FilesSetting.to')}}</span>
						{{moment(filterData.endDate).format(dateFormat)}}
					</v-flex>
				</v-layout>
				<p v-else class="text-body-1 mb-0">{{$t('FilesSetting.no-filter')}}</p>
			</v-col>

			<!-- calculations & image -->
			<v-col md="6" cols="12">
				<!-- calculations -->
				<h2 class="text-h6 mb-2">{{$t('FilesSetting.automatic-calculations')}}</h2>
				<v-layout wrap align-center class="mb-4">
					<div class="text-body-1 me-3">{{$t('FilesSetting.tax-calculation')}}</div>

					<v-radio-group v-model="taxFixed" row :disabled="loading" hide-details class="mt-0">
						<v-radio
							:label="$t('FilesSetting.percentage')"
							:value="false"
						></v-radio>
						<v-radio
							:label="$t('FilesSetting.fixed-amount')"
							:value="true"
						></v-radio>
					</v-radio-group>
				</v-layout>

				<v-layout wrap align-center>
					<v-flex md4 sm5 xs12 class="mb-sm-0 mb-2 me-sm-4">
						<v-text-field
							v-model.number="taxAmount"
							:disabled="loading"
							hide-details="auto"
							:label="$t('FilesSetting.value')"
							dense></v-text-field>
					</v-flex>
					<v-flex md4 sm5 xs12>
						<v-text-field
							v-model.number="taxLabel"
							:disabled="loading"
							hide-details="auto"
							:label="$t('FilesSetting.tax-label')"
							dense></v-text-field>
					</v-flex>
				</v-layout>

				<v-checkbox
					v-model="hasTaxInvoice"
					:label="$t('FilesSetting.a-taxed-commercial-invoice-becomes-a-tax-invoice')"
					:disabled="loading"
					hide-details
					class="mb-2"/>

				<!-- date format -->
				<v-layout wrap class="mt-4" :class="{'mb-4': !dateFormat}">
					<v-flex shrink lg4 md6 sm3 xs12>
						<h1 class="text-h6 mt-1 mb-sm-0 mb-3 me-3">{{$t('FilesSetting.date-format')}}</h1>
					</v-flex>
					<v-flex lg5 md6 sm4 xs12>
						<v-autocomplete
							v-model="dateFormat"
							:items="dateFormats"
							:disabled="loading"
							outlined
							hide-details="auto"
							:label="$t('FilesSetting.format')"
							dense
							:hint="dateFormat ? 'eg. ' + moment().format(dateFormat) : null"
							:persistent-hint="Boolean(dateFormat)"
						></v-autocomplete>
					</v-flex>
				</v-layout>

				<!-- template image -->
				<h2 class="text-h6 mb-3">{{$t('FilesSetting.current-files-images')}}</h2>
				
				<div class="d-flex flex-wrap align-start">
					<!-- template image -->
					<v-card max-width="180" max-height="380" class="me-3" :loading="loading">
						<v-card-title class="justify-center py-0 pt-1">
							<span class="text-body-1">{{$t('FilesSetting.template')}}</span>
						</v-card-title>
						<v-card-text class="pa-2 pb-0">
							<v-img
								v-if="!loading && (fileSettings.templateUrl || chosenTemplate)"
								:src="(BASE_API_URL+'/').concat(chosenTemplate || fileSettings.templateUrl)"
								style="border: 1px solid #ccc; border-radius: 5px"
								width="100%"
								height="100%"
								lazy-src="/img/placeholder-image.png"
							/>
							<v-img
								v-else
								src="/img/placeholder-image.png"
								width="100%"
								height="100%"
							/>
						</v-card-text>
						<v-card-actions class="py-1">
							<v-spacer></v-spacer>
							<v-btn
								color="primary lighten-2"
								class="px-6"
								@click="isTemplate = true; imagesDialog = true;"
								text
								:loading="imagesLoading"
								:disabled="imagesLoading"
							>
								{{$t('change')}}
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>

					<!-- container image -->
					<v-card max-width="180" max-height="380" :loading="loading">
						<v-card-title class="justify-center py-0 pt-1">
							<span class="text-body-1">{{$t('FilesSetting.container')}}</span>
						</v-card-title>
						<v-card-text class="pa-2 pb-0">
							<v-img
								v-if="!loading && (fileSettings.containerUrl || chosenContainer)"
								:src="(BASE_API_URL+'/').concat(chosenContainer || fileSettings.containerUrl)"
								style="border: 1px solid #ccc; border-radius: 5px"
								width="100%"
								height="100%"
								contain
								lazy-src="/img/placeholder-image.png"
							/>
							<v-img
								v-else
								src="/img/placeholder-image.png"
								width="100%"
								height="100%"
							/>
						</v-card-text>
						<v-card-actions class="py-1">
							<v-spacer></v-spacer>
							<v-btn
								color="primary lighten-2"
								class="px-6"
								@click="isTemplate = false; imagesDialog = true;"
								text
								:loading="imagesLoading"
								:disabled="imagesLoading"
							>
								{{$t('change')}}
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</div>
			</v-col>
		</v-row>

		<!-- actions -->
		<v-row>
			<v-col cols="12" class="d-flex">
				<v-spacer></v-spacer>
				<v-btn @click="saveSettings" :loading="loading" :disabled="loading" color="primary">
					{{$t('save')}}
				</v-btn>
			</v-col>
		</v-row>

		<!-- Fiscal year filters -->
		<v-dialog v-model="filtersDialog" width="900">
			<v-card>
				<v-card-title class="primary white--text justify-center py-2">
					<v-spacer/>
					<span class="text-h6">{{$t('FilesSetting.fiscal-years')}}</span>
					<v-spacer/>
					<v-btn text small fab @click="filtersDialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-card-text class="pt-4 pb-1">
					<!-- add period form -->
					<v-expand-transition>
						<v-form ref="formFilter" @submit.prevent="submitFilter" v-if="isAddingFilter || editFilterId">
							<v-row class="align-center mt-0 mb-2">
								<v-col md="4" sm="6" cols="12" class="pt-0 pb-2 pb-0">
									<v-text-field
										v-model="filterInputs.name"
										:label="$t('headers.period-name')"
										changeable-with-arrows
										hide-details
										:disabled="filterInputs.loading"
										:rules="rules.required"
									></v-text-field>
								</v-col>
								<v-col md="3" sm="6" cols="12" class="pt-0 pb-2 pb-0">
									<bee-date-input
										v-model="filterInputs.startDate"
										:label="$t('FilesSetting.start-date')"
										changeable-with-arrows
										prepend-icon="mdi-calendar"
										:input-format-order="dateFormat || 'DD-MM-YYYY'"
										:user-menu-picker="true"
										hide-details
										:disabled="filterInputs.loading"
										:rules="rules.required"
									></bee-date-input>
								</v-col>
								<v-col md="3" sm="6" cols="12" class="pt-0 pb-2 pb-0">
									<bee-date-input
										v-model="filterInputs.endDate"
										:label="$t('FilesSetting.end-date')"
										changeable-with-arrows
										prepend-icon="mdi-calendar"
										:input-format-order="dateFormat || 'DD-MM-YYYY'"
										:user-menu-picker="true"
										hide-details
										:disabled="filterInputs.loading"
										:rules="rules.required"
									></bee-date-input>
								</v-col>
								<v-col md="2" sm="6" class="pb-2 pb-0 text-md-center text-sm-start text-end">
									<v-btn
										color="primary"
										type="submit"
										:loading="filterInputs.loading"
										:disabled="filterInputs.loading"
									>
										{{editFilterId ? $t('edit') : $t('add')}}
									</v-btn>
									<v-btn
										class="ms-2"
										icon
										@click="resetFilterInputs()"
									>
										<v-icon>mdi-close-circle-outline</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-expand-transition>

					<!-- table -->
					<v-data-table
						:headers="filtersHeaders"
						:items="filterInputs.loading ? [] : fiscalYears"
						:items-per-page="10"
						:no-data-text="$t('no-data-text')"
						:no-results-text="$t('no-results-text')"
						:loading-text="$t('loading-text')"
						:footer-props="{
							'items-per-page-options': [10, 20, 40, 100, -1],
							'items-per-page-all-text': $t('items-per-page-all-text'),
							'items-per-page-text': $t('items-per-page-text'),
							showCurrentPage:true
						}"
						fixed-header
						:height="filtersItems.length > 7  ? '55vh' : null"
						:loading="filterInputs.loading"
						dense
						class="elevation-2"
					>
						<!-- startDate -->
						<template v-slot:item.startDate="{ item }">
							{{moment(item.startDate).format(dateFormat)}}
						</template>

						<!-- endDate -->
						<template v-slot:item.endDate="{ item }">
							{{moment(item.endDate).format(dateFormat)}}
						</template>

						<!-- active -->
						<template v-slot:item.active="{ item }">
							<div class="d-flex justify-center align-center">
								<v-btn
									v-if="item.id !== activeFilterId"
									icon
									@click="activeFilterId = item.id"
								>
									<v-icon>mdi-checkbox-blank-outline</v-icon>
								</v-btn>
								<v-btn
									v-else
									icon
									@click="activeFilterId = null"
									color="primary"
								>
									<v-icon>mdi-checkbox-marked</v-icon>
								</v-btn>
								<v-checkbox
									v-if="0"
									hide-details
									:value="item.id === activeFilterId"
									@change="$event ? activeFilterId = item.id : activeFilterId = null"
									class="mt-0 pt-0 flex-shrink-1"
								></v-checkbox>
							</div>
						</template>

						<!-- actions -->
						<template v-slot:item.actions="{ item }">
							<div class="d-flex justify-center">
								<tooltip :text="$t('tooltips.edit')">
									<v-btn text small>
										<v-icon @click="editFilterId = item.id; fillFilterInputsToEdit(item);">
											mdi-pencil
										</v-icon>
									</v-btn>
								</tooltip>
								
								<tooltip :text="$t('tooltips.delete')">
									<v-btn text small @click="$refs.DeleteFilterDialog.activeDialog(item.id, 'fiscalYears')">
										<v-icon>
											mdi-delete
										</v-icon>
									</v-btn>
								</tooltip>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-btn
						v-if="!isAddingFilter && !editFilterId"
						@click="isAddingFilter = true"
						color="primary"
					>
						{{$t('FilesSetting.add-fiscal-year')}}
					</v-btn>
					<v-spacer/>
					<v-btn text color="primary" @click="saveSettings(); filtersDialog = false">
						{{$t('save')}}
					</v-btn>
					<v-btn text @click="resetFilterInputs(); filtersDialog = false">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- images dialog -->
		<v-dialog
			v-model="imagesDialog"
			width="900"
			ref="dialog"
		>
			<v-card color="white" :loading="imagesLoading">
				<!-- dialog title -->
				<v-card-title class="primary white--text justify-center py-2">
					<v-spacer/>
					<span class="text-h6">
						{{
							isTemplate
							? $t('FilesSetting.manage-company-files-template')
							: $t('FilesSetting.manage-company-files-container')
						}}
					</span>
					<v-spacer/>
					<v-btn text small fab @click="imagesDialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>

				<!-- dialog content -->
				<v-card-text class="pb-1">
					<v-row class="ma-0">
						<v-col
							md="4"
							sm="12"
							v-for="item in (isTemplate? templates : containers)"
							:key="item.id"
						>
							<v-card>
								<v-img 
									:src="(BASE_API_URL+'/').concat(isTemplate ? item.fileTemplatePath : item.companyContainerPath)"
									class="mx-auto"
									width="80%"
									:contain="!isTemplate"
									:max-height="isTemplate ? null : '100px'"
									lazy-src="/img/placeholder-image.png"
								/>
								<v-card-actions
									v-if="
										chosenTemplate !== item.fileTemplatePath &&
										chosenContainer !== item.companyContainerPath
									"
								>
									<v-btn
										color="primary lighten-2"
										class="px-6"
										@click="
											isTemplate
												? chosenTemplate = item.fileTemplatePath
												: chosenContainer = item.companyContainerPath; 
											imagesDialog = false
										"
										:disabled="imagesLoading"
										text
									>
										{{$t('FilesSetting.use')}}
									</v-btn>
									<v-spacer></v-spacer>
									<v-btn
										color="red lighten-1"
										class="px-6"
										@click="$refs.DeleteDialog.activeDialog(item.id, isTemplate ? 'templates' : 'containers')"
										:disabled="
											imagesLoading ||
											fileSettings.templateUrl === item.fileTemplatePath ||
											fileSettings.containerUrl === item.companyContainerPath"
										text
									>
										{{$t('delete')}}
									</v-btn>
								</v-card-actions>

								<v-card-actions v-else>
									<v-spacer/>
									<p class="success--text text-uppercase" style="margin: 7px 0">
										{{$t('FilesSetting.selected')}}
									</p>
									<v-spacer/>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col
							v-if="(isTemplate && templates.length === 0) || (!isTemplate && containers.length === 0)"
							cols="12"
							class="d-flex justify-center align-center text-h6 pt-8"
						>
							<v-icon size="30" class="me-1">mdi-image-off-outline</v-icon>
							{{
								isTemplate
								? $t('FilesSetting.there-is-no-templates')
								: $t('FilesSetting.there-is-no-containers')
							}}
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-btn @click="uploadDialog = true" text color="primary" dark>
						<v-icon class="me-2">mdi-tray-arrow-up</v-icon>
						{{
							isTemplate 
							? $t('FilesSetting.upload-new-template')
							: $t('FilesSetting.upload-new-container')
						}}
					</v-btn>
				</v-card-actions>
		  	</v-card>
		</v-dialog>
		
		<!-- upload image dialog -->
		<v-dialog v-model="uploadDialog" :width="isTemplate ? '265px': '315px'" :persistent="uploadLoading">
			<v-card :loading="uploadLoading">
				<v-card-title class="py-2">
					<v-spacer></v-spacer>
					<span class="text-h6 ms-6">{{$t('FilesSetting.upload-image')}}</span>
					<v-spacer></v-spacer>
					<v-btn text small light fab @click="uploadDialog = false"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-card-text class="pb-3">
					<bee-drop-img
						v-model="uploadFile"
						:height="isTemplate ? '250px': '200px'"
						:width="isTemplate ? '200px': '250px'"
						contain
						:drag-text="$t('FilesSetting.drag-text')"
						:drop-text="$t('FilesSetting.drop-text')"
						:error-message="$t('FilesSetting.error-message')"
						class="mt-3 mx-auto"
					></bee-drop-img>
					<p
						v-html="isTemplate
  						? $t('FilesSetting.template-image-note')
  						: $t('FilesSetting.container-image-note')"
						class="black--text text-caption mx-auto mt-4 mb-0"
						:style="{width: isTemplate ? '200px': '250px'}"
					></p>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn
						color="primary"
						small
						class="elevation-0"
						:disabled="uploadLoading || !uploadFile"
						@click="uploadImage()"
					>
						{{$t('FilesSetting.upload')}}
					</v-btn>
					<v-btn
						color="primary"
						small
						class="elevation-0"
						:disabled="uploadLoading || !uploadFile"
						@click="uploadImage(true)"
					>
						{{$t('FilesSetting.upload-and-change')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- delete-dialog is decleared here to use it as ref -->
		<delete-dialog ref="DeleteDialog" @on-delete="isTemplate ? fetchTemplates() : fetchContainers()">
			<span class="text-body-1">
				{{$t('are-you-sure-you-want-to')}} 
				<span class="text-uppercase error--text">{{$t('delete')}}</span> 
				{{$t('FilesSetting.delete-the-image')}}
			</span>
		</delete-dialog>
		
		<delete-dialog ref="DeleteFilterDialog" @on-delete="fetchFiscalYears">
			<span class="text-body-1">
				{{$t('are-you-sure-you-want-to')}} 
				<span class="text-uppercase error--text">{{$t('delete')}}</span> 
				{{$t('FilesSetting.the-period')}}
			</span>
		</delete-dialog>

		<v-snackbar
            v-model="snackbar"
            color="primary lighten-1">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false">
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { BASE_API_URL } from '../../constants';
// import templates from '../../enums/file-templates';
import fileTypes from '../../enums/file-types';
import Tooltip from '../../components/Tooltip';
import ThreePartSequence from '../../components/ThreePartSequence';
import DeleteDialog from '../../components/DeleteDialog';
import * as shownFilesConfigs from '../../configs/shown-files-config'
import rules from '../../validation rules'
import moment from 'moment'

export default {
    data: () => ({
		loading: false,
		snackbar: false,
		message: null,

		filtersLoading: false,
		filtersDialog: false,
		isAddingFilter: false,
		editFilterId: null,
		filterInputs: {
			name: null,
			startDate: null,
			endDate: null,
			loading: false
		},
		filterData: null,

		imagesDialog: false,
		imagesLoading: false,

		uploadDialog: false,
		uploadLoading: false,
		uploadFile: null,
		isTemplate: null,
		dateFormats: [
			'DD-MM-YYYY', 'MM-DD-YYYY',
			'YYYY-MM-DD', 'YYYY-DD-MM',
		],

        // data
        invoicesSequence: null,
        salesContractsSequence: null,
        commercialInvoicesSequence: null,
		packingOrdersSequence: null,
		productReleaseOrdersSequence: null,
		portDispatchesSequence: null,
		technicalAndFinancialOffersSequence: null,
		activeFilterId: null,
        taxFixed: false,
		taxAmount: null,
		taxLabel: null,
		hasTaxInvoice: false,
		chosenTemplate: null,
		chosenContainer: null,
		paddedDigitsCount: null,
		dateFormat: 'DD-MM-YYYY',

		BASE_API_URL,
		fileTypes,
		shownFilesConfigs,
		rules,
		// templates,
	}),
	
	watch: {
		uploadDialog(val) {
			if (val) {
				this.uploadFile = null;
			}
		}
	},

	methods: {
		fetchSettings() {
			this.loading = true;
            this.$store.dispatch('fileSettings/fetch')
			.then(data => {
				this.fillSettingsData(data);
			})
			.finally(() => {
				this.loading = false;
			})
		},

		fillSettingsData(data) {
			this.taxFixed = data.taxFixed;
			this.taxAmount = data.taxAmount;
			this.taxLabel = data.taxLabel;
			this.hasTaxInvoice = data.hasTaxInvoice;
			this.chosenTemplate = data.templateUrl;
			this.chosenContainer = data.containerUrl;
			this.invoicesSequence = this.getSequenceByFileType(fileTypes.invoice);
			this.salesContractsSequence = this.getSequenceByFileType(fileTypes.salesContract);
			this.commercialInvoicesSequence = this.getSequenceByFileType(fileTypes.commercialInvoice);
			this.packingOrdersSequence = this.getSequenceByFileType(fileTypes.packingOrder);
			this.productReleaseOrdersSequence = this.getSequenceByFileType(fileTypes.productReleaseOrder);
			this.portDispatchesSequence = this.getSequenceByFileType(fileTypes.portDispatch);
			this.technicalAndFinancialOffersSequence = this.getSequenceByFileType(fileTypes.technicalAndFinancialOffer);
			this.dateFormat = data.dateFormat ? data.dateFormat.toUpperCase() : 'DD-MM-YYYY';
			this.activeFilterId = data.fiscalYear ? data.fiscalYear.id : null;
			this.filterData = data.fiscalYear;
			
			this.paddedDigitsCount = this.getSequenceByFileType(fileTypes.invoice)?.paddedDigitsCount;
		},

		fetchFiscalYears() {
			this.filtersLoading = true;
			return this.$store.dispatch('fiscalYears/fetchAll')
			.finally(() => {
				this.filtersLoading = false;
			})
		},

		fetchTemplates(isInitFetch) {
			this.imagesLoading = true;
			return this.$store.dispatch('templates/fetchAll')
			.finally(() => {
				if (!isInitFetch) { 
					this.imagesLoading = false;
				}
			})
		},

		fetchContainers(isInitFetch) {
			this.imagesLoading = true;
			return this.$store.dispatch('containers/fetchAll')
			.finally(() => {
				if (!isInitFetch) { 
					this.imagesLoading = false;
				}
			})
		},

		saveSettings() {
			this.loading = true;
            this.$store.dispatch('fileSettings/update', {
				templateUrl: this.chosenTemplate,
				containerUrl: this.chosenContainer,
				fiscalYearId: this.activeFilterId,
				taxFixed: this.taxFixed,
				taxLabel: this.taxLabel,
				taxAmount: this.taxAmount || 0,
				hasTaxInvoice: this.hasTaxInvoice,
				sequences: this.getSequencesArray(),
				dateFormat: this.dateFormat.toLowerCase().replace(/mm/g, 'MM'),
				fiscalYearStartDate: this.startDate,
				fiscalYearEndDate: this.endDate,
			}).then(() => {
				this.$store.dispatch('fileSettings/fetch').then((data) => this.fillSettingsData(data));
				this.loading = false;
				this.message = this.$t('messages.settings-saved-successfully');
				this.snackbar = true;
			}).catch((e) => {
				this.loading = false;
                this.message = e.response.data.message;
				this.snackbar = true;
			});
		},

		getSequencesArray() {
			var x = [
				this.salesContractsSequence,
				this.invoicesSequence,
				this.commercialInvoicesSequence,
				null, // PREVIOUSLY: packing lists (has been removed per the customer request)
				null, // PREVIOUSLY: shipping advise (has been removed per the customer request)
				this.packingOrdersSequence,
				this.productReleaseOrdersSequence,
				null, // Fumigation Certificate (has no sequence)
				null, // Health Certificate (has no sequence)
				null, // Weight And Quality Certificate (has no sequence)
				this.portDispatchesSequence,
				this.technicalAndFinancialOffersSequence,
			].map((c, index) => ({
				fileType: index,
				sequence: {
					...c,
					paddedDigitsCount: this.paddedDigitsCount || 0,
				}
			})).filter(c => c.sequence != null);
			return x;
		},

		// filter inputs form
		fillFilterInputsToEdit(data) {
			this.filterInputs.name = data.name;
			this.filterInputs.startDate = moment(data.startDate).format('YYYY-MM-DD');
			this.filterInputs.endDate = moment(data.endDate).format('YYYY-MM-DD');
		},

		resetFilterInputs() {
			this.isAddingFilter = false;
			this.editFilterId = null;
			this.filterInputs = {
				name: null,
				startDate: null,
				endDate: null,
				loading: false
			}
		},

		submitFilter() {
			if (this.$refs.formFilter.validate()) {
				this.filterInputs.loading = true;
				const actionName = this.editFilterId ? 'update' : 'create';
				this.$store.dispatch(`fiscalYears/${actionName}`, {
					id: this.editFilterId,
					name: this.filterInputs.name,
					startDate: this.filterInputs.startDate,
					endDate: this.filterInputs.endDate,
				})
				.then(() => {
					this.resetFilterInputs();

					this.fetchFiscalYears()
					.finally(() => {
						this.filterInputs.loading = false
					});
				})
				.catch((e) => {
					this.message = e.response.data.message;
					this.uploadLoading = false;
					this.snackbar = true;
				})
			}
		},

		uploadImage(updateAndChange) {
			this.uploadLoading = true;
			const moduleToUse = this.isTemplate ? 'templates' : 'containers';
			this.$store.dispatch(`${moduleToUse}/create`, {
				file: this.uploadFile
			})
			.then((response) => {
				this.message = this.$t('FilesSetting.uploaded');
				let promise;
				if (this.isTemplate) {
					promise = this.fetchTemplates()
					.then(() => {
						if (updateAndChange) {
							this.chosenTemplate = this.getTemplateById(response.data).fileTemplatePath;
							this.saveSettings();
						}
					})
				}
				else {
					promise = this.fetchContainers()
					.then(() => {
						if (updateAndChange) {
							this.chosenContainer = this.getContainerById(response.data).companyContainerPath;
							this.saveSettings();
						}
					})
				}
				promise.finally(() => {
					this.uploadLoading = false;
					this.uploadDialog = false;
				});
			})
			.catch((e) => {
				this.message = e.response.data.message;
				this.uploadLoading = false;
			})
			.finally(() => {
				this.snackbar = true;
			})
		},

		// helpers
		isCompanyHasFile(fileType) {
            if (this.company.fileTypes.length === 0) {
                return true;
            }
            return this.company.fileTypes.includes(fileType);
        },
		moment,
	},

	computed: {
		...mapState({
			company: state => state.companies.company,
			fileSettings: state => state.fileSettings.fileSettings,
			templates: state => state.templates.templates,
			containers: state => state.containers.containers,
			fiscalYears: state => state.fiscalYears.fiscalYears,
		}),

		...mapGetters({
            getSequenceByFileType: 'fileSettings/getSequenceByFileType',
            getTemplateById: 'templates/getTemplateById',
            getContainerById: 'containers/getContainerById',
		}),

		filtersHeaders() {
			return [
                { text:this.$t('headers.period-name'), value:'name', align:'start', sortable: false, class:'primary--text', cellClass:'primary--text' },
                { text:this.$t('headers.start-data'), value:'startDate', align:'center', sortable: false, cellClass: 'fixed-width-date' },
                { text:this.$t('headers.end-date'), value:'endDate', align:'center', sortable: false, cellClass: 'fixed-width-date' },
                { text:this.$t('headers.active'), value:'active', align:'center', sortable: false },
                { text:this.$t('headers.actions'), value:'actions', align:'center', sortable: false, width: 100 },
            ]
		},
		
		filtersItems() {
			return [
				{
					id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10),
					name: 'test1',
					startDate: '2022-01-02T22:02:00Z',
					endDate: '2021-01-02T22:02:00Z',
				},
				{
					id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10),
					name: 'test2',
					startDate: '2020-12-02T23:02:00Z',
					endDate: '2021-12-02T22:02:00Z',
				},
				{
					id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10),
					name: 'test1',
					startDate: '2020-01-02T22:02:00Z',
					endDate: '2019-01-02T22:02:00Z',
				},
			]
		},
	},

	mounted() {
		if (Object.keys(this.fileSettings).length === 0) {
			this.fetchSettings();
		}
		else {
			this.fillSettingsData(this.fileSettings);
		}
		
		Promise.all([
			this.fetchTemplates(true),
			this.fetchContainers(true),
		])
		.finally(() => {
			this.imagesLoading = false;
		})

		this.fetchFiscalYears();
	},
	
	components: {
		'three-part-sequence': ThreePartSequence,
		DeleteDialog,
		Tooltip
	}
};
</script>

<style>
</style>