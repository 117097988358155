var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('h1',{staticClass:"text-h6 primary--text"},[_vm._v(" "+_vm._s(((_vm.$t('AuditLog.audit-log')) + " " + (_vm.$t('AuditLog.of-$', { username: _vm.getUserById(_vm.$route.params.userId) ? _vm.getUserById(_vm.$route.params.userId).username : '-' }))))+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-body-1 primary--text mb-0"},[_vm._v(" "+_vm._s(_vm.$t('AuditLog.last-login'))+" "),(_vm.lastLoginLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":"2","size":"15"}}):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.lastLogin ? _vm.lastLogin : '-'))])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"ms-4 px-6",attrs:{"to":{ name:'users' },"elevation":"1","small":""}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]):_vm._e()],1),_c('v-form',{staticClass:"my-2",on:{"submit":function($event){$event.preventDefault();return _vm.filter()}}},[_c('v-row',{staticClass:"align-center ma-0"},[_c('v-col',{staticClass:"text-body-1 primary--text",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('filter'))+" ")]),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"md":"3","sm":"4","cols":"12"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('headers.company'),"items":_vm.companies,"item-text":"name","item-value":"id","return-object":false,"loading":_vm.filterLoading,"disabled":_vm.filterLoading,"filled":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}})],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-menu',{ref:"menuOfFromDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('from-date'),"prepend-icon":"mdi-calendar","dense":"","filled":"","outlined":"","hide-details":"","readonly":"","disabled":_vm.loading},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":new Date((Date.now()*2) - new Date()).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":function($event){return _vm.$refs.menuOfFromDate.save(_vm.fromDate)}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-menu',{ref:"menuOfToDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('to-date'),"prepend-icon":"mdi-calendar","dense":"","filled":"","outlined":"","hide-details":"","readonly":"","disabled":_vm.loading},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":new Date((Date.now()*2) - new Date()).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":function($event){return _vm.$refs.menuOfToDate.save(_vm.toDate)}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"px-sm-4",attrs:{"text":"","color":"success","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"type":"submit"},on:{"click":function($event){_vm.isFiltered = true}}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")]),(_vm.isFiltered)?_c('v-btn',{staticClass:"px-sm-4",attrs:{"text":"","disabled":!_vm.isFiltered || _vm.loading},on:{"click":function($event){_vm.resetFilter(); _vm.filter();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.items,"items-per-page":20,"no-data-text":_vm.$t('no-data-text'),"no-results-text":_vm.$t('no-results-text'),"loading-text":_vm.$t('loading-text'),"footer-props":{
            'items-per-page-options': [10, 20, 40, 100],
            'items-per-page-all-text': _vm.$t('items-per-page-all-text'),
            'items-per-page-text': _vm.$t('items-per-page-text'),
            showCurrentPage: true
          },"options":_vm.options,"server-items-length":_vm.totalItems,"disable-sort":"","loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date))+" ")]}},{key:"item.company",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyById(item.companyId) ? _vm.getCompanyById(item.companyId).name : '-')+" ")]}},{key:"item.context",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFileName(item.context))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(" "+_vm._s(_vm.getActionName(item))+" ")])]}},{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }