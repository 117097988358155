import router from './router';
import store from './store/index'
export function findMonthDate(date){
    if (!date) return null;

    return window.moment(date).format('YYYY-MM');
}

export function findFullDate(date){
    if (!date) return null;
   
    return date + '-01'
}

export function setBuyerDataLabels(buyer) {
    return [
        buyer.name ? '' + buyer.name : null,
        buyer.taxNumber ? 'Tax number: ' + buyer.taxNumber : null,
        buyer.address ? 'Address: ' + buyer.address : null,
        buyer.phoneNumbers[0] ? 'Phone number: ' + buyer.phoneNumbers[0] : null
    ].filter(c => c).join('\n');
}

export function getBuyerName(buyerData) {
    if (buyerData.split('\n')[0].toLocaleLowerCase().includes('name:')){
        return buyerData.split('\n')[0].slice(6);    
    }
    return buyerData.split('\n')[0];
    
    // new Date(
    //     (new Date() * 2) - Date.now()
    // ).toISOString().substr(0, 10)
}

export function getCostumerItems(items) {
    const newItems = [];
    items.forEach(id => {
        newItems.push(store.getters['items/getItemById'](id))
    })
    return newItems;
}

export function orderItemsDueCostumer(items) {
    const favItems = [];
    const otherItems = store.state.items.items.filter(c => !items.includes(c.id))

    items.forEach(id => {
        favItems.push(store.getters['items/getItemById'](id))
    });

    return favItems.concat([...favItems, ...otherItems])
}

export function backToLastCompanyContext() {
    const prevPath = localStorage.getItem('last-context-path');
    router.push(prevPath || { name: 'home' });
}