import { BASE_API_URL } from "../../constants";

export default {
    namespaced: true,
    actions: {
        salesContracts({ commit }, { companyId, isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, destination, packing, shipping, intermediaryBank, documents, wireTransfer, instructions, goodsDescription, quantityNote, insurance, tax, transactions, bankCountry }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/salesContracts`, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, destination, packing, shipping, intermediaryBank, documents, wireTransfer, instructions, goodsDescription, quantityNote, insurance, tax, transactions, bankCountry }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        invoices({ commit }, { companyId, isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/invoices`, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        commercialInvoices({ commit }, { companyId, isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/commercialInvoices`, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        packingLists({ commit }, { companyId, items, date, to, sealesNo, loadingPort, dischargePort, shippingMarks, packing, referenceContract, referenceInvoice, descriptionOfGoods, unitNote, packingDate, expiryDate, containerNumber, billOfLadingNumber, note }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/packingLists`, { items, date, from: null, to, shippingCompany: null, sealesNo, loadingPort, dischargePort, shippingMarks, packing, referenceContract, referenceInvoice, descriptionOfGoods, unitNote, packingDate, expiryDate, containerNumber, billOfLadingNumber, note }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        shippingAdvises({ commit }, { companyId, isManualSequence, sequence, manualSequence, items, date, contractNo, consignee, shippingCompany, shippingDate, loadingPort, destinationPort, billOfLadingNo, containerNo, containerSize, customStampNo, productsOrderInContainer }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/shippingAdvices`, { isManualSequence, sequence, manualSequence, items, currency: 'Q', date, contractNo, consignee, shippingCompany, shippingDate, loadingPort, destinationPort, billOfLadingNo, containerNo, containerSize, customStampNo, productsOrderInContainer }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        packingOrders({ commit }, { companyId, isManualSequence, sequence, manualSequence, items, date, referenceContract, customer, consignee, destinationCountry, destinationPort, containerSize, exportDate, containerLoadingDate, productsOrderInContainer, notes, productionDate, expiryDate }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/packingOrders`, { isManualSequence, sequence, manualSequence, items, currency: 'Q', date, referenceContract, customer, consignee, destinationCountry, destinationPort, containerSize, exportDate, containerLoadingDate, productsOrderInContainer, notes, productionDate, expiryDate }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        productReleaseOrders({ commit }, { companyId, isManualSequence, sequence, manualSequence, items, date, referenceContract, warehouseNo, packingOrderNo, portCompany, tariffSystemId, destination, contract, booking, shipName, containerNo, shippingDate, voyage, stampNo, pilotName, truckPlate, licenseNo, platformPlate, goodsDescription, boxMark, packingDate, expiryDate, observers, dispatchedBy, transportedBy, receivedBy }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/productReleaseOrders`, { isManualSequence, sequence, manualSequence, items, date, referenceContract, warehouseNo, packingOrderNo, portCompany, tariffSystemId, destination, contract, booking, shipName, containerNo, shippingDate, voyage, stampNo, pilotName, truckPlate, licenseNo, platformPlate, goodsDescription, boxMark, packingDate, expiryDate, observers, dispatchedBy, transportedBy, receivedBy }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },
        
        fumigationCertificate({ commit }, { companyId, date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/fumigationCertificate`, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        healthCertificate({ commit }, { companyId, date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/healthCertificate`, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        weightAndQualityCertificate({ commit }, { companyId, date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/weightAndQualityCertificate`, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },
        
        portDispatch({ commit }, { companyId, date, isManualSequence, sequence, manualSequence, packingOrderNo, contractNo, tariffRegime, portCompanyName, shippingCompany, containerNo, labelNo, destination, booking, shipmentDate, shipmentBy, pilotName, licenseNo, truckPlates, platformPlates, containing, packingDate, expiryDate, dispatcher, receiver, transportedBy, custody, items }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/dispatchForPortFile`, { date, isManualSequence, sequence, manualSequence, packingOrderNo, contractNo, tariffRegime, portCompanyName, shippingCompany, containerNo, labelNo, destination, booking, shipmentDate, shipmentBy, pilotName, licenseNo, truckPlates, platformPlates, containing, packingDate, expiryDate, dispatcher, receiver, transportedBy, custody, items }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },

        technicalAndFinancialOffer({ commit }, { companyId, isManualSequence, sequence, fileDate, date, currency, manualSequence, title, buyer, implementationPeriod, offerValidity, internalArea, externalArea, description, scopeDescription, notes, internalAffairsEntries, externalAffairsEntries }){
            return window.axios.post(`${BASE_API_URL}/reportsPreview/${companyId}/TechnicalFinancialOffer`, { isManualSequence, sequence, fileDate, date, currency, manualSequence, title, buyer, implementationPeriod, offerValidity, internalArea, externalArea, description, scopeDescription, notes, internalAffairsEntries, externalAffairsEntries }, { responseType: 'blob' })
            .then((response) => {
                return response.data;
            });
        },
    }
}