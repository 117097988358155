<template>
    <div>
        <v-dialog
            transition="dialog-transition"
            width="500"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar
                        color="red lighten-1"
                        dark
                        flat
                        class="text-uppercase justify-center"
                    >
                        <v-spacer/>
                        {{$t('confirm-delete')}}
                        <v-spacer/>
                        <v-btn text small light fab @click="dialog.value = false" color="white"><v-icon>mdi-close</v-icon></v-btn>

                    </v-toolbar>
                    <v-card-text class="pa-0 pl-2">
                        <!-- dialog content -->
                        <v-container>
                            <!-- default slot -->
                            <slot></slot>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            :loading="loading"
                            @click="deleteData"
                            color="red lighten-1 white--text"
                            elevation="1"
                        >
                            {{$t('delete')}}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            @click="dialog.value = false"
                            elevation="1"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            transition="slide-y-reverse-transition"
            color="red lighten-1 white--text"
            class="text-body-2"
        >
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    data(){
        return {
            snackbar: false,
            message: null,
            
            dataId: null,
            moduleType: null,

            loading: false,
        }
    },

    methods:{
        activeDialog(id, moduleType){
            this.$refs.dialog.isActive = true;
            this.dataId = id;
            this.moduleType = moduleType;
        },

        deleteData(){
            this.loading = true;

            this.$store.dispatch(`${this.moduleType}/delete`, { id: this.dataId })
            .then(() => {
                this.message = this.$t('messages.delete-operation-completed-successfully');
                this.$emit('on-delete');
                this.$refs.dialog.isActive = false;
            })
            .catch((e) => {
                this.message = e.response.data.message;
            })
            .finally(() => {
                this.snackbar = true;
                this.loading = false;
            });

            this.dataId = null;
            this.moduleType = null;
        },
    }
}
</script>

<style>

</style>