export default {
    'management': 'Management',
    'documents': 'Documents',
    'files-settings': 'Files settings',
    'customers-and-suppliers': 'Customers & suppliers',
    'items-and-materials': 'Items & materials',
    'sales-contracts': 'Sales contracts',
    'proforma-invoices': 'Proforma Invoices',
    'commercial-invoices': 'Commercial invoices',
    'packing-lists': 'Packing lists',
    'shipping-advises': 'Shipping advises',
    'technical-and-financial-offers': 'Technical and financial offers'
}