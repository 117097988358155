<template>
	<div class="home">
        <p class="text-h6 primary--text text-center mb-2">{{$t('Home.welcome-text')}}</p>

		<!-- logo -->
		<v-img
			src="/img/acacia.png"
            max-width="26.1%"
			width="160"
            min-width="150"
			min-height="200"
			max-height="200"
            contain
			class="logo"
            transition="scale-transition"
		></v-img>
        <span class="overline text-caption mb-3">Acacia — {{ $version }}</span>
        
        <p class="text-body-1 primary--text mb-2">{{$t('Home.select-company')}}</p>

        <v-progress-circular
        	v-if="loading"
        	indeterminate
        	color="primary"
        	width="3"
        	size="26"
        ></v-progress-circular>
        
        <!-- companies images -->
        <div v-else class="d-flex flex-wrap justify-center">
            <div
                v-ripple
            	v-for="company in showCompanies"
            	:key="company.id"
            	class="company-logo"
                @click="
                    setAxiosHeaderForXCompany(company.id);
                    logSignInOperation(company.id);
                    $router.push({ name: 'land-page', params:{companyId: company.id} })
                "
            >
                <v-img
                    :src="`${BASE_API_URL}/${company.logoImagePath}`"
                    lazy-src="/img/placeholder-image.png"
                    width="150"
                    max-height="200"
                    transition="scale-transition"
                    contain
                ></v-img>
                <span class="text-body-1">{{company.name}}</span>
            </div>
        </div>

        <p class="powered-by mb-0">
            powered by 
            <a
            	target="_blank"
            	href="https://www.beetronix.com"
            	class="black--text font-weight-bold text-decoration-none"
            >BEETRONIX</a>
        </p>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { BASE_API_URL } from '../constants'
export default {
    name: 'Home',
    
    data: () => ({
        loading: true,
        BASE_API_URL
    }),

    computed:{
        showCompanies(){
            if (this.currentUser.isAdmin) return this.companies;
            const companies = [];
            this.companies.forEach(c => {
                if (this.currentUser.companyPermissions.map(a => a.companyId).includes(c.id)){
                    companies.push(this.getCompanyById(c.id))
                }
            })
            return companies;
        },

        ...mapState({
            currentUser: state => state.users.currentUser,
            companies: state => state.companies.companies,
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        })
    },

    methods:{
        setAxiosHeaderForXCompany(id){
            window.axios.defaults.headers.common['x-company'] = id;
        },

        logSignInOperation(id) {
            this.$store.dispatch('users/logSignInOperation', { companyId: id })
        }
    },

    mounted(){
        this.loading = true;
        this.$store.dispatch('companies/fetchAll')
        .finally(() => {
            this.loading = false;
        })
    }
}
</script>

<style lang="scss" scoped>
.home{
	// margin-top: -38px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
    min-height: 80vh;

    .company-logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        border: 1px solid transparent;
        border-radius: 6px;
        padding: 0 10px 10px;
        cursor: pointer;

        transition: border-color .3s;
        &:hover {
            border-color: #ccc;
        }
    }
}
</style>