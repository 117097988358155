<template>
    <v-tooltip top color="black" open-delay="500">
		<template v-slot:activator="{ on:tooltip, attrs }">
			<span v-bind="attrs" v-on="{...tooltip}">
				<slot></slot>
			</span>
		</template>
      <span class="text-caption">{{text}}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        text: { type: String }
    }
}
</script>