import Home from '../pages/Home'

import Login from '../pages/sign/Login'

// company pages
import Invoices from '../pages/company/Invoices'
import CommercialInvoices from '../pages/company/CommercialInvoices'
import CustomersAndSuppliers from '../pages/company/CustomersAndSuppliers'
import FilesSetting from '../pages/company/FilesSetting'
import LandPage from '../pages/company/LandPage'
import Materials from '../pages/company/Materials'
import PackingLists from '../pages/company/PackingLists'
import SalesContracts from '../pages/company/SalesContracts'
import ShippingAdvises from '../pages/company/ShippingAdvises'
import PackingOrders from '../pages/company/PackingOrders'
import ProductReleaseOrders from '../pages/company/ProductReleaseOrders'
import FumigationCertificates from '../pages/company/FumigationCertificates'
import HealthCertificates from '../pages/company/HealthCertificates'
import WeightAndQualityCertificates from '../pages/company/WeightAndQualityCertificates'
import PortDispatches from '../pages/company/PortDispatches'
import TechnicalAndFinancialOffers from '../pages/company/TechnicalAndFinancialOffers'
// pages in company
import PackagingUnits from '../pages/company/views/PackagingUnits'
import SalesContract from '../pages/company/views/SalesContract'
import PackingList from '../pages/company/views/PackingList'
import Invoice from '../pages/company/views/Invoice'
import CommercialInvoice from '../pages/company/views/CommercialInvoice'
import ShippingAdvise from '../pages/company/views/ShippingAdvise'
import PackingOrder from '../pages/company/views/PackingOrder'
import ProductReleaseOrder from '../pages/company/views/ProductReleaseOrder'
import FumigationCertificate from '../pages/company/views/FumigationCertificate'
import HealthCertificate from '../pages/company/views/HealthCertificate'
import WeightAndQualityCertificate from '../pages/company/views/WeightAndQualityCertificate'
import PortDispatch from '../pages/company/views/PortDispatch'
import TechnicalAndFinancialOffer from '../pages/company/views/TechnicalAndFinancialOffer'

// setting pages
import Companies from '../pages/settings/Companies'
import Users from '../pages/settings/Users'
import Templates from '../pages/settings/Templates'

// pages in setting
import AuditLog from '../pages/settings/views/AuditLog'

// configs
import * as shownFilesConfigs from '@/configs/shown-files-config'

const routes = [
    {
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	
	// company pages	
	{
		path:'/invoices/:companyId',
		name:'invoices',
		component: Invoices,
		show: shownFilesConfigs.invoices
	},
	{
		path:'/commercial-invoices/:companyId',
		name:'commercial-invoices',
		component: CommercialInvoices,
		show: shownFilesConfigs.commercialInvoices
	},
	{
		path:'/customers-and-suppliers/:companyId',
		name:'customers-and-suppliers',
		component: CustomersAndSuppliers
	},
	{
		path:'/files-setting/:companyId',
		name:'files-setting',
		component: FilesSetting
	},
	{
		path:'/materials/:companyId',
		name:'materials',
		component: Materials
	},
	{
		path: '/land-page/:companyId',
		name: 'land-page',
		component: LandPage
	},
	{
		path:'/packing-lists/:companyId',
		name:'packing-lists',
		component: PackingLists,
		show: shownFilesConfigs.packingLists
	},
	{
		path:'/sales-contracts/:companyId',
		name:'sales-contracts',
		component: SalesContracts,
		show: shownFilesConfigs.salesContracts
	},
	{
		path:'/shipping-advises/:companyId',
		name:'shipping-advises',
		component: ShippingAdvises,
		show: shownFilesConfigs.shippingAdvises
	},
	{
		path:'/packing-orders/:companyId',
		name:'packing-orders',
		component: PackingOrders,
		show: shownFilesConfigs.packingOrders
	},
	{
		path:'/product-release-orders/:companyId',
		name:'product-release-orders',
		component: ProductReleaseOrders,
		show: shownFilesConfigs.productReleaseOrders
	},
	{
		path:'/fumigation-certificates/:companyId',
		name:'fumigation-certificates',
		component: FumigationCertificates,
		show: shownFilesConfigs.fumigationCertificates
	},
	{
		path:'/health-certificates/:companyId',
		name:'health-certificates',
		component: HealthCertificates,
		show: shownFilesConfigs.healthCertificates,
	},
	{
		path:'/weight-and-quality-certificates/:companyId',
		name:'weight-and-quality-certificates',
		component: WeightAndQualityCertificates,
		show: shownFilesConfigs.weightAndQualityCertificates
	},
	{
		path:'/port-dispatches/:companyId',
		name:'port-dispatches',
		component: PortDispatches,
		show: shownFilesConfigs.portDispatches
	},
	{
		path:'/technical-and-financial-offers/:companyId',
		name:'technical-and-financial-offers',
		component: TechnicalAndFinancialOffers,
		show: shownFilesConfigs.technicalAndFinancialOffers
	},
	// pages in company
	{
		path:'/sales-contract/:companyId',
		name:'sales-contract',
		component: SalesContract,
		show: shownFilesConfigs.salesContracts
	},
	{
		path:'/packing-list/:companyId',
		name:'packing-list',
		component: PackingList,
		show: shownFilesConfigs.packingLists
	},
	{
		path:'/invoice/:companyId',
		name:'invoice',
		component: Invoice,
		show: shownFilesConfigs.invoices
	},
	{
		path:'/commercial-invoice/:companyId',
		name:'commercial-invoice',
		component: CommercialInvoice,
		show: shownFilesConfigs.commercialInvoices
	},
	{
		path:'/packaging-units/:companyId',
		name:'packaging-units',
		component: PackagingUnits,
	},
	{
		path:'/shipping-advise/:companyId',
		name:'shipping-advise',
		component: ShippingAdvise,
		show: shownFilesConfigs.shippingAdvises
	},
	{
		path:'/packing-order/:companyId',
		name:'packing-order',
		component: PackingOrder,
		show: shownFilesConfigs.packingOrders
	},
	{
		path:'/product-release-order/:companyId',
		name:'product-release-order',
		component: ProductReleaseOrder,
		show: shownFilesConfigs.productReleaseOrders
	},
	{
		path:'/fumigation-certificate/:companyId',
		name:'fumigation-certificate',
		component: FumigationCertificate,
		show: shownFilesConfigs.fumigationCertificates
	},
	{
		path:'/health-certificate/:companyId',
		name:'health-certificate',
		component: HealthCertificate,
		show: shownFilesConfigs.healthCertificates
	},
	{
		path:'/weight-and-quality-certificate/:companyId',
		name:'weight-and-quality-certificate',
		component: WeightAndQualityCertificate,
		show: shownFilesConfigs.weightAndQualityCertificates
	},
	{
		path:'/port-dispatch/:companyId',
		name:'port-dispatch',
		component: PortDispatch,
		show: shownFilesConfigs.portDispatches
	},
	{
		path:'/technical-and-financial-offer/:companyId',
		name:'technical-and-financial-offer',
		component: TechnicalAndFinancialOffer,
		show: shownFilesConfigs.technicalAndFinancialOffers
	},

	// setting pages
	{
		path:'/companies',
		name:'companies',
		component: Companies
	},
	{
		path:'/users',
		name:'users',
		component: Users
	},
	{
		path:'/templates',
		name:'templates',
		component: Templates
	},
	// pages in setting
	{
		path:'/audit-log/:userId',
		name:'audit-log',
		component: AuditLog
	},
	
	// undefined routes
	{
		path: '*',
		redirect: '/'
	}
];

export default routes.filter(c => c.show === undefined || c.show)