<template>
	<v-menu
		ref="menu"
		v-if="$options.filters.isUsed(menuPicker)"
		v-model="menu"
		:close-on-content-click="false"
		:transition="menuTransition"
		offset-y
		min-width="auto"
    >
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-if="!$slots['activator'] && !$scopedSlots['activator']"
				v-model="localeValue"
				:label="inputLabel"
				:disabled="disabled"
				:readonly="inputProps.readonly || readonly"
				:hide-details="hideDetails"
				:outlined="inputProps.outlined"
				:filled="inputProps.filled"
				:dense="inputProps.dense"
				:clearable="inputProps.clearable"
				:loading="inputProps.loading"
				:hint="hint"
				:persistent-hint="persistentHint"
				prepend-icon="mdi-calendar"
				
				maxLength="10"
				v-bind="attrs"
				v-on="on"
				:rules="rules"
			></v-text-field>
				<!-- @blur="menu && localeValue ? save(localeValue) : null" -->

			<!-- @slot يستخدم لتبديل حقل الإدخال الافتارضي في حال استخدام ال **`menu-picker`** -->
			<slot
				v-else
				name="activator"
				v-bind="{on, attrs}"
			></slot>
		</template>

		<!-- 
			يتم قدحه عند تغيير التاريخ
			@event change
			@property {String} newValue - القيمة الجديدة للتاريخ
		-->
		<!--
			الحدث الخاص ب **`sync.`** لل `active-picker`
			@event update:active-picker
			@property {String} value - القيمة الجديدة active-picker
		-->
		<!--
			الحدث الخاص ب **`sync.`** لل `picker-date`
			@event update:picker-date
			@property {String} value - القيمة الجديدة picker-date
		-->
		<v-date-picker
			v-model="localeValue"
			:active-picker.sync="$options.filters.isUsed(calendar) ? null : localActivePicker"
			:max="Number(yearsOverMax) ? calculateYearsOver(Number(yearsOverMax)) : maxDate"
			:min="minDate"
			@change="$emit('change', $event); save($event)"
			@update:active-picker="$emit('update:active-picker', $event)"
			@update:picker-date="$emit('update:picker-date', $event)"

			:allowed-dates="allowedDates"
			:color="color"
			:dark="dark"
			:day-format="dayFormat"
			:disabled="disabled"
			:elevation="elevation"
			:event-color="eventColor"
			:events="events"
			:first-day-of-week="firstDayOfWeek"
			:flat="flat"
			:full-width="fullWidth"
			:header-color="headerColor"
			:header-date-format="headerDateFormat"
			:landscape="landscape"
			:light="light"
			:locale="locale"
			:locale-first-day-of-year="localeFirstDayOfYear"
			:month-format="monthFormat"
			:multiple="multiple"
			:next-icon="nextIcon"
			:next-month-aria-label="nextMonthAriaLabel"
			:next-year-aria-label="nextYearAriaLabel"
			:no-title="noTitle"
			:picker-date="pickerDate"
			:prev-icon="prevIcon"
			:prev-month-aria-label="prevMonthAriaLabel"
			:prev-year-aria-label="prevYearAriaLabel"
			:range="range"
			:reactive="reactive"
			:readonly="readonly"
			:scrollable="scrollable"
			:selected-items-text="selectedItemsText"
			:show-adjacent-months="showAdjacentMonths"
			:show-current="showCurrent"
			:show-week="showWeek"
			:title-date-format="titleDateFormat"
			:type="type"
			:weekday-format="weekdayFormat"
			:width="width"
			:year-format="yearFormat"
			:year-icon="yearIcon"
		>
			<slot v-bind="{ menu: this.$refs.menu }"></slot>
		</v-date-picker>
	</v-menu>

	<v-date-picker
		v-else
		v-model="localeValue"
		:active-picker.sync="localActivePicker"
		:max="Number(yearsOverMax) ? calculateYearsOver(Number(yearsOverMax)) : maxDate"
		:min="minDate"
		@change="$emit('change', $event); save($event)"
		@update:active-picker="$emit('update:active-picker', $event)"
		@update:picker-date="$emit('update:picker-date', $event)"

		:allowed-dates="allowedDates"
		:color="color"
		:dark="dark"
		:day-format="dayFormat"
		:disabled="disabled"
		:elevation="elevation"
		:event-color="eventColor"
		:events="events"
		:first-day-of-week="firstDayOfWeek"
		:flat="flat"
		:full-width="fullWidth"
		:header-color="headerColor"
		:header-date-format="headerDateFormat"
		:landscape="landscape"
		:light="light"
		:locale="locale"
		:locale-first-day-of-year="localeFirstDayOfYear"
		:month-format="monthFormat"
		:multiple="multiple"
		:next-icon="nextIcon"
		:next-month-aria-label="nextMonthAriaLabel"
		:next-year-aria-label="nextYearAriaLabel"
		:no-title="noTitle"
		:picker-date="pickerDate"
		:prev-icon="prevIcon"
		:prev-month-aria-label="prevMonthAriaLabel"
		:prev-year-aria-label="prevYearAriaLabel"
		:range="range"
		:reactive="reactive"
		:readonly="readonly"
		:scrollable="scrollable"
		:selected-items-text="selectedItemsText"
		:show-adjacent-months="showAdjacentMonths"
		:show-current="showCurrent"
		:show-week="showWeek"
		:title-date-format="titleDateFormat"
		:type="type"
		:weekday-format="weekdayFormat"
		:width="width"
		:year-format="yearFormat"
		:year-icon="yearIcon"
	>
		<!--
			@slot يعرض أسفل ال date picker، يستخدم كمثال في وضع أزرار موافق أو إغلاق
			@binding {VueComponent} menu - ref إلى  v-menu في date-picker
		-->
		<slot v-bind="{ menu: this.$refs.menu }"></slot>
	</v-date-picker>
</template>

<script>
// import { mergeListeners } from 'vuetify/src/util/mergeData'
// import { createItemTypeNativeListeners } from 'vuetify/src/components/VDatePicker/util/eventHelpers'
/**
 * 	@example ../../docs/BeeDatePicker.md
 * 	@version v1.0.1
 */
export default {
	name: 'BeeDatePicker',

	props: {
		/** label حقل الإدخال الافتراضي في حال استخدام `menu-picker` */
		'input-label': {
			type: String,
			default: 'Date'
		},

		/** تأثير ال transition للقائمة في حال استخدام `menu-picker */
		'menu-transition': {
			type: String,
			default: 'scale-transition'
		},

		/** أعلى قيمة للتاريخ يمكن اختيارها */
		'max-date': { 
			type: String,
			default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},

		/** أدنى قيمة للتاريخ يمكن اختيارها */
		'min-date': { 
			type: String,
			default: '1950-01-01'
		},

		/** استخدام قائمة بداخلها ال date-picker لاختيار التاريخ مع حقل إدخال افتراضي */
		'menu-picker': {
			type: [Boolean, String],
			default: false
		},

		/** تعطيل إغلاق ال date-picker بعد تغيير التاريخ */
		'disable-close-on-change': {
			type: [Boolean, String],
			default: false
		},

		/** عرض رسالة أسفل حقل الإدخال الافتراضي */
		hint: {
			type: String,
			default: undefined
		},
		
		/** تثبيت ال hint وعرضها دائماً لحقل الإدخال */
		'persistent-hint': {
			type: String,
			default: undefined
		},

		/** إخفاء ال hint و ال validation errors وعند إعطاء القيمة **`auto`** فيتم عرض الرسائل فقط في حال وجودها */
		'hide-details': {
			type: [Boolean, String],
			default: false
		},

		/**
		 * تحديد بعض خصائص حقل الإدخال الافتراضي
		 * 
		 * ```js
		 * {
		 * 	readonly: boolean,
		 * 	outlined: boolean,
		 * 	filled: boolean,
		 * 	dense: boolean,
		 * 	clearable: boolean,
		 * 	loading: boolean
		 * }
		 * ```
		 */
		'input-props': {
			type: Object,
			default: () => ({
				readonly: true,
				outlined: false,
				filled: false,
				dense: false,
				clearable: false,
				loading: false
			})
		},

		/** مصفوفة من التوابع التي تمثل شرط قبول الإدخال أو عرض رسالة خطأ، للمزيد [vuetify rules](https://vuetifyjs.com/en/api/v-text-field/#props-rules) */
		rules: { type: Array, default: undefined },

		/** عدد السنوات المراد إضافتها على التاريخ الحالي ليتبع لها آخر تاريخ يمكن اختياره */
		'years-over-max': {
			type: [String, Number],
			default: undefined
		},

		/** تابع لحساب أعلى تاريخ يمكن اختياره بالاعتماد على `years-over-max` */
		'calculate-years-over': {
			type: Function,
			default: (val) => (new Date(new Date().setYear(new Date().getFullYear() + val)).toISOString().substr(0, 10))
		},
		
		// formatInputFuction
		/** إظهار ال date picker مباشرة من دون مراحل السنة ثم الشهر ثم اليوم */
		calendar: { type: Boolean, default: false },
		
		/** Date picker model وتأخذ ال format 'YYYY-MM-DD' أو 'YYYY-MM' */
		value: { type: String, default: null },

		// default
		/** راجع [Vuetify v-date-picker active-picker](https://vuetifyjs.com/en/api/v-date-picker/#props-active-picker) */
		'active-picker': {
			type: String,
			default: undefined
		},
		
		/** راجع [Vuetify v-date-picker allowed-dates](https://vuetifyjs.com/en/api/v-date-picker/#props-allowed-dates) */
		'allowed-dates': {
			type: Function,
			default: null
		},

		/** راجع [Vuetify v-date-picker color](https://vuetifyjs.com/en/api/v-date-picker/#props-color) */
		color: {
			type: String,
			default: undefined
		},

		/** راجع [Vuetify v-date-picker dark](https://vuetifyjs.com/en/api/v-date-picker/#props-dark) */
		dark: {
			type: [Boolean, String],
			default: undefined
		},

		/** راجع [Vuetify v-date-picker day-format](https://vuetifyjs.com/en/api/v-date-picker/#props-day-format) */
		'day-format': {
			type: Function,
			default: undefined
		},
		
		/** راجع [Vuetify v-date-picker disabled](https://vuetifyjs.com/en/api/v-date-picker/#props-disabled) */
		disabled: {
			type: [Boolean, String],
			default: false
		},
		
		/** راجع [Vuetify v-date-picker elevation](https://vuetifyjs.com/en/api/v-date-picker/#props-elevation) */
		elevation: {
			type: [Number, String],
			default: undefined
		},

		/** راجع [Vuetify v-date-picker event-color](https://vuetifyjs.com/en/api/v-date-picker/#props-event-color) */
		'event-color': {
			type: [Array, Function, Object, String],
			default: 'warning'
		},

		/** راجع [Vuetify v-date-picker events](https://vuetifyjs.com/en/api/v-date-picker/#props-events) */
		events: {
			type: [Array, Function, Object],
			default: null
		},

		/** راجع [Vuetify v-date-picker first-day-of-week](https://vuetifyjs.com/en/api/v-date-picker/#props-first-day-of-week) */
		'first-day-of-week': {
			type: [String, Number],
			default: 0
		},

		/** راجع [Vuetify v-date-picker flat](https://vuetifyjs.com/en/api/v-date-picker/#props-flat) */
		flat: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker full-width](https://vuetifyjs.com/en/api/v-date-picker/#props-full-width) */
		'full-width': {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker header-color](https://vuetifyjs.com/en/api/v-date-picker/#props-header-color) */
		'header-color': {
			type: String,
			default: undefined
		},

		/** راجع [Vuetify v-date-picker header-date-format](https://vuetifyjs.com/en/api/v-date-picker/#props-header-date-format) */
		'header-date-format': {
			type: Function,
			default: null
		},

		/** راجع [Vuetify v-date-picker landscape](https://vuetifyjs.com/en/api/v-date-picker/#props-landscape) */
		landscape: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker light](https://vuetifyjs.com/en/api/v-date-picker/#props-light) */
		light: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker locale](https://vuetifyjs.com/en/api/v-date-picker/#props-locale) */
		locale: {
			type: String,
			default: undefined
		},

		/** راجع [Vuetify v-date-picker locale-first-day-of-year](https://vuetifyjs.com/en/api/v-date-picker/#props-locale-first-day-of-year) */
		'locale-first-day-of-year': {
			type: [String, Number],
			default: 0
		},

		/** راجع [Vuetify v-date-picker month-format](https://vuetifyjs.com/en/api/v-date-picker/#props-month-format) */
		'month-format': {
			type: Function,
			default: null
		},

		/** راجع [Vuetify v-date-picker multiple](https://vuetifyjs.com/en/api/v-date-picker/#props-multiple) */
		multiple: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker next-icon](https://vuetifyjs.com/en/api/v-date-picker/#props-next-icon) */
		'next-icon': {
			type: String,
			default: '$next'
		},

		/** راجع [Vuetify v-date-picker next-month-aria-label](https://vuetifyjs.com/en/api/v-date-picker/#props-next-month-aria-label) */
		'next-month-aria-label': {
			type: String,
			default: '$vuetify.datePicker.nextMonthAriaLabel'
		},

		/** راجع [Vuetify v-date-picker next-year-aria-label](https://vuetifyjs.com/en/api/v-date-picker/#props-next-year-aria-label) */
		'next-year-aria-label': {
			type: String,
			default: '$vuetify.datePicker.nextYearAriaLabel'
		},

		/** راجع [Vuetify v-date-picker no-title](https://vuetifyjs.com/en/api/v-date-picker/#props-no-title) */
		'no-title': {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker picker-date](https://vuetifyjs.com/en/api/v-date-picker/#props-picker-date) */
		'picker-date': {
			type: String,
			default: undefined
		},

		/** راجع [Vuetify v-date-picker prev-icon](https://vuetifyjs.com/en/api/v-date-picker/#props-prev-icon) */
		'prev-icon': {
			type: String,
			default: '$prev'
		},

		/** راجع [Vuetify v-date-picker prev-month-aria-label](https://vuetifyjs.com/en/api/v-date-picker/#props-prev-month-aria-label) */
		'prev-month-aria-label': {
			type: String,
			default: '$vuetify.datePicker.prevMonthAriaLabel'
		},

		/** راجع [Vuetify v-date-picker prev-year-aria-label](https://vuetifyjs.com/en/api/v-date-picker/#props-prev-year-aria-label) */
		'prev-year-aria-label': {
			type: String,
			default: '$vuetify.datePicker.prevYearAriaLabel'
		},

		/** راجع [Vuetify v-date-picker range](https://vuetifyjs.com/en/api/v-date-picker/#props-range) */
		range: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker reactive](https://vuetifyjs.com/en/api/v-date-picker/#props-reactive) */
		reactive: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker readonly](https://vuetifyjs.com/en/api/v-date-picker/#props-readonly) */
		readonly: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker scrollable](https://vuetifyjs.com/en/api/v-date-picker/#props-scrollable) */
		scrollable: {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker selected-items-text](https://vuetifyjs.com/en/api/v-date-picker/#props-selected-items-text) */
		'selected-items-text': {
			type: String,
			default: '$vuetify.datePicker.itemsSelected'
		},

		/** راجع [Vuetify v-date-picker show-adjacent-months](https://vuetifyjs.com/en/api/v-date-picker/#props-show-adjacent-months) */
		'show-adjacent-months': {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker show-current](https://vuetifyjs.com/en/api/v-date-picker/#props-show-current) */
		'show-current': {
			type: [Boolean, String],
			default: true
		},

		/** راجع [Vuetify v-date-picker show-week](https://vuetifyjs.com/en/api/v-date-picker/#props-show-week) */
		'show-week': {
			type: Boolean,
			default: false
		},

		/** راجع [Vuetify v-date-picker title-date-format](https://vuetifyjs.com/en/api/v-date-picker/#props-title-date-format) */
		'title-date-format': {
			type: Function,
			default: null
		},

		/** راجع [Vuetify v-date-picker type](https://vuetifyjs.com/en/api/v-date-picker/#props-type) */
		type: {
			type: String,
			default: 'date'
		},

		/** راجع [Vuetify v-date-picker weekday-format](https://vuetifyjs.com/en/api/v-date-picker/#props-weekday-format) */
		'weekday-format': {
			type: Function,
			default: null
		},

		/** راجع [Vuetify v-date-picker width](https://vuetifyjs.com/en/api/v-date-picker/#props-width) */
		width: {
			type: [Number, String],
			default: 290
		},

		/** راجع [Vuetify v-date-picker year-format](https://vuetifyjs.com/en/api/v-date-picker/#props-year-format) */
		'year-format': {
			type: Function,
			default: null
		},

		/** راجع [Vuetify v-date-picker year-icon](https://vuetifyjs.com/en/api/v-date-picker/#props-year-icon) */
		'year-icon': {
			type: String,
			default: null
		}
	},

	data: () => ({
		menu: false
	}),

	filters: {
		/* eslint-disable no-unneeded-ternary */
		isUsed(prop) { return typeof prop === 'string' ? true : false || prop }
		/* eslint-ensable no-unneeded-ternary */
	},
	
	watch: {
		menu (val) {
			val && setTimeout(() => {
				if (this.$options.filters.isUsed(this.calendar)) {
					this.localActivePicker = 'DATE';
				} else {
					this.localActivePicker = 'YEAR';
				}
			})
		}
	},

	computed: {
		localeValue: {
			get() {
				return this.value;
			},
			set(newValue) {
				/* 
					let text = newValue;
					if (/[0-9]*[a-zA-Z]+/g.test(text)) {
						text = ''
					} else if (newValue.length === 4 || newValue.length === 7) {
						text += '-'
					}
				*/
				/**
				 * الحدث الخاص بتحديث القيمة المرتبطة بال v-model
				 * @event input
				 * @property {String} newValue - القيمة الجديدة
				 */
				this.$emit('input', newValue);
			}
		},

		localActivePicker: {
			get() {
				return this.activePicker;
			},
			set(newValue) {
				this.$emit('update:active-picker', newValue);
			}
		}
	},
	
	methods: {
		save (date) {
			if (!this.disableCloseOnChange) {
				this.$refs.menu.save(date)
			}
		}
	}
}
</script>

<style>

</style>