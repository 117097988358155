<template>
    <div
    	class="bee-drop-img"
        :style="{
            width: width,
            height: height,
        }"
    >
        <div
            v-ripple
            class="drop-zone"
            @click="$refs.fileInput.click()"
            @drop.prevent="updateImage"
            @dragover.prevent="icon= 'mdi-image'"
            @dragleave="icon= 'mdi-tray-arrow-down'"
        >
            <div v-if="!value" class="d-flex flex-column justify-center align-center">
                <v-icon color="grey" large>{{icon}}</v-icon>
                <span class="text-center mt-2">{{icon === 'mdi-image' ? dropText : dragText}}</span>
            </div>
            <div
                v-else
                class="drop-zone__show-image"
                ref="showImage"
                :style="{
                    backgroundSize: contain ? 'contain' : 'cover'
                }"
            ></div>
            <input
            	type="file"
            	ref="fileInput"
            	accept="image/*"
            	class="drop-zone__input"
            	@change="setImage($refs.fileInput.files[0])"
            >
            <div v-if="value && !hideImageName" class="drop-zone__title">{{value.name}}</div>
        </div>
        <div v-if="!hideDetails" class="error__container">
            <div :class="{'error__msg--show': error}" class="error__msg text-caption error--text mb-0">
                {{error}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        width: { type: String, default: '100%' },
        height: { type: String, default: '150px' },
        'drag-text': { type: String, default: 'Drap and drop image here' },
        'drop-text': { type: String, default: 'Drop the image' },
        'hide-details': { type: Boolean, default: false },
        'hide-image-name': { type: Boolean, default: false },
        'error-message': { type: String, default: 'only images are allowed' },
        contain: { type: Boolean, default: false },
        value: { type: File }
    },

    data() {
        return {
            icon: 'mdi-tray-arrow-down',
            error: null
        }
    },

    watch: {
        value(val) {
            if (!val) {
                this.$refs.fileInput.value = null;
                this.$refs.showImage.style.backgroundImage = null
                this.icon = 'mdi-tray-arrow-down';
            } else {
                this.setImage(val)
            }
        }
    },
    
    methods:{
        updateImage(event) {
            const file = event.dataTransfer.files[0];
            this.setImage(file);
        },

        setImage(file) {
            if (/^image\/.*/g.test(file.type)){
                this.error = null;
                this.$emit('input', file)
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', () => {
                    const uploadedImage = reader.result;
                    this.$refs.showImage.style.backgroundImage = `url(${uploadedImage})`;
                });
            } else {
                this.error = this.errorMessage;
            }
        }
    },
}
</script>

<style lang="scss">
$color-light-gray: #eee;
.bee-drop-img {
    .drop-zone{
        position: relative;
        z-index: 3;
        border: 1px solid $color-light-gray;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        width: 100%;
        height: 100%;

        &__input{
            display: none;
        }

        &__show-image {
            width: 100%;
            height: 100%;

            background-position: center;
        }

        &__title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 3px 0;
            text-align: center;
            color: white;
            background-color: #3338;
        }
    }
    .error {
        &__container {
            height: 20px;
        }

        &__msg {
            transform: translateY(-10px);
            transition: transform .4s;
            
            &--show {
                transform: translateY(0);
            }
        }
    }
}
</style>