export default {
    companies: 'Companies',
    'add-a-company': 'Add a company',

    'edit-a-company': 'Edit a company',

    'the-company': 'the company?',

    'company-dialog': {
        'company-name': 'Company name',
        'tax-number': 'Tax number',
        'manager-name': 'Manager name',
        'financial-manager-name': 'Financial manager name',
        'company-logo': 'Company logo',
        'digital-signature': 'Digital signature',
        'company-files': 'Company files',
        'files': 'Files',
        'select-all': 'Select All',
        'currencies': 'Currencies',
        'currency': 'currency',
        'phone-numbers': 'Phone numbers',
        'phone-number': 'Phone number',
        'addresses': 'Addresses',
        'address': 'Address',
    },

    'financial-account-dailog': {
        'financial-accounts': 'Financial Accounts',
        'account-details': 'Account details',
        'the-account': 'the account?',
    }
}