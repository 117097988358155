import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        commercialInvoices: []
    },

    actions:{
        fetchAll({ commit }, { sequence, buyer, seller, date }){
            return window.axios.get('/files/commercialInvoices', {
                params:{
                    sequence, buyer, seller, date
                }
            })
            .then((response) => {
                commit(types.STORE_COMMERCIAL_INVOICES, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/commercialInvoices/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }){
            return window.axios.post('/files/commercialInvoices', { isManualSequence, manualSequence, sequence, currency, buyer, date, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry })
            .then((response) => {
                return response.data;
            });
        },

        update({ commit }, { id, isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry }){
            return window.axios.put(`/files/commercialInvoices/${id}`, { isManualSequence, manualSequence, sequence, currency, buyer, date, seller, paymentTerms, shippingMarks, shipping, destination, packing, referenceContract, consignee, advancePayment, intermediaryBank, multilineNotes, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, tax, transactions, bankCountry })
            .then((response) => {
                return id;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/commercialInvoices/${id}`);
        },
    },

    mutations:{
        [types.STORE_COMMERCIAL_INVOICES](state, commercialInvoices){
            state.commercialInvoices = commercialInvoices;
        }
    },

    getters:{
        getCommercialInvoiceById: state => id => state.commercialInvoices.find(c => c.id === id),
    }
}