<template>
    <v-snackbar
        top
        app
        v-model="localSnackbar"
        :timeout="timeout"
        :color="color"
    >
        {{$t('draft.there-is-a-draft-do-you-want-to-use-it')}}
        <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                light
                :color="btnsColor"
                elevation="1"
                class="text-capitalize me-1"
                small
                @click="$emit('cofirm')"
            >
                {{$t('draft.yes')}}
            </v-btn>
            <v-btn
                v-bind="attrs"
                light
                :color="btnsColor"
                elevation="1"
                class="text-capitalize me-1"
                small
                @click="$emit('update-snackbar', false)"
            >
                {{$t('draft.no')}}
            </v-btn>

            <v-divider vertical class="mx-3 me-4" />

            <v-btn
                v-bind="attrs"
                light
                :color="btnsColor"
                elevation="1"
                class="text-capitalize me-1"
                small
                @click="$emit('reject')"
            >
                <span class="error--text">{{$t('draft.delete')}}</span> 
                {{$t('draft.it')}}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        snackbar: { type: Boolean, default: false },
        timeout: { type: Number, default: -1 },
        color: { type: String, default: 'grey darken-3' },
        btnsColor: { type: String, default: 'grey lighten-3' },
    },

    model:{
        prop: 'snackbar',
        event: 'update-snackbar'
    },

    computed: {
        localSnackbar: {
            get() {
                return this.snackbar
            },
            set() {
                this.$emit('update-snackbar', this.snackbar);
            }
        }
    }

}
</script>

<style>

</style>