export default {
    'items-and-materials': 'Articulos y materiales',
    'the-item': 'es el artículo?',
    'manage-packaging-units': 'Administrar las unidades de empaque',
    'add-an-item': 'Agregar artículo',

    dialog: {
        'add-an-item': 'Agregar artículo',
        'edit-an-existing-item': 'Editar artículo',
        'items-of-materials-name': 'Nombre de artçiulos y materiales',
        'description': 'Descripción',
        'packaging-units-for-item': 'Unidades de empaque por artículo',
        'unit': 'Unidad',
        'price': 'Precio',
        'default-unit': 'unidad por defecto',
    }
}