<template>
	<div>
		<v-form @submit.prevent="submit(false)" ref="form" :disabled="viewerOnly">
            <!-- entry dialog -->
            <v-dialog
                v-model="dialog"
                width="800"
                ref="dialog"
                @click:outside="resetDialog"
            >
                <v-form @submit.prevent="!isToEditEntry ? saveEntry() : editEntry()" >
                    <v-card>
                        <!-- dialog title -->
                        <v-card-title class="primary white--text justify-center">
                            <v-spacer/>
                            <span class="text-h6">{{ !isToEditEntry ? 'Add' : 'Edit' }} item</span>
                            <v-spacer/>
                            <v-btn small text fab @click="dialog = false" color="white">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <!-- dialog content -->
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <!-- Calidad -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-text-field
                                            v-model="quality"
                                            label="Calidad"
                                            ref="qualityInput"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>

                                    <!-- Calidad del cliente -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-text-field
                                            v-model="clientQuality"
                                            label="Calidad del cliente"
                                            type="number"
                                            min="0"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>

                                    <!-- No. de bultos -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-currency-text-field
                                            v-model.number="quantity"
                                            :items="packingTypes"
                                            label="No. de bultos"
                                            hide-details
                                        ></v-currency-text-field>
                                    </v-col>

                                    <!-- Tipo de embaque -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-autocomplete
                                            v-model="packingType"
                                            :items="packingTypes"
                                            label="Tipo de embaque"
                                            hide-details
                                        ></v-autocomplete>
                                    </v-col>
                                    
                                    <!-- Marca del empaque -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-text-field
                                            v-model="packagingBrand"
                                            label="Marca del empaque"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>

                                    <!-- Peso neto KGS -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-currency-text-field
                                            v-model.number="netWeight"
                                            label="Peso neto KGS"
                                            hide-details
                                        ></v-currency-text-field>
                                    </v-col>

                                    <!-- Peso bruto KGS -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-currency-text-field
                                            v-model.number="grossWeight"
                                            label="Peso bruto KGS"
                                            hide-details
                                        ></v-currency-text-field>
                                    </v-col>
                                    
                                    <!-- Color fleje -->
                                    <v-col md="4" sm="6" cols="12">
                                        <v-text-field
                                            v-model="strapColor"
                                            label="Color fleje"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary lighten-2"
                                class="px-6"
                                text
                                type="submit"
                            >
                                {{ !isToEditEntry ? 'Add' : 'Edit' }}
                            </v-btn>
                            <v-btn
                                class="px-6"
                                text
                                @click="dialog = false; resetDialog()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>

            <div class="d-flex justify-space-between align-center mb-0">
                <!-- title and number -->
                <v-row class="align-center ma-0 mb-1">
                    <!-- title -->
                    <v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
                        <h1 class="text-h5 primary--text">{{title}}</h1>

                        <!-- back button -->
                        <v-btn
                            v-if="$vuetify.breakpoint.smAndDown"
                            :to="{name:'port-dispatches'}"
                            :disabled="submitLoading"
                            elevation="1"
                            small
                            class="px-6"
                        >
                            {{$t('back')}}
                        </v-btn>
                    </v-col>

                    <!-- num -->
                    <v-col lg="2" md="3" sm="6" cols="12" class="py-0">
                        <v-text-field
                            v-model="num"
                            :loading="loading || editLoading || numLoading"
                            :append-icon="isAdmin ? 'mdi-pencil' : null"
                            @click:append="editSequence"
                            :readonly="!isManual"
                            :class="{ 'v-input--is-disabled': !isManual }"
                            :disabled="submitLoading || numLoading"
                            dense
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <!-- date -->
                    <v-col md="3" sm="6" cols="12">
                        <bee-date-input
                            v-model="date"
                            changeable-with-arrows
                            label="Fecha"
                            prepend-icon="mdi-calendar"
                            :input-format-order="dateFormat"
                            :user-menu-picker="true"
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                            :rules="rules.required"
                            dense
                            hide-details="auto"
                        ></bee-date-input>
                    </v-col>
                </v-row>

                <!-- back button -->
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" :to="{name:'port-dispatches'}" :disabled="submitLoading" elevation="1" small class="px-6">
                    {{$t('back')}}
                </v-btn>
            </div>

            <!-- BASIC INFORMATION -->
            <h2 class="text-body-1 primary--text mb-3">Información básica</h2>
            <v-row class="mb-3">
                <!-- Contrato No. -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="contractNo"
                        label="Contrato No."
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Orden de empaque No. -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="packingOrderNo"
                        label="Orden de empaque No."
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Regimen arancelario -->
                <v-col sm="4" cols="12">
                    <v-autocomplete-server
                        v-model="tariffRegime"
                        label="Regimen arancelario"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                        :input-field-enum="inputEnums.tariffRegime"
                    ></v-autocomplete-server>
                </v-col>

                <!-- Empresa portuaria -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="portCompanyName"
                        label="Empresa portuaria"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Para Embarcar en naviera -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="shippingCompany"
                        label="Para Embarcar en naviera"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Contenedor No. -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="containerNo"
                        label="Contenedor No."
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Marchamo No. -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="labelNo"
                        label="Marchamo No."
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Destino -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="destination"
                        label="Destino"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Booking -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="booking"
                        label="Booking"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Fecha buque -->
                <v-col sm="4" cols="12">
                    <bee-date-input
                        v-model="shipmentDate"
                        changeable-with-arrows
                        label="Fecha buque"
                        prepend-icon="mdi-calendar"
                        :input-format-order="dateFormat"
                        :menu-picker-years-over-max="10"
                        :user-menu-picker="true"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></bee-date-input>
                </v-col>

                <!-- Por medio del buque -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="shipmentBy"
                        label="Por medio del buque"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                
                <!-- Nombre piloto -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="pilotName"
                        label="Nombre piloto"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Licencia No. -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="licenseNo"
                        label="Licencia No."
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Camion placas -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="truckPlates"
                        label="Camion placas"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Plataforma placas -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="platformPlates"
                        label="Plataforma placas"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Conteniendo -->
                <v-col sm="4" cols="12">
                    <v-text-field
                        v-model="containing"
                        label="Conteniendo"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>

            <!-- ITEMS -->
            <h2 class="text-body-1 primary--text mb-3">Elementos</h2>

            <!-- table -->
            <v-data-table
                :items="loading ? [] : itemEntries"
                disable-pagination
                disable-sort
                :loading="editLoading"
                no-data-text="No items were added."
                :headers="headers"
                dense
                class="elevation-1 mb-3"
            >   
                <!-- quality -->
                <template v-slot:item.data.quality="{item}">
                    {{item.data.quality ? item.data.quality : '-'}}
                </template>
                <!-- clientQuality -->
                <template v-slot:item.data.clientQuality="{item}">
                    {{item.data.clientQuality ? item.data.clientQuality : '-'}}
                </template>
                <!-- quantity -->
                <template v-slot:item.data.quantity="{item}">
                    {{item.data.quantity ? item.data.quantity : '-'}}
                </template>
                <!-- packingType -->
                <template v-slot:item.data.packingType="{item}">
                    {{item.data.packingType ? item.data.packingType : '-'}}
                </template>
                <!-- packagingBrand -->
                <template v-slot:item.data.packagingBrand="{item}">
                    {{item.data.packagingBrand ? item.data.packagingBrand : '-'}}
                </template>
                <!-- netWeight -->
                <template v-slot:item.data.netWeight="{item}">
                    {{item.data.netWeight ? item.data.netWeight : '-'}}
                </template>
                <!-- grossWeight -->
                <template v-slot:item.data.grossWeight="{item}">
                    {{item.data.grossWeight ? item.data.grossWeight : '-'}}
                </template>
                <!-- strapColor -->
                <template v-slot:item.data.strapColor="{item}">
                    {{item.data.strapColor ? item.data.strapColor : '-'}}
                </template>

                <!-- actions -->
                <template v-slot:item.actions="{item}">
                    <div class="d-flex justify-center">
                        <tooltip text="Edit item">
                            <v-btn
                                text
                                small
                                :disabled="submitLoading || editLoading"
                                hide-details="auto"
                                @click="isToEditEntry = true; fillToEditEntry(item.no)"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Delete item">
                            <v-btn @click="removeEntry(item.no)" text small :disabled="submitLoading || editLoading"
                            hide-details="auto">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </div>
                </template>

                <!-- add button -->
                <template v-slot:footer.prepend v-if="!viewerOnly">
                    <v-btn text @click="dialog = true; isToEditEntry = false;" :disabled="submitLoading || editLoading"
                    hide-details="auto">
                        <v-icon class="me-3">mdi-plus-circle-outline</v-icon> Add item
                    </v-btn>
                </template>
            </v-data-table>

            <!-- totals -->
            <v-row class="mt-1">
                <v-spacer></v-spacer>
                <v-col lg="2" md="3" sm="4" cols="12" class="py-sm-0 py-1">
                    <v-text-field
                        outlined
                        readonly
                        hide-details
                        dense
                        label="Bultos total"
                        :value="
                            itemEntries.length > 0
                            ? itemEntries.map(c => c.data.quantity).reduce((a, b) => a + b)
                            : 0"
                    ></v-text-field>
                </v-col>
                <v-col lg="2" md="3" sm="4" cols="12" class="py-sm-0 py-1">
                    <v-text-field
                        outlined
                        readonly
                        hide-details
                        dense
                        label="Peso neto total"
                        :value="
                            itemEntries.length > 0
                            ? itemEntries.map(c => c.data.netWeight).reduce((a, b) => a + b)
                            : 0"
                    ></v-text-field>
                </v-col>
                <v-col lg="2" md="3" sm="4" cols="12" class="py-sm-0 py-1">
                    <v-text-field
                        outlined
                        readonly
                        hide-details
                        dense
                        label="Peso bruto total"
                        :value="
                            itemEntries.length > 0
                            ? itemEntries.map(c => c.data.grossWeight).reduce((a, b) => a + b)
                            : 0"
                    ></v-text-field>
                </v-col>
            </v-row>
            
            <v-row class="mt-0 mb-3">
                <!-- Fecha de PROD. -->
                <v-col md="3" sm="6" cols="12">
                    <bee-date-input
                        v-model="packingDate"
                        changeable-with-arrows
                        label="Packing date"
                        prepend-icon="mdi-calendar"
                        :input-format-order="dateFormat"
                        :menu-picker-years-over-max="10"
                        :user-menu-picker="true"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                        input-type="month"
                    ></bee-date-input>
                </v-col>

                <!-- Fecha de EXPI. -->
                <v-col md="3" sm="6" cols="12">
                    <bee-date-input
                        v-model="expiryDate"
                        changeable-with-arrows
                        label="Expiry date"
                        prepend-icon="mdi-calendar"
                        :input-format-order="dateFormat"
                        :menu-picker-years-over-max="10"
                        :user-menu-picker="true"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                        input-type="month"
                    ></bee-date-input>
                </v-col>
            </v-row>

            <v-row class="mb-4">
                <!-- Despachado por -->
                <v-col md="3" sm="6" cols="12">
                    <v-autocomplete-server
                        v-model="dispatcher"
                        label="Despachado por"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                        :input-field-enum="inputEnums.dispatcher"
                    ></v-autocomplete-server>
                </v-col>

                <!-- Transportador por -->
                <v-col md="3" sm="6" cols="12">
                    <v-autocomplete-server
                        v-model="transportedBy"
                        label="Transportador por"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                        :input-field-enum="inputEnums.transportedBy"
                    ></v-autocomplete-server>
                </v-col>

                <!-- Recibido por -->
                <v-col md="3" sm="6" cols="12">
                    <v-text-field
                        v-model="receiver"
                        label="Recibido por"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <!-- Custodia -->
                <v-col md="3" sm="6" cols="12">
                    <v-autocomplete-server
                        v-model="custody"
                        label="Custodia"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        dense
                        hide-details="auto"
                        :input-field-enum="inputEnums.custody"
                    ></v-autocomplete-server>
                </v-col>
            </v-row>

            <!-- actions -->
            <div class="d-flex justify-end" v-if="!viewerOnly">
                <v-btn dis :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
                    Save
                </v-btn>
                <v-btn dis @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
                    Save & Export as PDF
                </v-btn>
                <v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
                    <v-icon class="me-1">mdi-eye-outline</v-icon>
                    {{$t('preview')}}
                </v-btn>
            </div>
        </v-form>

        <!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`port-dispatch:${$route.params.companyId}`); draftSnackbar = false; date = moment().format('YYYY-MM-DD');"
        />

        <!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
import Tooltip from '../../../components/Tooltip'
import fileTypes from '../../../enums/file-types'
import { findFullDate, findMonthDate } from '../../../helpers'
import { BASE_API_URL } from '../../../constants'
import { mapState } from 'vuex';
import moment from 'moment'
import rules from '../../../validation rules'
export default {
	data: () => ({
		title: null,
        loading: false,
        submitLoading: false,
        editLoading: false,
        itemLoading: false,
        numLoading: false,
        previewLoading: false,
        rules,

        draft: null,
        isDraftUsed: null,
        draftSnackbar: false,

        snackbar: false,
        message: null,

        entryCounter: 0,
        isToEditEntry: false,
        itemEditNo: null,

		inputEnums: {
			tariffRegime: 0,
            custody: 1,
            dispatcher: 2,
            transportedBy: 3,
		},

        // entry dialog
        dialog: false,
        packingTypes: ['SACOS', 'CAJAS'],
        quality: null,
        clientQuality: null,
        quantity: null,
        packingType: 'SACOS',
        packagingBrand: null,
        netWeight: null,
        grossWeight: null,
        strapColor: null,
		
        /* DATA */
        isManual: false,
		num: null,
        sequence: null,
        contractNo: null,
        packingOrderNo: null,
        date: null,
        tariffRegime: null,
        portCompanyName: null,
        shippingCompany: null,
        containerNo: null,
        labelNo: null,
        destination: null,
        booking: null,
        shipmentDate: null,
        shipmentBy: null,
        pilotName: null,
        licenseNo: null,
        truckPlates: null,
        platformPlates: null,
        containing: null,

        itemEntries: [],

        packingDate: null,
        expiryDate: null,
        dispatcher: null,
        transportedBy: null,
        receiver: null,
        custody: null,
    }),
    
    watch: {
        draftFieldsWatcher() {
            if (!this.$route.query.id) {
                this.$localDrafts.save(`port-dispatch:${this.$route.params.companyId}`, {
                    contractNo: this.contractNo,
                    packingOrderNo: this.packingOrderNo,
                    date: this.date,
                    tariffRegime: this.tariffRegime,
                    portCompanyName: this.portCompanyName,
                    shippingCompany: this.shippingCompany,
                    containerNo: this.containerNo,
                    labelNo: this.labelNo,
                    destination: this.destination,
                    booking: this.booking,
                    shipmentDate: this.shipmentDate,
                    shipmentBy: this.shipmentBy,
                    pilotName: this.pilotName,
                    licenseNo: this.licenseNo,
                    truckPlates: this.truckPlates,
                    platformPlates: this.platformPlates,
                    containing: this.containing,

                    itemEntries: this.itemEntries,

                    packingDate: this.packingDate,
                    expiryDate: this.expiryDate,
                    dispatcher: this.dispatcher,
                    transportedBy: this.transportedBy,
                    receiver: this.receiver,
                    custody: this.custody,
                })
            }
        }
    },

	methods: {
        // submit
        submit(exportPdf){
            if (this.$refs.form.validate()) {
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
                this.$store.dispatch('sequences/reserve', {
                    sequence: this.isManual ? this.num : this.sequence,
                    fileType: fileTypes.portDispatch,
                    isManual: this.isManual,
                    overrideCollision: true
                })
                .then(() => {
                    this.$store.dispatch(`portDispatches/${actionName}`, {
                        id: this.$route.query.id,
                        isManualSequence: this.isManual,
                        manualSequence: this.num,
                        sequence: this.sequence,
                        date: this.date,
                        packingOrderNo: this.packingOrderNo,
                        contractNo: this.containerNo,
                        tariffRegime: this.tariffRegime,
                        portCompanyName: this.portCompanyName,
                        shippingCompany: this.shippingCompany,
                        containerNo: this.containerNo,
                        labelNo: this.labelNo,
                        destination: this.destination,
                        booking: this.booking,
                        shipmentDate: this.shipmentDate,
                        shipmentBy: this.shipmentBy,
                        pilotName: this.pilotName,
                        licenseNo: this.licenseNo,
                        truckPlates: this.truckPlates,
                        platformPlates: this.platformPlates,
                        containing: this.containing,
                        packingDate: findFullDate(this.packingDate),
                        expiryDate: findFullDate(this.expiryDate),
                        dispatcher: this.dispatcher,
                        receiver: this.receiver,
                        transportedBy: this.transportedBy,
                        custody: this.custody,

                        items: this.itemEntries.map(c => c.data),
                    })
                    .then((id) => {
                        const fileId = actionName === 'create' ? id.data : this.$route.query.id;
                        this.message = 'El despacho del puerto se ha guardado con éxito';
                        if (exportPdf) {
                            this.report(fileId);
                        }
                        if (this.isDraftUsed){
                            this.$localDrafts.remove(`port-dispatch:${this.$route.params.companyId}`);
                        }
                        this.$router.push({ name: 'port-dispatches' })
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.submitLoading = false;
                        this.snackbar = true;
                    })
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
        },

        preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/portDispatch', {
					companyId: this.$route.params.companyId,
                    isManualSequence: this.isManual,
                    manualSequence: this.num,
                    sequence: this.sequence,
                    date: this.date,
                    packingOrderNo: this.packingOrderNo,
                    contractNo: this.containerNo,
                    tariffRegime: this.tariffRegime,
                    portCompanyName: this.portCompanyName,
                    shippingCompany: this.shippingCompany,
                    containerNo: this.containerNo,
                    labelNo: this.labelNo,
                    destination: this.destination,
                    booking: this.booking,
                    shipmentDate: this.shipmentDate,
                    shipmentBy: this.shipmentBy,
                    pilotName: this.pilotName,
                    licenseNo: this.licenseNo,
                    truckPlates: this.truckPlates,
                    platformPlates: this.platformPlates,
                    containing: this.containing,
                    packingDate: findFullDate(this.packingDate),
                    expiryDate: findFullDate(this.expiryDate),
                    dispatcher: this.dispatcher,
                    receiver: this.receiver,
                    transportedBy: this.transportedBy,
                    custody: this.custody,

                    items: this.itemEntries.map(c => c.data),
				})
				.then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
				.catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
			}
		},

        // entry dialog functions
        resetInputEntries(){
            this.quality = null;
            this.clientQuality = null;
            this.quantity = null;
            this.packagingBrand = null;
            this.netWeight = null;
            this.grossWeight = null;
            this.strapColor = null;
        },

        resetDialog(){
            this.resetInputEntries();
            this.isToEditEntry = false;  
        },

        saveEntry(){
            this.itemEntries.push({
                no: ++this.entryCounter,
                data: {
                    quality: this.quality,
                    clientQuality: this.clientQuality,
                    quantity: this.quantity,
                    packingType: this.packingType,
                    packagingBrand: this.packagingBrand,
                    netWeight: this.netWeight,
                    grossWeight: this.grossWeight,
                    strapColor: this.strapColor
                }
            });

            this.resetInputEntries();
            this.$refs.qualityInput.focus();
        },

        fillToEditEntry(itemNo){
            this.itemEditNo = itemNo;
            const entry = this.itemEntries.find(c => c.no === itemNo);

            this.quality = entry.data.quality
            this.clientQuality = entry.data.clientQuality
            this.quantity = entry.data.quantity
            this.packingType = entry.data.packingType
            this.packagingBrand = entry.data.packagingBrand
            this.netWeight = entry.data.netWeight
            this.grossWeight = entry.data.grossWeight
            this.strapColor = entry.data.strapColor

            this.dialog = true;
        },

        editEntry(){
            const entry = this.itemEntries.find(c => c.no === this.itemEditNo);
            entry.data.quality = this.quality;
            entry.data.clientQuality = this.clientQuality;
            entry.data.quantity = this.quantity;
            entry.data.packingType = this.packingType;
            entry.data.packagingBrand = this.packagingBrand;
            entry.data.netWeight = this.netWeight;
            entry.data.grossWeight = this.grossWeight;
            entry.data.strapColor = this.strapColor;
            
            this.dialog = false;
            this.resetInputEntries();
            this.isToEditEntry = false;
        },

        removeEntry(itemNo){
            const index = itemNo - 1;
            this.itemEntries.splice(index, 1);
            this.itemEntries.forEach(entry => {
                if (entry.no - 1 > index) entry.no--;
            });
            this.entryCounter--;
        },

        // equence
        editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },
        
		// fetch sequence
        fetchSequence() {
            this.numLoading = true
            return this.$store.dispatch('sequences/fetch', { fileType: fileTypes.portDispatch })
                .then(response => {
                    this.num = response.value;
                    this.sequence = response;
                })
                .finally(() => {
                    this.numLoading = false;
                })
        },

		// fill all field if edit
        fillAllFieldsToEdit(data, fillSequence = true){
            if (fillSequence) { this.num = data.sequence; }

            this.date = data.date ? moment(data.date).format('YYYY-MM-DD') : null;
            this.packingOrderNo = data.packingOrderNo;
            this.contractNo = data.containerNo;
            this.tariffRegime = data.tariffRegime;
            this.portCompanyName = data.portCompanyName;
            this.shippingCompany = data.shippingCompany;
            this.containerNo = data.containerNo;
            this.labelNo = data.labelNo;
            this.destination = data.destination;
            this.booking = data.booking;
            this.shipmentDate = data.shipmentDate ? moment(data.shipmentDate).format('YYYY-MM-DD') : null;
            this.shipmentBy = data.shipmentBy;
            this.pilotName = data.pilotName;
            this.licenseNo = data.licenseNo;
            this.truckPlates = data.truckPlates;
            this.platformPlates = data.platformPlates;
            this.containing = data.containing;
            this.packingDate = data.shipmentDate ? findMonthDate(data.packingDate) : null;
            this.expiryDate = data.shipmentDate ? findMonthDate(data.expiryDate) : null;
            this.dispatcher = data.dispatcher;
            this.receiver = data.receiver;
            this.transportedBy = data.transportedBy;
            this.custody = data.custody;
            
            data.items.forEach(item => {
                this.itemEntries.push({
                    no: ++this.entryCounter,
                    data: {
                        quality: item.quality,
                        clientQuality: item.clientQuality,
                        quantity: item.quantity,
                        packingType: item.packingType,
                        packagingBrand: item.packagingBrand,
                        netWeight: item.netWeight,
                        grossWeight: item.grossWeight,
                        strapColor: item.strapColor
                    }
                }); 
            });
        },

        // fill data from draft
        fillDataFromDraft() {
            this.contractNo = this.draft.contractNo;
            this.packingOrderNo = this.draft.packingOrderNo;
            this.date = this.draft.date;
            this.tariffRegime = this.draft.tariffRegime;
            this.portCompanyName = this.draft.portCompanyName;
            this.shippingCompany = this.draft.shippingCompany;
            this.containerNo = this.draft.containerNo;
            this.labelNo = this.draft.labelNo;
            this.destination = this.draft.destination;
            this.booking = this.draft.booking;
            this.shipmentDate = this.draft.shipmentDate;
            this.shipmentBy = this.draft.shipmentBy;
            this.pilotName = this.draft.pilotName;
            this.licenseNo = this.draft.licenseNo;
            this.truckPlates = this.draft.truckPlates;
            this.platformPlates = this.draft.platformPlates;
            this.containing = this.draft.containing;
            this.itemEntries = this.draft.itemEntries;
            this.packingDate = this.draft.packingDate;
            this.expiryDate = this.draft.expiryDate;
            this.dispatcher = this.draft.dispatcher;
            this.transportedBy = this.draft.transportedBy;
            this.receiver = this.draft.receiver;
            this.custody = this.draft.custody;

            this.entryCounter = this.draft.itemEntries.length;
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/file/${id}?fileType=${fileTypes.portDispatch}`);
        },

        // helpers
        moment
    },
    
    computed: {
        headers() {
            const headers = [
                { text:'No', value:'no', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text:'Calidad', value:'data.quality', align:'center' },
                { text:'Calidad del cliente', value:'data.clientQuality', align:'center' },
                { text:'No. de bultos', value:'data.quantity', align:'center' },
                { text:'Tipo de embaque', value:'data.packingType', align:'center' },
                { text:'Marca del empaque', value:'data.packagingBrand', align:'center' },
                { text:'Peso neto KGS', value:'data.netWeight', align:'center' },
                { text:'Peso bruto KGS', value:'data.grossWeight', align:'center' },
                { text:'Color fleje', value:'data.strapColor', align:'center' },
                { text:'Actions', value:'actions', align:'center' },
            ];

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
        },

        ...mapState({
            isAdmin: state => state.users.currentUser.isAdmin,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings,
        }),

        dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

        draftFieldsWatcher() {
            return (`${this.isToEditEntry} | ${this.contractNo} | ${this.packingOrderNo} | ${this.tariffRegime} | ${this.portCompanyName} | ${this.shippingCompany} | ${this.containerNo} | ${this.labelNo} | ${this.destination} | ${this.booking} | ${this.shipmentDate} | ${this.shipmentBy} | ${this.pilotName} | ${this.licenseNo} | ${this.truckPlates} | ${this.platformPlates} | ${this.containing} | ${this.itemEntries} | ${this.packingDate} | ${this.expiryDate} | ${this.dispatcher} | ${this.transportedBy} | ${this.receiver} | ${this.custody}`)
        }
    },

	mounted(){
        this.loading = true;
        if (this.$route.query.id){
            this.editLoading = true;

            if (this.$route.query.viewer) {
                this.title = 'Avance ' + 'despacho a puerto';
            }
            else if (this.$route.query.duplicate) {
                this.title = 'Duplicar ' + 'despacho a puerto';
            }
            else {
                this.title = 'Editar ' + 'despacho a puerto';
            }

            Promise.all([
                this.$store.dispatch('portDispatches/fetchById', { id: this.$route.query.id })
                .then((data) => {
                    // if it duplicate mode don't fill sequence and create new sequence and fill it
                    if (this.$route.query.duplicate) {
                        this.fillAllFieldsToEdit(data, false);
                        this.fetchSequence();
                    }
                    else {
                        this.fillAllFieldsToEdit(data);
                    }
                })
            ])
            .finally(() => {
                this.loading = false;
                this.editLoading = false;
            });
        }
        else {
            this.title = 'Agregar ' + 'despacho a puerto';
            
            this.fetchSequence()
            .finally(() => {
                this.loading = false;
            });
            // get draft and show snackbar
            this.draft = this.$localDrafts.get(`port-dispatch:${this.$route.params.companyId}`);
            if (this.draft){
                setTimeout(() => {
                    this.draftSnackbar = true;
                }, 500)
            }
            else {
                this.date = moment().format('YYYY-MM-DD')
            }
        }
    },

    components: {
        Tooltip
    }
}
</script>

<style>

</style>