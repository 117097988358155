export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const STORE_COMPANIES = 'STORE_COMPANIES';
export const STORE_COMPANY = 'STORE_COMPANY';

export const STORE_UNITS = 'STORE_UNITS';

export const STORE_CUSTOMERS = 'STORE_CUSTOMERS';

export const STORE_ITEMS = 'STORE_ITEMS';

export const STORE_FILE_SETTINGS = 'STORE_FILE_SETTINGS';

export const STORE_FISCAL_YEARS = 'STORE_FISCAL_YEARS';

export const STORE_TEMPLATES = 'STORE_TEMPLATES';
export const STORE_CONTAINERS = 'STORE_CONTAINERS';

export const STORE_SALES_CONTRACTS = 'STORE_SALES_CONTRACTS';

export const STORE_INVOICES = 'STORE_INVOICES';

export const STORE_COMMERCIAL_INVOICES = 'STORE_COMMERCIAL_INVOICES';

export const STORE_PACKING_LISTS = 'STORE_PACKING_LISTS';

export const STORE_SHIPPING_ADVISES = 'STORE_SHIPPING_ADVISES';

export const STORE_PACKING_OREDERS = 'STORE_PACKING_OREDERS';

export const STORE_PRODUCT_RELEASE_ORDERS = 'STORE_PRODUCT_RELEASE_ORDERS';

export const STORE_USERS = 'STORE_USERS';
export const STORE_CURRENT_USER = 'STORE_CURRENT_USER';

export const STORE_FUMIGATION = 'STORE_FUMIGATION';

export const STORE_HEALTH = 'STORE_HEALTH';

export const STORE_WEIGHT_AND_QUALITY = 'STORE_WEIGHT_AND_QUALITY';

export const STORE_PORT_DISPATCHES = 'STORE_PORT_DISPATCHES';

export const STORE_TECHNICAL_AND_FINANCIAL_OFFERS = 'STORE_TECHNICAL_AND_FINANCIAL_OFFERS';