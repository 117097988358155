<template>
    <div>
        <v-dialog
          v-model="dialog"
          persistent
          width="90%"
          ref="dialog"
        >
            <v-card>
                <!-- dialog title -->
                <v-card-title class="primary white--text py-2 justify-center">
                    <v-spacer/>
                    <span class="text-h6">{{$t('Companies.financial-account-dailog.financial-accounts')}}</span>
                    <v-spacer/>
                    <v-btn text small light fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>

                <!-- dialog content -->
                <v-card-text class="py-2">
                    <div class="text-h6 primary--text mb-3">
                        {{$t('Companies.financial-account-dailog.account-details')}}
                    </div>
                    <v-form @submit.prevent="toEdit ? editAccount(): addAccount()" ref="form">
                        <!-- data inputs -->
                        <v-row class="px-3 mb-0">
                            <!-- Beneficiary name -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="beneficiaryName"
                                    :label="$t('bank.beneficiary-name')"
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Identifier -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="identifier"
                                    :label="$t('bank.identifier')"
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Bank name -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="bankName"
                                    :label="$t('bank.bank-name')"
                                    dense
                                    hide-details="auto"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- Bank country -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="country"
                                    :label="$t('bank.bank-country')"
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- address -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="address"
                                    :label="$t('bank.address')"
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Account Number -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="accountNumber"
                                    :label="$t('bank.account-number')"
                                    dense
                                    hide-details="auto"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- Swift Code -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="swiftCode"
                                    :label="$t('bank.swift-code')"
                                    dense
                                    hide-details="auto"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- IBan -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="iban"
                                    :label="$t('bank.iban')"
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Branch -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-text-field
                                    v-model="branch"
                                    :label="$t('bank.branch')"
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- intermediary bank -->
                            <v-col lg="3" md="4" sm="6" cols="12" class="py-2">
                                <v-textarea
                                    v-model="intermediaryBank"
                                    :label="$t('bank.intermediary-bank')"
                                    dense
                                    hide-details="auto"
                                    rows="2"
                                    outlined
                                    auto-grow
                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <!-- action buttons -->
                        <div class="d-flex">
                            <v-spacer/>
                            <v-btn
                                text
                                color="primary"
                                class="mb-4"
                                :loading="loading"
                                :disabled="loading"
                                type="submit"
                            >
                                {{ toEdit ? $t('save') : $t('add')}}
                                <v-icon class="ms-1">
                                    {{ 
                                        toEdit
                                        ? 'mdi-checkbox-marked-circle-outline'
                                        : 'mdi-plus-circle-outline'
                                    }}
                                </v-icon>
                            </v-btn>

                            <v-btn
                                text
                                :disabled="loading"
                                @click="$refs.form.reset(); toEdit = false"
                                class="mb-4"
                            >
                                {{$t('reset')}}
                            </v-btn>
                        </div>
                    </v-form>

                    <!-- accounts table -->
                    <v-data-table
                        :headers="headers"
                        :items="loading ? [] : accounts"
                        hide-default-footer
                        disable-sort
                        :no-data-text="$t('no-data-text')"
                        :no-results-text="$t('no-results-text')"
                        :loading-text="$t('loading-text')"
                        :loading="loading"
                        dense
                        class="elevation-2"
                    >
                        <!-- accountNumber -->
                        <template v-slot:item.accountNumber="{item}">
                            {{ item.accountNumber ? item.accountNumber : '-' }}
                        </template>
                        
                        <!-- bankName -->
                        <template v-slot:item.bankName="{item}">
                            {{ item.bankName ? item.bankName : '-' }}
                        </template>
                        
                        <!-- beneficiaryName -->
                        <template v-slot:item.beneficiaryName="{item}">
                            {{ item.beneficiaryName ? item.beneficiaryName : '-' }}
                        </template>
                        
                        <!-- identifier -->
                        <template v-slot:item.identifier="{item}">
                            {{ item.identifier ? item.identifier : '-' }}
                        </template>
                        
                        <!-- address -->
                        <template v-slot:item.address="{item}">
                            {{ item.address ? item.address : '-' }}
                        </template>
                        
                        <!-- swiftCode -->
                        <template v-slot:item.swiftCode="{item}">
                            {{ item.swiftCode ? item.swiftCode : '-' }}
                        </template>
                        
                        <!-- iban -->
                        <template v-slot:item.iban="{item}">
                            {{ item.iban ? item.iban : '-' }}
                        </template>
                        
                        <!-- branch -->
                        <template v-slot:item.branch="{item}">
                            {{ item.branch ? item.branch : '-' }}
                        </template>
                        
                        <!-- actions -->
                        <template v-slot:item.actions="{item}">
                            {{ item.actions ? item.actions : '-' }}
                        </template>

                        <!-- actions -->
                        <template v-slot:item.actions="{item}">
                            <tooltip text="Edit account">
                                <v-btn text small @click="fillToEdit(item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </tooltip>
                            
                            <tooltip text="Delete account">
                                <v-btn text small @click="activeDeleteDialog(item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="px-6"
                        color="primary"
                        @click="dialog = false"
                        :disabled="loading"
                    >
                        {{$t('done')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>

        <!-- delete dialog -->
        <v-dialog
            transition="dialog-transition"
            width="500"
            ref="DeleteDialog"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar
                        color="red lighten-1"
                        dark
                        flat
                        class="text-uppercase justify-center"
                    >
                        <v-spacer/>
                        {{$t('confirm-delete')}}
                        <v-spacer/>
                    </v-toolbar>
                    <v-card-text class="pa-0 pl-2">
                        <!-- dialog content -->
                        <v-container>
                            <span class="text-body-1">
                                {{$t('are-you-sure-you-want-to')}} 
                                <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                                {{$t('Companies.company-dialog.the-account')}}
                            </span>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            @click="deleteAccount"
                            :loading="loading"
                            :disabled="loading"
                            color="red lighten-1 white--text"
                        >
                            {{$t('delete')}}
                        </v-btn>
                        <v-btn
                            @click="dialog.value = false"
                            :disabled="loading"
                        >
                            {{$t('close')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import rules from '../validation rules'
import Tooltip from '../components/Tooltip'
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        // helpers
        dialog: false,
        snackbar: false,
        message: null,
        loading: false,
        toEdit: false,
        rules,

        companyId: null,
        accountId: null,

        // data
        beneficiaryName: null,
        identifier: null,
        accounts: null,
        bankName: null,
        country: null,
        address: null,
        accountNumber: null,
        swiftCode: null,
        iban: null,
        branch: null,
        intermediaryBank: null,
    }),

    methods:{
        resetData(){
            if (this.bankName){
                this.$refs.form.resetValidate();
            }

            this.companyId = null;
            this.accountId = null;

            this.beneficiaryName = null;
            this.identifier = null;
            this.accounts = null;
            this.bankName = null;
            this.country = null;
            this.intermediaryBank = null;
            this.address = null;
            this.accountNumber = null;
            this.swiftCode = null;
            this.iban = null;
            this.branch = null;
        },

        fillToEdit(accountId){
            this.toEdit = true;
            this.accountId = accountId;

            var account = this.accounts.find(c => c.id === accountId);
            this.identifier = account.identifier;
            this.beneficiaryName = account.beneficiaryName;
            this.bankName = account.bankName;
            this.country = account.country;
            this.intermediaryBank = account.intermediaryBank;
            this.address = account.address;
            this.accountNumber = account.accountNumber;
            this.swiftCode = account.swiftCode;
            this.iban = account.iban;
            this.branch = account.branch;
        },

        // account methods
        fetchCompanyAccounts(companyId){
            return this.$store.dispatch('companies/fetchById', { id: companyId })
            .then((data) => {
                this.accounts = data.bankAccounts;
            })
        },

        activeDialog(companyId){
            this.resetData();
            this.loading = true;
            this.companyId = companyId;
            this.fetchCompanyAccounts(companyId).finally(() => {
                this.loading = false;
            })
            this.dialog = true;
        },

        addAccount(){
            this.loading = true;
            this.$store.dispatch('bankAccounts/create', {
                companyId: this.companyId,
                beneficiaryName: this.beneficiaryName,
                identifier: this.identifier,
                bankName: this.bankName,
                country: this.country,
                intermediaryBank: this.intermediaryBank,
                address: this.address,
                accountNumber: this.accountNumber,
                swiftCode: this.swiftCode,
                iban: this.iban,
                branch: this.branch
            })
            .then(() => {
                this.$refs.form.reset();
                this.message = this.$t('messages.account-has-been-successfully-added');
            })
            .catch((e) => {
                this.message = e.response.data.message;
            })
            .finally(() => {
                this.fetchCompanyAccounts(this.companyId).finally(() => {
                    this.loading = false;
                    this.snackbar = true;
                })
            })
        },

        editAccount(){
            this.loading = true;
            this.$store.dispatch('bankAccounts/update', {
                companyId: this.companyId,
                bankAccountId: this.accountId,
                beneficiaryName: this.beneficiaryName,
                identifier: this.identifier,
                bankName: this.bankName,
                country: this.country,
                intermediaryBank: this.intermediaryBank,
                address: this.address,
                accountNumber: this.accountNumber,
                swiftCode: this.swiftCode,
                iban: this.iban,
                branch: this.branch
            })
            .then(() => {
                this.$refs.form.reset();
                this.message = this.$t('messages.account-has-been-successfully-edited');
                this.toEdit = false;
            })
            .catch((e) => {
                this.message = e.response.data.message;
            })
            .finally(() => {
                this.fetchCompanyAccounts(this.companyId).finally(() => {
                    this.loading = false;
                    this.snackbar = true;
                })
            })
        },
        
        activeDeleteDialog(accountId){
            this.accountId = accountId;
            this.$refs.DeleteDialog.isActive = true;
        },

        deleteAccount(){
            this.loading = true;
            this.$store.dispatch('bankAccounts/delete', {
                companyId: this.companyId,
                bankAccountId: this.accountId
            })
            .then(() => {
                this.message = this.$t('messages.account-has-been-deleted');
                this.$refs.DeleteDialog.isActive = false;
            })
            .catch((e) => {
                this.message = e.response.data.message;
            })
            .finally(() => {
                this.fetchCompanyAccounts(this.companyId).finally(() => {
                    this.loading = false;
                    this.snackbar = true;
                })
            })
        },
    },

    computed:{
        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),
        headers() {
            return [
                { text: this.$t('bank.account-number'), value:'accountNumber', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('bank.bank-name'), value:'bankName', align:'center' },
                { text: this.$t('bank.beneficiary-name'), value:'beneficiaryName', align:'center' },
                { text: this.$t('bank.identifier'), value:'identifier', align:'center' },
                { text: this.$t('bank.address'), value:'address', align:'center' },
                { text: this.$t('bank.swift-code'), value:'swiftCode', align:'center' },
                { text: this.$t('bank.iban'), value:'iban', align:'center' },
                { text: this.$t('bank.branch'), value:'branch', align:'center' },
                { text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]
        },
    },

    components:{
        Tooltip
    }
}
</script>

<style>

</style>