<template>
    <div>
        <v-dialog
          v-model="dialog"
          width="500"
          persistent
          ref="dialog"
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text justify-center py-2">
                        <v-spacer/>
                        <span class="text-h6">{{title}}</span>
                        <v-spacer/>
                        <v-btn text small light fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>

                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text class="pb-0">
                        <v-container>
                            <v-row class="mt-0">
                                <!-- client name -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                      v-model="name"
                                      :label="$t('CustomersAndSuppliers.dialog.customer-or-supplier-name')"
                                      :loading="dataLoading"
                                      dense
                                      hide-details="auto"
                                      :rules="rules.required"
                                    ></v-text-field>
                                </v-col>

                                <!-- tax number -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                      v-model="taxNumber"
                                      :label="$t('CustomersAndSuppliers.dialog.tax-number')"
                                      :loading="dataLoading"
                                      dense
                                      hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- box number -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                      v-model="boxNumber"
                                      :label="$t('CustomersAndSuppliers.dialog.box-number')"
                                      :loading="dataLoading"
                                      dense
                                      hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- address -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                      v-model="address"
                                      :label="$t('CustomersAndSuppliers.dialog.address')"
                                      :loading="dataLoading"
                                      dense
                                      hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- favorite items -->
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="favoriteItems"
                                        :items="items"
                                        :label="$t('CustomersAndSuppliers.dialog.favorite-items')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        :loading="dataLoading"
                                        hide-details
                                        dense
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip
                                                small
                                                close
                                                class="mt-1"
                                                @click:close="index >= 0 ? favoriteItems.splice(index, 1) : null"
                                            >
                                                {{ item.name }}
                                            </v-chip>
                                            <!-- <span v-if="index === 0" class="me-1">{{ item.name }},</span>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ favoriteItems.length - 1 }})
                                            </span> -->
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <!-- phones and emails -->
                            <v-row class="mt-4">
                                <!-- phones -->
                                <v-col sm="6" cols="12" class="py-0">
                                    <!-- phone title -->
                                    <div class="d-flex align-center mb-3">
                                        <h3 class="text-body-1 primary--text">
                                            {{$t('CustomersAndSuppliers.dialog.phone-numbers')}}
                                        </h3>
                                        <v-btn text small class="ms-2" @click="addPhone">
                                            <v-icon>
                                                mdi-plus-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <!-- phones inputs -->
                                    <div class="d-flex align-center mb-5" v-for="phone in phones" :key="phone.id">
                                        <v-text-field
                                          v-model="phone.value"
                                          :label="$t('CustomersAndSuppliers.dialog.phone-number')"
                                          dense
                                          hide-details="auto"
                                          :rules="rules.required"
                                        ></v-text-field>

                                        <v-btn text small class="ms-2" @click="removePhone(phone.id)">
                                            <v-icon>
                                                mdi-minus-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>

                                <!-- emails -->
                                <v-col sm="6" cols="12" class="py-0">
                                    <!-- email title -->
                                    <div class="d-flex align-center mb-3">
                                        <h3 class="text-body-1 primary--text">
                                            {{$t('CustomersAndSuppliers.dialog.emails')}}
                                        </h3>
                                        <v-btn text small class="ms-2" @click="addEmail">
                                            <v-icon>
                                                mdi-plus-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <!-- emails inputs -->
                                    <div class="d-flex align-center mb-5" v-for="email in emails" :key="email.id">
                                        <v-text-field
                                          v-model="email.value"
                                          :label="$t('CustomersAndSuppliers.dialog.email')"
                                          dense
                                          hide-details="auto"
                                          :rules="rules.required"
                                        ></v-text-field>

                                        <v-btn text small class="ms-2" @click="removeEmail(email.id)">
                                            <v-icon>
                                                mdi-minus-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            :loading="loading"
                            :disabled="loading || dataLoading"
                            type="submit"
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            @click="dialog = false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import rules from '../validation rules';
export default {
    data: () => ({
        // helpers
        id: null,
        dialog: false,
        snackbar: false,
        message: null,
        loading: false,
        title: null,

        customer: null,
        dataLoading: false,

        phoneCounter: 0,
        emailCounter: 0,

        rules,

        // data
        name: null,
        taxNumber: null,
        boxNumber: null,
        address: null,
        phones: [],
        emails: [],
        favoriteItems: []
    }),

    methods:{
        resetData(){
            if (this.name){
                this.$refs.form.resetValidation();
            }

            this.id = null;
            this.customer = null;
            this.phoneCounter = 0;
            this.emailCounter = 0;

            this.name = null;
            this.taxNumber = null;
            this.boxNumber = null;
            this.address = null;
            this.phones = [];
            this.emails = [];
            this.favoriteItems = [];
        },

        fillData(customerId){
            this.dataLoading = true;
            this.$store.dispatch('customers/fetchById', { id: customerId })
            .then((data) => {
                this.customer = data;
            })
            .finally(() => {
                this.name = this.customer.name;
                this.taxNumber = this.customer.taxNumber;
                this.boxNumber = this.customer.boxNumber;
                this.address = this.customer.address;

                this.customer.phoneNumbers.forEach((phoneNumber) => {
                    this.phones.push({
                        id: this.phoneCounter++,
                        value: phoneNumber
                    });
                })

                this.customer.emails.forEach((email) => {
                    this.emails.push({
                        id: this.emailCounter++,
                        value: email
                    });
                })

                this.favoriteItems = this.customer.items;
                this.dataLoading = false;
            })
        },
        
        addClient(){
            this.resetData();
            this.title = this.$t('CustomersAndSuppliers.dialog.add-a-customer');
            this.dialog = true;
        },

        editClient(customerId){
            this.resetData();
            this.title = this.$t('CustomersAndSuppliers.dialog.edit-an-existing-customer');
            this.id = customerId;
            this.fillData(customerId);
            this.dialog = true;
        },

        // phone
        addPhone(){
            this.phones.push({
                id: this.phoneCounter++,
                value: null
            });
        },
        removePhone(index){
            this.phones.splice(index, 1);
            this.phones.forEach(phone => {
                if (phone.id > index) phone.id--;
            });
            this.phoneCounter--;
        },

        // email
        addEmail(){
            this.emails.push({
                id: this.emailCounter++,
                value: null
            });
        },
        removeEmail(index){
            this.emails.splice(index, 1);
            this.emails.forEach(email => {
                if (email.id > index) email.id--;
            });
            this.emailCounter--;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                
                const actionName = !this.id ? 'create' : 'update';
                this.$store.dispatch(`customers/${actionName}`, {
                    id: this.id,
                    name: this.name,
                    taxNumber: this.taxNumber,
                    boxNumber: this.boxNumber,
                    address: this.address,
                    phoneNumbers: this.phones.map(c => c.value),
                    emails: this.emails.map(c => c.value),
                    items: this.favoriteItems
                })
                .then(() => {
                    this.message = 
                        !this.id
                        ? this.$t('messages.customer-has-been-successfully-added')
                        : this.$t('messages.customer-has-been-successfully-edited');
                    this.$emit('on-save');
                    this.dialog = false;
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                })
                .finally(() => {
                    this.loading = false;
                    this.snackbar = true;
                })
            }
        }
    },

    computed:{
        ...mapState({
            items: state => state.items.items,
        }),
        
        ...mapGetters({
            getCustomerById: 'customers/getCustomerById'
        })
    }
}
</script>

<style>

</style>