<template>
    <v-form @submit.prevent="submit(false)" ref="form" :disabled="viewerOnly">
        <!-- entry dialog -->
        <v-dialog
            v-model="dialog"
            width="800"
            ref="dialog"
            @click:outside="resetDialog"
        >
            <v-form @submit.prevent="!isToEditEntry ? saveEntry() : editEntry()" >
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text justify-center py-3">
                        <v-spacer/>
                        <span class="text-h6 ms-3">
                            {{ !isToEditEntry ? $t('add') : $t('edit') }} {{$t('ShippingAdvises.item')}}
                        </span>
                        <v-spacer/>
                        <v-btn text small fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <!-- Quality -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="quality"
                                        :label="$t('headers.quality')"
                                        ref="qualityInput"
                                        hide-details
                                    ></v-text-field>
                                </v-col>

                                <!-- Customer Quality -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="customerQuantity"
                                        :label="$t('headers.customer-quality')"
                                        hide-details
                                    ></v-text-field>
                                </v-col>

                                <!-- Net weight -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-currency-text-field
                                        v-model.number="netWeight"
                                        :label="$t('headers.net-weight')"
                                        hide-details
                                    ></v-currency-text-field>
                                </v-col>

                                <!-- Packing -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-autocomplete
                                        v-model="packing"
                                        :items="packingTypes"
                                        :label="$t('headers.packing')"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>

                                <!-- Net weight per package -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-currency-text-field
                                        v-model.number="netWeightPerPackage"
                                        :label="$t('headers.net-weight-per-package')"
                                        type="number"
                                        min="0"
                                        hide-details
                                    ></v-currency-text-field>
                                </v-col>

                                <!-- Number of packages -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-currency-text-field
                                        v-model.number="numberOfPackages"
                                        :label="$t('headers.number-of-packages')"
                                        type="number"
                                        min="0"
                                        hide-details
                                    ></v-currency-text-field>
                                </v-col>

                                <!-- Shrink colour -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="shrinkColor"
                                        :label="$t('headers.shrink-color')"
                                        hide-details
                                    ></v-text-field>
                                </v-col>

                                <!-- Packing date -->
                                <v-col md="4" sm="6" cols="12">
                                    <bee-date-input
                                        v-model="packingDate"
                                        changeable-with-arrows
                                        :menu-picker-years-over-max="10"
                                        :label="$t('forms.packing-date')"
                                        prepend-icon="mdi-calendar"
                                        :input-format-order="dateFormat"
                                        input-type="month"
                                        :user-menu-picker="true"
                                    ></bee-date-input>
                                </v-col>

                                <!-- Expiry date -->
                                <v-col md="4" sm="6" cols="12">
                                    <bee-date-input
                                        v-model="expiryDate"
                                        changeable-with-arrows
                                        :menu-picker-years-over-max="10"
                                        :label="$t('forms.expiry-date')"
                                        prepend-icon="mdi-calendar"
                                        :input-format-order="dateFormat"
                                        input-type="month"
                                        :user-menu-picker="true"
                                    ></bee-date-input>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            type="submit"
                        >
                            {{ !isToEditEntry ? $t('add') : $t('edit') }}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            @click="dialog = false; resetDialog();"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <div class="d-flex justify-space-between align-center mb-1">
            <v-row class="align-center ma-0">
                <!-- title -->
                <v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
                    <h1 class="text-h5 primary--text">{{title}}</h1>
                    <v-btn
                        v-if="$vuetify.breakpoint.smAndDown"
                    	:to="{name: 'shipping-advises'}"
                    	:disabled="submitLoading"
                    	elevation="1"
                    	small
                    	class="px-6"
                    >
                        {{$t('back')}}
                    </v-btn>
                </v-col>

                <!-- date -->
                <v-col sm="4" cols="12">
                    <bee-date-input
                        v-model="date"
                        changeable-with-arrows
                        :label="$t('forms.date')"
                        prepend-icon="mdi-calendar"
                        :input-format-order="dateFormat"
                        :user-menu-picker="true"
                        dense
                        hide-details="auto"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        :rules="rules.required"
                    ></bee-date-input>
                </v-col>
            </v-row>

            <!-- back button -->
            <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                :to="{name: 'shipping-advises'}"
                :disabled="submitLoading"
                elevation="1"
                small
                class="px-6"
            >
                {{$t('back')}}
            </v-btn>
        </div>

        <!-- BASIC INFORMATION -->
        <h2 class="text-body-1 primary--text mb-3">Basic information</h2>

        <v-row class="mb-3">
            <v-col md="6" cols="12">
                <v-row>
                    <!-- Contract No. -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="contractNo"
                            :label="$t('forms.contract-no')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                    
                    <!-- consignee -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="consignee"
                            :label="$t('forms.consignee')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- Shipping company -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="shippingCompany"
                            :label="$t('forms.shipping-company')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- Shipping Date -->
                    <v-col cols="12">
                        <bee-date-input
                            v-model="shippingDate"
                            changeable-with-arrows
                            :label="$t('forms.shipping-date')"
                            prepend-icon="mdi-calendar"
                            :menu-picker-years-over-max="10"
                            :input-format-order="dateFormat"
                            :user-menu-picker="true"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                        ></bee-date-input>
                    </v-col>

                    <!-- Port of loading -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="loadingPort"
                            :label="$t('forms.port-of-loading')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>

            <v-col md="6" cols="12">
                <v-row>
                    <!-- B/L No. -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="BLNo"
                            :label="$t('forms.bl-no')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                    
                    <!-- Container No. -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="containerNo"
                            :label="$t('forms.container-no')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                    
                    <!-- Port of destination -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="destinationPort"
                            :label="$t('forms.port-of-destination')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                    
                    <!-- Container size -->
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="containerSize"
                            :items="containerSizes"
                            :label="$t('forms.container-size')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-autocomplete>
                    </v-col>
                    
                    <!-- Custom stamp No. -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="customStampNo"
                            :label="$t('forms.custom-stamp-no')"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- table -->
        <v-data-table
            :items="loading ? [] : tableEntries"
            disable-sort
            :loading="editLoading"
            :no-data-text="$t('no-data-text')"
            :no-results-text="$t('no-results-text')"
            :loading-text="$t('loading-text')"
            :footer-props="{ 
                'items-per-page-all-text': $t('items-per-page-all-text'),
                'items-per-page-text': $t('items-per-page-text'),
                showCurrentPage:true
            }"
            :headers="headers"
            dense
            class="elevation-1 mb-6"
        >
            <!-- quality -->
            <template v-slot:item.data.quality="{item}">
                {{ item.data.quality ? item.data.quality : '-' }}
            </template>

            <!-- customerQuantity -->
            <template v-slot:item.data.customerQuantity="{item}">
                {{ item.data.customerQuantity ? item.data.customerQuantity : '-' }}
            </template>

            <!-- netWeight -->
            <template v-slot:item.data.netWeight="{item}">
                {{ item.data.netWeight ? item.data.netWeight : '-' | currency }}
            </template>

            <!-- packing -->
            <template v-slot:item.data.packing="{item}">
                {{ item.data.packing ? item.data.packing : '-' }}
            </template>

            <!-- netWeightPerPackage -->
            <template v-slot:item.data.netWeightPerPackage="{item}">
                {{ item.data.netWeightPerPackage ? item.data.netWeightPerPackage : '-' }}
            </template>

            <!-- numberOfPackages -->
            <template v-slot:item.data.numberOfPackages="{item}">
                {{ item.data.numberOfPackages ? item.data.numberOfPackages : '-' }}
            </template>

            <!-- shrinkColor -->
            <template v-slot:item.data.shrinkColor="{item}">
                {{ item.data.shrinkColor ? item.data.shrinkColor : '-' }}
            </template>

            <!-- packingDate -->
            <template v-slot:item.data.packingDate="{item}">
                {{ item.data.packingDate ? findMonthDate(item.data.packingDate) : '-' }}
            </template>

            <!-- expiryDate -->
            <template v-slot:item.data.expiryDate="{item}">
                {{ item.data.expiryDate ? findMonthDate(item.data.expiryDate) : '-' }}
            </template>
            
            <!-- actions -->
            <template v-slot:item.actions="{item}"> 
                <div class="d-flex justify-center">
                    <tooltip text="Edit item">
                        <v-btn
                            text
                            small
                            @click="isToEditEntry = true; fillToEditEntry(item.no)"
                            :disabled="submitLoading"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete item">
                        <v-btn @click="removeEntry(item.no)" :disabled="submitLoading" text small>
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>

            <!-- add button -->
            <template v-slot:footer.prepend v-if="!viewerOnly">
                <v-btn text @click="dialog = true; isToEditEntry = false;" :disabled="submitLoading">
                    <v-icon class="me-3">mdi-plus-circle-outline</v-icon>
                    {{$t('add') + ' ' + $t('ShippingAdvises.item')}}
                </v-btn>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>

        <v-row class="mb-3">
            <!-- Total of Weight -->
            <v-col md="2" sm="3" cols="12">
                <v-text-field
                    :value="$options.filters.currency(totalWeight)"
                    :label="$t('forms.total-of-weight')"
                    outlined
                    readonly
                    dense
                    hide-details="auto"
                    :loading="loading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- Total of packages -->
            <v-col md="2" sm="3" cols="12">
                <v-text-field
                    :value="$options.filters.currency(totalPackages)"
                    :label="$t('forms.total-of-packages')"
                    outlined
                    readonly
                    dense
                    hide-details="auto"
                    :loading="loading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- products order -->
            <v-col md="4" sm="6" cols="12">
                <v-menu
                    top
                    eager
                    v-model="productsOrderInContainerMenu"
                    :close-on-content-click="false"
                    :disabled="submitLoading"
                    open-on-click
                    transition="scale-transition"
                    origin="bottom left"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="productsOrderInContainer"
                            :label="$t('forms.products-order-in-container')"
                            outlined
                            readonly
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-card>
                        <v-card-text class="pb-0">
                            <img :src="`${BASE_API_URL}/${fileSettings.containerUrl}`" width="400" class="mb-1"/>
                            
                            <v-text-field
                                v-model="productsOrderInContainer"
                                :label="$t('forms.products-order-in-container')"
                                ref="productsOrderInContainerInput"
                                hide-details
                                outlined
                                @keypress.enter="productsOrderInContainerMenu = false"
                                :loading="loading || editLoading"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn text small color="primary" class="ms-3" @click="productsOrderInContainerMenu = false">
                                {{$t('close')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col>
        </v-row>

        <!-- actions -->
        <div class="d-flex justify-end" v-if="!viewerOnly">
            <v-btn dis :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
                {{$t('save')}}
            </v-btn>
            <v-btn dis @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
                {{$t('save-and-export-as-PDF')}}
            </v-btn>
            <v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
                <v-icon class="me-1">mdi-eye-outline</v-icon>
                {{$t('preview')}}
            </v-btn>
        </div>

        <!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`shipping-advise:${$route.params.companyId}`); draftSnackbar = false; date = moment().format('YYYY-MM-DD');"
        />
        
        <!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </v-form>
</template>

<script>
import Tooltip from '../../../components/Tooltip'
import rules from '../../../validation rules'
import fileTypes from '../../../enums/file-types'
import packingTypes from '../../../enums/packing-types'
import containerSizes from '../../../enums/container-sizes'
import { findMonthDate, findFullDate } from '../../../helpers'
import { BASE_API_URL } from '../../../constants'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    data: () => ({
        // helpers
        title: null,

        dialog: false,
        entryCounter: 0,

        draft: null,
        isDraftUsed: false,
        draftSnackbar: false,

        snackbar: null,
        message: null,

        packingTypes,
        
        loading: true,
        numLoading: false,
        editLoading: false,
        submitLoading: false,
        previewLoading: false,
        
        productsOrderInContainerMenu: false,

        containerSizes,

        rules,
        BASE_API_URL,

        /* dialog */
        // dialog helperes
        isToEditEntry: false,
        itemEditNo: null,

        // dialog inputs data
        quality: null,
        customerQuantity: null,
        netWeight: null,
        packing: null,
        netWeightPerPackage: null,
        numberOfPackages: null,
        shrinkColor: null,
        packingDate: null,
        expiryDate: null,
        
        // data
        num: null,
        contractNo: null,
        sequence: null,
        isManual: false,
        consignee: null,
        shippingCompany: null,
        shippingDate: null,
        loadingPort: null,
        date: null,
        BLNo: null,
        containerNo: null,
        destinationPort: null,
        containerSize: null,
        customStampNo: null,
        tableEntries: [],
        productsOrderInContainer: null
    }),

    watch: {
        // products order Menu 
        productsOrderInContainerMenu(val){
            val && setTimeout(() => this.$refs.productsOrderInContainerInput.focus(), 50);
        },

        // draft fields watcher
        draftFieldsWatcher() {
            if (!this.$route.query.id) {
                this.$localDrafts.save(`shipping-advise:${this.$route.params.companyId}`, {
                    contractNo: this.contractNo,
                    consignee: this.consignee,
                    shippingCompany: this.shippingCompany,
                    shippingDate: this.shippingDate,
                    loadingPort: this.loadingPort,
                    date: this.date,
                    BLNo: this.BLNo,
                    containerNo: this.containerNo,
                    destinationPort: this.destinationPort,
                    containerSize: this.containerSize,
                    customStampNo: this.customStampNo,
                    tableEntries: this.tableEntries,
                    productsOrderInContainer: this.productsOrderInContainer
                })
            }
        }
    },

    methods: {
        editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },

        // entry dialog functions
        resetInputEntries(){
            this.quality = null;
            this.customerQuantity = null;
            this.netWeight = null;
            this.packing = null;
            this.netWeightPerPackage = null;
            this.numberOfPackages = null;
            this.shrinkColor = null;
            this.packingDate = null;
            this.expiryDate = null;
        },

        resetDialog(){
            this.resetInputEntries();
            this.isToEditEntry = false;
        },

        saveEntry(){
            this.tableEntries.push({
                no: ++this.entryCounter,
                data: {
                    quality: this.quality,
                    customerQuantity: this.customerQuantity,
                    netWeight: this.netWeight,
                    packing: this.packing,
                    netWeightPerPackage: this.netWeightPerPackage,
                    numberOfPackages: this.numberOfPackages,
                    shrinkColor: this.shrinkColor,
                    packingDate: this.packingDate ? this.findFullDate(this.packingDate) : null,
                    expiryDate: this.expiryDate ? this.findFullDate(this.expiryDate) : null,
                }
            });

            this.resetInputEntries();
            this.$refs.qualityInput.focus();
        },

        fillToEditEntry(itemNo){
            this.itemEditNo = itemNo;
            const entry = this.tableEntries.find(c => c.no === itemNo);
            this.quality = entry.data.quality;
            this.customerQuantity = entry.data.customerQuantity;
            this.netWeight = entry.data.netWeight;
            this.packing = entry.data.packing;
            this.netWeightPerPackage = entry.data.netWeightPerPackage;
            this.numberOfPackages = entry.data.numberOfPackages;
            this.shrinkColor = entry.data.shrinkColor;
            this.packingDate = entry.data.packingDate ? this.findMonthDate(entry.data.packingDate) : null;
            this.expiryDate = entry.data.expiryDate ? this.findMonthDate(entry.data.expiryDate) : null;
            
            this.dialog = true;
        },

        editEntry(){
            const entry = this.tableEntries.find(c => c.no === this.itemEditNo);
            entry.data.quality = this.quality;
            entry.data.customerQuantity = this.customerQuantity;
            entry.data.netWeight = this.netWeight;
            entry.data.packing = this.packing;
            entry.data.netWeightPerPackage = this.netWeightPerPackage;
            entry.data.numberOfPackages = this.numberOfPackages;
            entry.data.shrinkColor = this.shrinkColor;
            entry.data.packingDate = this.findFullDate(this.packingDate);
            entry.data.expiryDate = this.findFullDate(this.expiryDate);
            
            this.dialog = false;
            this.resetInputEntries();
            this.isToEditEntry = false;
        },

        removeEntry(itemNo){
            const index = itemNo - 1;
            this.tableEntries.splice(index, 1);
            this.tableEntries.forEach(entry => {
                if (entry.no - 1 > index) entry.no--;
            });
            this.entryCounter--;
        },

        // submit
        submit(exportPdf){
            if (this.$refs.form.validate()) {
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
                this.$store.dispatch(`shippingAdvises/${actionName}`, {
                    id: this.$route.query.id,
                    isManualSequence: this.isManual,
                    manualSequence: this.num,
                    sequence: this.sequence,
                    items: this.tableEntries.map(c => c.data),
                    date: this.date,
                    contractNo: this.contractNo,
                    consignee: this.consignee,
                    shippingCompany: this.shippingCompany,
                    shippingDate: this.shippingDate,
                    loadingPort: this.loadingPort,
                    destinationPort: this.destinationPort,
                    billOfLadingNo: this.BLNo,
                    containerNo: this.containerNo,
                    containerSize: this.containerSize,
                    customStampNo: this.customStampNo,
                    productsOrderInContainer: this.productsOrderInContainer
                })
                .then((id) => {
                    const fileId = actionName === 'create' ? id.data : this.$route.query.id;
                    this.message = this.$t('messages.shipping-advice-has-been-successfully-saved');
                    if (exportPdf) {
                        this.report(fileId);
                    }
                    if (this.isDraftUsed){
                        this.$localDrafts.remove(`shipping-advise:${this.$route.params.companyId}`);
                    }
                    this.$router.push({ name: 'shipping-advises' })
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                })
                .finally(() => {
                    this.submitLoading = false;
                    this.snackbar = true;
                })
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
        },

        preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/shippingAdvises', {
                    companyId: this.$route.params.companyId,
                    isManualSequence: this.isManual,
                    manualSequence: this.num,
                    sequence: this.sequence,
                    items: this.tableEntries.map(c => c.data),
                    date: this.date,
                    contractNo: this.contractNo,
                    consignee: this.consignee,
                    shippingCompany: this.shippingCompany,
                    shippingDate: this.shippingDate,
                    loadingPort: this.loadingPort,
                    destinationPort: this.destinationPort,
                    billOfLadingNo: this.BLNo,
                    containerNo: this.containerNo,
                    containerSize: this.containerSize,
                    customStampNo: this.customStampNo,
                    productsOrderInContainer: this.productsOrderInContainer
                })
                .then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
            }
        },

        // edit file
        fillAllToEdit(data){
            this.num = data.sequence;
            this.contractNo = data.contractNo;
            this.consignee = data.consignee;
            this.shippingCompany = data.shippingCompany;
            this.shippingDate = data.shippingDate ? window.moment(data.shippingDate).format('YYYY-MM-DD') : null;
            this.loadingPort = data.loadingPort;
            this.date = data.date ? window.moment(data.date).format('YYYY-MM-DD') : null;
            this.BLNo = data.billOfLadingNo;
            this.containerNo = data.containerNo;
            this.destinationPort = data.destinationPort;
            this.containerSize = data.containerSize;
            this.customStampNo = data.customStampNo;
            this.productsOrderInContainer = data.productsOrderInContainer;
            data.items.forEach(item => {
                this.tableEntries.push({
                    no: ++this.entryCounter,
                    data:{
                        quality: item.quality,
                        customerQuantity: item.customerQuantity,
                        netWeight: item.netWeight,
                        packing: item.packing,
                        netWeightPerPackage: item.netWeightPerPackage,
                        numberOfPackages: item.numberOfPackages,
                        shrinkColor: item.shrinkColor,
                        packingDate: item.packingDate ? window.moment(item.packingDate).format('YYYY-MM-DD') : null,
                        expiryDate: item.expiryDate ? window.moment(item.expiryDate).format('YYYY-MM-DD') : null,
                    }
                });
            })
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/ShippingAdvices/${id}`);
        },

        // fill data from draft
        fillDataFromDraft() {
            this.contractNo = this.draft.contractNo;
            this.consignee = this.draft.consignee;
            this.shippingCompany = this.draft.shippingCompany;
            this.shippingDate = this.draft.shippingDate;
            this.loadingPort = this.draft.loadingPort;
            this.date = this.draft.date;
            this.BLNo = this.draft.BLNo;
            this.containerNo = this.draft.containerNo;
            this.destinationPort = this.draft.destinationPort;
            this.containerSize = this.draft.containerSize;
            this.customStampNo = this.draft.customStampNo;
            this.productsOrderInContainer = this.draft.productsOrderInContainer;
            this.tableEntries = this.draft.tableEntries;
            this.entryCounter = this.draft.tableEntries.length;
        },

        // helpers
        findMonthDate,
        findFullDate,
        moment
    },

    computed:{
        ...mapState({
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings,
        }),

        dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

        totalWeight(){
            return this.tableEntries.map(c => c.data.netWeight).reduce((a, b) => a + b, 0);
        },

        totalPackages(){
            return this.tableEntries.map(c => c.data.numberOfPackages).reduce((a, b) => a + b, 0);
        },

        headers() {
            const headers = [
                { text: this.$t('headers.no'), value:'no', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.quality'), value:'data.quality', align:'center' },
                { text: this.$t('headers.customer-quality'), value:'data.customerQuantity', align:'center' },
                { text: this.$t('headers.net-weight'), value:'data.netWeight', align:'center' },
                { text: this.$t('headers.packing'), value:'data.packing', align:'center' },
                { text: this.$t('headers.net-weight-per-package'), value:'data.netWeightPerPackage', align:'center' },
                { text: this.$t('headers.number-of-packages'), value:'data.numberOfPackages', align:'center' },
                { text: this.$t('headers.shrink-color'), value:'data.shrinkColor', align:'center' },
                { text: this.$t('headers.packing-date'), value:'data.packingDate', align:'center', cellClass: 'fixed-width-date' },
                { text: this.$t('headers.expiry-date'), value:'data.expiryDate', align:'center', cellClass: 'fixed-width-date' },
                { text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
        },

        draftFieldsWatcher() {
            return (`${this.isToEditEntry} | ${this.contractNo} | ${this.consignee} | ${this.shippingCompany} | ${this.shippingDate} | ${this.loadingPort} | ${this.BLNo} | ${this.containerNo} | ${this.destinationPort} | ${this.containerSize} | ${this.customStampNo} | ${this.tableEntries} | ${this.productsOrderInContainer}`)
        },
    },

    components:{
        Tooltip
    },

    mounted(){
        if (this.$route.query.id){
            if (this.$route.query.viewer) {
                this.title = this.$t('title.preview') + this.$t('ShippingAdvises.shipping-advise');
            }
            else if (this.$route.query.duplicate) {
                this.title = this.$t('title.duplicate') + this.$t('ShippingAdvises.shipping-advise');
            }
            else {
                this.title = this.$t('title.edit') + this.$t('ShippingAdvises.shipping-advise');
            }

            this.$store.dispatch('shippingAdvises/fetchById', { id: this.$route.query.id })
            .then((data) => {
                this.fillAllToEdit(data);
            })
            .finally(() => {
                this.loading = false;
            });
        }
        else {
            this.title = this.$t('title.add') + this.$t('ShippingAdvises.shipping-advise');

            this.loading = false;
            this.numLoading = true;
            this.$store.dispatch('sequences/fetch', { fileType: fileTypes.shippingAdvise })
            .then(response => {
                this.num = response.value;
                this.sequence = response;
            })
            .finally(() => {
                this.numLoading = false;
            });

            // get draft and show snackbar
            this.draft = this.$localDrafts.get(`shipping-advise:${this.$route.params.companyId}`);
            if (this.draft){
                setTimeout(() => {
                    this.draftSnackbar = true;
                }, 500)
            }
            else {
                this.date = this.moment().format('YYYY-MM-DD');
            }
        }
    }
}
</script>

<style>

</style>