var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h1',{staticClass:"text-h5 primary--text"},[_vm._v("Despacho a Puerto")]),_c('delete-dialog',{ref:"DeleteDialog",on:{"on-delete":_vm.filter}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" Estás seguro que quieres "),_c('span',{staticClass:"error--text"},[_vm._v("ELIMINAR")]),_vm._v(" Despacho a Puerto? ")])]),(!_vm.viewerOnly)?_c('v-btn',{staticClass:"lightPrimary white--text",attrs:{"text":"","small":"","to":{
                name:'port-dispatch',
                params:{companyId: _vm.$route.params.companyId},
              }}},[_vm._v(" Agregar despacho a puerto ")]):_vm._e()],1),_c('v-form',{staticClass:"my-3",on:{"submit":function($event){$event.preventDefault();return _vm.filter()}}},[_c('v-row',{staticClass:"align-center ma-0"},[_c('v-col',{staticClass:"text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_vm._v(" Filter ")]),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.filterLoading,"label":"Número","filled":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.num),callback:function ($$v) {_vm.num=$$v},expression:"num"}})],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-menu',{ref:"menuOfDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.date ? _vm.moment(_vm.date) : null,"label":"Fecha","prepend-icon":"mdi-calendar","dense":"","filled":"","outlined":"","hide-details":"","readonly":"","disabled":_vm.loading}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":function($event){return _vm.$refs.menuOfDate.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"success","width":"80","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"type":"submit"},on:{"click":function($event){_vm.isFiltered = true}}},[_vm._v(" Search ")]),(_vm.isFiltered)?_c('v-btn',{attrs:{"icon":"","small":"","disabled":!_vm.isFiltered || _vm.loading},on:{"click":function($event){return _vm.fetchPortDispatches();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.portDispatches,"items-per-page":20,"footer-props":{ 'items-per-page-options': [10, 20, 40, 100, -1], showCurrentPage:true},"loading":_vm.loading,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.contractNo",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.contractNo ? item.contractNo : 'N/A')+" ")]}},{key:"item.packingOrderNo",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.packingOrderNo ? item.packingOrderNo : 'N/A')+" ")]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.date ? _vm.moment(item.date) : 'N/A')+" ")]}},{key:"item.shipmentDate",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipmentDate ? _vm.moment(item.shipmentDate) : 'N/A')+" ")]}},{key:"item.containerNo",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.containerNo ? item.containerNo : 'N/A')+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('tooltip',{attrs:{"text":"Descargar como pdf"}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.report(item.id)}}},[_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1)],1),(_vm.viewerOnly)?[_c('tooltip',{attrs:{"text":"Avance"}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                    name:'port-dispatch',
                                    params:{companyId: _vm.$route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)]:[_c('tooltip',{attrs:{"text":"Duplicar"}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                    name:'port-dispatch',
                                    params:{companyId: _vm.$route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }}},[_c('v-icon',[_vm._v(" mdi-content-duplicate ")])],1)],1),_c('tooltip',{attrs:{"text":"Editar"}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                    name:'port-dispatch',
                                    params:{companyId: _vm.$route.params.companyId},
                                    query:{id:item.id}
                                }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1),_c('tooltip',{attrs:{"text":"Eliminar"}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.DeleteDialog.activeDialog(item.id, 'portDispatches')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]],2)]}},{key:"footer.page-text",fn:function(ref){
                                var pageStart = ref.pageStart;
                                var pageStop = ref.pageStop;
                                var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" of "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }