<template>
    <div>
        <div class="d-flex justify-space-between align-center mb-5">
            <h1 class="text-h5 primary--text">{{$t('PackagingUnits.packaging-units')}}</h1>
            
            <!-- unit-dialog is decleared here to use it as ref -->
            <unit-dialog ref="UnitDialog" @on-save="fetchUnits"></unit-dialog>
            
            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="fetchUnits">
                <span class="text-body-1">
                    {{$t('are-you-sure-you-want-to')}} 
                    <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                    {{$t('PackagingUnits.the-unit')}}
                </span>
            </delete-dialog>

            <v-spacer/>
            
            <v-btn text small class="lightPrimary white--text" @click="addUnit">
                {{$t('PackagingUnits.add-a-unit')}}
            </v-btn>
            <v-btn :to="{ name:'materials' }" elevation="1" small class="ms-4 px-6">
                {{$t('back')}}
            </v-btn>
        </div>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : units"
          :items-per-page="5"
          :loading="loading"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          class="elevation-2"
        >
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('tooltips.edit')">
                        <v-btn text small @click="editUnit(item.id)">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip :text="$t('tooltips.delete')">
                        <v-btn text small @click="$refs.DeleteDialog.activeDialog(item.id, 'units')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteDialog from '../../../components/DeleteDialog'
import UnitDialog from '../../../components/UnitDialog'
import Tooltip from '../../../components/Tooltip'
import { mapState } from 'vuex';
export default {
    data: () => ({
        loading: true,
    }),

    components:{
        DeleteDialog,
        UnitDialog,
        Tooltip
    },

    methods:{
        // dialog funcitons
        addUnit(){
            this.$refs.UnitDialog.addUnit();
        },

        editUnit(unitId){
            this.$refs.UnitDialog.editUnit(unitId);
        },

        fetchUnits(){
            this.loading = true;
            this.$store.dispatch('units/fetchAll')
            .finally(() => {
                this.loading = false;
            })
        }
    },

    computed:{
        ...mapState({
            units: state => state.units.units,
        }),

        headers() {
            return [
                { text: this.$t('headers.name'), value: 'name', align:'start', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.actions'), value: 'actions', align:'center', sortable:false },
            ]
        }
    },

    mounted(){
        this.fetchUnits();
    },
}
</script>

<style lang="scss">

</style>