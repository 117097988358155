import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        companies: [],
        company: {
            bankAccounts: [],
            fileTypes: []
        }
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/companies')
            .then((response) => {
                commit(types.STORE_COMPANIES, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            // this endpoint to fetch more date for company like bank accounts
            return window.axios.get(`/companies/${id}`)
            .then((response) => {
                commit(types.STORE_COMPANY, response.data);
                return response.data;
            })
        },

        create({ commit }, { name, taxNumber, managerName, financialManagerName, currencies, phoneNumbers, addresses, fileTypes }){
            return window.axios.post('/companies', {
                name,
                taxNumber,
                managerName,
                financialManagerName,
                currencies,
                phoneNumbers,
                addresses,
                fileTypes
            })
        },

        update({ commit }, { id, name, taxNumber, managerName, financialManagerName, currencies, phoneNumbers, addresses, fileTypes }){
            return window.axios.put(`/companies/${id}`, {
                name,
                taxNumber,
                managerName,
                financialManagerName,
                currencies,
                phoneNumbers,
                addresses,
                fileTypes
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/companies/${id}`);
        },

        uploadLogo({ commit }, { id, file }){
            var formData = new FormData();
            formData.append('file', file);
            return window.axios.post(`/companies/${id}/logo`, formData)
        },

        uploadSignature({ commit }, { id, file }){
            var formData = new FormData();
            formData.append('file', file);
            return window.axios.post(`/companies/${id}/signature`, formData)
        },
    },

    mutations:{
        [types.STORE_COMPANIES](state, companies){
            state.companies = companies;
        },

        [types.STORE_COMPANY](state, company){
            state.company = company;
        }
    },

    getters:{
        getCompanyById: state => id => state.companies.find(c => c.id === id),
    }
}