<template>
<v-form @submit.prevent="submit(false)" ref="form" :disabled="viewerOnly">
    <!-- entry dialog -->
    <v-dialog
        v-model="dialog"
        width="800"
        ref="dialog"
    >
        <v-form @submit.prevent="!isToEditEntry ? saveEntry() : editEntry()" >
            <v-card>
                <!-- dialog title -->
                <v-card-title class="primary white--text justify-center">
                    <v-spacer/>
                    <span class="text-h6">{{ !isToEditEntry ? 'Add' : 'Edit' }} item</span>
                    <v-spacer/>
                    <v-btn text small color="white" fab @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <!-- dialog content -->
                <v-card-text>
                    <v-container>
                        <v-row>
                            <!-- Calidad -->
                            <v-col md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="quality"
                                    label="Calidad"
                                    ref="qualityInput"
                                    hide-details
                                ></v-text-field>
                            </v-col>

                            <!-- Calidad del cliente -->
                            <v-col md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="customerQuality"
                                    label="Calidad del cliente"
                                    hide-details
                                ></v-text-field>
                            </v-col>

                            <!-- No. de bultos -->
                            <v-col md="4" sm="6" cols="12">
                                <v-currency-text-field
                                    v-model.number="packingNumbers"
                                    label="No. de bultos"
                                    type="number"
                                    min="0"
                                    hide-details
                                ></v-currency-text-field>
                            </v-col>

                            <!-- Tepo de embaque -->
                            <v-col md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="packingType"
                                    label="Tepo de embaque"
                                    hide-details
                                ></v-text-field>
                            </v-col>

                            <!-- Marca del empaque -->
                            <v-col md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="packingUnit"
                                    label="Marca del empaque"
                                    hide-details
                                ></v-text-field>
                            </v-col>

                            <!-- Peso neto KGs -->
                            <v-col md="4" sm="6" cols="12">
                                <v-currency-text-field
                                    v-model.number="unitWeight"
                                    label="Peso neto KGs"
                                    type="number"
                                    min="0"
                                    hide-details
                                ></v-currency-text-field>
                            </v-col>

                            <!-- Peso bruto KGs -->
                            <v-col md="4" sm="6" cols="12">
                                <v-currency-text-field
                                    v-model.number="grossWeight"
                                    label="Peso bruto KGs"
                                    type="number"
                                    min="0"
                                    hide-details
                                ></v-currency-text-field>
                            </v-col>

                            <!-- Color fleje -->
                            <v-col md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="strapColor"
                                    label="Color fleje"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary lighten-2"
                        class="px-6"
                        text
                        type="submit"
                    >
                        {{ !isToEditEntry ? 'Add' : 'Edit' }}
                    </v-btn>
                    <v-btn
                        class="px-6"
                        text
                        @click="isToEditEntry = false; dialog = false"
                    >
                        {{$t('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
    <div class="d-flex justify-space-between align-center mb-1">
        <!-- title and number -->
        <v-row class="align-center ma-0 mb-1">
            <!-- title -->
            <v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
                <h1 class="text-h5 primary--text">{{title}}</h1>

                <!-- back button -->
                <v-btn
                    v-if="$vuetify.breakpoint.smAndDown"
                    :to="{name: 'product-release-orders'}"
                    :disabled="submitLoading"
                    elevation="1"
                    small
                    class="px-6"
                >
                    {{$t('back')}}
                </v-btn>
            </v-col>

            <!-- num -->
            <v-col lg="2" md="3" sm="6" cols="12" class="py-0">
                <v-text-field
                    v-model="num"
                    :loading="loading || editLoading || numLoading"
                    :append-outer-icon="isAdmin ? 'mdi-pencil' : null"
                    @click:append-outer="editSequence"
                    :readonly="!isManual"
                    dense
                    hide-details="auto"
                    :class="{ 'v-input--is-disabled': !isManual }"
                    :disabled="submitLoading || numLoading"
                ></v-text-field>
            </v-col>

            <!-- Fecha -->
            <v-col md="3" sm="6" cols="12">
                <bee-date-input
                    v-model="date"
                    changeable-with-arrows
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    :input-format-order="dateFormat"
                    :user-menu-picker="true"
                    dense
                    hide-details="auto"
                    :loading="editLoading"
                    :disabled="submitLoading || editLoading"
                    :rules="rules.required"
                ></bee-date-input>
            </v-col>
        </v-row>

        <!-- back button -->
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            :to="{name: 'product-release-orders'}"
            :disabled="submitLoading"
            elevation="1"
            small
            class="px-6"
        >
            {{$t('back')}}
        </v-btn>
    </div>

    <!-- FIRST SECTION -->
    <h2 class="text-body-1 primary--text mb-3">Primera sección</h2>

    <v-row class="mb-3">
        <!-- No. de contrato -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="referenceContract"
                label="No. de contrato"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>
        
        <!-- No. Orden de empaque -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="packingOrderNo"
                label="No. Orden de empaque"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Salida de bodega No. -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="warehouseNo"
                label="Salida de bodega No."
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Empresa portuaria -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="portCompany"
                label="Empresa portuaria"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>
    </v-row>

    <!-- SECOND SECTION -->
    <h2 class="text-body-1 primary--text mb-3">segunda sección</h2>

    <v-row class="mb-3">
        <!-- Regimen arancelario -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="tariffSystemId"
                label="Regimen arancelario"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Para embarcar en naviera -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="contract"
                label="Para embarcar en naviera"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Destino -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="destination"
                label="Destino"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Booking -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="booking"
                label="Booking"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Nombre del buque -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="shipName"
                label="Nombre del buque"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- No. Contenedor -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="containerNo"
                label="No. Contenedor"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Fecha de buque -->
        <v-col sm="4" cols="12">
            <bee-date-input
                v-model="shippingDate"
                changeable-with-arrows
                label="Fecha de buque"
                prepend-icon="mdi-calendar"
                :menu-picker-years-over-max="10"
                :input-format-order="dateFormat"
                :user-menu-picker="true"
                dense
                hide-details="auto"
                :loading="editLoading"
                :disabled="submitLoading || editLoading"
            ></bee-date-input>
        </v-col>

        <!-- Viaje -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="voyage"
                label="Viaje"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- No. Marchamo -->
        <v-col sm="4" cols="12">
            <v-text-field
                v-model="stampNo"
                label="No. Marchamo"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>
    </v-row>

    <!-- THIRD SECTION -->
    <h2 class="text-body-1 primary--text mb-3">Tercera sección</h2>

    <v-row class="mb-3">
        <!-- Nombre piloto -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="pilotName"
                label="Nombre piloto"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Camion placas -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="truckPlate"
                label="Camion placas"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Licencia no. -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="licenseNo"
                label="Licencia no."
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- No. Marchamo -->
        <v-col md="3" sm="6" cols="12">
            <v-text-field
                v-model="platformPlate"
                label="Plataforma placas"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>
    </v-row>

    <!-- GOODS DESCRIPTION -->
    <h2 class="text-body-1 primary--text mb-3">Conteniendo</h2>

    <!-- Conteniendo -->
    <v-row class="mb-2">
        <v-col md="6" sm="8" cols="12">
            <v-text-field
                v-model="goodsDescription"
                label="Conteniendo"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>
    </v-row>

    <!-- table -->
    <v-data-table
        :items="loading ? [] : tableEntries"
        disable-pagination
        disable-sort
        :loading="editLoading"
        no-data-text="No items were added."
        :headers="headers"
        dense
        class="elevation-1 mb-3"
    >
        <!-- quality-->
        <template v-slot:item.data.quality="{item}">
            {{ item.data.quality ? item.data.quality : '-' }}
        </template>
        
        <!-- customerQuality-->
        <template v-slot:item.data.customerQuality="{item}">
            {{ item.data.customerQuality ? item.data.customerQuality : '-' }}
        </template>
        
        <!-- packingNumbers-->
        <template v-slot:item.data.packingNumbers="{item}">
            {{ item.data.packingNumbers ? item.data.packingNumbers : '-' | currency }}
        </template>
        
        <!-- packingType-->
        <template v-slot:item.data.packingType="{item}">
            {{ item.data.packingType ? item.data.packingType : '-' }}
        </template>
        
        <!-- packingUnit-->
        <template v-slot:item.data.packingUnit="{item}">
            {{ item.data.packingUnit ? item.data.packingUnit : '-' }}
        </template>
        
        <!-- unitWeight-->
        <template v-slot:item.data.unitWeight="{item}">
            {{ item.data.unitWeight ? item.data.unitWeight : '-' | currency }}
        </template>
        
        <!-- grossWeight-->
        <template v-slot:item.data.grossWeight="{item}">
            {{ item.data.grossWeight ? item.data.grossWeight : '-' | currency }}
        </template>
        
        <!-- strapColor-->
        <template v-slot:item.data.strapColor="{item}">
            {{ item.data.strapColor ? item.data.strapColor : '-' }}
        </template>
        
        <!-- actions -->
        <template v-slot:item.actions="{item}">
            <div class="d-flex justify-center">
                <tooltip text="Edit item">
                    <v-btn
                        text
                        small
                        :disabled="submitLoading"
                        @click="isToEditEntry = true; fillToEditEntry(item.no)"
                    >
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </tooltip>
                
                <tooltip text="Delete item">
                    <v-btn @click="removeEntry(item.no)" text small :disabled="submitLoading">
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </tooltip>
            </div>
        </template>

        <!-- add button -->
        <template v-slot:footer.prepend v-if="!viewerOnly">
            <v-btn text @click="dialog = true; isToEditEntry = false;" :disabled="submitLoading">
                <v-icon class="me-3">mdi-plus-circle-outline</v-icon> Add item
            </v-btn>
        </template>
    </v-data-table>

    <!-- totals -->
    <v-row class="mb-3">
        <!-- Total No. de bultos -->
        <v-col md="2" sm="3" cols="12">
            <v-text-field
                :value="$options.filters.currency(totalPackingNumbers)"
                label="Total No. de bultos"
                outlined
                readonly
                dense
                hide-details="auto"
                :loading="loading || editLoading"
            ></v-text-field>
        </v-col>

        <!-- Total peso neto -->
        <v-col md="2" sm="3" cols="12">
            <v-text-field
                :value="$options.filters.currency(totalUnitWeight)"
                label="Total peso neto"
                outlined
                readonly
                dense
                hide-details="auto"
                :loading="loading || editLoading"
            ></v-text-field>
        </v-col>

        <!-- Total peso burto -->
        <v-col md="2" sm="3" cols="12">
            <v-text-field
                :value="$options.filters.currency(totalGrossWeight)"
                label="Total peso burto"
                outlined
                readonly
                dense
                hide-details="auto"
                :loading="loading || editLoading"
            ></v-text-field>
        </v-col>
    </v-row>

    <!-- LAST SECTION -->
    <h2 class="text-body-1 primary--text mb-3">Ultima sección</h2>
    <v-row class="mb-1">
        <!-- Marca de caja -->
        <v-col sm='4' cols="12">
            <v-text-field
                v-model="boxMark"
                label="Marca de caja"
                dense
                hide-details="auto"
                :loading="loading || editLoading"
                :disabled="submitLoading"
            ></v-text-field>
        </v-col>

        <!-- Packing date -->
        <v-col sm="4" cols="12">
            <bee-date-input
                v-model="packingDate"
                changeable-with-arrows
                label="Packing date"
                prepend-icon="mdi-calendar"
                :menu-picker-years-over-max="10"
                :input-format-order="dateFormat"
                :user-menu-picker="true"
                dense
                hide-details="auto"
                :loading="editLoading"
                :disabled="submitLoading || editLoading"
                input-type="month"
            ></bee-date-input>
        </v-col>

        <!-- Expiry date -->
        <v-col sm="4" cols="12">
            <bee-date-input
                v-model="expiryDate"
                changeable-with-arrows
                label="Expiry date"
                prepend-icon="mdi-calendar"
                :menu-picker-years-over-max="10"
                :input-format-order="dateFormat"
                :user-menu-picker="true"
                dense
                hide-details="auto"
                :loading="editLoading"
                :disabled="submitLoading || editLoading"
                input-type="month"
            ></bee-date-input>
        </v-col>
    </v-row>

    <!-- Observaciones -->
    <v-textarea
        v-model="observers"
        label="Observaciones"
        :disabled="submitLoading"
        rows="3"
        auto-grow
        hide-details
        outlined
        class="mb-3"
    ></v-textarea>

    <!-- by -->
    <div class="mb-5">
        <v-row class="justify-space-between">
            <!-- Despachadoe por -->
            <v-col sm='4' cols="6">
                <v-textarea
                    v-model="dispatchedBy"
                    label="Despachadoe por"
                    rows="2"
                    :disabled="submitLoading"
                    auto-grow
                    dense
                    hide-details="auto"
                    outlined
                    :loading="loading || editLoading"
                ></v-textarea>
            </v-col>

            <!-- Transportador por -->
            <v-col sm='4' cols="6">
                <v-textarea
                    v-model="transportedBy"
                    label="Transportador por"
                    rows="2"
                    :disabled="submitLoading"
                    auto-grow
                    dense
                    hide-details="auto"
                    outlined
                    :loading="loading || editLoading"
                ></v-textarea>
            </v-col>

            <!-- Recibido por -->
            <v-col sm="4" cols="12">
                <v-textarea
                    v-model="receivedBy"
                    label="Recibido por"
                    rows="2"
                    :disabled="submitLoading"
                    auto-grow
                    dense
                    hide-details="auto"
                    outlined
                    :loading="loading || editLoading"
                ></v-textarea>
            </v-col>
        </v-row>
    </div>

    <!-- actions -->
    <div class="d-flex justify-end" v-if="!viewerOnly">
        <v-btn dis :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
            Save
        </v-btn>
        <v-btn dis @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
            Save & Export as PDF
        </v-btn>
        <v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
            <v-icon class="me-1">mdi-eye-outline</v-icon>
            {{$t('preview')}}
        </v-btn>
    </div>

    <!-- draft snackbar -->
    <v-draft-snackbar
        v-model="draftSnackbar"
        @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
        @reject="$localDrafts.remove(`product-release-order:${$route.params.companyId}`); draftSnackbar = false; date = moment().format('YYYY-MM-DD');"
    />
        
    <!-- messages snackbar -->
    <v-snackbar
        v-model="snackbar"
        color="primary lighten-1"
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</v-form>
</template>

<script>
import Tooltip from '../../../components/Tooltip'
import fileTypes from '../../../enums/file-types'
import rules from '../../../validation rules'
import { findMonthDate, findFullDate } from '../../../helpers'
import { BASE_API_URL } from '../../../constants'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    data: () => ({
        // helpers
        title: null,

        dialog: false,
        entryCounter: 0,

        draft: null,
        isDraftUsed: false,
        draftSnackbar: false,

        snackbar: null,
        message: null,
        
        loading: true,
        numLoading: false,
        editLoading: false,
        submitLoading: false,
        previewLoading: false,

        rules,

        /* dialog */
        // dialog helperes
        isToEditEntry: false,
        itemEditNo: null,

        // dialog inputs data
        quality: null,
        customerQuality: null,
        packingNumbers: null,
        packingType: null,
        packingUnit: null,
        unitWeight: null,
        grossWeight: null,
        strapColor: null,

        /* data */
        num: null,
        sequence: null,
        isManual: false,

        // first section
        referenceContract: null,
        packingOrderNo: null,
        warehouseNo: null,
        date:null,
        portCompany: null,
        
        // second section
        tariffSystemId: null,
        destination: null,
        contract: null,
        booking: null,
        shipName: null,
        containerNo: null,
        shippingDate: null,
        voyage: null,
        stampNo: null,
        
        // third section
        pilotName: null,
        truckPlate: null,
        licenseNo: null,
        platformPlate: null,
        
        // goods description
        goodsDescription: null,

        // last section
        boxMark: null,
        packingDate: null,
        expiryDate: null,

        tableEntries: [],
        
        // by
        observers: null,
        dispatchedBy: null,
        transportedBy: null,
        receivedBy: null,
    }),

    watch: {
        draftFieldsWatcher() {
            if (!this.$route.query.id) {
                this.$localDrafts.save(`product-release-order:${this.$route.params.companyId}`, {
                    referenceContract: this.referenceContract,
                    packingOrderNo: this.packingOrderNo,
                    warehouseNo: this.warehouseNo,
                    date: this.date,
                    portCompany: this.portCompany,
                    tariffSystemId: this.tariffSystemId,
                    destination: this.destination,
                    contract: this.contract,
                    booking: this.booking,
                    shipName: this.shipName,
                    containerNo: this.containerNo,
                    shippingDate: this.shippingDate,
                    voyage: this.voyage,
                    stampNo: this.stampNo,
                    pilotName: this.pilotName,
                    truckPlate: this.truckPlate,
                    licenseNo: this.licenseNo,
                    platformPlate: this.platformPlate,
                    goodsDescription: this.goodsDescription,
                    boxMark: this.boxMark,
                    packingDate: this.packingDate,
                    expiryDate: this.expiryDate,
                    tableEntries: this.tableEntries,
                    observers: this.observers,
                    dispatchedBy: this.dispatchedBy,
                    transportedBy: this.transportedBy,
                    receivedBy: this.receivedBy
                })
            }
        }
    },

    methods: {
        editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },

        // entry dialog functions
        resetInputEntries(){
            this.quality = null;
            this.customerQuality = null;
            this.packingNumbers = null;
            this.packingType = null;
            this.packingUnit = null;
            this.unitWeight = null;
            this.grossWeight = null;
            this.strapColor = null;
        },

        saveEntry(){
            this.tableEntries.push({
                no: ++this.entryCounter,
                data:{
                    quality: this.quality,
                    customerQuality: this.customerQuality,
                    packingNumbers: this.packingNumbers,
                    packingType: this.packingType,
                    packingUnit: this.packingUnit,
                    unitWeight: this.unitWeight,
                    grossWeight: this.grossWeight,
                    strapColor: this.strapColor,
                }
            });

            this.resetInputEntries();
            this.$refs.qualityInput.focus();
        },

        fillToEditEntry(itemNo){
            this.itemEditNo = itemNo;
            const entry = this.tableEntries.find(c => c.no === itemNo);
            this.quality = entry.data.quality;
            this.customerQuality = entry.data.customerQuality;
            this.packingNumbers = entry.data.packingNumbers;
            this.packingType = entry.data.packingType;
            this.packingUnit = entry.data.packingUnit;
            this.unitWeight = entry.data.unitWeight;
            this.grossWeight = entry.data.grossWeight;
            this.strapColor = entry.data.strapColor;
            
            this.dialog = true;
        },

        editEntry(){
            const entry = this.tableEntries.find(c => c.no === this.itemEditNo);
            entry.data.quality = this.quality;
            entry.data.customerQuality = this.customerQuality;
            entry.data.packingNumbers = this.packingNumbers;
            entry.data.packingType = this.packingType;
            entry.data.packingUnit = this.packingUnit;
            entry.data.unitWeight = this.unitWeight;
            entry.data.grossWeight = this.grossWeight;
            entry.data.strapColor = this.strapColor;
            
            this.dialog = false;
            this.resetInputEntries();
            this.isToEditEntry = false;
        },

        removeEntry(itemNo){
            const index = itemNo - 1;
            this.tableEntries.splice(index, 1);
            this.tableEntries.forEach(entry => {
                if (entry.no - 1 > index) entry.no--;
            });
            this.entryCounter--;
        },
        // submit
        submit(exportPdf){
            if (this.$refs.form.validate()) {
                this.submitLoading = true;
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
                this.$store.dispatch('sequences/reserve', {
                    sequence: this.isManual ? this.num : this.sequence,
                    fileType: fileTypes.productReleaseOrder,
                    isManual: this.isManual,
                    overrideCollision: true
                })
                .then(() => {
                    this.$store.dispatch(`productReleaseOrders/${actionName}`, {
                        id: this.$route.query.id,
                        isManualSequence: this.isManual,
                        manualSequence: this.num,
                        sequence: this.sequence,
                        items: this.tableEntries.map(c => c.data),
                        date: this.date,
                        referenceContract: this.referenceContract,
                        warehouseNo: this.warehouseNo,
                        packingOrderNo: this.packingOrderNo,
                        portCompany: this.portCompany,
                        tariffSystemId: this.tariffSystemId,
                        destination: this.destination,
                        contract: this.contract,
                        booking: this.booking,
                        shipName: this.shipName,
                        containerNo: this.containerNo,
                        shippingDate: this.shippingDate,
                        voyage: this.voyage,
                        stampNo: this.stampNo,
                        pilotName: this.pilotName,
                        truckPlate: this.truckPlate,
                        licenseNo: this.licenseNo,
                        platformPlate: this.platformPlate,
                        goodsDescription: this.goodsDescription,
                        boxMark: this.boxMark,
                        packingDate: this.packingDate ? this.findFullDate(this.packingDate) : null,
                        expiryDate: this.expiryDate ? this.findFullDate(this.expiryDate) : null,
                        observers: this.observers,
                        dispatchedBy: this.dispatchedBy,
                        transportedBy: this.transportedBy,
                        receivedBy: this.receivedBy
                    })
                    .then((id) => {
                        const fileId = actionName === 'create' ? id.data : this.$route.query.id;
                        this.message = this.$t('messages.product-release-order-has-been-successfully-saved');
                        if (exportPdf) {
                            this.report(fileId);
                        }
                        if (this.isDraftUsed){
                            this.$localDrafts.remove(`product-release-order:${this.$route.params.companyId}`);
                        }
                        this.$router.push({ name: 'product-release-orders' })
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.submitLoading = false;
                        this.snackbar = true;
                    })
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
        },

        preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/productReleaseOrders', {
                    companyId: this.$route.params.companyId,
                    isManualSequence: this.isManual,
                    manualSequence: this.num,
                    sequence: this.sequence,
                    items: this.tableEntries.map(c => c.data),
                    date: this.date,
                    referenceContract: this.referenceContract,
                    warehouseNo: this.warehouseNo,
                    packingOrderNo: this.packingOrderNo,
                    portCompany: this.portCompany,
                    tariffSystemId: this.tariffSystemId,
                    destination: this.destination,
                    contract: this.contract,
                    booking: this.booking,
                    shipName: this.shipName,
                    containerNo: this.containerNo,
                    shippingDate: this.shippingDate,
                    voyage: this.voyage,
                    stampNo: this.stampNo,
                    pilotName: this.pilotName,
                    truckPlate: this.truckPlate,
                    licenseNo: this.licenseNo,
                    platformPlate: this.platformPlate,
                    goodsDescription: this.goodsDescription,
                    boxMark: this.boxMark,
                    packingDate: this.packingDate ? this.findFullDate(this.packingDate) : null,
                    expiryDate: this.expiryDate ? this.findFullDate(this.expiryDate) : null,
                    observers: this.observers,
                    dispatchedBy: this.dispatchedBy,
                    transportedBy: this.transportedBy,
                    receivedBy: this.receivedBy
                })
                .then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
            }
        },

        // fill to edit data
        fillAllToEdit(data, fillSequence = true){
            if (fillSequence) { this.num = data.sequence; }
            this.referenceContract = data.referenceContract;
            this.warehouseNo = data.warehouseNo;
            this.packingOrderNo = data.packingOrderNo;
            this.date = data.date ? window.moment(data.date).format('YYYY-MM-DD') : null;
            this.portCompany = data.portCompany;
            this.tariffSystemId = data.tariffSystemId;
            this.destination = data.destination;
            this.contract = data.contract;
            this.booking = data.booking;
            this.shipName = data.shipName;
            this.containerNo = data.containerNo;
            this.shippingDate = data.shippingDate ? window.moment(data.shippingDate).format('YYYY-MM-DD') : null;
            this.voyage = data.voyage;
            this.stampNo = data.stampNo;
            this.pilotName = data.pilotName;
            this.truckPlate = data.truckPlate;
            this.licenseNo = data.licenseNo;
            this.platformPlate = data.platformPlate;
            this.goodsDescription = data.goodsDescription;
            this.boxMark = data.boxMark;
            this.packingDate = data.packingDate ? findMonthDate(data.packingDate) : null;
            this.expiryDate = data.expiryDate ? findMonthDate(data.expiryDate) : null;
            this.observers = data.observers;
            this.dispatchedBy = data.dispatchedBy;
            this.transportedBy = data.transportedBy;
            this.receivedBy = data.receivedBy;
            
            data.items.forEach(item => {
                this.tableEntries.push({
                    no: ++this.entryCounter,
                    data: {
                        quality: item.quality,
                        customerQuality: item.customerQuality,
                        packingNumbers: item.packingNumbers,
                        packingType: item.packingType,
                        packingUnit: item.packingUnit,
                        unitWeight: item.unitWeight,
                        grossWeight: item.grossWeight,
                        strapColor: item.strapColor,
                    }
                })
            })
        },

        // fetch sequence
        fetchSequence() {
            return this.$store.dispatch('sequences/fetch', { fileType: fileTypes.productReleaseOrder })
            .then(response => {
                this.num = response.value;
                this.sequence = response;
            })
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/productReleaseOrders/${id}`);
        },

        // fill data from draft
        fillDataFromDraft(){
            this.referenceContract = this.draft.referenceContract;
            this.packingOrderNo = this.draft.packingOrderNo;
            this.warehouseNo = this.draft.warehouseNo;
            this.date = this.draft.date;
            this.portCompany = this.draft.portCompany;
            this.tariffSystemId = this.draft.tariffSystemId;
            this.destination = this.draft.destination;
            this.contract = this.draft.contract;
            this.booking = this.draft.booking;
            this.shipName = this.draft.shipName;
            this.containerNo = this.draft.containerNo;
            this.shippingDate = this.draft.shippingDate;
            this.voyage = this.draft.voyage;
            this.stampNo = this.draft.stampNo;
            this.pilotName = this.draft.pilotName;
            this.truckPlate = this.draft.truckPlate;
            this.licenseNo = this.draft.licenseNo;
            this.platformPlate = this.draft.platformPlate;
            this.goodsDescription = this.draft.goodsDescription;
            this.boxMark = this.draft.boxMark;
            this.packingDate = this.draft.packingDate;
            this.expiryDate = this.draft.expiryDate;
            this.observers = this.draft.observers;
            this.dispatchedBy = this.draft.dispatchedBy;
            this.transportedBy = this.draft.transportedBy;
            this.receivedBy = this.draft.receivedBy;
            this.tableEntries = this.draft.tableEntries;
            this.entryCounter = this.draft.tableEntries.length;
        },

        // helpers
        findMonthDate,
        findFullDate,
        moment
    },

    components:{
        Tooltip
    },

    computed:{
        ...mapState({
            isAdmin: state => state.users.currentUser.isAdmin,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings,
        }),

        dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

        headers() {
            const headers = [
                { text:'No.', value:'no', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text:'Calidad', value:'data.quality', align:'center' },
                { text:'Calidad del cliente', value:'data.customerQuality', align:'center' },
                { text:'No. de bultos', value:'data.packingNumbers', align:'center' },
                { text:'Tepo de embaque', value:'data.packingType', align:'center' },
                { text:'Marca del empaque', value:'data.packingUnit', align:'center' },
                { text:'Peso neto KGs', value:'data.unitWeight', align:'center' },
                { text:'Peso bruto KGs', value:'data.grossWeight', align:'center' },
                { text:'Color fleje', value:'data.strapColor', align:'center' },
                { text:'Actions', value:'actions', align:'center' },
            ];

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
        },

        totalPackingNumbers(){
            return this.tableEntries.map(c => c.data.packingNumbers).reduce((a, b) => a + b, 0);
        },
        
        totalUnitWeight(){
            return this.tableEntries.map(c => c.data.unitWeight).reduce((a, b) => a + b, 0);
        },
        
        totalGrossWeight(){
            return this.tableEntries.map(c => c.data.grossWeight).reduce((a, b) => a + b, 0);
        },

        draftFieldsWatcher(){
            return (` ${this.isToEditEntry} | ${this.referenceContract} | ${this.packingOrderNo} | ${this.warehouseNo} | ${this.portCompany} | ${this.tariffSystemId} | ${this.destination} | ${this.contract} | ${this.booking} | ${this.shipName} | ${this.containerNo} | ${this.shippingDate} | ${this.voyage} | ${this.stampNo} | ${this.pilotName} | ${this.truckPlate} | ${this.licenseNo} | ${this.platformPlate} | ${this.goodsDescription} | ${this.boxMark} | ${this.packingDate} | ${this.expiryDate} | ${this.tableEntries} | ${this.observers} | ${this.dispatchedBy} | ${this.transportedBy} | ${this.receivedBy}`)
        }
    },

    mounted(){
        if (this.$route.query.id){
            if (this.$route.query.viewer) {
                this.title = 'Avance ' + 'orden de salida de producto';
            }
            else if (this.$route.query.duplicate) {
                this.title = 'Duplicar ' + 'orden de salida de producto';
            }
            else {
                this.title = 'Editar ' + 'orden de salida de producto';
            }

            this.$store.dispatch('productReleaseOrders/fetchById', { id: this.$route.query.id })
            .then((data) => {
                // if it duplicate mode don't fill sequence
            // if it duplicate mode create new sequence and fill it
                if (this.$route.query.duplicate) {
                    this.fillAllToEdit(data, false);
                    this.numLoading = true;
                    this.fetchSequence()
                    .finally(() => {
                        this.numLoading = false;
                    });
                }
                else {
                    this.fillAllToEdit(data);
                }
            })
            .finally(() => {
                this.loading = false;
            });
        }
        else {
            this.title = 'Agregar ' + 'orden de salida de producto';
            this.loading = false;
            this.numLoading = true;
            this.fetchSequence()
            .finally(() => {
                this.numLoading = false;
            })

            // get draft and show snackbar
            this.draft = this.$localDrafts.get(`product-release-order:${this.$route.params.companyId}`);
            if (this.draft){
                setTimeout(() => {
                    this.draftSnackbar = true;
                }, 500)
            }
            else {
                this.date = this.moment().format('YYYY-MM-DD');
            }
        }
    }
}
</script>

<style>

</style>