export default {
    'audit-log' : 'سجل التغيرات',
    'of-$': 'لـ {username}',
    'last-login': 'آخر تسجيل دخول',
    'action-name': {
        create: 'إنشاء', 
        modify: 'تعديل', 
        delete: 'حذف', 
        login: 'الدخول إلى النظام',
        'login-to-company': 'الدخول إلى الشركة',
    }
}