export default {
    'items-and-materials': 'المواد والأصناف',
    'the-item': 'المادة',
    'manage-packaging-units': 'إدارة واحدات التعبئة',
    'add-an-item': 'إضافة مادة',

    dialog: {
        'add-an-item': 'إضافة مادة',
        'edit-an-existing-item': 'تعديل مادة',
        'items-of-materials-name': 'اسم المادة',
        'description': 'الوصف',
        'packaging-units-for-item': 'وحدات التعبة للمادة',
        'unit': 'الوحدة',
        'price': 'السعر',
        'default-unit': 'الوحدة الافتراضية',
    }
}