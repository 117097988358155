<template>
    <v-navigation-drawer
      id="drawer"
      app
      clipped
      dark
      :right="$i18n.locale === 'ar'"
      color="primary"
	  permanent
      width="254"
	  :mini-variant="drawer"
	  :expand-on-hover="$vuetify.breakpoint.smAndDown || drawer"
      v-if="!['home', 'companies', 'users', 'templates', 'login', 'audit-log'].includes($route.name)"
    >

        <v-list
        	v-if="!loading"
        	nav
        	:dense="pages.filter(page => page.show).length > Math.floor(($vuetify.breakpoint.height - 70) / 56)"
            expand
        >
            <!-- management -->
            <v-list-group v-model="managementList" active-class="white--text">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon color="light-blue lighten-3">mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="light-blue--text text--lighten-4">
                        {{$t('NavigationDrawer.management')}}
                    </v-list-item-title>
                </template>

                <v-list-item
                	v-for="(page, index) in pages.filter(page => page.show && page.type === 'management')"
                	:key="index"
                	:to="{name: page.target, params:{companyId: $route.params.companyId}}"
                	:disabled="page.disabled"
                    active-class="white--text"
                >
                    <v-list-item-icon>
                        <v-icon>{{page.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{page.title}} 
                        <template v-if="page.nextVersion">
                            <br/><span class="caption font-weight-thin">Available in next version</span>
                        </template>
                    </v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- documents -->
            <v-list-group v-model="documentsList" active-class="white--text">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon color="light-blue lighten-3">mdi-text-box-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="light-blue--text text--lighten-4">
                        {{$t('NavigationDrawer.documents')}}
                    </v-list-item-title>
                </template>

                <v-list-item
                	v-for="(page, index) in pages.filter(page => page.show && page.type === 'document')"
                	:key="index"
                	:to="{name: page.target, params:{companyId: $route.params.companyId}}"
                	:disabled="page.disabled"
                	active-class="white--text"
                >
                    <v-list-item-icon>
                        <v-icon>{{page.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{page.title}} 
                        <template v-if="page.nextVersion">
                            <br/><span class="caption font-weight-thin">Available in next version</span>
                        </template>
                    </v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
        
        <v-progress-linear v-else indeterminate color="white" height="2"></v-progress-linear>
    </v-navigation-drawer>
</template>

<script>
import fileTypes from '../enums/file-types'
import { mapGetters, mapState } from 'vuex'
import * as shownFilesConfigs from '../configs/shown-files-config'
export default {
    name: 'navigation-drawer',
    props:[
        'drawer',
        'loading'
    ],

    data: () => ({
        managementList: false,
        documentsList: true
    }),

    watch: {
        '$route.params.companyId'() {
            this.managementList = false;
            this.documentsList = true;
        }
    },

    computed:{
        pages(){
            return [
                { 
                    icon:'mdi-file-cog',
                    title: this.$t('NavigationDrawer.files-settings'),
                    target: 'files-setting',
                    show: !this.viewerOnly && this.currentUser.isAdmin,
                    type: 'management'
                },
                { 
                    icon:'mdi-account-supervisor',
                    title: this.$t('NavigationDrawer.customers-and-suppliers'),
                    target: 'customers-and-suppliers',
                    show: !this.viewerOnly && this.needToShow(-1),
                    type: 'management'
                },
                { 
                    icon:'mdi-palette-swatch',
                    title: this.$t('NavigationDrawer.items-and-materials'),
                    target: 'materials',
                    show: !this.viewerOnly && this.needToShow(-1),
                    type: 'management'
                },
                { 
                    icon:'mdi-clipboard-text-multiple',
                    title: this.$t('NavigationDrawer.sales-contracts'),
                    target: 'sales-contracts',
                    show:
                        shownFilesConfigs.salesContracts &&
                        this.isCompanyHasFile(fileTypes.salesContract) &&
                        this.needToShow(fileTypes.salesContract),
                    type: 'document'
                },
                { 
                    icon:'mdi-ticket-confirmation',
                    title: this.$t('NavigationDrawer.proforma-invoices'),
                    target: 'invoices',
                    show:
                        shownFilesConfigs.invoices &&
                        this.isCompanyHasFile(fileTypes.invoice) &&
                        this.needToShow(fileTypes.invoice),
                    type: 'document'
                },
                { 
                    icon:'mdi-ticket-percent',
                    title: this.$t('NavigationDrawer.commercial-invoices'),
                    target: 'commercial-invoices',
                    show: 
                        shownFilesConfigs.commercialInvoices &&
                        this.isCompanyHasFile(fileTypes.commercialInvoice) &&
                        this.needToShow(fileTypes.commercialInvoice),
                    type: 'document'
                },
                { 
                    icon:'mdi-cube-scan',
                    title: this.$t('NavigationDrawer.packing-lists'),
                    target: 'packing-lists',
                    show:
                        shownFilesConfigs.packingLists &&
                        this.isCompanyHasFile(fileTypes.packingList) &&
                        this.needToShow(fileTypes.packingList),
                    type: 'document'
                },
                { 
                    icon:'mdi-truck',
                    title: this.$t('NavigationDrawer.shipping-advises'),
                    target: 'shipping-advises',
                    show:
                        shownFilesConfigs.shippingAdvises &&
                        this.isCompanyHasFile(fileTypes.shippingAdvise) &&
                        this.needToShow(fileTypes.shippingAdvise),
                    type: 'document'
                },
                { 
                    icon:'mdi-package-variant-closed',
                    title:'Ordenes de empaque',
                    target: 'packing-orders',
                    show: 
                        shownFilesConfigs.packingOrders &&
                        this.isCompanyHasFile(fileTypes.packingOrder) &&
                        this.needToShow(fileTypes.packingOrder),
                    type: 'document'
                },
                { 
                    icon:'mdi-shipping-pallet',
                    title:'Ordenes de salida',
                    target: 'product-release-orders',
                    show:
                        shownFilesConfigs.productReleaseOrders &&
                        this.isCompanyHasFile(fileTypes.productReleaseOrder) &&
                        this.needToShow(fileTypes.productReleaseOrder),
                    type: 'document'
                },
                { 
                    icon:'mdi-pot-steam-outline',
                    title:this.$t('file-types.fumigation-certificate'),
                    target: 'fumigation-certificates',
                    show:
                        shownFilesConfigs.fumigationCertificates &&
                        this.isCompanyHasFile(fileTypes.fumigationCertificate) &&
                        this.needToShow(fileTypes.fumigationCertificate),
                    type: 'document'
                },
                { 
                    icon:'mdi-heart-pulse',
                    title:this.$t('file-types.health-certificate'),
                    target: 'health-certificates',
                    show:
                        shownFilesConfigs.healthCertificates &&
                        this.isCompanyHasFile(fileTypes.healthCertificate) &&
                        this.needToShow(fileTypes.healthCertificate),
                    type: 'document'
                },
                { 
                    icon:'mdi-certificate-outline',
                    title:this.$t('file-types.weight-and-quality-certificate'),
                    target: 'weight-and-quality-certificates',
                    show:
                        shownFilesConfigs.weightAndQualityCertificates &&
                        this.isCompanyHasFile(fileTypes.weightAndQualityCertificate) &&
                        this.needToShow(fileTypes.weightAndQualityCertificate),
                    type: 'document'
                },
                { 
                    icon:'mdi-ferry',
                    title:'Despacho a Puerto',
                    target: 'port-dispatches',
                    show:
                        shownFilesConfigs.portDispatches &&
                        this.isCompanyHasFile(fileTypes.portDispatch) &&
                        this.needToShow(fileTypes.portDispatch),
                    type: 'document'
                },
                { 
                    icon:'mdi-file-certificate-outline',
                    title:this.$t('NavigationDrawer.technical-and-financial-offers'),
                    target: 'technical-and-financial-offers',
                    show:
                        shownFilesConfigs.technicalAndFinancialOffers &&
                        this.isCompanyHasFile(fileTypes.technicalAndFinancialOffer) &&
                        this.needToShow(fileTypes.technicalAndFinancialOffer),
                    type: 'document'
                },
            ]
        },

        ...mapState({
            companies: state => state.companies.companies,
            company: state => state.companies.company,
            currentUser: state => state.users.currentUser,
            viewerOnly: state => state.users.viewerOnly,
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        })
    },

    methods: {
        needToShow(fileType){
            if (this.currentUser.isAdmin) return true;
            else if (fileType === -1) return true;
            else {
                return this.currentUser.companyPermissions
                .find(c => c.companyId === this.$route.params.companyId)
                .fileTypes.includes(fileType)
            }
        },

        isCompanyHasFile(fileType) {
            if (this.company.fileTypes.length === 0) {
                return true;
            }
            return this.company.fileTypes.includes(fileType);
        }
    }
}
</script>

<style lang="scss">
#drawer ::-webkit-scrollbar{
    width: 5px;
}
#drawer ::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 10px;
}
.active-name{
    border-right: 1px solid currentColor;
    border-left: 1px solid currentColor;
}
.active-item{
    [dir="ltr"] & {
        border-right: 2px solid currentColor;
    }
    [dir="rtl"] & {
        border-left: 2px solid currentColor;
    }
}
</style>