<template>
    <v-form @submit.prevent="submit(false)" ref="form" :disabled="viewerOnly">
        <!-- entry dialog -->
        <v-dialog
            v-model="dialog"
            width="800"
            ref="dialog"
            @click:outside="resetDialog"
        >
            <v-form @submit.prevent="!isToEditEntry ? saveEntry() : editEntry()" >
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text justify-center">
                        <v-spacer/>
                        <span class="text-h6">{{ !isToEditEntry ? 'Add' : 'Edit' }} item</span>
                        <v-spacer/>
                        <v-btn small text fab @click="dialog = false" color="white">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <!-- Calidad prodcuto -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="productQuality"
                                        label="Calidad prodcuto"
                                        ref="productQualityInput"
                                        hide-details
                                    ></v-text-field>
                                </v-col>

                                <!-- Peso Neto kg -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-currency-text-field
                                        v-model.number="netWeight"
                                        label="Peso Neto kg"
                                        type="number"
                                        min="0"
                                        hide-details
                                    ></v-currency-text-field>
                                </v-col>

                                <!-- Tipo de embaque -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-autocomplete
                                        v-model="packingType"
                                        :items="packingTypes"
                                        label="Tipo de embaque"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>

                                <!-- Peso neto unitario -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-currency-text-field
                                        v-model.number="unitWeight"
                                        label="Peso neto unitario"
                                        type="number"
                                        min="0"
                                        hide-details
                                    ></v-currency-text-field>
                                </v-col>
                                
                                <!-- Peso bruto unitario -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-currency-text-field
                                        v-model.number="unitGrossWeight"
                                        label="Peso bruto unitario"
                                        type="number"
                                        min="0"
                                        hide-details
                                    ></v-currency-text-field>
                                </v-col>

                                <!-- Marca de caja -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="boxMark"
                                        label="Marca de caja"
                                        hide-details
                                    ></v-text-field>
                                </v-col>

                                <!-- Color de fleje -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="strapColor"
                                        label="Color de fleje"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Marca en caja -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="markOnBox"
                                        label="Marca en caja"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            type="submit"
                        >
                            {{ !isToEditEntry ? 'Add' : 'Edit' }}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            @click="dialog = false; resetDialog()"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <div class="d-flex justify-space-between align-center mb-1">
            <!-- title and number -->
            <v-row class="align-center ma-0 mb-1">
                <!-- title -->
                <v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
                    <h1 class="text-h5 primary--text">{{title}}</h1>

                    <!-- back button -->
                    <v-btn
                        v-if="$vuetify.breakpoint.smAndDown"
                    	:to="{name:'packing-orders'}"
                    	:disabled="submitLoading"
                    	elevation="1"
                    	small
                    	class="px-6"
                    >
                        Back
                    </v-btn>
                </v-col>

                <!-- num -->
                <v-col lg="2" md="3" sm="6" cols="12">
                    <v-text-field
                        v-model="num"
                        :loading="loading || editLoading || numLoading"
                        :append-outer-icon="isAdmin ? 'mdi-pencil' : null"
                        @click:append-outer="editSequence"
                        :readonly="!isManual"
                        dense
                        hide-details="auto"
                        :class="{ 'v-input--is-disabled': !isManual }"
                        :disabled="submitLoading || numLoading"
                    ></v-text-field>
                </v-col>

                <!-- Fecha -->
                <v-col md="3" sm="6" cols="12">
                   <bee-date-input
                        v-model="date"
                        changeable-with-arrows
                        label="Fecha"
                        prepend-icon="mdi-calendar"
                        :menu-picker-years-over-max="10"
                        :input-format-order="dateFormat"
                        :user-menu-picker="true"
                        dense
                        hide-details="auto"
                        :loading="editLoading"
                        :disabled="submitLoading || editLoading"
                        :rules="rules.required"
                    ></bee-date-input>
                </v-col>
            </v-row>

            <!-- back button -->
            <v-btn
            	v-if="$vuetify.breakpoint.mdAndUp"
            	:to="{ name: 'packing-orders' }"
            	:disabled="submitLoading"
            	elevation="1"
            	small
            	class="px-6"
            >
                {{$t('back')}}
            </v-btn>
        </div>

        <!-- BASIC INFORMATION -->
        <h2 class="text-body-1 primary--text mb-3">Información básica</h2>
        <v-row class="mb-3">
            <v-col md="6" cols="12">
                <v-row>
                    <!-- Contrato -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="referenceContract"
                            label="Contrato"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                    
                    <!-- Cliente -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="customer"
                            label="Cliente"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- Consignatario -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="consignee"
                            label="Consignatario"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- Pais de destino -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="destinationCountry"
                            label="Pais de destino"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>

                    <!-- Puerto de destino -->
                    <v-col cols="12">
                        <v-text-field
                            v-model="destinationPort"
                            label="Puerto de destino"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="6" cols="12">
                <v-row>
                    <!-- Tamaño del contendor -->
                    <v-col cols="12"> 
                        <v-autocomplete
                            v-model="containerSize"
                            label="Tamaño del contendor"
                            :items="containerSizes"
                            dense
                            hide-details="auto"
                            :loading="loading || editLoading"
                            :disabled="submitLoading"
                        ></v-autocomplete>
                    </v-col>

                    <!-- Fecha de exportacion -->
                    <v-col cols="12">
                        <bee-date-input
                            v-model="exportDate"
                            changeable-with-arrows
                            label="Fecha de exportacion"
                            prepend-icon="mdi-calendar"
                            :menu-picker-years-over-max="10"
                            :input-format-order="dateFormat"
                            :user-menu-picker="true"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                        ></bee-date-input>
                    </v-col>

                    <!-- Fecha carga del contenedor -->
                    <v-col cols="12">
                        <bee-date-input
                            v-model="containerLoadingDate"
                            changeable-with-arrows
                            label="Fecha carga del contenedor"
                            prepend-icon="mdi-calendar"
                            :menu-picker-years-over-max="10"
                            :input-format-order="dateFormat"
                            :user-menu-picker="true"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                        ></bee-date-input>
                    </v-col>

                    <!-- Fecha de PROD. -->
                    <v-col cols="12">
                        <bee-date-input
                            v-model="productionDate"
                            changeable-with-arrows
                            label="Fecha de PROD."
                            prepend-icon="mdi-calendar"
                            :menu-picker-years-over-max="10"
                            :input-format-order="dateFormat"
                            :user-menu-picker="true"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                            input-type="month"
                        ></bee-date-input>
                    </v-col>

                    <!-- Fecha de EXPI. -->
                    <v-col cols="12">
                        <bee-date-input
                            v-model="expiryDate"
                            changeable-with-arrows
                            label="Fecha de EXPI."
                            prepend-icon="mdi-calendar"
                            :menu-picker-years-over-max="10"
                            :input-format-order="dateFormat"
                            :user-menu-picker="true"
                            dense
                            hide-details="auto"
                            :loading="editLoading"
                            :disabled="submitLoading || editLoading"
                            input-type="month"
                        ></bee-date-input>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- table -->
        <v-data-table
            :items="loading ? [] : tableEntries"
            disable-pagination
            disable-sort
            :loading="editLoading"
            no-data-text="No items were added."
            :headers="headers"
            dense
            class="elevation-1 mb-6"
        >
            <!-- productQuality-->
            <template v-slot:item.data.productQuality="{item}">
                {{ item.data.productQuality ? item.data.productQuality : '-' }}
            </template>
            
            <!-- netWeight-->
            <template v-slot:item.data.netWeight="{item}">
                {{ item.data.netWeight ? item.data.netWeight : '-' | currency }}
            </template>
            
            <!-- packingType-->
            <template v-slot:item.data.packingType="{item}">
                {{ item.data.packingType ? item.data.packingType : '-' }}
            </template>
            
            <!-- unitWeight-->
            <template v-slot:item.data.unitWeight="{item}">
                {{ item.data.unitWeight ? item.data.unitWeight : '-' | currency }}
            </template>

            <!-- unitGrossWeight-->
            <template v-slot:item.data.unitGrossWeight="{item}">
                {{ item.data.unitGrossWeight ? item.data.unitGrossWeight : '-' | currency }}
            </template>
            
            <!-- unitTotal-->
            <template v-slot:item.data.unitTotal="{item}">
                {{ item.data.unitTotal ? item.data.unitTotal : '-' | currency }}
            </template>
            
            <!-- boxMark-->
            <template v-slot:item.data.boxMark="{item}">
                {{ item.data.boxMark ? item.data.boxMark : '-' }}
            </template>
            
            <!-- strapColor-->
            <template v-slot:item.data.strapColor="{item}">
                {{ item.data.strapColor ? item.data.strapColor : '-' }}
            </template>
            
            <!-- markOnBox-->
            <template v-slot:item.data.markOnBox="{item}">
                {{ item.data.markOnBox ? item.data.markOnBox : '-' }}
            </template>
            
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Edit item">
                        <v-btn
                            text
                            small
                            :disabled="submitLoading"
                            @click="isToEditEntry = true; fillToEditEntry(item.no)"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete item">
                        <v-btn @click="removeEntry(item.no)" text small :disabled="submitLoading">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>

            <!-- add button -->
            <template v-slot:footer.prepend v-if="!viewerOnly">
                <v-btn text @click="dialog = true; isToEditEntry = false;" :disabled="submitLoading">
                    <v-icon class="me-3">mdi-plus-circle-outline</v-icon> Add item
                </v-btn>
            </template>
        </v-data-table>

        <v-row class="mb-3">
            <!-- Total en kg -->
            <v-col md="2" sm='4' cols="6">
                <v-text-field
                    :value="$options.filters.currency(total)"
                    label="Total en kg"
                    :disabled="submitLoading"
                    outlined
                    readonly
                    dense
                    hide-details
                    :loading="loading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- Total de -->
            <v-col md="2" sm='4' cols="6">
                <v-text-field
                    :value="$options.filters.currency(totalBags)"
                    :label="'Total de ' + packingType + 'S'"
                    :disabled="submitLoading"
                    outlined
                    readonly
                    dense
                    hide-details
                    :loading="loading || editLoading"
                ></v-text-field>
            </v-col>

            <!-- Organizar en contenedor -->
            <v-col md="4" sm="6" cols="12">
                <v-menu
                    top
                    eager
                    v-model="productsOrderInContainerMenu"
                    :close-on-content-click="false"
                    :disabled="submitLoading"
                    open-on-click
                    transition="scale-transition"
                    origin="bottom left"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="productsOrderInContainer"
                          label="Organizar en contenedor"
                          :disabled="submitLoading"
                          outlined
                          readonly
                          dense
                          hide-details
                          :loading="loading || editLoading"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                    </template>
                    <v-card>
                        <v-card-text class="pb-0">
                            <img :src="`${BASE_API_URL}/${fileSettings.containerUrl}`" width="400" class="mb-1"/>
                            <v-text-field
                                v-model="productsOrderInContainer"
                                label="Organizar en contenedor"
                                ref="productsOrderInput"
                                dense
                                hide-details
                                outlined
                                @keypress.enter="productsOrderInContainerMenu = false"
                                :loading="loading || editLoading"
                            ></v-text-field>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn text small color="primary" class="ms-3" @click="productsOrderInContainerMenu = false">
                                close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col>

            <!-- Nota -->
            <v-col md="4" sm="6" cols="12">
                <v-textarea
                  v-model="notes"
                  label="Nota"
                  rows="1"
                  auto-grow
                  no-resize
                  outlined
                  dense
                  hide-details
                  :disabled="submitLoading"
                ></v-textarea>
            </v-col>
        </v-row>

        <!-- actions -->
        <div class="d-flex justify-end" v-if="!viewerOnly">
            <v-btn dis :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-4">
                Save
            </v-btn>
            <v-btn dis @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-4">
                Save & Export as PDF
            </v-btn>
            <v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
                <v-icon class="me-1">mdi-eye-outline</v-icon>
                {{$t('preview')}}
            </v-btn>
        </div>

        <!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`packing-order:${$route.params.companyId}`); draftSnackbar = false; date = moment().format('YYYY-MM-DD');"
        />
        
        <!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-form>
</template>

<script>
import Tooltip from '../../../components/Tooltip'
import fileTypes from '../../../enums/file-types'
import rules from '../../../validation rules'
import containerSizes from '../../../enums/container-sizes'
import { findMonthDate, findFullDate } from '../../../helpers'
import { BASE_API_URL } from '../../../constants'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    data: () => ({
        // helpers
        title: null,
        
        dialog: false,
        entryCounter: 0,

        draft: null,
        isDraftUsed: false,
        draftSnackbar: false,

        snackbar: null,
        message: null,
        
        loading: true,
        numLoading: false,
        editLoading: false,
        submitLoading: false,
        previewLoading: false,

        containerSizes,

        productsOrderInContainerMenu: false,
        packingTypes: ['SACO', 'CAJA'],

        rules,
        BASE_API_URL,

        /* dialog */
        // dialog helperes
        isToEditEntry: false,
        itemEditNo: null,

        // dialog inputs data
        productQuality: null,
        netWeight: null,
        packingType: 'SACO',
        unitWeight: null,
        unitGrossWeight: null,
        boxMark: null,
        strapColor: null,
        markOnBox: null,

        // data
        num: null,
        isManual: false,
        sequence: null,
        referenceContract: null,
        customer: null,
        consignee: null,
        destinationCountry: null,
        destinationPort: null,
        containerSize: null,
        date:null,
        exportDate: null,
        containerLoadingDate: null,
        productionDate: null,
        expiryDate: null,
        productsOrderInContainer: null,
        notes: null,
        tableEntries: []
    }),

    watch: {
        // products order Menu 
        productsOrderInContainerMenu(val){
            val && setTimeout(() => this.$refs.productsOrderInput.focus(), 50);
        },

        // draft fields watcher
        draftFieldsWatcher(){
            if (!this.$route.query.id) {
                this.$localDrafts.save(`packing-order:${this.$route.params.companyId}`, {
                    referenceContract: this.referenceContract,
                    customer: this.customer,
                    consignee: this.consignee,
                    destinationCountry: this.destinationCountry,
                    destinationPort: this.destinationPort,
                    containerSize: this.containerSize,
                    date: this.date,
                    exportDate: this.exportDate,
                    containerLoadingDate: this.containerLoadingDate,
                    productionDate: this.productionDate,
                    expiryDate: this.expiryDate,
                    productsOrderInContainer: this.productsOrderInContainer,
                    notes: this.notes,
                    tableEntries: this.tableEntries
                })
            }
        }
    },

    methods: {
        editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },

        // entry dialog functions
        resetInputEntries(){
            this.productQuality = null;
            this.netWeight = null;
            this.unitWeight = null;
            this.unitGrossWeight = null;
            this.boxMark = null;
            this.strapColor = null;
            this.markOnBox = null;
        },

        resetDialog(){
            this.resetInputEntries();
            this.isToEditEntry = false;  
        },

        saveEntry(){
            this.tableEntries.push({
                no: ++this.entryCounter,
                data: {
                    productQuality: this.productQuality,
                    netWeight: this.netWeight,
                    unitGrossWeight: this.unitGrossWeight,
                    packingType: this.packingType,
                    unitWeight: this.unitWeight,
                    unitTotal: this.unitWeight > 0 ? this.netWeight / this.unitWeight : null,
                    boxMark: this.boxMark,
                    strapColor: this.strapColor,
                    markOnBox: this.markOnBox,
                }
            });

            this.resetInputEntries();
            this.$refs.productQualityInput.focus();
        },

        fillToEditEntry(itemNo){
            this.itemEditNo = itemNo;
            const entry = this.tableEntries.find(c => c.no === itemNo);
            this.productQuality = entry.data.productQuality;
            this.netWeight = entry.data.netWeight;
            this.unitGrossWeight = entry.data.unitGrossWeight;
            this.packingType = entry.data.packingType;
            this.unitWeight = entry.data.unitWeight;
            this.boxMark = entry.data.boxMark;
            this.strapColor = entry.data.strapColor;
            this.markOnBox = entry.data.markOnBox;
            
            this.dialog = true;
        },

        editEntry(){
            const entry = this.tableEntries.find(c => c.no === this.itemEditNo);
            entry.data.productQuality = this.productQuality;
            entry.data.netWeight = this.netWeight;
            entry.data.unitGrossWeight = this.unitGrossWeight;
            entry.data.packingType = this.packingType;
            entry.data.unitWeight = this.unitWeight;
            entry.data.unitTotal = this.unitWeight > 0 ? this.netWeight / this.unitWeight : null;
            entry.data.boxMark = this.boxMark;
            entry.data.strapColor = this.strapColor;
            entry.data.markOnBox = this.markOnBox;
            
            this.dialog = false;
            this.resetInputEntries();
            this.isToEditEntry = false;
        },

        removeEntry(itemNo){
            const index = itemNo - 1;
            this.tableEntries.splice(index, 1);
            this.tableEntries.forEach(entry => {
                if (entry.no - 1 > index) entry.no--;
            });
            this.entryCounter--;
        },

        // submit
        submit(exportPdf){
            if (this.$refs.form.validate()) {
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
                this.$store.dispatch('sequences/reserve', {
                    sequence: this.isManual ? this.num : this.sequence,
                    fileType: fileTypes.packingOrder,
                    isManual: this.isManual,
                    overrideCollision: true
                })
                .then(() => {
                    this.$store.dispatch(`packingOrders/${actionName}`, {
                        id: this.$route.query.id,
                        isManualSequence: this.isManual,
                        manualSequence: this.num,
                        sequence: this.sequence,
                        items: this.tableEntries.map(c => c.data),
                        date: this.date,
                        referenceContract: this.referenceContract,
                        customer: this.customer,
                        consignee: this.consignee,
                        destinationCountry: this.destinationCountry,
                        destinationPort: this.destinationPort,
                        containerSize: this.containerSize,
                        exportDate: this.exportDate,
                        containerLoadingDate: this.containerLoadingDate,
                        productsOrderInContainer: this.productsOrderInContainer,
                        notes: this.notes,
                        productionDate: this.findFullDate(this.productionDate),
                        expiryDate: this.findFullDate(this.expiryDate)
                    })
                    .then((id) => {
                        const fileId = actionName === 'create' ? id.data : this.$route.query.id;
                        this.message = this.$t('messages.packing-order-has-been-successfully-saved');
                        if (exportPdf) {
                            this.report(fileId);
                        }
                        if (this.isDraftUsed){
                            this.$localDrafts.remove(`packing-order:${this.$route.params.companyId}`);
                        }
                        this.$router.push({ name: 'packing-orders' })
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.submitLoading = false;
                        this.snackbar = true;
                    })
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
        },

        preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/packingOrders', {
                    companyId: this.$route.params.companyId,
                    isManualSequence: this.isManual,
                    manualSequence: this.num,
                    sequence: this.sequence,
                    items: this.tableEntries.map(c => c.data),
                    date: this.date,
                    referenceContract: this.referenceContract,
                    customer: this.customer,
                    consignee: this.consignee,
                    destinationCountry: this.destinationCountry,
                    destinationPort: this.destinationPort,
                    containerSize: this.containerSize,
                    exportDate: this.exportDate,
                    containerLoadingDate: this.containerLoadingDate,
                    productsOrderInContainer: this.productsOrderInContainer,
                    notes: this.notes,
                    productionDate: this.findFullDate(this.productionDate),
                    expiryDate: this.findFullDate(this.expiryDate)
                })
                .then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                    this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
            }
        },

        // edit file
        fillAllToEdit(data, fillSequence = true){
            if (fillSequence) { this.num = data.sequence; }
            this.referenceContract = data.referenceContract;
            this.customer = data.customer;
            this.consignee = data.consignee;
            this.destinationCountry = data.destinationCountry;
            this.destinationPort = data.destinationPort;
            this.containerSize = data.containerSize;
            this.date = data.date ? window.moment(data.date).format('YYYY-MM-DD') : null;
            this.exportDate = data.exportDate ? window.moment(data.exportDate).format('YYYY-MM-DD') : null;
            this.containerLoadingDate = data.containerLoadingDate ? window.moment(data.containerLoadingDate).format('YYYY-MM-DD') : null;
            this.productsOrderInContainer = data.productsOrderInContainer;
            this.notes = data.notes;
            this.productionDate = this.findMonthDate(data.productionDate);
            this.expiryDate = this.findMonthDate(data.expiryDate);
            data.items.forEach(item => {
                this.tableEntries.push({
                    no: ++this.entryCounter,
                    data: {
                        productQuality: item.productQuality,
                        netWeight: item.netWeight,
                        packingType: item.packingType,
                        unitGrossWeight: item.unitGrossWeight,
                        unitWeight: item.unitWeight,
                        unitTotal: item.unitTotal,
                        boxMark: item.boxMark,
                        strapColor: item.strapColor,
                        markOnBox: item.markOnBox,
                    }
                });
                this.packingType = item.packingType;
            })
        },

        // fetch sequence
        fetchSequence() {
            return this.$store.dispatch('sequences/fetch', { fileType: fileTypes.packingOrder })
            .then(response => {
                this.num = response.value;
                this.sequence = response;
            })
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/PackingOrders/${id}`);
        },

        // fill data from draft
        fillDataFromDraft() {
            this.referenceContract = this.draft.referenceContract;
            this.customer = this.draft.customer;
            this.consignee = this.draft.consignee;
            this.destinationCountry = this.draft.destinationCountry;
            this.destinationPort = this.draft.destinationPort;
            this.containerSize = this.draft.containerSize;
            this.date = this.draft.date;
            this.exportDate = this.draft.exportDate;
            this.containerLoadingDate = this.draft.containerLoadingDate;
            this.productionDate = this.draft.productionDate;
            this.expiryDate = this.draft.expiryDate;
            this.productsOrderInContainer = this.draft.productsOrderInContainer;
            this.notes = this.draft.notes;
            this.tableEntries = this.draft.tableEntries;
            this.entryCounter = this.draft.tableEntries.length;
        },

        // helpers
        findMonthDate,
        findFullDate,
        moment
    },

    computed:{
        ...mapState({
            isAdmin: state => state.users.currentUser.isAdmin,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings,
        }),

        dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

        headers() {
            const headers = [
                { text:'No.', value:'no', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text:'Calidad prodcuto', value:'data.productQuality', align:'center' },
                { text:'Peso Neto kg', value:'data.netWeight', align:'center' },
                { text:'Tipo de embaque', value:'data.packingType', align:'center' },
                { text:'Peso neto unitario', value:'data.unitWeight', align:'center' },
                { text:'Peso bruto unitario', value:'data.unitGrossWeight', align:'center' },
                { text:'Total de unidades', value:'data.unitTotal', align:'center' },
                { text:'Marca de caja', value:'data.boxMark', align:'center' },
                { text:'Color de fleje', value:'data.strapColor', align:'center' },
                { text:'Marca en caja', value:'data.markOnBox', align:'center' },
                { text:'Actions', value:'actions', align:'center' },
            ];

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
        },

        total(){
            return this.tableEntries.map(c => c.data.netWeight).reduce((a, b) => a + b, 0);
        },

        totalBags(){
            return this.tableEntries.map(c => c.data.unitTotal).reduce((a, b) => a + b, 0);
        },

        draftFieldsWatcher() {
            return (`${this.isToEditEntry} | ${this.referenceContract} | ${this.customer} | ${this.consignee} | ${this.destinationCountry} | ${this.destinationPort} | ${this.containerSize} | ${this.exportDate} | ${this.containerLoadingDate} | ${this.productionDate} | ${this.expiryDate} | ${this.productsOrderInContainer} | ${this.notes} | ${this.tableEntries}`)
        }
    },

    components:{
        Tooltip
    },

    mounted(){
        if (this.$route.query.id){
            if (this.$route.query.viewer) {
                this.title = 'Avance ' + 'orden de empaque';
            }
            else if (this.$route.query.duplicate) {
                this.title = 'Duplicar ' + 'orden de empaque';
            }
            else {
                this.title = 'Editar ' + 'orden de empaque';
            }
            this.$store.dispatch('packingOrders/fetchById', { id: this.$route.query.id })
            .then((data) => {
                // if it duplicate mode don't fill sequence
                if (this.$route.query.duplicate) {
                    this.fillAllToEdit(data, false);

                    // if it duplicate mode create new sequence and fill it
                    this.numLoading = true;
                    this.fetchSequence()
                    .finally(() => {
                        this.numLoading = false;
                    });
                }
                else {
                    this.fillAllToEdit(data);
                }
            })
            .finally(() => {
                this.loading = false;
            });
        }
        else {
            this.title = 'Agregar ' + 'orden de empaque';
            this.loading = false;
            this.numLoading = true;
            this.fetchSequence()
            .finally(() => {
                this.numLoading = false;
            });

            // get draft and show snackbar
            this.draft = this.$localDrafts.get(`packing-order:${this.$route.params.companyId}`);
            if (this.draft){
                setTimeout(() => {
                    this.draftSnackbar = true;
                }, 500)
            }
            else {
                this.date = this.moment().format('YYYY-MM-DD');
            }
        }
    }
}
</script>

<style>

</style>