<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 primary--text">Ordenes de salida</h1>

            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="filter">
                <span class="text-body-1">
                    Are you sure you want to <span class="error--text">DELETE</span> the Product Release Order?
                </span>
            </delete-dialog>

            <!-- Add an Proforma Invoice -->
            <v-btn
              v-if="!viewerOnly"
              text
              small
              class="lightPrimary white--text"
              :to="{
                name:'product-release-order',
                params:{companyId: $route.params.companyId},
              }"
            >
                Agregar orden de salida de producto
            </v-btn>
        </div>

        <!-- filter -->
        <v-expansion-panels v-if="0" accordion focusable class="my-5">
            <v-expansion-panel>
                <v-expansion-panel-header color="primary--text">Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-form class="my-2" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1">
                    Filter
                </v-col>

                <!-- Contrato -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-text-field
                        v-model="referenceContract"
                        :disabled="filterLoading"
                        label="Número"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- Despachado por -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-text-field
                        v-model="dispatchedBy"
                        label="Despachado por"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- Recibido por -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-text-field
                        v-model="receivedBy"
                        label="Recibido por"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- Contenedor No. -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-text-field
                        v-model="containerNo"
                        label="Contenedor No."
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date ? moment(date) : null"
                                label="Partir de la fecha"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate.save(date)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date2 ? moment(date2) : null"
                                label="Hasta la fecha"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date2"
                                :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate2.save(date2)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        width="80"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        Search
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        icon
                        small
                        @click="fetchShippingAdvices();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : productReleaseOrders"
          :items-per-page="20"
          :footer-props="{ 'items-per-page-options': [10, 20, 40, 100, -1], showCurrentPage:true}"
          :loading="loading"
          disable-sort
          dense
          class="elevation-2"
        >
            <!-- referenceContract -->
            <template v-slot:item.referenceContract="{item}">
                {{item.referenceContract ? item.referenceContract : 'N/A'}}
            </template>

            <!-- warehouseNo -->
            <template v-slot:item.warehouseNo="{item}">
                {{item.warehouseNo ? item.warehouseNo : 'N/A'}}
            </template>

            <!-- date -->
            <template v-slot:item.date="{item}">
                {{item.date ? moment(item.date) : 'N/A'}}
            </template>

            <!-- portCompany -->
            <template v-slot:item.portCompany="{item}">
                {{item.portCompany ? item.portCompany : 'N/A'}}
            </template>

            <!-- packingDate -->
            <template v-slot:item.packingDate="{item}">
                {{item.packingDate ? findMonthDate(item.packingDate) : 'N/A'}}
            </template>

            <!-- expiryDate -->
            <template v-slot:item.expiryDate="{item}">
                {{item.expiryDate ? findMonthDate(item.expiryDate) : 'N/A'}}
            </template>

            <!-- totalPackingNumbers -->
            <template v-slot:item.totalPackingNumbers="{item}">
                {{item.totalPackingNumbers ? item.totalPackingNumbers : 'N/A' | currency }}
            </template>

            <!-- totalUnitWeight -->
            <template v-slot:item.totalUnitWeight="{item}">
                {{item.totalUnitWeight ? item.totalUnitWeight : 'N/A' | currency }}
            </template>

            <!-- totalGrossWeight -->
            <template v-slot:item.totalGrossWeight="{item}">
                {{item.totalGrossWeight ? item.totalGrossWeight : 'N/A' | currency }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Descargar como pdf">
                        <v-btn text small @click="report(item.id)">
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <template v-if="viewerOnly">
                        <tooltip text="Avance">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'product-release-order',
                                    params:{companyId: $route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>

                    <template v-else>
                        <tooltip text="Duplicar">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'product-release-order',
                                    params:{companyId: $route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-content-duplicate
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Editar">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'product-release-order',
                                    params:{companyId: $route.params.companyId},
                                    query:{id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Eliminar">
                            <v-btn @click="$refs.DeleteDialog.activeDialog(item.id, 'productReleaseOrders')" text small>
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} of {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import { mapState, mapGetters } from 'vuex';
import { findMonthDate } from '../../helpers';
import { BASE_API_URL } from '../../constants';
export default {
    data: () => ({
        // filter data
        referenceContract: null,
        dispatchedBy: null,
        receivedBy: null,
        containerNo: null,
        date: null,
        
        date2: null,

        // helpers
        loading: true,
        filterLoading: true,
        isFiltered: false
    }),

    methods:{
        // filter funciton
        clearFilter(){
            this.referenceContract = null;
            this.dispatchedBy = null;
            this.receivedBy = null;
            this.containerNo = null;
            this.date = null;
            
            this.date2 = null;

            this.isFiltered = false;
        },
        
        filter(){
            this.loading = true;
            this.$store.dispatch('productReleaseOrders/fetchAll', {
                sequence: this.referenceContract,
                fromDate: this.date,
                toDate: this.date2
            })
            .finally(() => {
                this.loading = false;
            })
        },

        fetchShippingAdvices(){
            this.clearFilter();
            this.filter();
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/productReleaseOrders/${id}`);
        },

        // table funcitons
        moment(date){
            return window.moment(date).format(this.fileSettings.dateFormat ? this.fileSettings.dateFormat.toUpperCase() : 'DD-MM-YYYY');
        },

        // helpers
        findMonthDate
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                this.referenceContract === null &&
                this.dispatchedBy === null &&
                this.receivedBy === null &&
                this.containerNo === null &&
                this.date === null &&
                this.date2 === null
            )
        },

        ...mapState({
            customers: state => state.customers.customers,
            productReleaseOrders: state => state.productReleaseOrders.productReleaseOrders,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),

        headers() {
            const headers = [
                { text:'Número', value:'sequence', class:'primary--text', cellClass:'primary--text', width: 100 },
                { text:'Fetcha', value:'date', class:'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                { text:'Contrato', value:'referenceContract' },
                { text:'Salida de bodega no', value:'warehouseNo' },
                { text:'Empresa portuaria', value:'portCompany' },
                { text:'Packing date', value:'packingDate', class:'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                // { text:'Expiry data', value:'expiryDate', class:'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                { text:'Total No. de bultos', value:'totalPackingNumbers' },
                { text:'Total peso neto', value:'totalUnitWeight' },
                { text:'Total peso burto', value:'totalGrossWeight' },
                { text:'Actions', value:'actions', width: 150 },
            ];

            // set shared props
            for (let i = 0; i < headers.length; i++) {
                headers[i].align = 'center';
                headers[i].class = headers[i].class ? `${headers[i].class} px-2` : 'px-2';
                headers[i].cellClass = headers[i].cellClass ? `${headers[i].cellClass} px-2` : 'px-2';
            }

            return headers;
        },
    },

    mounted(){
        Promise.all([
            this.$store.dispatch('customers/fetchAll'),
            this.$store.dispatch('productReleaseOrders/fetchAll', { sequence: null, fromDate: null, toDate: null })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    components:{
        DeleteDialog,
        Tooltip
    }
}
</script>

<style>

</style>