import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        portDispatches: []
    },

    actions:{
        fetchAll({ commit }, { sequence, date }){
            return window.axios.get('/files/dispatchForPort', {
                params:{
                    sequence, date
                }
            })
            .then((response) => {
                commit(types.STORE_PORT_DISPATCHES, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/dispatchForPort/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { date, isManualSequence, sequence, manualSequence, packingOrderNo, contractNo, tariffRegime, portCompanyName, shippingCompany, containerNo, labelNo, destination, booking, shipmentDate, shipmentBy, pilotName, licenseNo, truckPlates, platformPlates, containing, packingDate, expiryDate, dispatcher, receiver, transportedBy, custody, items }){
            return window.axios.post('/files/dispatchForPort', { date, isManualSequence, sequence, manualSequence, packingOrderNo, contractNo, tariffRegime, portCompanyName, shippingCompany, containerNo, labelNo, destination, booking, shipmentDate, shipmentBy, pilotName, licenseNo, truckPlates, platformPlates, containing, packingDate, expiryDate, dispatcher, receiver, transportedBy, custody, items })
            .then((response) => {
                return response.data;
            });
        },

        update({ commit }, { id, date, isManualSequence, sequence, manualSequence, packingOrderNo, contractNo, tariffRegime, portCompanyName, shippingCompany, containerNo, labelNo, destination, booking, shipmentDate, shipmentBy, pilotName, licenseNo, truckPlates, platformPlates, containing, packingDate, expiryDate, dispatcher, receiver, transportedBy, custody, items }){
            return window.axios.put(`/files/dispatchForPort/${id}`, { date, isManualSequence, sequence, manualSequence, packingOrderNo, contractNo, tariffRegime, portCompanyName, shippingCompany, containerNo, labelNo, destination, booking, shipmentDate, shipmentBy, pilotName, licenseNo, truckPlates, platformPlates, containing, packingDate, expiryDate, dispatcher, receiver, transportedBy, custody, items })
            .then((response) => {
                return id;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/dispatchForPort/${id}`);
        }
    },

    mutations:{
        [types.STORE_PORT_DISPATCHES](state, portDispatches){
            state.portDispatches = portDispatches;
        }
    },

    getters:{
        getPortDispatchById: state => id => state.portDispatches.find(c => c.id === id),
    }
}