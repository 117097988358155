export default {
    'packaging-units': 'Unidades de empaque',
    'the-unit': 'es la unidad?',
    'add-a-unit': 'Agregar unidades',
    
    dialog: {
        'add-a-unit': 'Agregar unidades',
        'edit-an-existing-unit': 'Editar unidad',
        'unit-name': 'Nombre de la unidad',
    }
}