export default {
    users: 'Usuarios',
    'add-a-user': 'Agregar usuarios',
    'the-user': 'es el Usuario?',
    'edit-user': 'Editar usuario',
    'add-user': 'Agregar usuarios',

    'username': 'Nombre de usuario',
    'password': 'Contraseña   ',
    'confirm-password': 'Confirmar contraseña',
    'not-matched-password': 'Contraseña no coincide',
    'user-permissions': 'Permisos de usuario',
    'read-only': 'Solo para lectura',
    'read-and-write': 'Leer y escribir',

    'leave-it-empty-if-you-do-not-want-to-change-password': 'Déjalo vacío si no quieres cambiar la contraseña.'
}