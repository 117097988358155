import * as types from '../mutation-types'
export default {
    namespaced: true,

    state: {
        fiscalYears: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/CompanyFiscalYears')
            .then((response) => {
                commit(types.STORE_FISCAL_YEARS, response.data)  
            })
        },

        create({ commit }, { name, startDate, endDate }) {
            return window.axios.post('/CompanyFiscalYears', { name, startDate, endDate })
        },

        update({ commit }, { id, name, startDate, endDate }) {
            return window.axios.put(`/CompanyFiscalYears/${id}`, { name, startDate, endDate })
        },
        
        delete({ commit }, { id }) {
            return window.axios.delete(`/CompanyFiscalYears/${id}`)
        },
    },

    mutations: {
        [types.STORE_FISCAL_YEARS](state, fiscalYears) {
            state.fiscalYears = fiscalYears
        }
    },

    getters: {
        getFiscalYearById: state => id => state.fiscalYears.find(c => c.id === id)
    }
}