<template>
	<!--
		يتم قدحه عند نقر حقل الإدخال
		@event click
		@property {MouseEvent} event
	-->
	<!--
		يتم قدحه عند نقر أيقونة ما بعد حقل الإدخال
		@event click:append
		@property {Event} event
	-->
	<!--
		يتم قدحه عند نقر أيقونة ما قبل حقل الإدخال
		@event click:prepend
		@property {Event} event
	-->
	<!--
		يتم قدحه عند تحديث حالة ال validation
		@event update:error
		@property {Boolean} value - تمثل حالة وجود خطأ أم لا
	-->
	<v-input
		ref="v-input"
		v-model="localValue"
		:id="id || 'v-input'"
		:append-icon="appendIcon"
		:background-color="backgroundColor"
		:error="error || !valid"
		:error-count="errorCount"
		:error-messages="errorMessages"
		:height="height"
		:hide-details="hideDetails"
		:hide-spin-buttons="hideSpinButtons"
		:hint="hint"
		:dense="dense"
		:color="color"
		:dark="dark"
		:disabled="disabled"
		:readonly="readonly"
		:persistent-placeholder="persistentPlaceholder"
		
		:light="light"
		:loading="loading"
		:messages="messages"
		:persistent-hint="persistentHint || isFocused"
		:rules="rules"
		:success="success"
		:success-messages="successMessages"
		:validate-on-blur="validateOnBlur"
		:prepend-icon="prependIcon"

		@click="$emit('click', $event)"
		@click:append="$emit('click:append', $event)"
		@click:prepend="$emit('click:prepend', $event)"
		@update:error="$emit('update:error', $event)"

		class="bee-date-input"
		:class="{
			'bee-date-input--dense': $options.filters.isUsed(dense),
			'bee-date-input--fit': $options.filters.isUsed(fitInputs),
			'bee-date-input--remove-append': !$listeners['click:append'],
			'bee-date-input--remove-prepend':!$listeners['click:prepend'],
		}"
	>
		<!-- date menu -->
		<template
			v-if="!$slots['prepend'] && !$scopedSlots['prepend'] && $options.filters.isUsed(userMenuPicker)"
			v-slot:prepend
		>
			<bee-date-picker
				v-model="localValue"
				menu-picker
				calendar
				:color="$options.filters.isUsed(success) ? 'success' : color"
				:type="inputType"
				:years-over-max="menuPickerYearsOverMax"
				:calculate-years-over="menuPickerCalculateYearsOver"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						:color="valid 
							? isFocused 
								? $options.filters.isUsed(success) ? 'success' : color || 'primary'
								: $options.filters.isUsed(success) ? 'success' : null
							: 'error'"
						v-bind="attrs"
						v-on="on"
						@click="$emit('click:prepend', $event)"
					>{{prependIcon}}</v-icon>
				</template>
			</bee-date-picker>
		</template>
		<template v-else>
			<!-- @slot وضع عناصر في المنطقة السابقة للحقول الإدخال -->
			<slot name="prepend"></slot>
		</template>

		<!-- inputs -->
		<div
			class="bee-date-inputs"
			:class="{
				'bee-date-inputs--dense': $options.filters.isUsed(dense),
				'bee-date-inputs--fit': $options.filters.isUsed(fitInputs)
			}"
		>
			<!-- label -->
			<v-label
				v-if="label"
				absolute
				:for="id || 'v-input'"
				:focused="(isActiveValidate && !valid) || isFocused || $options.filters.isUsed(success)"
				:color="valid 
					? isFocused 
						? $options.filters.isUsed(success) ? 'success' : color || 'primary'
						: $options.filters.isUsed(success) ? 'success' : null
					: 'error'"
				:value="isActiveLabel"
				:dark="dark"
				:light="light"
				:left="$vuetify.rtl ? 'auto' : -(label.length > 5 && isActiveLabel ? label.length - 3 : 0)"
				:right="$vuetify.rtl ? -(label.length > 5 && isActiveLabel ? label.length - 3 : 0) : 'auto'"
				:disabled="disabled"
			>{{label}}</v-label>

			<!-- year -->
			<div :class="`order-${findOrderOf('year')}`">
				<!--
					يتم قدحه عند ضغط أي زر في أي حقل من حقول الإدخال
					@event keypress
					@property {KeyboardEvent} event
				-->
				<v-text-field
					v-model="year"
					ref="input-year"
					:label="!label ? yearInputOptions.label : null"
					:outlined="outlined"
					:filled="filled"
					:success="success"
					:error="!valid || error"
					:color="color"
					:dark="dark"
					:dense="dense"
					:disabled="disabled"
					:readonly="readonly"
					:height="height"
					:persistent-placeholder="persistentPlaceholder"
					:loading="loading"
					hide-details
					class="pa-0 ma-0"
					:class="[
						isFocused && valid ? `v-input--is-focused ${getColorClasses()}`: null,
						yearInputOptions.class
					]"
					:placeholder="!label ? yearInputOptions.placeholder : (isActiveLabel ? yearInputOptions.placeholder : null)"
					maxlength="4"
					:style="{
						width: getWidth(yearInputOptions.width),
						flexBasis: yearInputOptions.width && yearInputOptions.width.includes('%') ? yearInputOptions.width : null
					}"
					@keydown.up.prevent="year.length === 4 ? changeYear(1) : null"
					@keydown.down.prevent="year.length === 4 ? changeYear(-1) : null"
					@mousewheel="(e) => {
						if ($options.filters.isUsed(changeableOnScroll)) {
							e.preventDefault();
							e.wheelDelta < 0 ? changeYear(-1) : changeYear(1)
						};
					}"
					@keydown.left.prevent="year.length === 4 ? moveTo('left') : null"
					@keydown.right.prevent="year.length === 4 ? moveTo('right') : null"
					@keydown.backspace="year.length === 0 ? focusPrevious() : null"

					@focus="currentKey = 'year'; focusByOrder();"
					@paste="pasteDate($event)"
					@keypress="(e) => {$emit('keypress', e)}"
				></v-text-field>
			</div>
			
			<div v-show="isActiveLabel" class="order-2 mx-2 mt-1">{{splitSymbol}}</div>

			<!-- month -->
			<div :class="`order-${findOrderOf('month')}`">
				<v-text-field
					v-model="month"
					ref="input-month"
					:label="!label ? mounthInputOptions.label : null"
					:outlined="outlined"
					:filled="filled"
					:success="success"
					:error="!valid || error"
					:color="color"
					:dark="dark"
					:dense="dense"
					:disabled="disabled"
					:readonly="readonly"
					:height="height"
					:persistent-placeholder="persistentPlaceholder"
					:loading="loading"
					hide-details
					class="pa-0 ma-0"
					:class="[
						isFocused && valid ? `v-input--is-focused ${getColorClasses()}`: null,
						mounthInputOptions.class
					]"
					:placeholder="!label ? mounthInputOptions.placeholder : (isActiveLabel ? mounthInputOptions.placeholder : null)"
					maxlength="2"
					:style="{
						width: getWidth(mounthInputOptions.width),
						flexBasis: mounthInputOptions.width && mounthInputOptions.width.includes('%') ? mounthInputOptions.width : null
					}"
					@keydown.up.prevent="month.length === 2 ? changeMonth(1) : null"
					@keydown.down.prevent="month.length === 2 ? changeMonth(-1) : null"
					@mousewheel="(e) => {
						if ($options.filters.isUsed(changeableOnScroll)) {
							e.preventDefault();
							e.wheelDelta < 0 ? changeMonth(-1) : changeMonth(1);
						}
					}"
					@blur="month.length === 1 ? month = fixNum(month) : null"
					@keydown.left.prevent="month.length === 2 ? moveTo('left') : null"
					@keydown.right.prevent="month.length === 2 ? moveTo('right') : null"
					@keydown.backspace="month.length === 0 ? focusPrevious() : null"

					@focus="currentKey = 'month'; focusByOrder();"
					@paste="pasteDate($event)"
					@keypress="(e) => {$emit('keypress', e)}"
				></v-text-field>
			</div>
			
			<div v-show="isActiveLabel" :class="{'d-none': inputType === 'month'}" class="order-4 mx-2 mt-1">{{splitSymbol}}</div>

			<!-- day -->
			<div :class="[`order-${findOrderOf('day')}`, {'d-none': inputType === 'month'}]">
				<v-text-field
					v-model="day"
					ref="input-day"
					:label="!label ? dayInputOptions.label : null"
					:outlined="outlined"
					:filled="filled"
					:success="success"
					:error="!valid || error"
					:color="color"
					:dark="dark"
					:dense="dense"
					:disabled="disabled"
					:readonly="readonly"
					:height="height"
					:persistent-placeholder="persistentPlaceholder"
					:loading="loading"
					hide-details
					class="pa-0 ma-0"
					:class="[
						isFocused && valid ? `v-input--is-focused ${getColorClasses()}`: null,
						dayInputOptions.class
					]"
					:placeholder="!label ? dayInputOptions.placeholder : (isActiveLabel ? dayInputOptions.placeholder : null)"
					maxlength="2"
					:style="{
						width: getWidth(dayInputOptions.width),
						flexBasis: dayInputOptions.width && dayInputOptions.width.includes('%') ? dayInputOptions.width : null
					}"
					@keydown.up.prevent="day.length === 2 ? changeDay(1) : null"
					@keydown.down.prevent="day.length === 2 ? changeDay(-1) : null"
					@mousewheel="(e) => {
						if ($options.filters.isUsed(changeableOnScroll)) {
							e.preventDefault();
							e.wheelDelta < 0 ? changeDay(-1) : changeDay(1);
						}
					}"
					@blur="day.length === 1 ? day = fixNum(day) : null"
					@keydown.left.prevent="day.length === 2 ? moveTo('left') : null"
					@keydown.right.prevent="day.length === 2 ? moveTo('right') : null"
					@keydown.backspace="day.length === 0 ? focusPrevious() : null"

					@focus="currentKey = 'day'; focusByOrder();"
					@paste="pasteDate($event)"
					@keypress="(e) => {$emit('keypress', e)}"
				></v-text-field>
			</div>
		</div>

		<!-- clear icon -->
		<!--
			يتم قدحه عند ضغط زر تفريغ الحقل الإدخالات
			@event click:clear
			@property {Event} event
		-->
		<v-icon
			v-if="$options.filters.isUsed(clearable) && localValue"
			class="v-input__icon--clear"
			@click="
				localValue
				? (()=>{isActiveValidate = true; $emit('input', ''); $emit('click:clear', $event); focus();})()
				: null"
		>
			{{clearIcon}}
		</v-icon>

		<!-- default slots -->
		<template v-if="$slots['append'] || $scopedSlots['append']"  v-slot:append>
			<!-- @slot وضع عناصر في المنطقة اللاحقة لحقول الإدخال -->
			<slot name="append"></slot>
		</template>
	</v-input>
</template>

<script>
import BeeDatePicker from './BeeDatePicker.vue'

/**
 *  @example ../../docs/BeeDateInput.md
 * 	@version v1.0.0
 */
export default {
	name: 'BeeDateInput',

	props: {
		/** راجع [Vuetify v-text-field outlined](https://vuetifyjs.com/en/api/v-text-field/#props-outlined) */
		outlined: { type: Boolean, default: false },

		/** راجع [Vuetify v-text-field filled](https://vuetifyjs.com/en/api/v-text-field/#props-filled) */
		filled: { type: Boolean, default: false },

		/** تثبيت ال label في الأعلى سواءً في حال استخدام label أو inputs options */
		'persistent-placeholder': { type: Boolean, default: false },

		/** Date input model */
		value: { type: String, default: '' },

		/** إضافة زر لتفريغ حقول الإدخال */
		clearable: { type: Boolean, default: false },

		/** إضافة إمكانية تغيير قيمة الحقول باستخدام الاسهم `up`, `down` في أحد الحقول */
		'changeable-with-arrows': {
			type: Boolean,
			default: false
		},

		/** إضافة إمكانية تغيير قيمة الحقول عند ال scroll على أحد الحقول */
		'changeable-on-scroll': {
			type: Boolean,
			default: false
		},

		/**
		 * تحديد بعض خصائص حقل إدخال السنة
		 * 
		 * ```js
		 * {
		 * 	label: string,
		 * 	placeholder: string,
		 * 	width: string,
		 * 	class: string
		 * }
		 * ```
		 */
		'year-input-options': {
			type: Object,
			default: () => ({
				label: 'year',
				placeholder: 'yyyy',
				width: '40%',
				class: null
			})
		},

		/**
		 * تحديد بعض خصائص حقل إدخال للشهر
		 * 
		 * ```js
		 * {
		 * 	label: string,
		 * 	placeholder: string,
		 * 	width: string,
		 * 	class: string
		 * }
		 * ```
		 */
		'mounth-input-options': {
			type: Object,
			default: () => ({
				label: 'month',
				placeholder: 'mm',
				width: '30%',
				class: null
			})
		},

		/**
		 * تحديد بعض خصائص حقل إدخال لليوم
		 * 
		 * ```js
		 * {
		 * 	label: string,
		 * 	placeholder: string,
		 * 	width: string,
		 * 	class: string
		 * }
		 * ```
		 */
		'day-input-options': {
			type: Object,
			default: () => ({
				label: 'day',
				placeholder: 'dd',
				width: '30%',
				class: null
			})
		},

		/** تصغير حقول الإدخال إلى أدنى عرض ممكن */
		'fit-inputs': {
			type: Boolean,
			default: false
		},

		/** تحديد الرمز الفاصل بين حقول الإدخال */
		'split-symbol': {
			type: String,
			default: '-'
		},

		/** عرض date picker عند النقر على الأيقونة السابقة للحقول الإدخال */
		'user-menu-picker': {
			type: Boolean,
			default: false
		},

		/** عدد السنوات المراد إضافتها على التاريخ الحالي ليتبع لها آخر تاريخ يمكن اختياره عند استخدام **user-menu-picker***/
		'menu-picker-years-over-max': {
			type: [String, Number],
			default: undefined
		},

		/** تابع لحساب أعلى تاريخ يمكن اختياره بالاعتماد على `menu-picker-years-over-max` */
		'menu-picker-calculate-years-over': {
			type: Function,
			default: (val) => (new Date(new Date().setYear(new Date().getFullYear() + val)).toISOString().substr(0, 10))
		},

		/**
		 * تحديد ترتيب حقول الإدخال
		 * 
		 * @values
		    YYYY-MM-DD, YYYY-DD-MM,
			MM-YYYY-DD, MM-DD-YYYY,
			DD-YYYY-MM, DD-MM-YYYY,
			year-month-day, year-day-month,
			month-year-day, month-day-year,
			day-year-month, day-month-year
		 */
		'input-format-order': {
			type: String,
			default: 'YYYY-MM-DD',
			validator(val) {
				let valid = true;
				const availableFormats = [
					'YYYY-MM-DD', 'YYYY-DD-MM',
					'MM-YYYY-DD', 'MM-DD-YYYY',
					'DD-YYYY-MM', 'DD-MM-YYYY',

					'year-month-day', 'year-day-month',
					'month-year-day', 'month-day-year',
					'day-year-month', 'day-month-year'
				]
				if (!availableFormats.includes(val.toUpperCase())) {
					valid = false;
					console.error(`[Bee toolkit] (bee-date-input): input-format-order must be one of this values [${availableFormats.join(', ')}]`)
				}
				return valid;
			}
		},

		/** عند إعطاء القيمة  لجعل الإدخال فقط من أجل سنة وشهر */
		'input-type': { type: String, default: 'date' },

		// default
		
		/** راجع [Vuetify v-text-field append-icon](https://vuetifyjs.com/en/api/v-text-field/#props-append-icon) */
		'append-icon': { type: String },
		
		/** راجع [Vuetify v-text-field clear-icon](https://vuetifyjs.com/en/api/v-text-field/#props-clear-icon) */
		'clear-icon': { type: String, default: 'mdi-close' },
		
		/** راجع [Vuetify v-text-field background-color](https://vuetifyjs.com/en/api/v-text-field/#props-background-color) */
		'background-color': { type: String },
		
		/** راجع [Vuetify v-text-field color](https://vuetifyjs.com/en/api/v-text-field/#props-color) */
		color: { type: String },
		
		/** راجع [Vuetify v-text-field dark](https://vuetifyjs.com/en/api/v-text-field/#props-dark) */
		dark: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field dense](https://vuetifyjs.com/en/api/v-text-field/#props-dense) */
		dense: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field disabled](https://vuetifyjs.com/en/api/v-text-field/#props-disabled) */
		disabled: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field error](https://vuetifyjs.com/en/api/v-text-field/#props-error) */
		error: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field error-count](https://vuetifyjs.com/en/api/v-text-field/#props-error-count) */
		'error-count': { type: [String, Number], default: 1 },
		
		/** راجع [Vuetify v-text-field error-messages](https://vuetifyjs.com/en/api/v-text-field/#props-error-messages) */
		'error-messages': { type: [String, Array], default: () => [] },
		
		/** راجع [Vuetify v-text-field height](https://vuetifyjs.com/en/api/v-text-field/#props-height) */
		height: { type: [String, Number] },
		
		/** راجع [Vuetify v-text-field hide-details](https://vuetifyjs.com/en/api/v-text-field/#props-hide-details) */
		'hide-details': { type: [String, Boolean] },
		
		/** راجع [Vuetify v-text-field outlined](https://vuetifyjs.com/en/api/v-text-field/#props-hide-spin-buttons) */
		'hide-spin-buttons': { type: Boolean },
		
		/** راجع [Vuetify v-text-field hint](https://vuetifyjs.com/en/api/v-text-field/#props-hint) */
		hint: { type: String },
		
		/** راجع [Vuetify v-text-field id](https://vuetifyjs.com/en/api/v-text-field/#props-id) */
		id: { type: String },
		
		/** راجع [Vuetify v-text-field label](https://vuetifyjs.com/en/api/v-text-field/#props-label) */
		label: { type: String },
		
		/** راجع [Vuetify v-text-field light](https://vuetifyjs.com/en/api/v-text-field/#props-light) */
		light: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field loading](https://vuetifyjs.com/en/api/v-text-field/#props-loading) */
		loading: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field messages](https://vuetifyjs.com/en/api/v-text-field/#props-messages) */
		messages: { type: Array, default: () => [] },
		
		/** راجع [Vuetify v-text-field persistent-hint](https://vuetifyjs.com/en/api/v-text-field/#props-persistent-hint) */
		'persistent-hint': { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field prepend-icon](https://vuetifyjs.com/en/api/v-text-field/#props-prepend-icon) */
		'prepend-icon': { type: String },
		
		/** راجع [Vuetify v-text-field readonly](https://vuetifyjs.com/en/api/v-text-field/#props-readonly) */
		readonly: { type: Boolean, default: false },
		
		/** راجع [Vuetify v-text-field rules](https://vuetifyjs.com/en/api/v-text-field/#props-rules) */
		rules: { type: Array, default: () => [] },
		
		/** راجع [Vuetify v-text-field success](https://vuetifyjs.com/en/api/v-text-field/#props-success) */
		success: { type: Boolean, default: false },

		/** راجع [Vuetify v-text-field success-messages](https://vuetifyjs.com/en/api/v-text-field/#props-success-messages) */
		'success-messages': { type: [String, Array], default: () => [] },

		/** راجع [Vuetify v-text-field validate-on-blur](https://vuetifyjs.com/en/api/v-text-field/#props-validate-on-blur) */
		'validate-on-blur': { type: Boolean, default: false }
	},

	data() {
		return {
			year: '',
			month: '',
			day: '',
			
			isMounted: false,
			currentKey: null
		}
	},

	components: {
		BeeDatePicker
	},
		
	filters: {
		/* eslint-disable no-unneeded-ternary */
		isUsed(prop) { return typeof prop === 'string' ? true : false || prop }
		/* eslint-ensable no-unneeded-ternary */
	},

	watch: {
		year() {
			if (this.containLetter(this.year) || /^[0]+[1-9]*/g.test(this.year)) {
				this.$nextTick(() => {
					this.year = '';
				})
			} else if (this.year.length === 4 && (!this.month || !this.day)) {
				this.focusNext();
			} else if (this.isDayOutMonth) {
				this.$nextTick(() => {
					this.day = this.monthDays.find(c => c.month === this.month).days;
				})
			}
			this.updateValue();
		},
		month() {
			if (this.containLetter(this.month) || this.month === '00') {
				this.$nextTick(() => {
					this.month = '';
				})
			} else if (Number(this.month) > 12) {
				this.$nextTick(() => {
					if (/1[3-9]+/.test(this.month)) {
						this.month = '1';
					} else {
						this.month = '';
					}
				})
			} else if (this.month.length === 2) {
				if (!this.year || !this.day) {
					this.focusNext();
				} else if (this.isDayOutMonth) {
					this.$nextTick(() => {
						this.day = this.monthDays.find(c => c.month === this.month).days;
					})
				}
			}
			this.updateValue();
		},
		day() {
			if (this.containLetter(this.day) || this.day === '00') {
				this.$nextTick(() => {
					this.day = '';
				})
			} else if (this.day.length === 2 && (!this.year || !this.month)) {
				this.focusNext();
			}

			if (this.isDayOutMonth) {
				this.$nextTick(() => {
					this.day = this.monthDays.find(c => c.month === this.month).days;
				})
			}
			this.updateValue();
		},

		value(val) {
			if (val) {
				const dateParams = this.value.split('-');
				this.year = dateParams[0];
				this.month = dateParams[1];
				this.day = dateParams[2];
			} else {
				this.year = '';
				this.month = '';
				this.day = '';
				this.isActiveValidate = true;
			}
		},

		isFocused(val) {
			if (!val) {
				this.isActiveValidate = true;
				if (this.localValue) {
					if (!this.year) { this.year = this.localValue.slice(0, 4) }
					if (!this.month) { this.month = this.localValue.slice(5, 7) }
					if (!this.day) { this.day = this.localValue.slice(8) }
				}
			}
		}
	},

	computed: {
		localValue: {
			get() {
				return this.value;
			},
			set(newValue) {
				/**
				 * الحدث الخاص بتحديث التاريخ المرتبط بال v-model
				 * @event input
				 * @property {String} date
				 */
				this.$emit('input', newValue)
			}
		},

		monthDays() {
			return [
				{ month: '01', days: '31' },
				{ month: '02', days: this.leapYear(this.year) ? '29' : '28' },
				{ month: '03', days: '31' },
				{ month: '04', days: '30' },
				{ month: '05', days: '31' },
				{ month: '06', days: '30' },
				{ month: '07', days: '31' },
				{ month: '08', days: '31' },
				{ month: '09', days: '30' },
				{ month: '10', days: '31' },
				{ month: '11', days: '30' },
				{ month: '12', days: '31' }
			]
		},

		isDayOutMonth() {
			return (this.month && Number(this.day) > Number(this.monthDays.find(c => c.month === this.month).days))
		},

		valid() {
			/*
				if (this.rules.length > 0 && this.isActiveValidate) {
					for (let i = 0; i < this.rules.length; i++) {
						const rule = this.rules[i];
						if (typeof rule(this.localValue) === 'string') {
							return false;
						}
					}
					return true;
				} else {
					return true;
				}
			 */

			if (this.isActiveValidate && !this.$refs['v-input'].valid) {
				return false;
			} else {
				return true;
			}
		},

		isFocused() {
			if (
				this.isMounted && 
				(this.$refs['input-year'].isFocused ||
				this.$refs['input-month'].isFocused ||
				this.$refs['input-day'].isFocused)
			) {
				return true;
			} else {
				return false;
			}
		},

		isActiveValidate: {
			get() {
				return (this.isMounted && this.$refs['v-input'].hasFocused);
			},
			set(newValue) {
				this.$refs['v-input'].hasFocused = newValue
			}
		},

		isActiveLabel() {
			return this.isFocused || (this.year.length > 0 || this.month.length > 0 || this.day.length > 0) || this.persistentPlaceholder
		},

		inputOrder() {
			let format = this.inputFormatOrder.toLowerCase();
			if (this.inputType === 'month') {
				const tempFormat = format.split('-');
				tempFormat.splice(tempFormat.indexOf('dd'), 1)
				format = tempFormat.join('-');
				format += '-dd'
			}

			format = format.replace(/yyyy|year/g, 'year');
			format = format.replace(/mm|month/g, 'month');
			format = format.replace(/dd|day/g, 'day');

			const orders = [];
			format.split('-').forEach((key, index) => {
				let ref, order;

				/* eslint-disable indent */
				switch (key) {
					case 'year' : ref = 'input-year'; break;
					case 'month' : ref = 'input-month'; break;
					case 'day' : ref = 'input-day'; break;
				}
				switch (index) {
					case 0 : order = 1; break;
					case 1 : order = 3; break;
					case 2 : order = 5; break;
				}
				/* eslint-enable indent */
				orders.push({ ref, key, order })
			})

			return orders;
		}
	},

	methods: {
		// helpers
		leapYear(year) {
			return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
		},

		containLetter(value) {
			return /[0-9]*[a-zA-Z]+/g.test(value);
		},

		fixNum(num) {
			if (num <= 9) {
				return `0${num}`;
			} else {
				return `${num}`;
			}
		},

		getWidth(width) {
			if (this.$options.filters.isUsed(this.fitInputs)) {
				return '40px'
			} else if (Number(width)) {
				return Number(width) + 'px';
			}
		},

		getColorClasses() {
			if (this.$options.filters.isUsed(this.color)) {
				const colors = this.color.split(' ');
				const classes = [];
				colors.forEach(color => {
					if (color.includes('darken') || color.includes('lighten')) {
						classes.push(`text--${color}`)
					} else {
						classes.push(`${color}--text`);
					}
				})
				return classes.join(' ');
			}
		},

		pasteDate(event) {
			const paste = (event.clipboardData || window.clipboardData).getData('text');
			const pasteArr = paste.split('-');
			let year, yearOrder, month, monthOrder, day, dayOrder;

			if (pasteArr.length === 3) {
				yearOrder = this.inputOrder.find(c => c.key === 'year').order
				monthOrder = this.inputOrder.find(c => c.key === 'month').order
				dayOrder = this.inputOrder.find(c => c.key === 'day').order

				year = pasteArr[yearOrder === 5 ? yearOrder - 3 : (yearOrder === 3 ? yearOrder - 2 : 0)];
				month = pasteArr[monthOrder === 5 ? monthOrder - 3 : (monthOrder === 3 ? monthOrder - 2 : 0)];
				day = pasteArr[dayOrder === 5 ? dayOrder - 3 : (dayOrder === 3 ? dayOrder - 2 : 0)];

				// console.log(year, month, day)
				// console.log(yearOrder, monthOrder, dayOrder)
				// console.log(yearOrder === 5 ? yearOrder - 3 : (yearOrder === 3 ? yearOrder - 2 : 0), monthOrder === 5 ? monthOrder - 3 : (monthOrder === 3 ? monthOrder - 2 : 0), dayOrder === 5 ? dayOrder - 3 : (dayOrder === 3 ? dayOrder - 2 : 0))

				if (this.inputType === 'month') {
					this.localValue = `${year}-${month}`;
				} else {
					this.localValue = `${year}-${month}-${day}`;
				}
			}
		},

		// focus && input order
		findOrderOf(key) {
			const item = this.inputOrder.find(c => c.key === key);
			if (item) {
				return item.order;
			} else {
				return null;
			}
		},

		focusByOrder() {
			const input = this.prevInputOrder();
			
			if (input && this[input.key].length === 0) {
				this.$refs[input.ref].focus()
			}
		},

		nextInputOrder() {
			const currentOrder = this.findOrderOf(this.currentKey);
			let nextOrder;
			
			if (currentOrder === 1) {
				nextOrder = 3;
			} else if (currentOrder === 3) {
				nextOrder = 5;
			} else {
				return;
			}
			return this.inputOrder.find(c => c.order === nextOrder);
		},

		prevInputOrder() {
			const currentOrder = this.findOrderOf(this.currentKey);
			let prevOrder;

			if (currentOrder === 5) {
				prevOrder = 3;
			} else if (currentOrder === 3) {
				prevOrder = 1;
			} else {
				return;
			}
			return this.inputOrder.find(c => c.order === prevOrder);
		},

		focusNext() {
			const order = this.nextInputOrder();
			if (order) {
				const nextInput = this.$refs[order.ref];
				nextInput.focus();
				nextInput.$el.querySelector('input').select();
			}
		},

		focusPrevious() {
			const order = this.prevInputOrder();
			if (order) {
				const prevInput = this.$refs[order.ref];
				prevInput.focus();
				prevInput.$el.querySelector('input').select();
			}
		},

		// move with arrow
		moveTo(direction) {
			if (direction === 'left') {
				/**
				 * يتم قدحه عن نقر زر السهم الأيسر
				 * @event move-left
				 */
				this.$emit('move-left');
				if (!this.$vuetify.rtl) {
					this.focusPrevious();
				} else {
					this.focusNext();
				}
			} else if (direction === 'right') {
				/**
				 * يتم قدحه عن نقر زر السهم الأيمن
				 * @event move-right
				 */
				this.$emit('move-right');
				if (!this.$vuetify.rtl) {
					this.focusNext();
				} else {
					this.focusPrevious();
				}				
			}
		},

		// change functions with arrow or scroll
		canChange() {
			return (
				!this.$options.filters.isUsed(this.readonly) &&
				!this.$options.filters.isUsed(this.disabled) &&
				(
					this.$options.filters.isUsed(this.changeableWithArrows) ||
					this.$options.filters.isUsed(this.changeableOnScroll)
				)
			)
		},

		changeYear(num) {
			if (this.canChange()) {
				if ((Number(this.year) + num) >= 1000 && (Number(this.year) + num) <= 9999) {
					this.year = `${Number(this.year) + num}`;
				}
			}
		},
		changeMonth(num) {
			if (this.canChange()) {
				if ((Number(this.month) < 12 && num > 0) || (Number(this.month) > 1 && num < 0)) {
					this.month = this.fixNum(Number(this.month) + num);
				}
			}
		},
		changeDay(num) {
			if (this.canChange()) {
				if (this.month) {
					if (
						(Number(this.day) < Number(this.monthDays.find(c => c.month === this.month).days) && num > 0) ||
						(Number(this.day) > 1 && num < 0)
					) {
						this.day = this.fixNum(Number(this.day) + num);
					}
				}
			}
		},

		// value
		updateValue() {
			let date;
			if (this.inputType === 'month') {
				date = `${this.year}-${this.month}`;
				if (/[1-9][0-9]{3}-((0[1-9])|(1[0-2]))/g.test(date)) {
					this.localValue = date;
					this.$emit('change', date);
				} else if (date === '-') {
					this.localValue = '';
				}
			} else {
				date = `${this.year}-${this.month}-${this.day}`;
				if (/[1-9][0-9]{3}-((0[1-9])|(1[0-2]))-((0[1-9])|([1-3][0-9]))/g.test(date)) {
					this.localValue = date;
	
					/**
					 * يتم قدحه عندما يتم تغير التاريخ من المستخدم
					 * @event change
					 * @property {String} date - قيمة التاريخ الجديدة
					 */
					this.$emit('change', date);
				} else if (date === '--') {
					this.localValue = '';
				}
			}
		},

		// out helpers
		focus() {
			this.$refs[this.inputOrder.find(c => c.order === 1).ref].focus();
		},
		validate() {
			this.isActiveValidate = true;
			return this.$refs['v-input'].valid;
		},
		restValidation() {
			this.isActiveValidate = false;
		}
	},

	mounted() {
		this.isMounted = true;
		if (this.value) {
			const dateParams = this.value.split('-');
			this.year = dateParams[0];
			this.month = dateParams[1];
			this.day = dateParams[2];
		}
	}
}
</script>

<style lang="scss">
.bee-date-input {
	.bee-date-inputs{
		display: flex;
		flex-grow: 1;

		padding-top: 12px;
		margin-top: 4px;
		
		&--dense{
			padding-top: 0;
		}
		&--fit{
			flex-grow: 0;
		}
	}

	.v-input {
		&__prepend-outer {
			outline: none;
			margin-top: 20px;
		}
		&__slot .bee-date-inputs {
			position: relative;
			> .v-label {
				padding-top: 6px;
				&--active {
					max-width: 133%;
					transform: translate(-3px, -18px) scale(0.75);
					pointer-events: auto;

					[class*="v-application--is-rtl"] &,  [dir="rtl"] &{
						transform: translate(3px, -18px) scale(0.75);
					}
				}
			}
		}
		&__icon	{
			&--clear, &--append {
				margin-top: 16px;
			}
		}
		
	}
	&--dense{
		.v-input{
			&__prepend-outer{
				margin-top: 4px;
			}
			&__icon {
				&--clear, &--append {
					margin-top: 0px;
				}
			}
		}
	}
	&--fit{
		display: inline-flex;
	}

	&--remove-append {
		.v-input{
			&__icon	{
				&--append {
					button {
						cursor: unset !important;
						&:focus::after {
							opacity: 0;
						}
					}
				}
			}
		}
	}
	&--remove-prepend {
		.v-input{
			&__icon	{
				&--prepend {
					button {
						cursor: unset !important;
						&:focus::after {
							opacity: 0;
						}
					}
				}
			}
		}
	}
}
</style>