import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        salesContracts: []
    },

    actions:{
        fetchAll({ commit }, { sequence, buyer, seller, date }){
            return window.axios.get('/files/salesContracts', {
                params:{
                    sequence, buyer, seller, date
                }
            })
            .then((response) => {
                commit(types.STORE_SALES_CONTRACTS, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/salesContracts/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, destination, packing, shipping, intermediaryBank, documents, wireTransfer, instructions, goodsDescription, quantityNote, insurance, tax, transactions, bankCountry }){
            return window.axios.post('/files/salesContracts', { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, destination, packing, shipping, intermediaryBank, documents, wireTransfer, instructions, goodsDescription, quantityNote, insurance, tax, transactions, bankCountry })
            .then((response) => {
                return response.data;
            });
        },

        update({ commit }, { id, isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, destination, packing, shipping, intermediaryBank, documents, wireTransfer, instructions, goodsDescription, quantityNote, insurance, tax, transactions, bankCountry }){
            return window.axios.put(`/files/salesContracts/${id}`, { isManualSequence, manualSequence, sequence, currency, date, buyer, seller, paymentTerms, shippingMarks, showBankAccount, bankName, address, accountNumber, swiftCode, iban, branch, destination, packing, shipping, intermediaryBank, documents, wireTransfer, instructions, goodsDescription, quantityNote, insurance, tax, transactions, bankCountry })
            .then((response) => {
                return id;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/salesContracts/${id}`)
        },
    },

    mutations:{
        [types.STORE_SALES_CONTRACTS](state, salesContracts){
            state.salesContracts = salesContracts;
        }
    },

    getters:{
        getSalesContractsById: state => id => state.salesContracts.find(c => c.id === id)
    }
}