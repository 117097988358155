export default {
    'management': 'Gestión',
    'documents': 'documentos',
    'files-settings': 'Ajustar archivos',
    'customers-and-suppliers': 'Clientes y proveedores',
    'items-and-materials': 'Articulos y materiales',
    'sales-contracts': 'Contratos de ventas',
    'proforma-invoices': 'Facturas proforma',
    'commercial-invoices': 'Factura comercial',
    'packing-lists': 'Listas de empaque',
    'shipping-advises': 'Avisos de embarque',
    'technical-and-financial-offers': 'Ofertas técnicas y económicas',
}