export default {
    'select-company': 'اختر شركة',
    'switch-company': 'تبديل الشركة',
    'language': 'اللغة',
    'settings': 'الإعدادات',
    'manage-companies': 'إدارة الشركات',
    'manage-users': 'إدارة المستخدمين',
    'change-password': 'تغيير كلمة المرور',
    'manage-currencies': 'إدارة العملات',
    'view-templates': 'عرض القوالب',
    'signout': 'تسجيل خروج',
    'available-in-next-version': 'متوفر في الإصدار القادم',

    dialog: {
        'change-password': 'تغيير كلمة المرور ',
        'old-password': 'كلمة المرور القديمة',
        'new-password': 'كلمة المرور الجديدة',
        'confirm-new-password': 'تأكيد كلمة المرور الجديدة',
    }
}