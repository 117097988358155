export default {
    'audit-log' : 'Audit log',
    'of-$': 'of {username}',
    'last-login': 'Last login:',
    'action-name': {
        create: 'Create', 
        modify: 'Modify', 
        delete: 'Delete', 
        login: 'Login to system',
        'login-to-company': 'Login to company',
    } 
}