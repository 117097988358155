<template>
	<v-dialog
		v-model="localValue"
		width="800"
		persistent
	>
		<v-form ref="form" @submit.prevent="emitData">
			<v-card>
				<!-- dialog title -->
				<v-card-title class="primary white--text justify-center py-3">
					<v-spacer/>
					<span class="text-h6 ms-3">
						{{ !isEdit ? $t('add') : $t('edit') }} {{$t('affair')}}
					</span>
					<v-spacer/>
					<v-btn text small fab @click="localValue = false" color="white">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text class="py-2">
					<v-row class="ma-0">
						<!-- title -->
						<v-col sm="6" cols="12">
							<v-text-field
								v-model="title"
								:label="$t('forms.title')"
								dense
								hide-details="auto"
								:rules="rules.required"
							></v-text-field>
						</v-col>
					</v-row>
					<v-form ref="entryForm" @submit.prevent="isToEditEntry ? editEntry() : addEntry()">
						<v-row class="ma-0">
							<!-- day -->
							<v-col md="1" sm="4" cols="12">
								<v-autocomplete
									v-model="entryData.day"
									:label="$t('headers.day')"
									:items="days"
									item-text="name"
									item-value="name"
									ref="dayInput"
									dense
									append-icon
									hide-details="auto"
								></v-autocomplete>
							</v-col>

							<!-- quantity -->
							<v-col md="2" sm="4" cols="12">
								<v-text-field
									v-model.number="entryData.quantity"
									:label="$t('headers.quantity')"
									dense
									hide-details
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>

							<!-- price -->
							<v-col md="1" sm="4" cols="12">
								<v-currency-text-field
									v-model="entryData.price"
									:label="$t('headers.price')"
									dense
									hide-details
								></v-currency-text-field>
							</v-col>

							<!-- description -->
							<v-col md="auto" sm="4" cols="12" class="flex-grow-1">
								<v-text-field
									v-model="entryData.description"
									:label="$t('headers.description')"
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<v-col cols="auto">
								<!-- add button -->
								<v-btn
									text
									small
									class="align-self-center"
									type="submit"
								>
									<v-icon>
										{{ 
											isToEditEntry ? 'mdi-checkbox-marked-circle-outline' : 'mdi-plus-circle-outline'
										}}
									</v-icon>
								</v-btn>

								<!-- reset button -->
								<v-btn
									@click="$refs.entryForm.reset()"
									text
									small
									class="align-self-center"
									elevation="1"
								>
									{{$t('reset')}}
								</v-btn>
							</v-col>
						</v-row>
					</v-form>

					<!-- entries table -->
					<v-data-table
						:items="entries"
						disable-pagination
						hide-default-footer
						disable-sort
						no-data-text="No items were added."
						:headers="headers"
						dense
						class="elevation-1 mt-3"
					>
						<!-- day -->
						<template v-slot:item.data.day="{item}">
							{{item.data.day ? item.data.day : '-'}}
						</template>
						
						<!-- quantity -->
						<template v-slot:item.data.quantity="{item}">
							{{item.data.quantity ? item.data.quantity : '-'}}
						</template>
						
						<!-- price -->
						<template v-slot:item.data.price="{item}">
							{{item.data.price ? item.data.price : '-'}}
						</template>
						
						<!-- description -->
						<template v-slot:item.data.description="{ item }">
							{{
								item.data.description
								? item.data.description.length > 60
									? item.data.description.slice(0, 60) + '...'
									: item.data.description
								: '-'
							}}
						</template>

						<!-- total -->
						<template v-slot:item.total="{ item }">
							{{ item.data.quantity * item.data.price | currency }}
						</template>
						
						<!-- actions -->
						<template v-slot:item.actions="{ item }">
							<tooltip text="Edit item">
								<v-btn
									text
									small
									@click="fillToEdit(item.id)"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</tooltip>
							
							<tooltip text="Remove item">
								<v-btn
									text
									small
									@click="removeEntry(item.id)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</tooltip>
						</template>
					</v-data-table>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary lighten-2"
						class="px-6"
						text
						type="submit"
					>
						{{ !isEdit ? $t('add') : $t('edit') }}
					</v-btn>
					<v-btn
						class="px-6"
						text
						@click="localValue = false; $refs.form.reset();"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import days from '../enums/days'
import rules from '../validation rules';
import Tooltip from './Tooltip.vue';
export default {
	props: {
		item: { type: Object },
		value: { type: Boolean }
	},

	components: {
		Tooltip
	},

	data: () => ({
        // helpers
		isToEditEntry: false,
		editEntryNo: null,
		rowId: 0,

		days,
		rules,
		
		// DATA
		title: null,
		entryData: {
			day: null,
			quantity: null,
			price: null,
			description: null,
		},
		
		entries: [],
	}),

	watch: {
		value(val) {
			if (val) {
				if (this.isEdit) {
					this.title = this.item.title;
					this.item.entries.forEach(entry => {
						this.entries.push({
							id: this.rowId++,
							data: entry
						});
					});
				}
			} else {
				this.reset();
			}
		}
	},

	methods: {
		emitData() {
			if (this.$refs.form.validate()) {
				const data = { title: this.title, entries: this.entries.map(c => c.data) };
				if (this.isEdit) {
					this.$emit('update-entry', data);
					this.localValue = false;
				} else {
					this.$emit('add-entry', data);
					this.reset();
				}
			}
		},

		addEntry() {
			if (this.$refs.entryForm.validate()){
				this.entries.push({
					id: this.rowId++,
					data: { ...this.entryData }
				});
				this.$refs.entryForm.reset();
				this.$nextTick(() => {
					this.$refs.dayInput.focus();
				})
			}
		},

		editEntry() {
			this.entries[this.editEntryNo].data = { ...this.entryData };
			this.isToEditEntry = false;
			this.editEntryNo = null;
			this.$refs.entryForm.reset();
		},

		removeEntry(index) {
			this.entries.splice(index, 1);

			for (let i = 0; i < this.entries.length; i++) {
				if (this.entries[i].id > index) {
					this.entries[i].id--;
				}
			}
			this.rowId--;
		},

		fillToEdit(id) {
			this.isToEditEntry = true;
			this.editEntryNo = id;
			const item = this.entries.find(c => c.id === id);
			this.entryData = { ...item.data }
		},

		reset() {
			this.$refs.form.reset();
			this.$refs.entryForm.reset();
			this.isToEditEntry = null;
			this.editEntryNo = null;
			this.rowId = 0;
			this.entries = [];
		}
	},

	computed: {
		...mapState({
			viewerOnly: state => state.users.viewerOnly,
		}),

		localValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		},

		headers() {
			const headers = [
                { text: this.$t('headers.day'), value:'data.day', align:'center', class:'primary--text', cellClass:'primary--text', width: 60 },
                { text: this.$t('headers.quantity'), value:'data.quantity', align:'center', width: 60, },
                { text: this.$t('headers.price'), value:'data.price', align:'center', width: 40, },
                { text: this.$t('headers.description'), value:'data.description', align:'center' },
                { text: this.$t('headers.total'), value:'total', align:'center' },
				{ text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]

            if (this.viewerOnly || this.isToEditEntry) {
                return headers.slice(0, headers.length - 1)
            }

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2';
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';
			}
            return headers;
		},

		isEdit() {
			return Boolean(this.item);
		}
	}
}
</script>

<style>

</style>