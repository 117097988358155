<template>
    <div>
        <div class="d-flex justify-space-between align-center mb-5">
            <h1 class="text-h5 primary--text">{{$t('Companies.companies')}}</h1>

            <div>
                <!-- add button -->
                <v-btn text small class="lightPrimary white--text" @click="$refs.CompanyDialog.addCompany()">
                    {{$t('Companies.add-a-company')}}
                </v-btn>
                
                <!-- back button -->
                <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    elevation="1"
                    small
                    class="ms-4 px-6"
                    @click="backToLastCompanyContext"
                >
                    {{$t('back')}}
                </v-btn>
            </div>
        </div>

        <!-- delete-dialog is decleared here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="fetchCompanies">
            <span class="text-body-1">
                {{$t('are-you-sure-you-want-to')}} 
                <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                {{$t('Companies.the-company')}}
            </span>
        </delete-dialog>
        
        <!-- company-dialog declared here to use it as ref -->
        <company-dialog ref="CompanyDialog" @on-save="fetchCompanies"/>

        <!-- financial-account-dailog declared here to use it as ref -->
        <financial-account-dailog ref="FinancialAccountDailog"/>
        
        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : companies"
          :items-per-page="5"
          :loading="loading"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          dense
          class="elevation-2"
        >
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Download information" v-if="false">
                        <v-btn text small>
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip :text="$t('tooltips.bank-accounts')">
                        <v-btn text small @click="$refs.FinancialAccountDailog.activeDialog(item.id)">
                            <v-icon>
                                mdi-credit-card-multiple-outline
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip :text="$t('tooltips.edit')">
                        <v-btn
                            text
                            small
                            @click="$refs.CompanyDialog.editCompany(item.id)"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip v-if="false" :text="$t('tooltips.delete')">
                        <v-btn
                        	text
                        	small
                            @click="$refs.DeleteDialog.activeDialog(item.id, 'companies')"
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import CompanyDialog from '../../components/CompanyDialog'
import DeleteDialog from '../../components/DeleteDialog'
import FinancialAccountDailog from '../../components/FinancialAccountDailog'
import Tooltip from '../../components/Tooltip'
import { mapState } from 'vuex'
import { backToLastCompanyContext } from '../../helpers'
export default {
    data: () => ({
        // helpers
        loading: true,
        isFiltered: false,
    }),

    components:{
        CompanyDialog,
        DeleteDialog,
        FinancialAccountDailog,
        Tooltip,
    },

    computed:{
        ...mapState({
            companies: state => state.companies.companies
        }),
        headers() {
            return [
                { text: this.$t('headers.company'), value:'name', align:'start', sortable: false, class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.tax-no'), value:'taxNumber', align:'center', sortable: false },
                { text: this.$t('headers.manager'), value:'managerName', align:'center', sortable: false },
                { text: this.$t('headers.financial-manager'), value:'financialManagerName', align:'center', sortable: false },
                { text: this.$t('headers.actions'), value:'actions', align:'center', sortable: false },
            ]
        },
    },

    methods:{
        fetchCompanies(){
            this.loading = true;
            this.$store.dispatch('companies/fetchAll')
            .finally(() => {
                this.loading = false;
            })
        },

        // helpers
        backToLastCompanyContext
    },

    mounted(){
        this.fetchCompanies();
    }
}
</script>

<style>

</style>