<template>
	<div>
        <div class="d-flex flex-wrap align-center justify-space-between">
            <h1 class="text-h6 primary--text">
                {{
                    `${$t('AuditLog.audit-log')} ${
                        $t('AuditLog.of-$', {
                            username: 
                            getUserById($route.params.userId)
                            ? getUserById($route.params.userId).username
                            : '-' 
                        })
                    }`
                }}
            </h1>
            
            <v-spacer/>

            <p class="text-body-1 primary--text mb-0">
                {{$t('AuditLog.last-login')}}
                <v-progress-circular v-if="lastLoginLoading" indeterminate width="2" size="15"></v-progress-circular>
                <span v-else class="font-weight-bold">{{lastLogin ? lastLogin : '-'}}</span>
            </p>

            <!-- back button -->
            <v-btn
            	v-if="$vuetify.breakpoint.mdAndUp"
            	:to="{ name:'users' }"
            	elevation="1"
            	small
            	class="ms-4 px-6"
            >
                {{$t('back')}}
            </v-btn>
        </div>
        
        <!-- filter -->
        <v-form class="my-2" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text">
                    {{$t('filter')}}
                </v-col>

                <!-- comany -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-autocomplete
                        v-model="companyId"
                        :label="$t('headers.company')"
                        :items="companies"
                        item-text="name"
                        item-value="id"
                        :return-object="false"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-autocomplete>
                </v-col>

                <!-- from date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfFromDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fromDate"
                                :label="$t('from-date')"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="fromDate"
                            :max="new Date((Date.now()*2) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfFromDate.save(fromDate)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- to date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfToDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="toDate"
                                :label="$t('to-date')"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="toDate"
                            :max="new Date((Date.now()*2) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfToDate.save(toDate)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        class="px-sm-4"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        text
                        class="px-sm-4"
                        @click="resetFilter(); filter();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : items"
          :items-per-page="20"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-options': [10, 20, 40, 100],
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage: true
          }"
          :options.sync="options"
          :server-items-length="totalItems"
          disable-sort
          :loading="loading"
          dense
          class="elevation-2"
        >
            <!-- date -->
            <template v-slot:item.date="{ item }">
                {{moment(item.date)}}
            </template>

            <!-- company -->
            <template v-slot:item.company="{ item }">
                {{getCompanyById(item.companyId) ? getCompanyById(item.companyId).name : '-'}}
            </template>

            <!-- context -->
            <template v-slot:item.context="{ item }">
                {{getFileName(item.context)}}
            </template>

            <!-- action -->
            <template v-slot:item.action="{ item }">
                <span :class="getColor(item)">
                    {{getActionName(item)}}
                </span>
            </template>

            <!-- preview -->
            <!--
            <template v-slot:item.preview="{ item }">
                <tooltip text="preview">
                    <v-btn
                    	text
                    	:disabled="item.action === 2"
                    	:to="getRoute(item.companyId, item.fileType, item.fileId)"
                    >
                        <v-icon>
                            {{ item.action !== 2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
                        </v-icon>
                    </v-btn>
                </tooltip>
            </template>
            -->
            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>

    </div>
</template>

<script>
// import fileTypes from '../../../enums/file-types';
import moment from 'moment'
import { mapGetters, mapState } from 'vuex';
export default {
    data: () => ({
        // helpers
        loading: false,
        
        lastLogin: null,
        lastLoginLoading: null,
        dateFormat: 'DD-MM-YYYY',
        
        items: [],

        isFiltered: false,
        filterLoading: false,

        // pagination
        options: {},
        totalItems: 20,

        // filter
        companyId: null,
        fromDate: null,
        toDate: null,
    }),

    watch: {
        options() {
            this.filter();
        },
    },

    methods: {
        getColor(item) {
            switch (item.action) {
                case 'Create': return 'success--text';
                case 'Modify': return 'warning--text text--lighten-1';
                case 'Delete': return 'error--text';
                case 'Login': return item.companyId ? 'primary--text' : null
            }
        },

        getActionName(item) {
            switch (item.action) {
                case 'Create': return this.$t('AuditLog.action-name.create');
                case 'Modify': return this.$t('AuditLog.action-name.modify');
                case 'Delete': return this.$t('AuditLog.action-name.delete');
                case 'Login':
                    return (
                        item.companyId
                        ? this.$t('AuditLog.action-name.login-to-company')
                        : this.$t('AuditLog.action-name.login')
                    )
            }
        },

        getFileName(fileType) {
            switch (fileType) {
                case 'SalesContracts': return this.$t('file-types.sales-contract');
                case 'Invoices': return this.$t('file-types.proforma-invoice');
                case 'CommercialInvoices': return this.$t('file-types.commercial-invoice');
                case 'PackingLists': return this.$t('file-types.packing-list');
                case 'ShippingAdvices': return this.$t('file-types.shipping-advise');
                case 'PackingOrders': return 'Ordenes de empaque';
                case 'ProductReleaseOrders': return 'Ordenes de salida';
                case 'Fumigation': return this.$t('file-types.fumigation-certificate');
                case 'Health': return this.$t('file-types.health-certificate');
                case 'WeightAndQuality': return this.$t('file-types.weight-and-quality-certificate');
                case 'DispatchForPort': return 'Despacho a Puerto';
                default: return '-'
            }
        },

        /*
        getRoute(companyId, fileType, id) {
            switch (fileType) {
                case fileTypes.salesContract:
                    return { name: 'sales-contract', params: { companyId }, query: { id } }
                case fileTypes.invoice:
                    return { name: 'packing-list', params: { companyId }, query: { id } } 
                case fileTypes.commercialInvoice:
                    return { name: 'invoice', params: { companyId }, query: { id } } 
                case fileTypes.packingList:
                    return { name: 'commercial-invoice', params: { companyId }, query: { id } } 
                case fileTypes.shippingAdvise:
                    return { name: 'shipping-advise', params: { companyId }, query: { id } } 
                case fileTypes.packingOrder:
                    return { name: 'packing-order', params: { companyId }, query: { id } } 
                case fileTypes.productReleaseOrder:
                    return { name: 'product-release-order', params: { companyId }, query: { id } } 
                case fileTypes.fumigationCertificate:
                    return { name: 'fumigation-certificate', params: { companyId }, query: { id } } 
                case fileTypes.healthCertificate:
                    return { name: 'health-certificate', params: { companyId }, query: { id } } 
                case fileTypes.weightAndQualityCertificate:
                    return { name: 'weight-and-quality-certificate', params: { companyId }, query: { id } } 
                case fileTypes.portDispatch:
                    return { name: 'port-dispatch', params: { companyId }, query: { id } } 
            }
        },
        */

        resetFilter() {
            this.isFiltered = false;
            this.companyId = null;
            this.fromDate = null;
            this.toDate = null;
        },

        filter() {
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            this.$store.dispatch('users/fetchLogs', {
                userId: this.$route.params.userId,
                companyId: this.companyId,
                fromDate: this.fromDate,
                toDate: this.toDate,
                page,
                perPage: itemsPerPage
            })
            .then((data) => {
                this.totalItems = data.totalCount;
                this.items = data.usersLog;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // helpers
        moment(date) {
            return (
                moment(date).format(this.dateFormat + ', hh:mm A')
                .replace(/AM|PM/, (matched) => this.$i18n.locale === 'ar' ? ({ AM: 'ص', PM: 'م' })[matched] : matched)
            )
        },
    },

    computed: {
        isAllFieldsEmpty() {
            return !this.companyId && !this.fromDate && !this.toDate
        },

        headers() {
            return [
                { 
                    text: this.$t('headers.date-and-time'), value: 'date', align: 'center', class: 'primary--text', cellClass: 'primary--text', width: '25%'
                },
                { text: this.$t('headers.company'), value: 'company', align: 'center', width: '25%' },
                { text: this.$t('headers.file-context'), value: 'context', align: 'center', width: '25%' },
                { text: this.$t('headers.activity'), value: 'action', align: 'center', class: 'primary--text', width: '25%' },
            ]
        },

        ...mapState({
            companies: state => state.companies.companies,
            users: state => state.users.users,
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById',
            getUserById: 'users/getUserById',
        })
    },

    created() {
        if (this.users.length !== 0) {
            this.lastLogin = 
                this.getUserById(this.$route.params.userId)
                ? this.getUserById(this.$route.params.userId).lastLogin
                    ? this.moment(this.getUserById(this.$route.params.userId).lastLogin)
                    : '-'
                : '-'
        }
        else {
            this.lastLoginLoading = true;
            Promise.all([
                    this.$store.dispatch('users/fetchAll'),
                this.$store.dispatch('users/fetchLastLogin', { id: this.$route.params.userId })
                .then((data) => {
                    this.lastLogin = data ? this.moment(data) : null;
                })
            ])
            .finally(() => {
                this.lastLoginLoading = false;
            })
        }
    }
};
</script>

<style>
</style>