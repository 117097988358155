export default {
    namespaced: true,
    state: {
    },

    actions: {
        fetch({ commit }, { fileType }){
            return window.axios.get('/threePartSequences', {
                params: {
                    fileType
                }
            }).then((response) => {
                return response.data;
            });
        },

        reserve({ commit }, { sequence, fileType, overrideCollision, isManual }){
            if (sequence == null) {
                return Promise.resolve();
            }

            return window.axios.post(`/${(isManual ? 'sequences' : 'threePartSequences')}/reserve`, { 
                sequence,
                fileType,
                overrideCollision
            });
        },
    },

    mutations: {
    },

    getters: {
    }
}