export default {
    'files-settings': 'إعدادات الملفات',
    'serial-sequences-for-system-files': 'نمط ترميز مستندات الشركة',
    
    'dynamic-part-length': 'طول الجزء المتغير',
    'dynamic-part-will-be-left-padded-with-zeroes': 'ستتم محاذاة الجزء المتغير إلى اليسار بإضافة الأصفار',
    
    'proforma-invoices-sequence-beginning': 'بداية تسلسل الفواتير الأولية',
    'sales-contracts-sequence-beginning': 'بداية تسلسل عقود البيع',
    'commercial-invoices-sequence-beginning': 'بداية تسلسل الفواتير التجارية',
    'orden-de-empaque-sequence-beginning': 'بداية تسلسل Orden de empaque',
    'orden-de-salida-sequence-beginning': 'بداية تسلسل Orden de salida',
    'despacho-a-puerto-sequence-beginning': 'بداية تسلسل Despacho a puerto',
    'technical-and-financial-offers-sequence-beginning': 'بداية تسلسل العروض الفنية والمالية',
    
    'current-fiscal-year': 'السنة المالية الحالية',
    'start-date': 'تاريخ البداية',
    'end-date': 'تاريخ النهاية',

    'fiscal-years': 'السنوات المالية',
    'add-fiscal-year': 'إضافة سنة مالية',
    'no-filter': 'لم يتم اختيار أي سنة مالية. سيتم عرض جميع الملفات',
    to: 'إلى',
    'the-period': 'السنة المالية',
    
    'automatic-calculations': 'الحقول المحتسبة',
    'tax-calculation': 'حساب الضريبة',

    percentage: 'نسبة مئوية',
    'fixed-amount': 'مبلغ ثابت',
    'value': 'القيمة',
    'tax-label': 'التسمية الضريبية',
    'a-taxed-commercial-invoice-becomes-a-tax-invoice': 'تسمية الفواتير التجارية ذات الضريبة بـ"Tax invoice"',
    'date-format': 'صيغة التاريخ',
    'format': 'الصيغة',
    
    'current-files-images': 'صور الملفات الحالية ',
    'manage-company-files-template': 'إدارة قالب ملفات الشركة',
    'manage-company-files-container': 'تغيير صورة الحاوية',
    'use': 'استخدام',
    'upload-new-template': 'تحميل قالب جديد',
    'upload-new-container': 'تحميل صورة قالب جديدة',
    'upload-image': 'تحميل صورة',
    'drag-text': 'اسحب وأفلت صورة هنا',
    'drop-text': 'أفلت الصورة هنا',
    'error-message': 'مسموح بالصور فقط',

    selected: 'تم اختيارها',
    upload: 'تحميل',
    'upload-and-change': 'تحميل وتبديل',
    uploaded: 'تم تحميل الصورة بنجاح',
    'delete-the-image': 'حذف الصورة؟',
    template: 'قالب',
    container: 'حاوية',
    'there-is-no-templates': 'لا يوجد قوالب',
    'there-is-no-containers': 'لا يوجد حاويات',
    'template-image-note': `
        متطلبات صورة القالب: <br>
        العرض: 11654px، الارتفاع 2339px، <br>
        الدقة: 200dpi
    `,
    'container-image-note': `
        متطلبات صورة الحاوية: <br> 
        العرض: [915-970] px، الارتفاع [508-560] px
    `,
}