<template>
    <v-text-field
        v-model="currencyFormatted"
        @change="$emit('input', value)"
        :disabled="disabled"
        :hide-details="hideDetails"
        :outlined="outlined"
        :dense="dense"
        :label="label"
        :rules="required ? [val => /^(\d{1,3},)*\d{1,3}(\.\d*)?$/g.test(val) || 'This field is required.'] : []"
        @blur="$emit('blur')"
    ></v-text-field>
</template>

<script>
    export default {
        props: {
            value: {},
            disabled: { type: Boolean },
            outlined: { type: Boolean },
            label: { type: String },
            dense: { type: Boolean },
            required: { type: Boolean, default: true },
            'hide-details': { type: [Boolean, String] },
        },

        data: () => ({
            valid: true,
        }),

        methods: {
            formatAsCurrency (value, dec) {
                dec = dec || 0
                if (value === null) {
                    return null;
                }
                return this.$options.filters.currency(value);
            },
        },

        computed: {
            currencyFormatted: {
                get: function() {
                    return this.formatAsCurrency(this.value, 0)
                },
                set: function(newValue) {
                    this.$emit('input', newValue ? Number(newValue.replace(/[^0-9.]/g, '')) : '');
                }
            }
        }
    }
</script>
