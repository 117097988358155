export default {
    'files-settings': 'Files Settings',
    'serial-sequences-for-system-files': 'Serial sequences for system files',
    
    'dynamic-part-length': 'Dynamic part length',
    'dynamic-part-will-be-left-padded-with-zeroes': 'Dynamic part will be left-padded with zeroes.',
    
    'proforma-invoices-sequence-beginning': 'Proforma invoices sequence beginning',
    'sales-contracts-sequence-beginning': 'Sales contracts sequence beginning',
    'commercial-invoices-sequence-beginning': 'Commercial invoices sequence beginning',
    'orden-de-empaque-sequence-beginning': 'Orden de empaque sequence beginning',
    'orden-de-salida-sequence-beginning': 'Orden de salida sequence beginning',
    'despacho-a-puerto-sequence-beginning': 'Despacho a puerto sequence beginning',
    'technical-and-financial-offers-sequence-beginning': 'Technical and financial offers sequence beginning',
    
    'current-fiscal-year': 'Current fiscal year',
    'start-date': 'Start date',
    'end-date': 'End date',

    'fiscal-years': 'Fiscal years',
    'add-fiscal-year': 'Add fiscal year',
    'no-filter': 'No fiscal year is selected. All files will be shown',
    to: 'to',
    'the-period': 'the period?',
    
    'automatic-calculations': 'Automatic calculations',
    'tax-calculation': 'Tax calculation',

    percentage: 'Percentage',
    'fixed-amount': 'Fixed amount',
    'value': 'Value',
    'tax-label': 'Tax label (default: VAT)',
    'a-taxed-commercial-invoice-becomes-a-tax-invoice': 'A taxed commercial invoice becomes a tax invoice.',
    'date-format': 'Files date format',
    'format': 'Format',
    
    'current-files-images': 'Current files images',
    'manage-company-files-template': 'Change company files template',
    'manage-company-files-container': 'Change company files container',
    'use': 'use',
    'upload-new-template': 'Upload new template',
    'upload-new-container': 'Upload new container',
    'upload-image': 'Upload image',
    'drag-text': 'Drag and drop image here',
    'drop-text': 'Drop the image here',
    'error-message': 'only images are allowed',

    selected: 'selected',
    upload: 'upload',
    'upload-and-change': 'upload & change',
    uploaded: 'Image uploaded successfuly',
    'delete-the-image': 'delete the image?',
    template: 'Template',
    container: 'Container',
    'there-is-no-templates': 'There is no templates',
    'there-is-no-containers': 'There is no containers',
    'template-image-note': `
        Template image requerments: <br>
        width: 1654px, height: 2339px,<br>
        resolution: 200dpi
    `,
    'container-image-note': `
        Container image requerments: <br>
        width: [915-970]px, height: [508-560]px
    `,
}