export default {
    users: 'Users',
    'add-a-user': 'Add a user',
    'the-user': 'the User?',
    'edit-user': 'Edit User',
    'add-user': 'Add User',

    'username': 'Username',
    'password': 'Password',
    'confirm-password': 'Confirm password',
    'not-matched-password': 'Not matched password',
    'user-permissions': 'User permissions',
    'read-only': 'Read only',
    'read-and-write': 'Read & Write',

    'leave-it-empty-if-you-do-not-want-to-change-password': 'Leave it empty if you don\'t want to change password'
}