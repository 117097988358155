<template>
    <div>
        <v-dialog v-model="localDialog" width="300" persistent ref="dialog">
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text py-2 justify-center">
                        <v-spacer/>
                        <span class="text-h6 ms-6">{{$t('Navbar.dialog.change-password')}}</span>
                        <v-spacer/>
                        <v-btn text small light fab @click="localDialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text class="py-2">
                        <v-row class="ma-0">
                            <!-- old password -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="oldPassword"
                                    :label="$t('Navbar.dialog.old-password')"
                                    type="password"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- new password -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="newPassword"
                                    :label="$t('Navbar.dialog.new-password')"
                                    type="password"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- new passwordConfirmation -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="newPasswordConfirmation"
                                    :label="$t('Navbar.dialog.confirm-new-password')"
                                    type="password"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading"
                                    :rules="[value => (value && value === newPassword) || 'Not matched password']"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            :loading="loading"
                            :disabled="loading"
                            type="submit"
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            :disabled="loading"
                            @click="$emit('input', false)"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            absolute
            bottom
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import rules from '../validation rules';
export default {
    props: {
        dialog: { type: Boolean, default: false },
    },

    model: {
        prop: 'dialog',
        event: 'input'
    },

    data: () => ({
        // helpers
        id: null,
        snackbar: false,
        message: null,
        loading: false,

        rules,

        // data
        oldPassword: null,
        newPassword: null,
        newPasswordConfirmation:null
    }),

    watch:{
        dialog(){
            if (this.dialog === false){
                this.oldPassword = null;
                this.newPassword = null;
                this.newPasswordConfirmation = null;
                this.$refs.form.resetValidation();
            }
        }
    },

    methods:{
        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                this.$store.dispatch('users/updateSelfPassword', {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                    newPasswordConfirmation: this.newPasswordConfirmation
                })
                .then(() => {
                    this.message = this.$t('messages.password-is-updated');
                    this.localDialog = false;
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                })
                .finally(() => {
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    },

    computed: {
        localDialog: {
            get(){
                return this.dialog;
            },

            set(newValue){
                this.$emit('input', newValue)
            }
        }
    }
}
</script>

<style>

</style>