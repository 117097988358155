<template>
    <v-combobox
        v-if="inputType === 'text'"
        v-model="localValue"
        :items="items"
        auto-select-first
        :search-input.sync="search"
        :loading="loading || fetchLoading"
        :disabled="disabled | isUsed"
        :label="label"
        :hide-details="hideDetails"
        hide-no-data
        append-icon
        :dense="dense"
        :outlined="outlined"
        :rules="rules"
    ></v-combobox>
    <v-menu
    	v-else-if="inputType === 'textarea'"
        v-model="menu"
        offset-y
        :nudge-bottom="!hideDetails ? -20 : 1"
    	:disabled="menuItems.length === 0"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-textarea
                v-model="localValue"
                :items="items"
                :loading="loading || fetchLoading"
                :disabled="disabled | isUsed"
                :label="label"
                :hide-details="hideDetails"
                v-bind="attrs"
                v-on="on"
                :auto-grow="autoGrow"
                :no-resize="noResize"
                :rows="rows"
                :dense="dense"
                :outlined="outlined"
                :rules="rules"
                @keypress.enter.prevent="menu ? (() => {localValue = items[0]; menu = false;})() : localValue += '\n'"
                class="textarea-autocomplete"
            ></v-textarea>
        </template>

        <v-list>
            <v-list-item-group>
                <v-list-item
                    v-for="(item, index) in menuItems"
                    :key="index"
                    @click="localValue = item"
                    @change="localValue = item"
                    :class="{'primary--text v-list-item--active': item === localValue, 'v-list-item--highlighted': index === 0}"
                >
                    <v-list-item-title
                        v-html="item !== localValue ? highlightMatch(item) : item"
                        style="white-space: pre-line"
                    ></v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        loading: { type: Boolean },
        disabled: { type: Boolean },
        label: { type: String },
        'input-type': {
            type: String,
            default: 'text',
            validator(val) {
                const valid = ['text', 'textarea'].includes(val)
                if (!valid) {
                    console.error(`input-type prop value should be ${['text', 'textarea'].join(', ')}`)
                }
                return valid
            }
        },
        'auto-grow': { type: Boolean },
        'no-resize': { type: Boolean },
        rows: { type: [Number, String] },
        outlined: { type: Boolean },
        dense: { type: Boolean },
        'hide-details': { type: [Boolean, String] },
        rules: {},
        value: { type: String },
        'input-field-enum': { type: Number },
    },
    
    filters: {
        /* eslint-disable no-unneeded-ternary */
        isUsed(prop) { return typeof prop === 'string' ? true : false || prop }
		/* eslint-ensable no-unneeded-ternary */
    },

    data: () => ({
        fetchLoading: false,
        filePath: null,
        search: null,
        items: [],
        menu: false
    }),

    watch: {
        search() {
            this.fetchFromApi()
        }
    },
    
    computed: {
        localValue:{
            get() {
                return this.value;
            },
            set(newVal) {
                if (this.inputType === 'textarea') {
                    this.search = newVal;
                }
                this.$emit('input', newVal);
            }
        },

        menuItems() {
            const regExp = new RegExp(`^${this.search}`, 'i');
            return this.items.filter(item => regExp.test(item))
        }
    },

    methods: {
        fetchFromApi() {
            this.fetchLoading = true;
            window.axios.get(`/files/${this.filePath}/autocomplete`, {
                params: { attribute: this.inputFieldEnum, input: this.search }
            })
            .then((response) => {
                this.items = response.data;
                if (this.inputType === 'textarea') {
                    this.menu = this.menuItems.length > 0 ? true : false;
                }
            })
            .finally(() => {
                this.fetchLoading = false;
            })
        },

        highlightMatch(str){
            if (this.search.length > 0) {
                const regExp = new RegExp(`^${this.search}`, 'i')
                const lowerStr = str.toLowerCase();
                const strToReplace = str.slice(lowerStr.indexOf(this.search[0].toLowerCase()), this.search.length);
                return str.replace(regExp, `<span class="v-list-item__mask">${strToReplace}</span>`);
            } else {
                return str
            }
        }
    },

    mounted() {
        switch (this.$route.name) {
            case 'sales-contract' : this.filePath = 'salesContracts'; break;
            case 'invoice' : this.filePath = 'invoices'; break;
            case 'commercial-invoice' : this.filePath = 'commercialInvoices'; break;
            case 'packing-list' : this.filePath = 'packingLists'; break;
            case 'fumigation-certificate' : this.filePath = 'fumigation'; break;
            case 'health-certificate' : this.filePath = 'health'; break;
            case 'weight-and-quality-certificate' : this.filePath = 'WeightAndQuality'; break;
            case 'port-dispatch' : this.filePath = 'dispatchForPort'; break;
        }
    }
}
</script>

<style lang="scss">
.textarea-autocomplete {
    textarea {
        cursor: auto;
    }
}
</style>