import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
			light: {
				primary: "#084566", // #015931 #083866
				secondary: "#a7a7a7", // #FFCDD2
				success: '#13a689',
				error: '#ff3334',
				errorLight: '#eeb4b3',
				// green: '#0f8145',
				lightPrimary: '#13a689', // #6878bb
			},
        },
    },
})
