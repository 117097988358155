export default {
    'files-settings': 'Ajustes de Archivos',
    'serial-sequences-for-system-files': 'Estilo de codificación de documentos de la empresa',
    
    'dynamic-part-length': 'Longitud de pieza variable',
    'dynamic-part-will-be-left-padded-with-zeroes': 'La parte variable se alineará a la izquierda agregando ceros',
    
    'proforma-invoices-sequence-beginning': 'El inicio del coraltivo de las proformas ',
    'sales-contracts-sequence-beginning': 'El inicio del coraltivo de los contrato de venta',
    'commercial-invoices-sequence-beginning': 'El inicio del coraltivo de las facturas comerciales',
    'orden-de-empaque-sequence-beginning': 'El inicio del coraltivo de ordenes de empaque ',
    'orden-de-salida-sequence-beginning': 'El inicio del coraltivo rd ordenes de salida',
    'despacho-a-puerto-sequence-beginning': 'El inicio del coraltivo es despachoes a puerto',
    'technical-and-financial-offers-sequence-beginning': 'El inicio de las ofertas técnicas y financieras',
    
    'current-fiscal-year': 'Año Fiscal Actual',
    'start-date': 'Fecha de inicio',
    'end-date': 'Fecha de término',

    'fiscal-years': 'Años Fiscales',
    'add-fiscal-year': 'Agregar Año Fiscal',
    'no-filter': 'No se seleccionó Año fiscal. Se mostarán todos los archivos',
    to: 'a',
    'the-period': ' el año fiscal?',
    
    'automatic-calculations': 'Calculos automáticos',
    'tax-calculation': 'Cálculo de impuestos',

    percentage: 'Porcentaje',
    'fixed-amount': 'Cantidad fija',
    'value': 'Valor',
    'tax-label': 'NOMBRE DEL IMPUESTO ',
    'a-taxed-commercial-invoice-becomes-a-tax-invoice': 'nombrar las facturas comerciales que tiene impuesto ',
    'date-format': 'Formato de fecha',
    'format': 'Formato',
    
    'current-files-images': 'Imágenes de archivos actuales',
    'manage-company-files-template': 'editar plantillas de los archivos de la compaña',
    'manage-company-files-container': 'Cambiar el contenedor de archivos de empresa',
    'use': 'Uso',
    'upload-new-template': 'Cargar nueva plantilla',
    'upload-new-container': 'cargar imagen de plantilla nueva ',
    'upload-image': 'Cargar imagen',
    'drag-text': 'Arrastrar y soltar imagen aquí',
    'drop-text': 'Soltar la imagen aquí',
    'error-message': 'Solo imágenes son permitidas',

    selected: 'Seleccionado',
    upload: 'Cargar',
    'upload-and-change': 'Cargar y cambiar',
    uploaded: 'Imagen cargada exitosamente',
    'delete-the-image': 'Eliminar la imagen?',
    template: 'Plantilla',
    container: 'Contenedor',
    'there-is-no-templates': 'No hay Plantillas',
    'there-is-no-containers': 'No hay contenedores',
    'template-image-note': `
        Requisitos de Imagen de plantilla: <br>
        ancho: 1654px, altura: 2339px,<br>
        resolución: 200dpi
    `,
    'container-image-note': `
        Container image requerments: <br>
        ancho: [915-970]px, altura: [508-560]px
    `,
}