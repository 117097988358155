<template>
    <div>
        <v-dialog
          v-model="dialog"
          width="750"
          ref="dialog"
          persistent
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <!-- dialog title -->
                    <v-card-title class="primary white--text py-2 justify-center">
                        <v-spacer/>
                        <span class="text-h6">{{title}}</span>
                        <v-spacer/>
                        <v-btn text small light fab @click="dialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <!-- dialog content -->
                    <v-card-text class="pt-2 pb-0">
                        <v-row class="ma-0">
                            <!-- client name -->
                            <v-col sm="6" cols="12">
                                <v-text-field
                                    v-model="name"
                                    :label="$t('Companies.company-dialog.company-name')"
                                    :loading="fetchLoading"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- tax number -->
                            <v-col sm="6" cols="12">
                                <v-text-field
                                    v-model="taxNum"
                                    :label="$t('Companies.company-dialog.tax-number')"
                                    :loading="fetchLoading"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                    :rules="rules.required"
                                ></v-text-field>
                            </v-col>

                            <!-- manager name -->
                            <v-col sm="6" cols="12">
                                <v-text-field
                                    v-model="managerName"
                                    :label="$t('Companies.company-dialog.manager-name')"
                                    :loading="fetchLoading"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                ></v-text-field>
                            </v-col>

                            <!-- financial manager name -->
                            <v-col sm="6" cols="12">
                                <v-text-field
                                    v-model="financialManagerName"
                                    :label="$t('Companies.company-dialog.financial-manager-name')"
                                    :loading="fetchLoading"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                ></v-text-field>
                            </v-col>

                            <!-- Company logo -->
                            <v-col sm="6" cols="12">
                                <v-file-input
                                    v-model="logo"
                                    :label="$t('Companies.company-dialog.company-logo')"
                                    show-size
                                    truncate-length="17"
                                    prepend-icon="mdi-image-filter-hdr"
                                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                    :rules="!id ? rules.file : []"
                                ></v-file-input>
                            </v-col>

                            <!-- Digital signature -->
                            <v-col sm="6" cols="12">
                                <v-file-input
                                    v-model="signature"
                                    :label="$t('Companies.company-dialog.digital-signature')"
                                    show-size
                                    truncate-length="17"
                                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                    :rules="!id ? rules.file : []"
                                ></v-file-input>
                            </v-col>
                        </v-row>

                        <!-- file types -->
                        <h3 class="text-body-1 primary--text ms-3 my-2">
                            {{$t('Companies.company-dialog.company-files')}}
                        </h3>
                        <v-row class="align-center ma-0">
                            <v-col sm="6" cols="12">
                                <v-autocomplete
                                    v-model="companyFiles"
                                    :items="fileTypesItems"
                                    :label="$t('Companies.company-dialog.files')"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    dense
                                    hide-details="auto"
                                    :disabled="loading || fetchLoading"
                                    :rules="rules.required"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @click="toggle"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{$t('Companies.company-dialog.select-all')}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ companyFiles.length - 1 }})
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <!-- currencies, phones and addresses -->
                        <v-row class="ma-0">
                            <!-- Currencies -->
                            <v-col sm="3" cols="12">
                                <!-- Currency title -->
                                <div class="d-flex align-center mb-1">
                                    <h3 class="text-body-1 primary--text">
                                        {{$t('Companies.company-dialog.currencies')}}
                                    </h3>
                                    <v-btn text small class="ms-2" @click="addCurrency">
                                        <v-icon>
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                                
                                <!-- Currency inputs -->
                                <div class="d-flex align-center mb-4" v-for="currency in currencies" :key="currency.id">
                                    <v-text-field
                                    v-model="currency.value"
                                    dense
                                    hide-details="auto"
                                    :label="$t('Companies.company-dialog.currency')"
                                    :rules="rules.required"
                                    ></v-text-field>

                                    <v-btn text small class="ms-2" @click="removeCurrency(currency.id)">
                                        <v-icon>
                                            mdi-minus-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>

                            <!-- phones -->
                            <v-col sm="4" cols="12">
                                <!-- phone title -->
                                <div class="d-flex align-center mb-1">
                                    <h3 class="text-body-1 primary--text">
                                        {{$t('Companies.company-dialog.phone-numbers')}}
                                    </h3>
                                    <v-btn text small class="ms-2" @click="addPhone">
                                        <v-icon>
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                                
                                <!-- phones inputs -->
                                <div class="d-flex align-center mb-4" v-for="phone in phones" :key="phone.id">
                                    <v-text-field
                                        v-model="phone.value"
                                        dense
                                        hide-details="auto"
                                        :label="$t('Companies.company-dialog.phone-number')"
                                        :rules="rules.required"
                                    ></v-text-field>

                                    <v-btn text small class="ms-2" @click="removePhone(phone.id)">
                                        <v-icon>
                                            mdi-minus-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>

                            <!-- addresses -->
                            <v-col sm="5" cols="12">
                                <!-- address title -->
                                <div class="d-flex align-center mb-1">
                                    <h3 class="text-body-1 primary--text">
                                        {{$t('Companies.company-dialog.addresses')}}
                                    </h3>
                                    <v-btn text small class="ms-2" @click="addAddress">
                                        <v-icon>
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                                
                                <!-- addresses inputs -->
                                <div class="d-flex align-center mb-4" v-for="address in addresses" :key="address.id">
                                    <v-text-field
                                        v-model="address.value"
                                        dense
                                        hide-details="auto"
                                        :label="$t('Companies.company-dialog.address')"
                                        :rules="rules.required"
                                    ></v-text-field>

                                    <v-btn text small class="ms-2" @click="removeAddress(address.id)">
                                        <v-icon>
                                            mdi-minus-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary lighten-2"
                            class="px-6"
                            text
                            type="submit"
                            :loading="loading"
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            class="px-6"
                            text
                            @click="dialog = false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import rules from '../validation rules'
import fileTypes from '../enums/file-types'
import * as shownFilesConfigs from '../configs/shown-files-config'
import { mapState } from 'vuex';
export default {
    data: () => ({
        // helpers
        id: null,
        dialog: false,
        snackbar: false,
        message: null,
        loading: false,
        fetchLoading: false,
        title: null,
        rules,

        phoneCounter: 0,
        addressCounter: 0,
        currencyCounter: 0,

        // data
        name: null,
        taxNum: null,
        managerName: null,
        financialManagerName: null,
        logo: null,
        signature: null,
        companyFiles: [],
        currencies:[],
        phones: [],
        addresses: [],
    }),

    methods:{
        resetData(){
            if (this.name) {
                this.$refs.form.resetValidation();
            }
            this.id = null;
            this.phoneCounter = 0;
            this.addressCounter = 0;
            this.currencyCounter = 0;
            
            this.name = null;
            this.taxNum = null;
            this.managerName = null;
            this.financialManagerName = null;
            this.logo = null;
            this.signature = null;
            this.currencies = [];
            this.phones = [];
            this.addresses = [];
        },

        fillData(company){
            this.name = company.name;
            this.taxNum = company.taxNumber;
            this.managerName = company.managerName;
            this.financialManagerName = company.financialManagerName;
            this.companyFiles = company.fileTypes;
            company.currencies.forEach(currency => {
                this.currencies.push({
                    id: this.currencyCounter,
                    value: currency
                });
                this.currencyCounter++;
            });
            company.phoneNumbers.forEach(phone => {
                this.phones.push({
                    id: this.phoneCounter,
                    value: phone
                });
                this.phoneCounter++;
            });
            company.addresses.forEach(address => {
                this.addresses.push({
                    id: this.addressCounter,
                    value: address
                });
                this.addressCounter++;
            });
        },
        
        addCompany(){
            this.resetData();
            this.title = this.$t('Companies.add-a-company')
            this.dialog = true;
        },

        editCompany(id){
            this.resetData();
            this.id = id;
            this.title = this.$t('Companies.edit-a-company')
            
            // fetch company data
            this.fetchLoading = true;
            this.$store.dispatch('companies/fetchById', { id })
            .finally(() => {
                this.fetchLoading = false
                this.fillData(this.company);
            });

            this.dialog = true;
        },

        // submit
        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                // create company
                if (!this.id){
                    this.$store.dispatch('companies/create', {
                        name: this.name,
                        taxNumber: this.taxNum,
                        managerName: this.managerName,
                        financialManagerName: this.financialManagerName,
                        currencies: this.currencies.map(c => c.value),
                        phoneNumbers: this.phones.map(c => c.value),
                        addresses: this.addresses.map(c => c.value),
                        fileTypes: this.companyFiles
                    })
                    .then((id) => {
                        Promise.all([
                            // upload Logo
                            this.$store.dispatch('companies/uploadLogo', {
                                id,
                                file: this.logo
                            }),

                            // upload Signature
                            this.$store.dispatch('companies/uploadSignature', {
                                id,
                                file: this.signature
                            })
                        ])
                        .then(() => {
                            this.message = this.$t('messages.company-has-been-successfully-added');
                            this.$emit('on-save');
                            this.dialog = false;
                        })
                        .catch((e) => {
                            this.message = e.response.data.message;
                        })
                        .finally(() => {
                            this.snackbar = true;
                            this.loading = false;
                        })
                    })
                }

                // update company
                else {
                    var promises = []

                    // update company
                    promises.push(
                        this.$store.dispatch('companies/update', {
                            id: this.id,
                            name: this.name,
                            taxNumber: this.taxNum,
                            managerName: this.managerName,
                            financialManagerName: this.financialManagerName,
                            currencies: this.currencies.map(c => c.value),
                            phoneNumbers: this.phones.map(c => c.value),
                            addresses: this.addresses.map(c => c.value),
                            fileTypes: this.companyFiles
                        }),
                    )
                    // upload Logo
                    if (this.logo){
                        promises.push(
                            this.$store.dispatch('companies/uploadLogo', {
                                id: this.id,
                                file: this.logo
                            }),
                        )
                    }
                    // upload Signature
                    if (this.signature){ 
                        promises.push(
                            this.$store.dispatch('companies/uploadSignature', {
                                id: this.id,
                                file: this.signature
                            })
                        )
                    }
                    
                    Promise.all(promises)
                    .then(() => {
                        this.message = this.$t('messages.company-has-been-successfully-edited');
                        this.$emit('on-save');
                        this.dialog = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.snackbar = true;
                        this.loading = false;
                    })
                }
            }
        },

        // currencies
        addCurrency(){
            this.currencies.push({
                id: this.currencyCounter,
                value: null
            });
            this.currencyCounter++;
        },
        removeCurrency(index){
            this.currencies.splice(index, 1);
            this.currencies.forEach(currency => {
                if (currency.id > index) currency.id--;
            });
            this.currencyCounter--;
        },

        // phone
        addPhone(){
            this.phones.push({
                id: this.phoneCounter,
                value: null
            });
            this.phoneCounter++;
        },
        removePhone(index){
            this.phones.splice(index, 1);
            this.phones.forEach(phone => {
                if (phone.id > index) phone.id--;
            });
            this.phoneCounter--;
        },

        // addresses
        addAddress(){
            this.addresses.push({
                id: this.addressCounter,
                value: null
            });
            this.addressCounter++;
        },
        removeAddress(index){
            this.addresses.splice(index, 1);
            this.addresses.forEach(email => {
                if (email.id > index) email.id--;
            });
            this.addressCounter--;
        },

        toggle() {
            if (this.isAllFileTypes) {
                this.companyFiles = [];
            }
            else {
                this.companyFiles = this.fileTypesItems.map(c => c.id);
            }
        }
    },

    computed:{
        ...mapState({
            company: state => state.companies.company
        }),
        fileTypesItems() {
            const availableFiles = [
                {
                    name: this.$t('file-types.sales-contract'),
                    id: fileTypes.salesContract,
                    show: shownFilesConfigs.salesContracts
                },
                {
                    name: this.$t('file-types.proforma-invoice'),
                    id: fileTypes.invoice,
                    show: shownFilesConfigs.invoices
                },
                {
                    name: this.$t('file-types.commercial-invoice'),
                    id: fileTypes.commercialInvoice,
                    show: shownFilesConfigs.commercialInvoices
                },
                {
                    name: this.$t('file-types.packing-list'),
                    id: fileTypes.packingList,
                    show: shownFilesConfigs.packingLists
                },
                {
                    name: this.$t('file-types.shipping-advise'),
                    id: fileTypes.shippingAdvise,
                    show: shownFilesConfigs.shippingAdvises
                },
                {
                    name: 'Ordenes de empaque',
                    id: fileTypes.packingOrder,
                    show: shownFilesConfigs.packingOrders
                },
                {
                    name: 'Ordenes de salida',
                    id: fileTypes.productReleaseOrder,
                    show: shownFilesConfigs.productReleaseOrders
                },
                {
                    name: this.$t('file-types.fumigation-certificate'),
                    id: fileTypes.fumigationCertificate,
                    show: shownFilesConfigs.fumigationCertificates
                },
                {
                    name: this.$t('file-types.health-certificate'),
                    id: fileTypes.healthCertificate,
                    show: shownFilesConfigs.healthCertificates
                },
                {
                    name: this.$t('file-types.weight-and-quality-certificate'),
                    id: fileTypes.weightAndQualityCertificate,
                    show: shownFilesConfigs.weightAndQualityCertificates
                },
                {
                    name: 'Despacho a Puerto',
                    id: fileTypes.portDispatch,
                    show: shownFilesConfigs.portDispatches
                },
                {
                    name: this.$t('file-types.technical-and-financial-offers'),
                    id: fileTypes.technicalAndFinancialOffer,
                    show: shownFilesConfigs.technicalAndFinancialOffers
                },
            ];

            return availableFiles.filter(c => c.show);    
        },

        isAllFileTypes () {
            return this.companyFiles.length === this.fileTypesItems.length;
        },
        icon () {
            if (this.isAllFileTypes) return 'mdi-close-box';
            else if (this.companyFiles.length) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    }
}
</script>

<style>

</style>