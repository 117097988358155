<template>
    <v-app>
        <loader v-if="loading" />
        <template v-else>
            <navbar
                v-if="authenticated"
                @change-drawer="drawer =! drawer"
                @company-fetch:start="drawerLoading = true"
                @company-fetch:end="drawerLoading = false"
            />
            <navigation-drawer v-if="authenticated" :drawer="drawer" :loading="drawerLoading"/>
            
            <v-main>
                <v-container fluid class="px-8 py-4">
                    <router-view
                        v-if="authenticated || $route.name === 'login'"
                        @company-fetch:start="drawerLoading = true"
                        @company-fetch:end="drawerLoading = false"
                    />
                    <loader v-else/>
                </v-container>
            </v-main>
        </template>
    </v-app>
</template>

<script>
import Navbar from './components/Navbar'
import NavigationDrawer from './components/NavigationDrawer'
import Loader from './components/Loader'
import { mapState } from 'vuex'
export default {
    name: 'App',

    data: () => ({
        loading: false,

        drawer: false,
        drawerLoading: false,
    }),

    watch:{
		'$i18n.locale'(){
            this.setAppDirection();
            this.loading = true;
            setTimeout(() => { this.loading = false; }, 500);
        },
        '$route.params.companyId'(val) {
            if (val) {
                this.$store.dispatch('users/changeViewerOnly', { companyId: val })
            }
        }
	},

    computed:{
        ...mapState({
            authenticated: state => state.auth.authenticated,
        }),
    },

    methods:{
        setAxiosHeaderForXCompany(id){
            window.axios.defaults.headers.common['x-company'] = id;
        },
        
        setAppDirection(){
			if (this.$i18n.locale === 'ar'){
				this.$vuetify.rtl = true;
			}
			else {
				this.$vuetify.rtl = false;
			}
		}
    },

    mounted(){
        this.setAppDirection();
        if (this.$route.params.companyId){
            this.setAxiosHeaderForXCompany(this.$route.params.companyId);
        }
    },

    components:{
        Navbar,
        NavigationDrawer,
        Loader
    },
}
</script>

<style lang="scss">
@import "scss/main.scss";

.fixed-width{
    &-date{
        min-width: 110px;
    }

    &-text{
        min-width: 280px;
    }
}

* {
    & ::-webkit-scrollbar{
        width: 5px;
        height: 5px;
    }
    & ::-webkit-scrollbar-thumb{
        background-color: #ccc;
        border-radius: 10px;
    }
}

// remove arrows from input number type
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#app {
    .v-textarea textarea {
        line-height: 1.5rem;
    }
    
    .powered-by {
        position: absolute;
        bottom: 6px;
        a {
            font-size: 12px;
            letter-spacing: 0.1666666667em;
        }
    }
}
</style>
