import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (from.params.companyId) {
        localStorage.setItem('last-context-path', from.fullPath);
    }
    if (from.name === 'login' || to.name === 'login') {
        localStorage.removeItem('last-context-path');
    }
    next();
})
export default router
