import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        units: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/units')
            .then((response) => {
                commit(types.STORE_UNITS, response.data);
                return response.data;
            })
        },
        
        create({ commit }, { name }){
            return window.axios.post('/units', { name });
        },

        update({ commit }, { id, name }){
            return window.axios.put(`/units/${id}`, { name });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/units/${id}`);
        }
    },

    mutations:{
        [types.STORE_UNITS](state, units){
            state.units = units;
        }
    },

    getters:{
        getUnitById: state => id => state.units.find(c => c.id === id),
        getUnitByName: state => name => state.units.find(c => c.name === name)
    }
}