export default {
    'packaging-units': 'Packaging Units',
    'the-unit': 'the unit?',
    'add-a-unit': 'Add a unit',
    
    dialog: {
        'add-a-unit': 'Add a unit',
        'edit-an-existing-unit': 'Edit an existing unit',
        'unit-name': 'Unit name',
    }
}