export default {
    'select-company': 'Seleccionar compañía',
    'switch-company': 'Cambiar de compañía',
    'language': 'Lenguaje',
    'settings': 'Ajustes',
    'manage-companies': 'Administrar compañias',
    'manage-users': 'Administrar usuarios',
    'change-password': 'Cambiar contraseña',
    'manage-currencies': 'Administrar monedas',
    'view-templates': 'Ver plantillas',
    'signout': 'Cerrar sessión',
    'available-in-next-version': 'Disponible en la siguiente versión',

    dialog: {
        'change-password': 'Cambiar contraseña',
        'old-password': 'Contraseña anterior',
        'new-password': 'Nueva contraseña',
        'confirm-new-password': 'Confirmar nueva contraseña',
    }
}