<template>
    <v-app-bar
      app
      clipped-left
      clipped-right
      color="primary"
      dark
      height="70"
    >
        <!-- menu icon -->
        <v-btn
          text
          fab
          small
          @click="$emit('change-drawer')"
          v-if="$vuetify.breakpoint.mdAndUp && !['home', 'companies', 'users', 'audit-log'].includes($route.name)"
        >
            <v-icon>mdi-menu</v-icon>
        </v-btn>

        <!-- Password dialog -->
        <PasswordDialog v-model="dialog"/>

        <!-- logo -->
        <router-link
        	:to="{name: !['home', 'companies', 'users', 'audit-log'].includes($route.name) ? 'land-page' : null}"
        	class="d-flex align-center ms-md-8"
        	style="position:relative"
        >
            <img
              :src="needToLogo ? BASE_API_URL + '/' + company.logoImagePath : '/img/acacia-white.png'"
              :width="needToLogo ? '85px' : '50px'"
              :class="{'white-shadow' : needToLogo}"
            >
            <img
              v-if="needToLogo"
              :src="needToLogo ? BASE_API_URL + '/' + company.logoImagePath : '/img/acacia-white.png'"
              :width="needToLogo ? '85px' : '50px'"
              class="shadow-image"
            >
        </router-link>

        <v-spacer></v-spacer>

        <!-- home -->
        <v-btn
        	:to="{ name: 'home' }"
        	exact
        	icon
        	class="me-1"
        >
            <v-icon>mdi-home</v-icon>
        </v-btn>
        
        <!-- large screen navbar menus -->
        <template v-if="$vuetify.breakpoint.smAndUp">
            <!-- company -->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        :disabled="showCompanies.length === 0"
                        class="me-1"
                    >
                        <h1 class="text-body-1 text-capitalize">
                            {{ $route.name !== 'home' && company ? company.name : $t('Navbar.select-company')}}
                        </h1>
                        <v-icon class="ms-2" size="22">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        v-for="company in showCompanies"
                        :key="company.id"
                        :to="{name: 'land-page', params:{companyId: company.id}}"
                        @focus="setAxiosHeaderForXCompany(company.id); fetchCompanyData(company.id); logSignInOperation(company.id)"
                    >
                        <v-list-item-title>{{company.name}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- user -->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        class="me-1"
                    >
                        <v-icon>mdi-account</v-icon>
                        <span v-if="$vuetify.breakpoint.smAndUp" class="mx-1">{{self.username}}</span>
                        <v-icon size="22">
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="dialog = true">
                        <v-list-item-icon>
                            <v-icon>mdi-lock-reset</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.change-password')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.signout')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- settings -->
            <v-menu offset-y v-if="currentUser.isAdmin">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="me-1"
                    >
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        :to="{name:'companies'}"
                    >
                        <v-list-item-icon><v-icon>mdi-office-building-cog</v-icon></v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.manage-companies')}}</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item
                        :to="{name:'users'}"
                    >
                        <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.manage-users')}}</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item
                        v-if="0"
                        :to="{name:'templates'}"
                        disabled
                        class="mb-2 mt-2"
                    >
                        <v-list-item-title>
                            {{$t('Navbar.manage-currencies')}}
                            <br/><span class="caption font-weight-light">{{$t('Navbar.available-in-next-version')}}</span>
                        </v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item
                        v-if="0"
                        :to="{name:'templates'}"
                        disabled
                    >
                        <v-list-item-title>
                            {{$t('Navbar.view-templates')}}
                            <br/><span class="caption font-weight-light">{{$t('Navbar.available-in-next-version')}}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- language -->
            <v-menu offset-y >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                    >
                        <v-icon>mdi-translate</v-icon>
                    </v-btn>
                </template>
                
                <v-list>
                    <v-list-item
                        v-for="lang in $i18n.availableLocales"
                        :key="lang"
                        @click="i18nSetLang(lang)"
                    >
                        <v-list-item-title>{{getLang(lang)}}</v-list-item-title>
                        <v-icon v-if="lang === $i18n.locale" class="me-2">mdi-check</v-icon>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <!-- menu mobile -->
        <v-menu v-else offset-y :close-on-content-click="false" v-model="menu">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="px-sm-6"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon color="me">mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list>
                <!-- companies -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-domain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.switch-company')}}</v-list-item-title>
                    </template>

                    <v-list-item
                      v-for="company in showCompanies"
                      :key="company.id"
                      :to="{name: 'land-page', params:{companyId: company.id}}"
                      @focus="setAxiosHeaderForXCompany(company.id); fetchCompanyData(company.id); logSignInOperation(company.id)"
                      @click="menu = false"
                    >
                        <v-list-item-title>{{company.name}}</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <!-- language -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-translate</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.language')}}</v-list-item-title>
                    </template>

                    <v-list-item
                      v-for="lang in $i18n.availableLocales"
                      :key="lang"
                      @click="i18nSetLang(lang)"
                    >
                        <v-list-item-title>{{getLang(lang)}}</v-list-item-title>
                        <v-icon v-if="lang === $i18n.locale" class="me-2">mdi-check</v-icon>
                    </v-list-item>
                </v-list-group>

                <!-- settings -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.settings')}}</v-list-item-title>
                    </template>

                    <v-list-item
                      v-if="currentUser.isAdmin"
                      :to="{name:'companies'}"
                      @click="menu = false"
                    >
                        <v-list-item-title>{{$t('Navbar.manage-companies')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="currentUser.isAdmin"
                      :to="{name:'users'}"
                      @click="menu = false"
                    >
                        <v-list-item-title>{{$t('Navbar.manage-users')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="menu = false; dialog = true">
                        <v-list-item-title>{{$t('Navbar.change-password')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t('Navbar.signout')}}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
import PasswordDialog from '../components/PasswordDialog.vue'
import { i18nSetLang } from '../i18n/i18n'
import { mapState, mapGetters } from 'vuex'
import { BASE_API_URL } from '../constants'
export default {
    data: () => ({
        BASE_API_URL,
        dialog: false,
        menu: false,
    }),

    methods:{
        i18nSetLang,

        logout(){
            this.$store.dispatch('auth/logout');
            this.$router.replace({ name: 'login' });
        },
        
        setAxiosHeaderForXCompany(id){
            window.axios.defaults.headers.common['x-company'] = id;
        },

        fetchCompanyData(id) {
            this.$emit('company-fetch:start');
            if (this.$route.name !== 'files-setting') {
                this.$store.dispatch('fileSettings/fetch')
            };
            this.$store.dispatch('companies/fetchById', { id })
            .finally(() => {
                this.$emit('company-fetch:end');
            })
        },
         
        logSignInOperation(id) {
            this.$store.dispatch('users/logSignInOperation', { companyId: id })
        },

        getLang(lang){
            switch (lang) {
                case 'ar': return 'عربي - Arabic';
                case 'en': return 'English - En (US)';
                case 'es': return 'Español - Spanish';
            }
        }
    },

    computed:{
        ...mapState({
            self: state => state.auth.self,
            currentUser: state => state.users.currentUser,
            companies: state => state.companies.companies
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),

        needToLogo(){
            return this.$route.name !== 'home' && this.company && this.company.logoImagePath;
        },

        company(){
            return this.getCompanyById(this.$route.params.companyId);
        },

        showCompanies(){
            if (this.currentUser.isAdmin) return this.companies;
            const companies = [];
            this.companies.forEach(c => {
                if (this.currentUser.companyPermissions.map(a => a.companyId).includes(c.id)){
                    companies.push(this.getCompanyById(c.id))
                }
            })
            return companies;
        }
    },

    components:{
        PasswordDialog
    },

    mounted() {
        // fetch company data if you are not in land-page because it fetch company data
        if (this.$route.params.companyId && this.$route.name !== 'land-page') {
            this.fetchCompanyData(this.$route.params.companyId);
        }
    }
}
</script>

<style lang="scss">
.shadow-image{
    position:absolute;
    top: 0;
    left: 0;
    z-index: -1; 
    transform: scale(1.03);
    filter: brightness(0) invert(1);
}
.white-shadow{
    filter: drop-shadow(0px 1px 0px #fff);
}
</style>