<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 primary--text">{{$t('ShippingAdvises.shipping-advises')}}</h1>

            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="filter">
                <span class="text-body-1">
                    {{$t('are-you-sure-you-want-to')}}
                    <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                    {{$t('ShippingAdvises.the-shipping-advice')}}
                </span>
            </delete-dialog>

            <!-- Add an Proforma Invoice -->
            <v-btn
              v-if="!viewerOnly"
              text
              small
              class="lightPrimary white--text"
              :to="{
                name:'shipping-advise',
                params:{companyId: $route.params.companyId},
              }"
            >
                {{$t('ShippingAdvises.add-a-shipping-advice')}}
            </v-btn>
        </div>

        <!-- filter -->
        <v-form class="my-2" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1">
                    {{$t('filter')}}
                </v-col>

                <!-- Consignee -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-combobox
                        v-model="consignee"
                        :label="$t('headers.consignee')"
                        :items="customers"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-combobox>
                </v-col>

                <!-- Container No. -->
                <v-col md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1" v-if="0">
                    <v-text-field
                        v-model="containerNo"
                        :label="$t('headers.container-no')"
                        :loading="filterLoading"
                        :disabled="filterLoading"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date ? moment(date) : null"
                                :label="$t('from-date')"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate.save(date)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- shipping date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="shippingDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="shippingDate ? moment(shippingDate) : null"
                                :label="$t('to-date')"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="shippingDate"
                            :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.shippingDateMenu.save(shippingDate)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        width="80"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        icon
                        small
                        @click="fetchShippingAdvices();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : shippingAdvises"
          :items-per-page="20"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-options': [10, 20, 40, 100, -1],
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          :loading="loading"
          disable-sort
          dense
          class="elevation-2"
        >
            <!-- contractNo -->
            <template v-slot:item.contractNo="{item}">
                {{item.contractNo ? item.contractNo: 'N/A' }}
            </template>
            
            <!-- consignee -->
            <template v-slot:item.consignee="{item}">
                {{item.consignee ? item.consignee: 'N/A' }}
            </template>
            
            <!-- shippingCompany -->
            <template v-slot:item.shippingCompany="{item}">
                {{item.shippingCompany ? item.shippingCompany: 'N/A' }}
            </template>

            <!-- shippingDate -->
            <template v-slot:item.shippingDate="{item}">
                {{ item.shippingDate ? findMonthDate(item.shippingDate) : 'N/A'}}
            </template>

            <!-- date -->
            <template v-slot:item.date="{item}">
                {{ item.date ? moment(item.date) : item.date}}
            </template>

            <!-- containerNo -->
            <template v-slot:item.containerNo="{item}">
                {{item.containerNo ? item.containerNo: 'N/A' }}
            </template>
            
            <!-- totalNetWeight -->
            <template v-slot:item.totalNetWeight="{item}">
                {{item.totalNetWeight ? item.totalNetWeight: 'N/A' | currency }}
            </template>

            <!-- totalPackages -->
            <template v-slot:item.totalPackages="{item}">
                {{item.totalPackages ? item.totalPackages: 'N/A' | currency }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('tooltips.download-as-pdf')">
                        <v-btn text small @click="report(item.id)">
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <template v-if="viewerOnly">
                        <tooltip :text="$t('tooltips.preview')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'shipping-advise',
                                    params:{companyId: $route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>

                    <template v-else>
                        <tooltip :text="$t('tooltips.duplicate')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'shipping-advise',
                                    params:{companyId: $route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-content-duplicate
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip :text="$t('tooltips.edit')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'shipping-advise',
                                    params:{companyId: $route.params.companyId},
                                    query:{id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip :text="$t('tooltips.delete')">
                            <v-btn @click="$refs.DeleteDialog.activeDialog(item.id, 'shippingAdvises')" text small>
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import { mapState, mapGetters } from 'vuex';
import { findMonthDate } from '../../helpers';
import { BASE_API_URL } from '../../constants';
export default {
    data: () => ({
        // filter data
        contractNo: null,
        consignee: null,
        containerNo: null,
        date: null,
        shippingDate: null,

        // helpers
        loading: true,
        filterLoading: true,
        isFiltered: false,
    }),

    methods:{
        // filter funciton
        clearFilter(){
            this.contractNo = null;
            this.consignee = null;
            this.containerNo = null;
            this.date = null;
            this.shippingDate = null;

            this.isFiltered = false;
        },
        
        filter(){
            this.loading = true;
            this.$store.dispatch('shippingAdvises/fetchAll', {
                sequence: this.contractNo,
                fromDate: this.date,
                toDate: this.shippingDate,
            })
            .finally(() => {
                this.loading = false;
            })
        },

        fetchShippingAdvices(){
            this.clearFilter();
            this.filter();
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/ShippingAdvices/${id}`);
        },

        // table funcitons
        moment(date){
            return window.moment(date).format(this.fileSettings.dateFormat ? this.fileSettings.dateFormat.toUpperCase() : 'DD-MM-YYYY');
        },

        // helpers
        findMonthDate,
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                this.contractNo === null &&
                this.consignee === null &&
                this.containerNo === null &&
                this.date === null &&
                this.shippingDate === null
            )
        },

        ...mapState({
            customers: state => state.customers.customers,
            shippingAdvises: state => state.shippingAdvises.shippingAdvises,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),

        headers() {
            const headers = [
                { text: this.$t('headers.date'), value:'date', class:'primary--text fixed-width-date', cellClass:'primary--text fixed-width-date', width: 120 },
                { text: this.$t('headers.contract-no'), value:'contractNo' },
                { text: this.$t('headers.consignee'), value:'consignee' },
                { text: this.$t('headers.shipping-company'), value:'shippingCompany' },
                { text: this.$t('headers.shipping-date'), value:'shippingDate', cellClass:'fixed-width-date' },
                { text: this.$t('headers.container-no'), value:'containerNo' },
                { text: this.$t('headers.total-of-weight'), value:'totalNetWeight' },
                { text: this.$t('headers.total-of-packages'), value:'totalPackages' },
                { text: this.$t('headers.actions'), value:'actions', width: 150 },
            ]

            // set shared props
            for (let i = 0; i < headers.length; i++) {
                headers[i].align = 'center';
            }

            return headers;
        },
    },

    mounted(){
        Promise.all([
            this.$store.dispatch('customers/fetchAll'),
            this.$store.dispatch('shippingAdvises/fetchAll', { sequence: null, fromDate: null, toDate: null })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    components:{
        DeleteDialog,
        Tooltip
    }
}
</script>

<style>

</style>