import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        fumigations: []
    },

    actions:{
        fetchAll({ commit }, { date }){
            return window.axios.get('/files/fumigation', {
                params:{
                    date
                }
            })
            .then((response) => {
                commit(types.STORE_FUMIGATION, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/fumigation/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.post('/files/fumigation', { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items })
            .then((response) => {
                return response.data;
            });
        },

        update({ commit }, { id, date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items }){
            return window.axios.put(`/files/fumigation/${id}`, { date, invoiceNumber, contractNumber, exporter, consignee, multiNotes, cargoDescription, placeOfOrigin, placeOfLoading, placeOfDestination, description, containerNumber, sealNumber, billOfLadingNumber, items })
            .then((response) => {
                return id;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/fumigation/${id}`);
        }
    },

    mutations:{
        [types.STORE_FUMIGATION](state, fumigations){
            state.fumigations = fumigations;
        }
    },

    getters:{
        getFumigationById: state => id => state.fumigations.find(c => c.id === id),
    }
}