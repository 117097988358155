export default {
    salesContract: 0,
    invoice: 1,
    commercialInvoice: 2,
    packingList: 3,
    shippingAdvise: 4,
    packingOrder: 5,
    productReleaseOrder: 6,
    
    fumigationCertificate: 7,
    healthCertificate: 8,
    weightAndQualityCertificate: 9,
    portDispatch: 10,

    technicalAndFinancialOffer: 11,
};