import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sharedTranslates from './shared-translates.json'

Vue.use(VueI18n)

function loadLocaleMessages(){
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]/i)
	const langs = [];
	const translates = [];
	const messages = {};

	locales.keys().forEach((key) => {
		// get all available languages 
		// (just create folder with the language key e.g. folder with name 'ar' in './locales')
		const matched = key.match(/([A-Za-z0-9-_]+)\//i);
		if (matched && !langs.includes(matched[1])){
			const lang = matched[1];
			langs.push(lang)
			messages[lang] = {};
		}

		// get all available translates keys
		// (just create js or json file in langauge folder e.g. create file 'page.js' in './locales/ar' folder)
		const matchedTr = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matchedTr && !translates.includes(matchedTr[1])){
			translates.push(matchedTr[1]);
		}
	})
	
	// set all translates messages
	locales.keys().forEach((key) => {
		// check if key is file path
		if (key.match(/[A-Za-z0-9-_,\s]+\.(json|js)/i)){
			langs.forEach(lang => {
				translates.forEach(translate => {
					if (key.includes(`/${lang}`) && key.includes(`/${translate}`)){
						// match key (with js) to check if language come from js file not json
						if (key.match(/([A-Za-z0-9-_]+)\.js$/i)) {
							// so import messages which exported as default in langauge file
							messages[lang][translate] = locales(key).default;
						}
						// else it is json file
						else {
							messages[lang][translate] = locales(key);
						}
					}
				})
			})
		}
	})

	/* eslint-disable dot-notation */
	/*
		var s = [];
		Object.keys(messages['en']).forEach((page) => {
			Object.keys(messages['en'][page]).forEach((key) => {
				if (typeof messages['en'][page][key] === 'object'){
					Object.keys(messages['en'][page][key]).forEach((subkey) => {
						s.push({
							page,
							key: key + ' => ' + subkey,
							ar: '',
							en: messages['en'][page][key][subkey],
							es: '',
						})
					})
				}
				else {
					s.push({
						page,
						key: key,
						ar: '',
						en: messages['en'][page][key],
						es: '',
					})
				}
			})
		})
		console.log(s)
		var ss = [];
		var sss = [];
		
		Object.keys(sharedTranslates).forEach((key) => {
			ss.push({
				page: 'shared',
				key,
				ar: sharedTranslates[key]['ar'],
				en: sharedTranslates[key]['en'],
				es: sharedTranslates[key]['es']
			})
		})
		ss.forEach(obj => {
			if (typeof obj.en === 'object'){
				Object.keys(obj.en).forEach(enKey => {
					sss.push({
						page: 'shared',
						key: obj.key + ' => ' + enKey,
						ar: '',
						en: obj['en'][enKey],
						es: ''
					})
				})
			}
			else {
				sss.push(obj)
			}
		})
		console.log(sss)
		console.log([s, sss].flat())
	*/
	/* eslint-enable dot-notation */

	Object.keys(sharedTranslates).forEach((key) => {
		langs.forEach(lang => {
			messages[lang][key] = sharedTranslates[key][lang];
		})
	})
	return messages
}

const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: loadLocaleMessages(),
});

/**
 * change language
 * @param {string} lang - language key want to change to
 */
const i18nSetLang = function (lang) {
	i18n.locale = lang;
	document.querySelector('html').lang = lang;
	document.querySelector('html').dir = i18n.locale === 'ar' ? 'rtl' : 'ltr';
	localStorage.setItem('mirta-lang', lang);
}

if ('mirta-lang' in localStorage){
	i18n.locale = localStorage.getItem('mirta-lang');
}
else {
	localStorage.setItem('mirta-lang', i18n.locale);
}

export { i18nSetLang }
export default i18n;
