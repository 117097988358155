export default {
    companies: 'Compañias',
    'add-a-company': 'Agregar compañía',
    'edit-a-company': 'Editar una compañía',

    'the-company': 'la compañía?',

    'company-dialog': {
        'company-name': 'Nombre de la compañía',
        'tax-number': 'Numero de NIT',
        'manager-name': 'Nombre del gerente',
        'financial-manager-name': 'Nombre del gerente financiero',
        'company-logo': 'Logo de la compañía',
        'digital-signature': 'Firma digital',
        'company-files': 'Archivos de la compañía',
        'files': 'Archivos  ',
        'select-all': 'Seleccionar todos',
        'currencies': 'Monedas',
        'currency': 'Moneda ',
        'phone-numbers': 'Numeros de telefono',
        'phone-number': 'No. De teléfono',
        'addresses': 'Direcciones',
        'address': 'Dirección',
    },

    'financial-account-dailog': {
        'financial-accounts': 'Cuentas financieras',
        'account-details': 'Detalles de la cuenta',
        'the-account': ' la cuenta?',
    }
}