import * as types from '../mutation-types';

export default {
    namespaced: true,

    state:{
        items: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/items')
            .then((response) => {
                commit(types.STORE_ITEMS, response.data.filter(c => c.deletedAt === null));
                return response.data.filter(c => !c.deletedAt);
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/items/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { name, description, units }){
            return window.axios.post('/items', { name, description, units })
        },

        update({ commit }, { id, name, description, units }){
            return window.axios.put(`/items/${id}`, {
                name, description, units
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/items/${id}`);
        }
    },

    mutations:{
        [types.STORE_ITEMS](state, items){
            state.items = items;
        }
    },

    getters:{
        getItemById: state => id => state.items.find(c => c.id === id),
        getItemByName: state => name => state.items.find(c => c.name === name)
    }
}