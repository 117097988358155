import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        users: [],
        viewerOnly: false,
        currentUser: {
            isAdmin: true,
            companyPermissions: []
        },
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/users')
            .then(response => {
                commit(types.STORE_USERS, response.data);
                return response.data;
            })
        },

        create({ commit }, { username, password, passwordConfirmation }){
            return window.axios.post('/users', { username, password, passwordConfirmation });
        },

        updateSelfPassword({ commit }, { oldPassword, newPassword, newPasswordConfirmation }){
            return window.axios.put('/users/self/changePassword', { oldPassword, newPassword, newPasswordConfirmation });
        },

        fetchSelfPermissions({ commit }){
            return window.axios.get('/users/self/permissions')
            .then(response => {
                commit(types.STORE_CURRENT_USER, response.data)
                return response.data;
            });
        },

        update({ commit }, { id, username, password, passwordConfirmation }){
            return window.axios.put(`/users/${id}`, { username, password, passwordConfirmation });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/users/${id}`);
        },

        fetchUserPermissions({ commit }, { id }){
            return window.axios.get(`/users/${id}/permissions`)
            .then(response => {
                return response.data;
            });
        },

        updateUserPermissions({ commit }, { id, permissions }){
            return window.axios.put(`/users/${id}/permissions`, permissions);
        },

        fetchLogs({ commit }, { userId, companyId, fromDate, toDate, page, perPage }){
            return window.axios.get('/users/log', {
                params: { userId, companyId, fromDate, toDate, page, perPage }
            })
            .then((response) => {
                return response.data
            });
        },

        fetchLastLogin({ commit }, { id }){
            return window.axios.get(`/users/log/${id}/lastLogin`)
            .then((response) => {
                return response.data;
            });
        },

        logSignInOperation({ commit }, { companyId }){
            return window.axios.post('/users/LogSignInOperation', null, { params: { companyId } });
        },

        changeViewerOnly({ state }, { companyId }) {
            if (state.currentUser.isAdmin) {
                state.viewerOnly = false;
            }
            else {
                state.viewerOnly =
                state.currentUser.companyPermissions.find(c => c.companyId === companyId).viewerOnly;
            }
        }
    },

    mutations:{
        [types.STORE_USERS](state, users){
            state.users = users;
        },

        [types.STORE_CURRENT_USER](state, currentUser){
            state.currentUser = currentUser;
        }
    },

    getters:{
        getUserById: state => id => state.users.find(c => c.id === id)
    }
}