var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h1',{staticClass:"text-h5 primary--text"},[_vm._v(_vm._s(_vm.$t('ShippingAdvises.shipping-advises')))]),_c('delete-dialog',{ref:"DeleteDialog",on:{"on-delete":_vm.filter}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('are-you-sure-you-want-to'))+" "),_c('span',{staticClass:"text-uppercase error--text"},[_vm._v(_vm._s(_vm.$t('delete')))]),_vm._v(" "+_vm._s(_vm.$t('ShippingAdvises.the-shipping-advice'))+" ")])]),(!_vm.viewerOnly)?_c('v-btn',{staticClass:"lightPrimary white--text",attrs:{"text":"","small":"","to":{
            name:'shipping-advise',
            params:{companyId: _vm.$route.params.companyId},
          }}},[_vm._v(" "+_vm._s(_vm.$t('ShippingAdvises.add-a-shipping-advice'))+" ")]):_vm._e()],1),_c('v-form',{staticClass:"my-2",on:{"submit":function($event){$event.preventDefault();return _vm.filter()}}},[_c('v-row',{staticClass:"align-center ma-0"},[_c('v-col',{staticClass:"text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('filter'))+" ")]),(0)?_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"md":"3","sm":"4","cols":"12"}},[_c('v-combobox',{attrs:{"label":_vm.$t('headers.consignee'),"items":_vm.customers,"item-text":"name","item-value":"name","return-object":false,"loading":_vm.filterLoading,"disabled":_vm.filterLoading,"filled":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.consignee),callback:function ($$v) {_vm.consignee=$$v},expression:"consignee"}})],1):_vm._e(),(0)?_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"md":"3","sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.container-no'),"loading":_vm.filterLoading,"disabled":_vm.filterLoading,"filled":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.containerNo),callback:function ($$v) {_vm.containerNo=$$v},expression:"containerNo"}})],1):_vm._e(),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-menu',{ref:"menuOfDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.date ? _vm.moment(_vm.date) : null,"label":_vm.$t('from-date'),"prepend-icon":"mdi-calendar","dense":"","filled":"","outlined":"","hide-details":"","readonly":"","disabled":_vm.loading}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":function($event){return _vm.$refs.menuOfDate.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"lg":"2","md":"3","sm":"4","cols":"12"}},[_c('v-menu',{ref:"shippingDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.shippingDate ? _vm.moment(_vm.shippingDate) : null,"label":_vm.$t('to-date'),"prepend-icon":"mdi-calendar","dense":"","filled":"","outlined":"","hide-details":"","readonly":"","disabled":_vm.loading}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":function($event){return _vm.$refs.shippingDateMenu.save(_vm.shippingDate)}},model:{value:(_vm.shippingDate),callback:function ($$v) {_vm.shippingDate=$$v},expression:"shippingDate"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1 pt-0 pb-sm-0 pb-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"success","width":"80","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"type":"submit"},on:{"click":function($event){_vm.isFiltered = true}}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")]),(_vm.isFiltered)?_c('v-btn',{attrs:{"icon":"","small":"","disabled":!_vm.isFiltered || _vm.loading},on:{"click":function($event){return _vm.fetchShippingAdvices();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.shippingAdvises,"items-per-page":20,"no-data-text":_vm.$t('no-data-text'),"no-results-text":_vm.$t('no-results-text'),"loading-text":_vm.$t('loading-text'),"footer-props":{
        'items-per-page-options': [10, 20, 40, 100, -1],
        'items-per-page-all-text': _vm.$t('items-per-page-all-text'),
        'items-per-page-text': _vm.$t('items-per-page-text'),
        showCurrentPage:true
      },"loading":_vm.loading,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.contractNo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.contractNo ? item.contractNo: 'N/A')+" ")]}},{key:"item.consignee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.consignee ? item.consignee: 'N/A')+" ")]}},{key:"item.shippingCompany",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.shippingCompany ? item.shippingCompany: 'N/A')+" ")]}},{key:"item.shippingDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.shippingDate ? _vm.findMonthDate(item.shippingDate) : 'N/A')+" ")]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.date ? _vm.moment(item.date) : item.date)+" ")]}},{key:"item.containerNo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.containerNo ? item.containerNo: 'N/A')+" ")]}},{key:"item.totalNetWeight",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalNetWeight ? item.totalNetWeight: 'N/A'))+" ")]}},{key:"item.totalPackages",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalPackages ? item.totalPackages: 'N/A'))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.download-as-pdf')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.report(item.id)}}},[_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1)],1),(_vm.viewerOnly)?[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.preview')}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                name:'shipping-advise',
                                params:{companyId: _vm.$route.params.companyId},
                                query:{viewer: 1, id:item.id}
                            }}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)]:[_c('tooltip',{attrs:{"text":_vm.$t('tooltips.duplicate')}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                name:'shipping-advise',
                                params:{companyId: _vm.$route.params.companyId},
                                query:{duplicate: 1, id:item.id}
                            }}},[_c('v-icon',[_vm._v(" mdi-content-duplicate ")])],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.edit')}},[_c('v-btn',{attrs:{"text":"","small":"","to":{
                                name:'shipping-advise',
                                params:{companyId: _vm.$route.params.companyId},
                                query:{id:item.id}
                            }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.delete')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.DeleteDialog.activeDialog(item.id, 'shippingAdvises')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]],2)]}},{key:"footer.page-text",fn:function(ref){
                            var pageStart = ref.pageStart;
                            var pageStop = ref.pageStop;
                            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }