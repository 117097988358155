import * as types from '../mutation-types'

export default {
    namespaced: true,
    
    state:{
        shippingAdvises: []
    },

    actions:{
        fetchAll({ commit }, { sequence, fromDate, toDate }){
            return window.axios.get('/files/shippingAdvices', {
                params: { sequence, fromDate, toDate }
            })
            .then((response) => {
                commit(types.STORE_SHIPPING_ADVISES, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/shippingAdvices/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { isManualSequence, sequence, manualSequence, items, date, contractNo, consignee, shippingCompany, shippingDate, loadingPort, destinationPort, billOfLadingNo, containerNo, containerSize, customStampNo, productsOrderInContainer }){
            return window.axios.post('/files/shippingAdvices', {
                isManualSequence, sequence, manualSequence, items, currency: 'Q', date, contractNo, consignee, shippingCompany, shippingDate, loadingPort, destinationPort, billOfLadingNo, containerNo, containerSize, customStampNo, productsOrderInContainer
            })
        },
        
        update({ commit }, { id, isManualSequence, sequence, manualSequence, items, date, contractNo, consignee, shippingCompany, shippingDate, loadingPort, destinationPort, billOfLadingNo, containerNo, containerSize, customStampNo, productsOrderInContainer }){
            return window.axios.put(`/files/shippingAdvices/${id}`, {
                isManualSequence, sequence, manualSequence, items, currency: 'Q', date, contractNo, consignee, shippingCompany, shippingDate, loadingPort, destinationPort, billOfLadingNo, containerNo, containerSize, customStampNo, productsOrderInContainer
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/shippingAdvices/${id}`);
        }
    },

    mutations:{
        [types.STORE_SHIPPING_ADVISES](state, shippingAdvises){
            state.shippingAdvises = shippingAdvises;
        }
    },

    getters:{
        getShippingAdviseById: state => id => state.shippingAdvises.find(c => c.id === id)
    }
}