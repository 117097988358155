import i18n from "./i18n/i18n";

const messages = {
    ar: {
        required: 'هذا الحقل مطلوب',
        name: 'هذا الحقل مطلوب',
        number: 'هذا الحقل يتطلب قيماً غير سالبة',
        requiredAsNumber: 'هذا الحقل مطلوب',
        url: 'رابط غير صالح',
        date: 'التاريخ غير صالح - يجب أن يكون التاريخ يوم - شهر - سنة',
        password: 'يجب أن تكون كلمة المرور مكونة من 4 محارف على الأقل',
        email: 'ايميل غير صالح',
        file: 'هذا الملف مطلوب',
    },
    en: {
        required: 'This field is required.',
        name: 'This field is required.',
        number: 'This field requires a non-negative number.',
        requiredAsNumber: 'This field is required.',
        url: 'Invalid URL.',
        date: 'Invalid date. Date must be \'yyyy-mm-dd\'.',
        password: 'Password must be at least 4 characters.',
        email: 'Invalid e-mail.',
        file: 'This file is required.',
    },
    es: {
        required: 'Este campo es requerido',
        name: 'Este campo es requerido',
        number: 'Este campo requiere un número no negativo',
        requiredAsNumber: 'Este campo es requerido',
        url: 'URL inválido',
        date: 'Fecha inválida. La fecha debe ser \'año-mes-dia\'',
        password: 'La contraseña debe contenedor un minimo de 4 caracteres',
        email: 'E-mail inválido',
        file: 'Este campo es requerido',
    }
}

/*
var s = [];
Object.keys(messages.en).forEach((key) => {
    s.push({
        page: 'validation',
        key: key,
        ar: '',
        en: messages.en[key],
        es: '',
    })
})
console.log(s)
*/

// this is validations rules for vuetify component validation
export default {
    required: [val => (val || '').length > 0 || messages[i18n.locale].required],
    name: [val => (val || '').length > 0 || messages[i18n.locale].name],
    number: [val => (val >= 0 && val !== null) || messages[i18n.locale].number],
    requiredAsNumber: [val => (val >= 0 && val !== null) || messages[i18n.locale].requiredAsNumber],
    url: [
        val => {
            const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
            return regexp.test(val) || messages[i18n.locale].url
        }
    ],
    date: [
        val => {
            const date = /^(19|20)[0-9]{2}[-](1[0-2]|0[1-9])[-](0[1-9]|(1|2)[0-9]|3(0|1))$/;
            return date.test(val) || messages[i18n.locale].date
        }
    ],
    password: [val => (val || '').length >= 4 || messages[i18n.locale].password],
    email: [
        val => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(val) || messages[i18n.locale].email
        }
    ],
    
    file: [val => val || messages[i18n.locale].file]
}