<template>
	<div>
		<div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 primary--text">{{$t('FumigationCertificates.fumigation-certificates')}}</h1>
            
            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="filter">
                <span class="text-body-1">
                    {{$t('are-you-sure-you-want-to')}} 
                    <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                    {{$t('FumigationCertificates.fumigation-certificate')}}
                </span>
            </delete-dialog>

            <!-- Add an fumigation certificate -->
            <v-btn
              v-if="!viewerOnly"
              text
              small
              class="lightPrimary white--text"
              :to="{
                name:'fumigation-certificate',
                params:{companyId: $route.params.companyId},
              }"
            >
                {{$t('FumigationCertificates.add-a-fumigation-certificate')}}
            </v-btn>
        </div>

		<!-- filter -->
        <v-form class="my-2" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1">
                    {{$t('filter')}}
                </v-col>
                
                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date ? moment(date) : null"
                                :label="$t('headers.date')"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate.save(date)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        width="80"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        icon
                        small
                        @click="fetchFumigation();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

		<!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : fumigations"
          :items-per-page="20"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-options': [10, 20, 40, 100, -1],
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          :loading="loading"
          disable-sort
          dense
          class="elevation-2"
        >
            <!-- date -->
            <template v-slot:item.date="{item}">
                {{item.date ? moment(item.date) : null}}
            </template>

            <!-- containerNumber -->
            <template v-slot:item.containerNumber="{item}">
                {{item.containerNumber ? item.containerNumber : 'N/A'}}
            </template>

            <!-- sealNumber -->
            <template v-slot:item.sealNumber="{item}">
                {{item.sealNumber ? item.sealNumber : 'N/A'}}
            </template>

            <!-- billOfLadingNumber -->
            <template v-slot:item.billOfLadingNumber="{item}">
                {{item.billOfLadingNumber ? item.billOfLadingNumber : 'N/A'}}
            </template>

            <!-- totalNetWeight -->
            <template v-slot:item.totalNetWeight="{item}">
                {{item.totalNetWeight ? item.totalNetWeight : 'N/A'}}
            </template>

            <!-- totalGrossWeight -->
            <template v-slot:item.totalGrossWeight="{item}">
                {{item.totalGrossWeight ? item.totalGrossWeight : 'N/A'}}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('tooltips.download-as-pdf')">
                        <v-btn text small @click="report(item.id)">
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <template v-if="viewerOnly">
                        <tooltip :text="$t('tooltips.preview')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'fumigation-certificate',
                                    params:{companyId: $route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>

                    <template v-else>
                        <tooltip :text="$t('tooltips.duplicate')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'fumigation-certificate',
                                    params:{companyId: $route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-content-duplicate
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip :text="$t('tooltips.edit')">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'fumigation-certificate',
                                    params:{companyId: $route.params.companyId},
                                    query:{id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip :text="$t('tooltips.delete')">
                            <v-btn @click="$refs.DeleteDialog.activeDialog(item.id, 'fumigationCertificates')" text small>
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
	</div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import fileTypes from '../../enums/file-types';
import { BASE_API_URL } from '../../constants';
import { mapState } from 'vuex'

export default {
	data: () => ({
		// filter data
		date: null,
		
		// helpers
        loading: true,
        filterLoading: true,
        isFiltered: false,
	}),

	methods: {
		// filter funciton
        clearFilter(){
            this.date = null;

            this.isFiltered = false;
        },
        
        filter(){
            this.loading = true;
            this.$store.dispatch('fumigationCertificates/fetchAll', {
                date: this.date
            })
            .finally(() => {
                this.loading = false;
            })
        },

        fetchFumigation(){
            this.clearFilter();
            this.filter();
        },

        // report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/file/${id}?fileType=${fileTypes.fumigationCertificate}`);
        },

        // helpers
        moment(date) {
            return window.moment(date).format(this.fileSettings.dateFormat ? this.fileSettings.dateFormat.toUpperCase() : 'DD-MM-YYYY');
        }
	},
	
	computed: {
		isAllFieldsEmpty(){
            return this.date === null
		},

		headers() {
            const headers = [
                { text: this.$t('headers.date'), value:'date', class:'primary--text fixed-width-date', cellClass:'primary--text fixed-width-date', width: 120 },
                { text: this.$t('headers.container-no'), value:'containerNumber' },
                { text: this.$t('headers.seal-number'), value:'sealNumber' },
                { text: this.$t('headers.bill-of-loading-number'), value:'billOfLadingNumber' },
                { text: this.$t('headers.total-net-weight'), value:'totalNetWeight' },
                { text: this.$t('headers.total-gross-weight'), value:'totalGrossWeight' },
                
                { text: this.$t('headers.actions'), value:'actions', width: 150 },
            ]

            // set shared props
            for (let i = 0; i < headers.length; i++) {
                headers[i].align = 'center';
                headers[i].class = headers[i].class ? `${headers[i].class} px-2` : 'px-2';
                headers[i].cellClass = headers[i].cellClass ? `${headers[i].cellClass} px-2` : 'px-2';
            }

            return headers;
        },
		
		...mapState({
            fumigations: state => state.fumigationCertificates.fumigations,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings
        }),
	},

	components: {
		DeleteDialog,
		Tooltip
	},

	mounted() {
		Promise.all([
            this.$store.dispatch('fumigationCertificates/fetchAll', { sequence: null, date: null })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
	}
}
</script>

<style>

</style>