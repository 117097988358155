<template>
	<div>
		<div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 primary--text">Despacho a Puerto</h1>

            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="filter">
                <span class="text-body-1">
                    Estás seguro que quieres <span class="error--text">ELIMINAR</span> Despacho a Puerto?
                </span>
            </delete-dialog>

            <!-- Add an Proforma Invoice -->
            <v-btn
              v-if="!viewerOnly"
              text
              small
              class="lightPrimary white--text"
              :to="{
                name:'port-dispatch',
                params:{companyId: $route.params.companyId},
              }"
            >
                Agregar despacho a puerto
            </v-btn>
        </div>

		<!-- filter -->
        <v-form class="my-3" @submit.prevent="filter()">
            <v-row class="align-center ma-0">
                <v-col cols="auto" class="text-body-1 primary--text px-1 pt-0 pb-sm-0 pb-1">
                    Filter
                </v-col>

                <!-- Contrato -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-text-field
                        v-model="num"
                        :disabled="filterLoading"
                        label="Número"
                        filled
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>

                <!-- date -->
                <v-col lg="2" md="3" sm="4" cols="12" class="px-1 pt-0 pb-sm-0 pb-1">
                    <v-menu
                        ref="menuOfDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="date ? moment(date) : null"
                                label="Fecha"
                                prepend-icon="mdi-calendar"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            :max="new Date((Date.now()*2.18915) - new Date()).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="$refs.menuOfDate.save(date)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-spacer/>

                <v-col cols="auto" class="px-1 pt-0 pb-sm-0 pb-1">
                    <!-- search button -->
                    <v-btn
                        text
                        color="success"
                        width="80"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        @click="isFiltered = true"
                        type="submit"
                    >
                        Search
                    </v-btn>
                    <v-btn
                        v-if="isFiltered"
                        icon
                        small
                        @click="fetchPortDispatches();"
                        :disabled="!isFiltered || loading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
		
		<!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : portDispatches"
          :items-per-page="20"
          :footer-props="{ 'items-per-page-options': [10, 20, 40, 100, -1], showCurrentPage:true}"
          :loading="loading"
          disable-sort
          dense
          class="elevation-2"
        >
            
            <!-- contractNo -->
            <template v-slot:item.contractNo="{item}">
                {{item.contractNo ? item.contractNo : 'N/A'}}
            </template>
            
            <!-- packingOrderNo -->
            <template v-slot:item.packingOrderNo="{item}">
                {{item.packingOrderNo ? item.packingOrderNo : 'N/A'}}
            </template>
            
            <!-- date -->
            <template v-slot:item.date="{item}">
                {{item.date ? moment(item.date) : 'N/A'}}
            </template>
            
            <!-- shipmentDate -->
            <template v-slot:item.shipmentDate="{item}">
                {{item.shipmentDate ? moment(item.shipmentDate) : 'N/A'}}
            </template>
            
            <!-- containerNo -->
            <template v-slot:item.containerNo="{item}">
                {{item.containerNo ? item.containerNo : 'N/A'}}
            </template>
            
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Descargar como pdf">
                        <v-btn text small @click="report(item.id)">
                            <v-icon>
                                mdi-tray-arrow-down
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <template v-if="viewerOnly">
                        <tooltip text="Avance">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'port-dispatch',
                                    params:{companyId: $route.params.companyId},
                                    query:{viewer: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>

                    <template v-else>
                        <tooltip text="Duplicar">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'port-dispatch',
                                    params:{companyId: $route.params.companyId},
                                    query:{duplicate: 1, id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-content-duplicate
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Editar">
                            <v-btn
                                text
                                small
                                :to="{
                                    name:'port-dispatch',
                                    params:{companyId: $route.params.companyId},
                                    query:{id:item.id}
                                }"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Eliminar">
                            <v-btn @click="$refs.DeleteDialog.activeDialog(item.id, 'portDispatches')" text small>
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </tooltip>
                    </template>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} of {{itemsLength}}
            </template>
        </v-data-table>
	</div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import Tooltip from '../../components/Tooltip'
import fileTypes from '../../enums/file-types';
import { mapState } from 'vuex';
import { BASE_API_URL } from '../../constants'
export default {
	data: () => ({
		// helpers
        loading: true,
        filterLoading: true,
		isFiltered: false,
		
		// filter
		num: null,
		date: null,
	}),

	methods: {
		filter(){
            this.loading = true;
            this.$store.dispatch('portDispatches/fetchAll', {
                sequence: this.num,
                date: this.date 
            })
            .finally(() => {
                this.loading = false;
            })
		},

		clearFilter() {
			this.num = null;
			this.date = null;
            this.isFiltered = false;
		},
		
		fetchPortDispatches() {
			this.clearFilter();
            this.filter();
		},

		// report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/file/${id}?fileType=${fileTypes.portDispatch}`);
		},
		
		// table funcitons
        moment(date){
            return window.moment(date).format(this.fileSettings.dateFormat ? this.fileSettings.dateFormat.toUpperCase() : 'DD-MM-YYYY');
        }
	},

	computed: {
		isAllFieldsEmpty(){
            return (this.num === null && this.date === null)
		},

		...mapState({
            portDispatches: state => state.portDispatches.portDispatches,
            viewerOnly: state => state.users.viewerOnly,
            fileSettings: state => state.fileSettings.fileSettings
        }),

        headers() {
            const headers = [
                { text:'Fecha', value:'date', class:'primary--text fixed-width-date', cellClass:'primary--text fixed-width-date', width: 120 },
                { text:'Número', value:'sequence', width: 100 },
                { text:'Contrato No.', value:'contractNo', },
                { text:'Orden de empaque No.', value:'packingOrderNo', },
                { text:'Fecha buque', value:'shipmentDate', class:'fixed-width-date', cellClass:'fixed-width-date', width: 120 },
                { text:'Contenedor No.', value:'containerNo', },
                { text:'Actions', value:'actions', width:150 },
            ]

            // set shared props
            for (let i = 0; i < headers.length; i++) {
                headers[i].align = 'center';
                headers[i].class = headers[i].class ? `${headers[i].class} px-2` : 'px-2';
                headers[i].cellClass = headers[i].cellClass ? `${headers[i].cellClass} px-2` : 'px-2';
            }

            return headers;
        },
	},

	components: {
		DeleteDialog,
		Tooltip
	},

	mounted() {
		this.$store.dispatch('portDispatches/fetchAll', { sequence: null, fromDate: null, toDate: null })
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
	}
}
</script>

<style>

</style>