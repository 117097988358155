export default {
    'packaging-units': 'وحدات التعبئة',
    'the-unit': 'الوحدة؟',
    'add-a-unit': 'إضافة وحدة',
    
    dialog: {
        'add-a-unit': 'إضافة وحدة',
        'edit-an-existing-unit': 'تعديل وحدة ',
        'unit-name': 'اسم الوحدة',
    }
}