import * as types from '../mutation-types'

export default {
    namespaced: true,
    
    state:{
        packingOrders: []
    },

    actions:{
        fetchAll({ commit }, { sequence, fromDate, toDate }){
            return window.axios.get('/files/packingOrders', {
                params: { sequence, fromDate, toDate }
            })
            .then((response) => {
                commit(types.STORE_PACKING_OREDERS, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/files/packingOrders/${id}`)
            .then((response) => {
                return response.data;
            })
        },
        
        create({ commit }, { isManualSequence, sequence, manualSequence, items, date, referenceContract, customer, consignee, destinationCountry, destinationPort, containerSize, exportDate, containerLoadingDate, productsOrderInContainer, notes, productionDate, expiryDate }){
            return window.axios.post('/files/packingOrders', {
                isManualSequence, sequence, manualSequence, items, currency: 'Q', date, referenceContract, customer, consignee, destinationCountry, destinationPort, containerSize, exportDate, containerLoadingDate, productsOrderInContainer, notes, productionDate, expiryDate
            })
        },
        
        update({ commit }, { id, isManualSequence, sequence, manualSequence, items, date, referenceContract, customer, consignee, destinationCountry, destinationPort, containerSize, exportDate, containerLoadingDate, productsOrderInContainer, notes, productionDate, expiryDate }){
            return window.axios.put(`/files/packingOrders/${id}`, {
                isManualSequence, sequence, manualSequence, items, currency: 'Q', date, referenceContract, customer, consignee, destinationCountry, destinationPort, containerSize, exportDate, containerLoadingDate, productsOrderInContainer, notes, productionDate, expiryDate
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/packingOrders/${id}`);
        }
    },

    mutations:{
        [types.STORE_PACKING_OREDERS](state, packingOrders){
            state.packingOrders = packingOrders;
        }
    },

    getters:{
        getPackingOrderById: state => id => state.packingOrders.find(c => c.id === id)
    }
}