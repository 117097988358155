var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-5"},[_c('h1',{staticClass:"text-h5 primary--text"},[_vm._v(_vm._s(_vm.$t('Companies.companies')))]),_c('div',[_c('v-btn',{staticClass:"lightPrimary white--text",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.CompanyDialog.addCompany()}}},[_vm._v(" "+_vm._s(_vm.$t('Companies.add-a-company'))+" ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"ms-4 px-6",attrs:{"elevation":"1","small":""},on:{"click":_vm.backToLastCompanyContext}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]):_vm._e()],1)]),_c('delete-dialog',{ref:"DeleteDialog",on:{"on-delete":_vm.fetchCompanies}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('are-you-sure-you-want-to'))+" "),_c('span',{staticClass:"text-uppercase error--text"},[_vm._v(_vm._s(_vm.$t('delete')))]),_vm._v(" "+_vm._s(_vm.$t('Companies.the-company'))+" ")])]),_c('company-dialog',{ref:"CompanyDialog",on:{"on-save":_vm.fetchCompanies}}),_c('financial-account-dailog',{ref:"FinancialAccountDailog"}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.companies,"items-per-page":5,"loading":_vm.loading,"no-data-text":_vm.$t('no-data-text'),"no-results-text":_vm.$t('no-results-text'),"loading-text":_vm.$t('loading-text'),"footer-props":{
        'items-per-page-all-text': _vm.$t('items-per-page-all-text'),
        'items-per-page-text': _vm.$t('items-per-page-text'),
        showCurrentPage:true
      },"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(false)?_c('tooltip',{attrs:{"text":"Download information"}},[_c('v-btn',{attrs:{"text":"","small":""}},[_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1)],1):_vm._e(),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.bank-accounts')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.FinancialAccountDailog.activeDialog(item.id)}}},[_c('v-icon',[_vm._v(" mdi-credit-card-multiple-outline ")])],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('tooltips.edit')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.CompanyDialog.editCompany(item.id)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1),(false)?_c('tooltip',{attrs:{"text":_vm.$t('tooltips.delete')}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$refs.DeleteDialog.activeDialog(item.id, 'companies')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1)]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }