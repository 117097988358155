import * as types from '../mutation-types'

export default {
    namespaced: true,
    
    state: {
        templates: []
    },

    actions: {
        fetchAll({ commit }){
            return window.axios.get('/companyFileSettings/templates')
            .then((response) => {
                commit(types.STORE_TEMPLATES, response.data);
                return response.data;
            })
        },

        create({ commit }, { file }){
            const formData = new FormData();
            formData.append('file', file);
            return window.axios.post('/companyFileSettings/templates', formData);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/companyFileSettings/templates/${id}`)
        },
    },

    mutations: {
        [types.STORE_TEMPLATES](state, templates) {
            state.templates = templates
        }
    },

    getters: {
        getTemplateById: state => id => state.templates.find(c => c.id === id)
    }
}