export const salesContracts = true;
export const invoices = true;
export const commercialInvoices = true;
export const packingLists = true;
export const shippingAdvises = true;
export const packingOrders = true;
export const productReleaseOrders = true;
export const fumigationCertificates = true;
export const healthCertificates = true;
export const weightAndQualityCertificates = true;
export const portDispatches = true;
export const technicalAndFinancialOffers = false;