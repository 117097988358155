export default {
    'customers-and-Suppliers': 'الزبائن والموردون',
    'add-a-customer': 'إضافة زبون',
    'the-customer': 'الزبون؟',

    dialog: {
        'add-a-customer': 'إضافة زبون',
        'customer-or-supplier-name': 'اسم الزبون أو المورد',
        'tax-number': 'الرقم الضريبي',
        'box-number': 'الصندوق البريدي',
        address: 'العنوان',
        'phone-numbers': 'أرقام الهاتف',
        'phone-number': 'رقم الهاتف',
        emails: 'الايميلات',
        email: 'الايميل',
        'favorite-items': 'تفضيلات الزبون'
    }
}