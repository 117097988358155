<template>
	<div>
		<!-- internal affairs dialog -->
		<affair-dialog
			v-model="dialog"
			@add-entry="addEntry($event, affairType)"
			@update-entry="editEntry($event, affairType)"
			:item="dialogItem"
		></affair-dialog>
		
		<v-form @submit.prevent="submit(false)" ref="form" :disabled="viewerOnly">
			<div class="d-flex justify-space-between align-center mb-1">
				<!-- title and number -->
				<v-row class="align-center ma-0 mb-1">
					<!-- title -->
					<v-col md="auto" cols="12" class="d-flex justify-space-between align-center flex-wrap py-0 ps-0">
						<h1 class="text-h5 primary--text">{{title}}</h1>

						<!-- back button -->
						<v-btn
							v-if="$vuetify.breakpoint.smAndDown"
							:to="{ name: 'technical-and-financial-offers' }"
							:disabled="submitLoading"
							elevation="1"
							small
							class="px-6"
						>
							{{$t('back')}}
						</v-btn>
					</v-col>

					<!-- num -->
					<v-col lg="2" md="3" sm="6" cols="12" class="py-0">
						<v-text-field
							v-model="num"
							:loading="loading || editLoading"
							:rules="rules.required"
							:append-outer-icon="isAdmin ? 'mdi-pencil' : null"
							@click:append-outer="editSequence"
							dense
							hide-details
							:readonly="!isManual"
							:class="{ 'v-input--is-disabled': !isManual }"
						></v-text-field>
					</v-col>

					<!-- date -->
					<v-col md="3" sm="6" cols="12">
						<bee-date-input
							v-model="fileDate"
							changeable-with-arrows
							:label="$t('forms.file-date')"
							prepend-icon="mdi-calendar"
							:input-format-order="dateFormat"
							:user-menu-picker="true"
							dense
							hide-details="auto"
							:loading="editLoading"
							:disabled="submitLoading || editLoading"
							:rules="rules.required"
						></bee-date-input>
					</v-col>
				</v-row>

				<!-- back button -->
				<v-btn
					v-if="$vuetify.breakpoint.mdAndUp"
					:to="{ name: 'technical-and-financial-offers' }"
					:disabled="submitLoading"
					elevation="1"
					small
					class="px-6"
				>
					{{$t('back')}}
				</v-btn>
			</div>

			<!-- basic information -->
			<h2 class="text-body-1 primary--text mb-3">{{$t('sections.basic-information')}}</h2>

			<v-row class="justify-start mb-0">
				<v-col sm="6" cols="12">
					<v-row>
						<!-- title -->
						<v-col cols="12">
							<v-text-field
								v-model="fileTitle"
								dense
								hide-details="auto"
								:label="$t('forms.title')"
								:loading="loading"
								:disabled="loading || submitLoading"
							></v-text-field>
						</v-col>
						
						<!-- buyer -->
						<v-col md="7" cols="12">
							<v-combobox
								v-model="buyer"
								:label="$t('forms.buyer')"
								:items="customers"
								item-text="name"
								item-value="name"
								:return-object="false"
								dense
								hide-details="auto"
								:loading="loading"
								:disabled="loading || submitLoading"
								:rules="rules.required"
							></v-combobox>
						</v-col>

						<!-- date -->
						<v-col md="5" cols="12">
							<bee-date-input
								v-model="date"
								changeable-with-arrows
								:label="$t('forms.date')"
								prepend-icon="mdi-calendar"
								:input-format-order="dateFormat"
								:user-menu-picker="true"
								dense
								hide-details="auto"
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
								:rules="rules.required"
							></bee-date-input>
						</v-col>
						
						<!-- implementation period -->
						<v-col md="4" sm="6" cols="12">
							<v-text-field
								v-model="implementationPeriod"
								:label="$t('forms.implementation-period')"
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
								dense
								hide-details="auto"
							></v-text-field>
						</v-col>
						
						<!-- offer validity -->
						<v-col md="4" sm="6" cols="12">
							<v-text-field
								v-model="offerValidity"
								:label="$t('forms.offer-validity')"
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
								dense
								hide-details="auto"
							></v-text-field>
						</v-col>

						<!-- currency -->
						<v-col md="4" sm="6" cols="12">
							<v-autocomplete
								v-model="currency"
								:items="company.currencies"
								:label="$t('forms.currency')"
								item-text="name"
								item-value="name"
								dense
								hide-details="auto"
								:loading="loading || editLoading"
								:disabled="loading || submitLoading || editLoading"
								:rules="rules.required"
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-col>

				<v-col sm="6" cols="12">
					<v-row>
						<!-- internal area -->
						<v-col sm="6" cols="12">
							<v-text-field
								v-model.number="internalArea"
								:label="$t('forms.internal-area')"
								type="number"
								min="0"
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
								dense
								hide-details="auto"
							></v-text-field>
						</v-col>
						
						<!-- external area -->
						<v-col sm="6" cols="12">
							<v-text-field
								v-model.number="externalArea"
								:label="$t('forms.external-area')"
								type="number"
								min="0"
								dense
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
								hide-details="auto"
							></v-text-field>
						</v-col>

						<!-- description -->
						<v-col cols="12">
							<v-textarea
								v-model="description"
								:label="$t('forms.description')"
								outlined
								dense
								hide-details="auto"
								no-resize
								rows="1"
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
								auto-grow
							></v-textarea>
						</v-col>

						<!-- scope description -->
						<v-col cols="12">
							<v-textarea
								v-model="scopeDescription"
								:label="$t('forms.scope-description')"
								outlined
								dense
								hide-details="auto"
								no-resize
								rows="1"
								auto-grow
								:loading="editLoading"
								:disabled="submitLoading || editLoading"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- internal affairs -->
			<h2 class="text-body-1 primary--text mb-3">{{$t('sections.internal-affairs')}}</h2>

			<!-- table -->
            <v-data-table
                :items="loading ? [] : internalAffairsEntries"
                disable-pagination
                disable-sort
				:items-per-page="-1"
                :loading="editLoading"
                no-data-text="No items were added."
                :headers="headers"
                dense
                class="elevation-1 mb-3"
				hide-default-footer
            >   
                <!-- totalQuantity -->
                <template v-slot:item.totalQuantity="{item}">
                    {{item.data.entries.map(c => c.quantity).reduce((a, b) => a + b, 0)}}
                </template>

                <!-- entriesCount -->
                <template v-slot:item.entriesCount="{item}">
                    {{item.data.entries.length}}
                </template>

                <!-- total -->
                <template v-slot:item.total="{item}">
                    {{item.data.entries.map(c => c.quantity * c.price).reduce((a, b) => a + b, 0)}}
                </template>

                <!-- actions -->
                <template v-slot:item.actions="{item}">
                    <div class="d-flex justify-center">
                        <tooltip text="Edit item">
                            <v-btn
                                text
                                small
                                :disabled="submitLoading || editLoading"
                                hide-details="auto"
                                @click="editEntryId = item.id; dialog = true; affairType = type.internalAffair;"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Delete item">
                            <v-btn
                            	@click="removeEntry(item.no, type.internalAffair)"
                            	text
                            	small
                            	:disabled="submitLoading || editLoading"
                            	hide-details="auto"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </tooltip>
                    </div>
                </template>

                <!-- add button -->
                <template v-slot:footer v-if="!viewerOnly">
					<v-divider/>

                    <v-btn
                    	text
                    	@click="dialog = true; affairType = type.internalAffair; isToEditEntry = false;"
                    	:disabled="submitLoading || editLoading"
                    	hide-details="auto"
						class="ms-2 my-2"
                    >
                        <v-icon class="me-3">mdi-plus-circle-outline</v-icon> {{$t('add')}}
                    </v-btn>
                </template>
            </v-data-table>

			<!-- total -->
			<v-row>
				<v-spacer/>
				<v-col md="2" sm="3" cols="12">
					<v-text-field
						:label="$t('forms.balance')"
						dense
						outlined
						hide-details
						readonly
						:value="internalAffairsTotal"
					></v-text-field>
				</v-col>
			</v-row>

			<!-- external affairs -->
			<h2 class="text-body-1 primary--text mb-3">{{$t('sections.external-affairs')}}</h2>

			<!-- table -->
            <v-data-table
                :items="loading ? [] : externalAffairsEntries"
                disable-pagination
                disable-sort
				:items-per-page="-1"
                :loading="editLoading"
                no-data-text="No items were added."
                :headers="headers"
                dense
                class="elevation-1 mb-3"
				hide-default-footer
            >   
                <!-- totalQuantity -->
                <template v-slot:item.totalQuantity="{item}">
                    {{item.data.entries.map(c => c.quantity).reduce((a, b) => a + b, 0)}}
                </template>

                <!-- entriesCount -->
                <template v-slot:item.entriesCount="{item}">
                    {{item.data.entries.length}}
                </template>

                <!-- total -->
                <template v-slot:item.total="{item}">
                    {{item.data.entries.map(c => c.quantity * c.price).reduce((a, b) => a + b, 0)}}
                </template>

                <!-- actions -->
                <template v-slot:item.actions="{item}">
                    <div class="d-flex justify-center">
                        <tooltip text="Edit item">
                            <v-btn
                                text
                                small
                                :disabled="submitLoading || editLoading"
                                hide-details="auto"
                                @click="editEntryId = item.id; dialog = true; affairType = type.externalAffair"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </tooltip>
                        
                        <tooltip text="Delete item">
                            <v-btn
                            	@click="removeEntry(item.no, type.externalAffair)"
                            	text
                            	small
                            	:disabled="submitLoading || editLoading"
                            	hide-details="auto"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </tooltip>
                    </div>
                </template>

                <!-- add button -->
                <template v-slot:footer v-if="!viewerOnly">
					<v-divider/>

                    <v-btn
                    	text
                    	@click="dialog = true; affairType = type.externalAffair; isToEditEntry = false;"
                    	:disabled="submitLoading || editLoading"
                    	hide-details="auto"
						class="ms-2 my-2"
                    >
                        <v-icon class="me-3">mdi-plus-circle-outline</v-icon> {{$t('add')}}
                    </v-btn>
                </template>
            </v-data-table>

			<v-row class="mb-2">
				<!-- notes -->
				<v-col lg="5" md="6" sm="8" cols="12" order-sm="0" order="2">
					<v-textarea
						v-model="notes"
						:label="$t('forms.notes')"
						dense
						rows="1"
						no-resize
						auto-grow
						outlined
						hide-details="auto"
						:loading="editLoading"
						:disabled="submitLoading || editLoading"
					></v-textarea>
				</v-col>

				<v-spacer/>

				<!-- total -->
				<v-col md="2" sm="3" cols="12" order-sm="0" order="1">
					<v-text-field
						:label="$t('forms.balance')"
						dense
						outlined
						hide-details
						readonly
						:value="externalAffairsTotal"
					></v-text-field>
				</v-col>
			</v-row>

			<!-- actions -->
			<div class="d-flex flex-wrap justify-end" v-if="!viewerOnly">
				<v-btn :loading="submitLoading" :disabled="submitLoading || previewLoading" type="submit" color="primary" class="px-6 me-sm-4 mb-sm-0 mb-1">
					{{$t('save')}}
				</v-btn>
				<v-btn @click="submit(true)" :disabled="submitLoading || previewLoading" color="primary lighten-1" class="px-6 me-sm-4 mb-sm-0 mb-1">
					{{$t('save-and-export-as-PDF')}}
				</v-btn>
				<v-btn @click="preview" :loading="previewLoading" :disabled="submitLoading || previewLoading" color="success darken-1">
					<v-icon class="me-sm-1 mb-sm-0 mb-1">mdi-eye-outline</v-icon>
					{{$t('preview')}}
				</v-btn>
			</div>
		</v-form>
		
		<!-- draft snackbar -->
        <v-draft-snackbar
        	v-model="draftSnackbar"
            @cofirm="fillDataFromDraft(); isDraftUsed = true; draftSnackbar = false"
            @reject="$localDrafts.remove(`technical-and-financial-offer:${$route.params.companyId}`); draftSnackbar = false;"
        />

		<!-- messages snackbar -->
        <v-snackbar
            v-model="snackbar"
            color="primary lighten-1"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import rules from '../../../validation rules';
import AffairDialog from '../../../components/AffairDialog.vue';
import Tooltip from '../../../components/Tooltip.vue';
import fileTypes from '@/enums/file-types';
import { BASE_API_URL } from '@/constants';

export default {
	components: { AffairDialog, Tooltip },

	data: () => ({
		// helpers
		title: null,
        loading: false,
        submitLoading: false,
        editLoading: false,
        itemLoading: false,
        previewLoading: false,
        rules,
		type: {
			internalAffair: 0,
			externalAffair: 1,
		},
		affairType: null,

		draft: null,
        isDraftUsed: null,
        draftSnackbar: false,

        snackbar: false,
		message: null,

		dialog: false,
        
		internalAffairRowId: 0,
		externalAffairRowId: 0,

        isToEditEntry: false,
		editEntryId: null,

		/* DATA */
		num: null,
        sequence: null,
        isManual: false,

		fileDate: null,

		fileTitle: null,
		buyer: null,
		date: null,
		implementationPeriod: null,
		offerValidity: null,
		internalArea: null,
		currency: null,
		externalArea: null,
		description: null,
		scopeDescription: null,
		notes: null,
		internalAffairsEntries: [],
		externalAffairsEntries: [],
	}),

	watch: {
		draftFieldsWatcher() {
			if (!this.$route.query.id){
                this.$localDrafts.save(`technical-and-financial-offer:${this.$route.params.companyId}`, {
                    fileTitle: this.fileTitle,
                    buyer: this.buyer,
					date: this.date,
					implementationPeriod: this.implementationPeriod,
					offerValidity: this.offerValidity,
					currency: this.currency,
					internalArea: this.internalArea,
					externalArea: this.externalArea,
					description: this.description,
					scopeDescription: this.scopeDescription,
					internalAffairsEntries: this.internalAffairsEntries,
					externalAffairsEntries: this.externalAffairsEntries,
					notes: this.notes,
                })
            }
		},

		dialog(val) {
			if (!val) {
				this.editEntryId = null;
			}
		}
	},

	methods: {
		editSequence() {
            if (confirm('Are you sure you want to edit this file\'s sequence?')) {
                this.isManual = true;
            }
        },

		// submit
        submit(exportPdf){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                var actionName = this.$route.query.id && !this.$route.query.duplicate ? 'update' : 'create';
				this.$store.dispatch('sequences/reserve', {
					sequence: this.isManual ? this.num : this.sequence,
                    fileType: fileTypes.technicalAndFinancialOffer,
                    isManual: this.isManual,
                    overrideCollision: true
                }).then(() => {
					this.$store.dispatch(`technicalAndFinancialOffers/${actionName}`, {
						id: this.$route.query.id,
						isManualSequence: this.isManual,
						sequence: this.sequence,
						fileDate: this.fileDate,
						date: this.date,
						currency: this.currency,
						manualSequence: this.num,
						title: this.fileTitle,
						buyer: this.buyer,
						implementationPeriod: this.implementationPeriod,
						offerValidity: this.offerValidity,
						internalArea: this.internalArea,
						externalArea: this.externalArea,
						description: this.description,
						scopeDescription: this.scopeDescription,
						notes: this.notes,
						internalAffairsEntries: this.internalAffairsEntries.map(c => c.data),
						externalAffairsEntries: this.externalAffairsEntries.map(c => c.data)
					})
					.then((id) => {
						this.message = this.$t('messages.technical-and-financial-offer-has-been-successfully-saved');
						if (exportPdf) {
							this.report(id);
						}
						if (this.isDraftUsed){
							this.$localDrafts.remove(`technical-and-financial-offer:${this.$route.params.companyId}`);
						}
						this.$router.push({ name: 'technical-and-financial-offers' })
					})
					.catch((e) => {
						this.message = e.response.data.message;
					})
					.finally(() => {
						this.submitLoading = false;
						this.snackbar = true;
					})
				})
				.catch((e) => {
					this.message = e.response.data.message;
				})
            } else {
                this.message = this.$t('messages.there-are-required-fields-still-empty');
                this.snackbar = true;
            }
		},

		preview() {
            if (this.$refs.form.validate()){
                this.previewLoading = true;
                this.$store.dispatch('reportsPreview/technicalAndFinancialOffer', {
					companyId: this.$route.params.companyId,
					isManualSequence: this.isManual,
					sequence: this.sequence,
					fileDate: this.fileDate,
					date: this.date,
					currency: this.currency,
					manualSequence: this.num,
					title: this.fileTitle,
					buyer: this.buyer,
					implementationPeriod: this.implementationPeriod,
					offerValidity: this.offerValidity,
					internalArea: this.internalArea,
					externalArea: this.externalArea,
					description: this.description,
					scopeDescription: this.scopeDescription,
					notes: this.notes,
					internalAffairsEntries: this.internalAffairsEntries.map(c => c.data),
					externalAffairsEntries: this.externalAffairsEntries.map(c => c.data)
				})
				.then((file) => {
                    const url = window.URL.createObjectURL(file);
                    window.open(url, '_blank')
                })
				.catch((e) => {
                    this.message = e.response.data.message;
					this.snackbar = true;
                })
                .finally(() => {
                    this.previewLoading = false;
                })
			}
		},
		
		// fill data
		fillAllFieldsToEdit(data, fillSequence = true){
			if (fillSequence) { this.num = data.sequence; }
			this.date = moment(data.date).format('YYYY-MM-DD');
			this.fileDate = moment(data.fileDate).format('YYYY-MM-DD');
			this.currency = data.currency;
			this.fileTitle = data.title;
			this.buyer = data.buyer;
			this.implementationPeriod = data.implementationPeriod;
			this.offerValidity = data.offerValidity;
			this.internalArea = data.internalArea;
			this.externalArea = data.externalArea;
			this.description = data.description;
			this.scopeDescription = data.scopeDescription;
			this.notes = data.notes;
			
            data.internalAffairsEntries.forEach((item) => {
                this.internalAffairsEntries.push({
                    id: this.internalAffairRowId,
					data: {
						title: item.title,
						entries: item.entries
					}
                });
                this.internalAffairRowId++;
            })

            data.externalAffairsEntries.forEach((item) => {
                this.externalAffairsEntries.push({
                    id: this.internalAffairRowId,
					data: {
						title: item.title,
						entries: item.entries
					}
                });
                this.externalAffairRowId++;
            })
        },

		// fetch Data
        fetchData(){
            return Promise.all([
                this.$store.dispatch('customers/fetchAll'),
				this.$store.dispatch('companies/fetchById', { id: this.$route.params.companyId })
            ])
        },

		// fetch sequence
        fetchSequence() {
            return this.$store.dispatch('sequences/fetch', { fileType: fileTypes.technicalAndFinancialOffer })
                .then(response => {
                    this.num = response.value;
                    this.sequence = response;
                })
        },

		// table functions
		addEntry(entry, type){
			switch (type) {
				case this.type.internalAffair : {	
					this.internalAffairsEntries.push({
						id: this.internalAffairRowId++,
						data: entry
					});
					break;
				}
				case this.type.externalAffair : {
					this.externalAffairsEntries.push({
						id: this.externalAffairRowId++,
						data: entry
					});
					break;
				}
			}
        },

        editEntry(entry, type){
			let entryData;
			switch (type) {
				case this.type.internalAffair: {
					entryData = this.internalAffairsEntries.find(c => c.id === this.editEntryId);
					break;
				}
				case this.type.externalAffair: {
					entryData = this.externalAffairsEntries.find(c => c.id === this.editEntryId);
					break;
				}
			}
			entryData.data = entry;
			
            this.dialog = false;
        },

        removeEntry(index, type){
			switch (type) {
				case this.type.internalAffair: {
					this.internalAffairsEntries.splice(index, 1);
					this.internalAffairsEntries.forEach(product => {
						if (product.id > index){
							product.id--;
						}
					});
					this.internalAffairRowId--;
					break;
				}
				case this.type.externalAffair: {
					this.externalAffairsEntries.splice(index, 1);
					this.externalAffairsEntries.forEach(product => {
						if (product.id > index){
							product.id--;
						}
					});
					this.externalAffairRowId--;
					break;
				}
			}
        },

		// report
        report(id){
            window.open(BASE_API_URL + `/reports/${this.$route.params.companyId}/technicalFinancialOffer/${id}`);
        },
		
		// fill data from draft
        fillDataFromDraft() {
			this.fileTitle = this.draft.fileTitle;
			this.buyer = this.draft.buyer;
			this.date = this.draft.date;
			this.implementationPeriod = this.draft.implementationPeriod;
			this.offerValidity = this.draft.offerValidity;
			this.currency = this.draft.currency;
			this.internalArea = this.draft.internalArea;
			this.externalArea = this.draft.externalArea;
			this.description = this.draft.description;
			this.scopeDescription = this.draft.scopeDescription;
            this.notes = this.draft.notes;
			
			this.internalAffairsEntries = this.draft.internalAffairsEntries;
			this.externalAffairsEntries = this.draft.externalAffairsEntries;
            this.internalAffairRowId = this.draft.internalAffairsEntries.length;
            this.externalAffairRowId = this.draft.externalAffairsEntries.length;
		},
		
		// helpers
		moment,
	},

	computed: {
		...mapState({
			company: state => state.companies.company,
			isAdmin: state => state.users.currentUser.isAdmin,
			viewerOnly: state => state.users.viewerOnly,
			customers: state => state.customers.customers,
			fileSettings: state => state.fileSettings.fileSettings,
		}),
		...mapGetters({
			getCompanyById: 'companies/getCompanyById'
		}),

		dateFormat() {
            return this.fileSettings.dateFormat || 'DD-MM-YYYY'
        },

		headers() {
            const headers = [
                { text: this.$t('headers.title'), value:'data.title', align:'center', class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.total-quantity'), value:'totalQuantity', align:'center' },
                { text: this.$t('headers.entries-count'), value:'entriesCount', align:'center' },
                { text: this.$t('headers.total'), value:'total', align:'center' },
				{ text: this.$t('headers.actions'), value:'actions', align:'center' },
            ]

            if (this.viewerOnly) {
                return headers.slice(0, headers.length - 1)
            }
            return headers;
		},

		// dialog data
		dialogItem() {
			if (this.editEntryId !== null && this.editEntryId >= 0) {
				let items;
				if (this.affairType === this.type.internalAffair) {
					items = this.internalAffairsEntries;
				}
				else if (this.affairType === this.type.externalAffair) {
					items = this.externalAffairsEntries;
				}
				return items.find(c => c.id === this.editEntryId).data;
			} else {
				return null;
			}
		},
		
		// affairs data
		internalAffairsTotal() {
			return this.internalAffairsEntries
				.map(c => c.data)
				.map(c => c.entries.map(e => e.price * e.quantity).reduce((a, b) => a + b, 0))
				.reduce((a, b) => a + b, 0);
		},

		externalAffairsTotal() {
			return this.externalAffairsEntries
				.map(c => c.data)
				.map(c => c.entries.map(e => e.price * e.quantity).reduce((a, b) => a + b, 0))
				.reduce((a, b) => a + b, 0);
		},
		
		// draft
		draftFieldsWatcher(){
			return (`${this.editEntryId} | ${this.internalAffairsEntries} | ${this.externalAffairsEntries} | ${this.fileTitle} | ${this.buyer} | ${this.date} | ${this.implementationPeriod} | ${this.offerValidity} | ${this.currency} | ${this.internalArea} | ${this.externalArea} | ${this.description} | ${this.scopeDescription} | ${this.notes}`)
        },
	},

	mounted() {
		this.loading = true;
        this.exporter = this.getCompanyById(this.$route.params.companyId).name;

        if (this.$route.query.id){
            this.editLoading = true;

            if (this.$route.query.viewer) {
                this.title = this.$t('title.preview') + this.$t('TechnicalAndFinancialOffers.technical-and-financial-offer');
            }
            else if (this.$route.query.duplicate) {
                this.title = this.$t('title.duplicate') + this.$t('TechnicalAndFinancialOffers.technical-and-financial-offer');
            }
            else {
                this.title = this.$t('title.edit') + this.$t('TechnicalAndFinancialOffers.technical-and-financial-offer');
            }

            Promise.all([
                this.$store.dispatch('technicalAndFinancialOffers/fetchById', { id: this.$route.query.id })
                .then((data) => {
					// if it duplicate mode don't fill sequence
                    if (this.$route.query.duplicate) {
                        this.fillAllFieldsToEdit(data, false);
                    }
                    else {
                        this.fillAllFieldsToEdit(data);
                    }
                }),

				this.fetchData(),

				// if it duplicate mode create new sequence and fill it
				this.$route.query.duplicate ? this.fetchSequence() : null
            ])
            .finally(() => {
                this.loading = false;
                this.editLoading = false;
            });
        }
        else {
            this.title = this.$t('title.add') + this.$t('TechnicalAndFinancialOffers.technical-and-financial-offer');

			Promise.all([
				this.fetchData(),
				this.fetchSequence()
			]).finally(() => {
				this.loading = false;

				// get draft and show snackbar
				this.draft = this.$localDrafts.get(`technical-and-financial-offer:${this.$route.params.companyId}`);
				if (this.draft){
					setTimeout(() => {
						this.draftSnackbar = true;
					}, 500)
				}
				this.fileDate = moment().format('YYYY-MM-DD');
			})
        }
	},
}
</script>

<style>

</style>