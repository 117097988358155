import * as types from '../mutation-types'

export default {
    namespaced: true,
    state: {
        fileSettings: {}
    },

    actions: {
        fetch({ commit }){
            return window.axios.get('/companyFileSettings')
                .then((response) => {
                    commit(types.STORE_FILE_SETTINGS, response.data);
                    return response.data;
                });
        },

        update({ commit }, { fiscalYearId, templateUrl, containerUrl, taxFixed, taxAmount, taxLabel, hasTaxInvoice, sequences, dateFormat, fiscalYearStartDate, fiscalYearEndDate }){
            return window.axios.post('/companyFileSettings', { 
                fiscalYearId,
                templateUrl,
                containerUrl,
                taxFixed,
                taxAmount,
                taxLabel,
                hasTaxInvoice,
                sequences,
                fileTypes: [],
                dateFormat,
                fiscalYearStartDate,
                fiscalYearEndDate
            });
        },
    },

    mutations: {
        [types.STORE_FILE_SETTINGS](state, settings){
            state.fileSettings = settings;
        }
    },

    getters: {
        getSequenceByFileType: state => fileType => {
            const setting = state.fileSettings.sequences.find(c => c.fileType === fileType);
            return setting ? setting.sequence : null;
        }
    }
}