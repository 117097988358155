import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        packingLists: []
    },

    actions:{
        fetchAll({ commit }, { sequence, to, date }){
            return window.axios.get('/files/packingLists', { 
                params: { sequence, to, date }
            })
            .then((response) => {
                commit(types.STORE_PACKING_LISTS, response.data);
                return response.data;
            })
        },
        
        fetchById({ commit }, { id }){
            return window.axios.get(`/files/packingLists/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { items, date, to, sealesNo, loadingPort, dischargePort, shippingMarks, packing, referenceContract, referenceInvoice, descriptionOfGoods, unitNote, packingDate, expiryDate, containerNumber, billOfLadingNumber, note }){
            return window.axios.post('/files/packingLists', {
                items, date, from: null, to, shippingCompany: null, sealesNo, loadingPort, dischargePort, shippingMarks, packing, referenceContract, referenceInvoice, descriptionOfGoods, unitNote, packingDate, expiryDate, containerNumber, billOfLadingNumber, note
            });
            // "items": [
            //     {
            //       "description": "string",
            //       "quantity": 0,
            //       "unit": "string",
            //       "netWeight": 0,
            //       "grossWeight": 0
            //     }
        },

        update({ commit }, { id, items, date, to, sealesNo, loadingPort, dischargePort, shippingMarks, packing, referenceContract, referenceInvoice, descriptionOfGoods, unitNote, packingDate, expiryDate, containerNumber, billOfLadingNumber, note }){
            return window.axios.put(`/files/packingLists/${id}`, {
                items, date, from: null, to, shippingCompany: null, sealesNo, loadingPort, dischargePort, shippingMarks, packing, referenceContract, referenceInvoice, descriptionOfGoods, unitNote, packingDate, expiryDate, containerNumber, billOfLadingNumber, note
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/files/packingLists/${id}`);
        }
    },

    mutations:{
        [types.STORE_PACKING_LISTS](state, packingLists){
            state.packingLists = packingLists;
        }
    },

    getters:{
        getPackingListById: state => id => state.packingLists.find(c => c.id === id)
    }
}