<template>
    <div>
        <div class="d-flex justify-space-between align-center mb-5">
            <h1 class="text-h5 primary--text">{{$t('Materials.items-and-materials')}}</h1>

            <!-- material-dialog is decleared here to use it as ref -->
            <material-dialog ref="MaterialDialog" @on-save="fetchItems"/>
            
            <!-- delete-dialog is decleared here to use it as ref -->
            <delete-dialog ref="DeleteDialog" @on-delete="fetchItems">
                <span class="text-body-1">
                    {{$t('are-you-sure-you-want-to')}} 
                    <span class="text-uppercase error--text">{{$t('delete')}}</span> 
                    {{$t('Materials.the-item')}}
                </span>
            </delete-dialog>
            
            <div>
                <!-- packaging-units -->
                <v-btn
                  text
                  small
                  class="lightPrimary white--text me-2"
                  :to="{
                    name:'packaging-units',
                    params:{companyId: $route.params.companyId},
                  }"
                >
                    {{$t('Materials.manage-packaging-units')}}
                </v-btn>

                <!-- add new material -->
                <v-btn text small class="lightPrimary white--text" @click="addMaterial">
                    {{$t('Materials.add-an-item')}}
                </v-btn>
            </div>
        </div>

        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="loading ? [] : items"
          :items-per-page="20"
          :no-data-text="$t('no-data-text')"
          :no-results-text="$t('no-results-text')"
          :loading-text="$t('loading-text')"
          :footer-props="{
            'items-per-page-options': [10, 20, 40, 100, -1],
            'items-per-page-all-text': $t('items-per-page-all-text'),
            'items-per-page-text': $t('items-per-page-text'),
            showCurrentPage:true
          }"
          :loading="loading"
          dense
          class="elevation-2"
        >
            <!-- description -->
            <template v-slot:item.description="{item} ">
                {{
                    item.description 
                    ? item.description.length > 100 ? item.description.slice(0, 100) + '...' : item.description
                    : 'N/A'
                }}
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('tooltips.edit')">
                        <v-btn text small @click="$refs.MaterialDialog.editMaterial(item.id)">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip :text="$t('tooltips.delete')">
                        <v-btn text small @click="$refs.DeleteDialog.activeDialog(item.id, 'items')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>

            <!-- footer -->
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{pageStart}} - {{pageStop}} {{$t('of')}} {{itemsLength}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteDialog from '../../components/DeleteDialog'
import MaterialDialog from '../../components/MaterialDialog'
import Tooltip from '../../components/Tooltip'
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        // helpers
        loading: true,
    }),

    components:{
        DeleteDialog,
        MaterialDialog,
        Tooltip
    },

    methods:{
        addMaterial(){
            this.$refs.MaterialDialog.addMaterial();
        },

        fetchItems(){
            this.loading = true;
            this.$store.dispatch('items/fetchAll')
            .finally(() => {
                this.loading = false;
            })
        },
    },

    computed:{
        ...mapState({
            items: state => state.items.items
        }),
        headers() {
            return [
                { text: this.$t('headers.name'), value:'name', align:'center', sortable: false, class:'primary--text', cellClass:'primary--text' },
                { text: this.$t('headers.description'), value:'description', align:'center', sortable: false, cellClass:'fixed-width-text' },
                { text: this.$t('headers.actions'), value:'actions', align:'center', sortable: false },
            ]
        }
    },

    mounted(){
        this.fetchItems();
    }
}
</script>

<style>

</style>