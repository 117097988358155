import * as types from '../mutation-types';

export default {
    namespaced: true,

    state:{
        customers: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/customers')
            .then((response) => {
                commit(types.STORE_CUSTOMERS, response.data.filter(c => c.deletedAt == null));
                return response.data.filter(c => c.deletedAt == null);
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/customers/${id}`)
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { name, taxNumber, boxNumber, address, phoneNumbers, emails, items }){
            return window.axios.post('/customers', { name, taxNumber, boxNumber, address, phoneNumbers, emails, items })
        },

        update({ commit }, { id, name, taxNumber, boxNumber, address, phoneNumbers, emails, items }){
            return window.axios.put(`/customers/${id}`, {
                name, taxNumber, boxNumber, address, phoneNumbers, emails, items
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/customers/${id}`);
        }
    },

    mutations:{
        [types.STORE_CUSTOMERS](state, customers){
            state.customers = customers;
        }
    },

    getters:{
        getCustomerById: state => id => state.customers.find(c => c.id === id),
        getCustomerByName: state => name => state.customers.find(c => c.name === name),
    }
}