export default {
    'items-and-materials': 'Items & Materials',
    'the-item': 'the item?',
    'manage-packaging-units': 'Manage packaging units',
    'add-an-item': 'Add an item',

    dialog: {
        'add-an-item': 'Add an item',
        'edit-an-existing-item': 'Edit an existing item',
        'items-of-materials-name': 'Items of materials name',
        'description': 'Description',
        'packaging-units-for-item': 'Packaging units for item',
        'unit': 'Unit',
        'price': 'Price',
        'default-unit': 'Default unit'
    }
}