<template>
    <div class="land-page">
        <img
          :src="company.logoImagePath ? BASE_API_URL + '/' + company.logoImagePath : '/img/acacia.png'"
          class="logo"
        >
        <span class="overline mt-3">Acacia — {{ $version }}</span>

        <p class="powered-by mb-0">
            powered by 
            <a
            	target="_blank"
            	href="https://www.beetronix.com"
            	class="black--text font-weight-bold text-decoration-none"
            >BEETRONIX</a>
        </p>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { BASE_API_URL } from '../../constants'

export default {
    data: () => ({
        BASE_API_URL
    }),

    methods: {
        fetchCompanyData(id) {
            this.$emit('company-fetch:start');
            this.$store.dispatch('fileSettings/fetch')
            this.$store.dispatch('companies/fetchById', { id })
            .finally(() => {
                this.$emit('company-fetch:end');
            })
        },
    },

    computed:{
        ...mapState({
            companies: state => state.companies.companies
        }),

        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        }),

        company(){
            return this.getCompanyById(this.$route.params.companyId);
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.fetchCompanyData(this.$route.params.companyId);
        })
    }
}
</script>

<style lang="scss">
.land-page{
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 84vh;
    
    .logo{
        max-width: 40%;
        min-width: 200px;
    }
}
</style>