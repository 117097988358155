import * as type from '../mutation-types'

export default {
	namespaced: true,

	state: {
		technicalAndFinancialOffers: []
	},

	actions: {
		fetchAll({ commit }, { to, sequence, date }) {
			return window.axios.get('/files/technicalFinancialOffers', {
				params: { to, sequence, date }
			})
			.then((response) => {
				commit(type.STORE_TECHNICAL_AND_FINANCIAL_OFFERS, response.data)
			})
		},

		fetchById({ commit }, { id }) {
			return window.axios.get(`/files/technicalFinancialOffers/${id}`)
			.then(response => {
				return response.data;
			})
		},

		create({ commit }, { 
			isManualSequence, sequence, fileDate, date, currency, manualSequence, title, buyer, implementationPeriod, offerValidity, internalArea, externalArea, description, scopeDescription, notes, internalAffairsEntries, externalAffairsEntries
		}) {
			return window.axios.post('/files/technicalFinancialOffers', { isManualSequence, sequence, fileDate, date, currency, manualSequence, title, buyer, implementationPeriod, offerValidity, internalArea, externalArea, description, scopeDescription, notes, internalAffairsEntries, externalAffairsEntries })
			.then((response) => {
                return response.data;
            });
		},

		update({ commit }, { 
			id, isManualSequence, sequence, fileDate, date, currency, manualSequence, title, buyer, implementationPeriod, offerValidity, internalArea, externalArea, description, scopeDescription, notes, internalAffairsEntries, externalAffairsEntries
		}) {
			return window.axios.put(`/files/technicalFinancialOffers/${id}`, { isManualSequence, sequence, fileDate, date, currency, manualSequence, title, buyer, implementationPeriod, offerValidity, internalArea, externalArea, description, scopeDescription, notes, internalAffairsEntries, externalAffairsEntries })
			.then((response) => {
                return id;
            });
		},

		delete({ commit }, { id }) {
			return window.axios.delete(`/files/technicalFinancialOffers/${id}`)
		},
	},

	mutations: {
		[type.STORE_TECHNICAL_AND_FINANCIAL_OFFERS](state, technicalAndFinancialOffers) {
			state.technicalAndFinancialOffers = technicalAndFinancialOffers;
		}
	},

	getters: {
		getTechnicalAndFinancialOfferById: state => id => state.technicalAndFinancialOffers.find(c => c.id === id)
	}
}