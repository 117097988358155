export default {
    'customers-and-Suppliers': 'Cllientes y proveedores',
    'add-a-customer': 'Agregar cliente',
    'the-customer': 'el cliente?',

    dialog: {
        'add-a-customer': 'Agregar cliente',
        'customer-or-supplier-name': 'Nombre de cliente o proveedor',
        'tax-number': 'Tax No.',
        'box-number': 'Codigo postal',
        address: 'Dirección',
        'phone-numbers': 'Numeros de telefono',
        'phone-number': 'No. De teléfono',
        emails: 'Emails',
        email: 'Email',
        'favorite-items': 'Elementos favoritos del cliente'
    }
}