import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import i18n from './i18n/i18n'
import moment from 'moment'
import { BASE_API_URL, VERSION } from './constants';
import * as types from './store/mutation-types'
import VCurrencyTextField from './components/VCurrencyTextField';
import VDraftSnackbar from './components/VDraftSnackbar.vue';
import BeeDropImg from './components/BeeDropImg.vue';
import BeeDateInput from './components/BeeDateInput.vue';
import VAutocompleteServer from './components/VAutocompleteServer.vue';
import localDrafts from './local-drafts';

Vue.config.productionTip = false
Vue.prototype.$version = VERSION;
Vue.prototype.$localDrafts = localDrafts;

window.axios = axios.create({
	baseURL: `${BASE_API_URL}/api`
});

window.moment = moment;

if ('auth' in localStorage) {
	try {
		const auth = JSON.parse(localStorage.getItem('auth'));
		const authHeader = `Bearer ${auth.token}`;

		window.axios.defaults.headers.common.Authorization = authHeader;
		store.dispatch('companies/fetchAll')
		.then(() => {
			store.commit(`auth/${types.LOGIN}`, auth);
			store.dispatch('users/fetchSelfPermissions')
			.then(() => {
				if (router.currentRoute.params.companyId) {
					store.dispatch('users/changeViewerOnly', { companyId: router.currentRoute.params.companyId });
				}
			})
		})
		.catch(() => {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' });
		});
	} catch (e) {
		store.dispatch(`auth/${types.LOGOUT}`);
		router.replace({ name: 'login' });
	}
} else {
	router.replace({ name: 'login' });
}

Vue.component('v-currency-text-field', VCurrencyTextField);
Vue.component('v-draft-snackbar', VDraftSnackbar);
Vue.component('bee-drop-img', BeeDropImg);
Vue.component('bee-date-input', BeeDateInput);
Vue.component('v-autocomplete-server', VAutocompleteServer);
Vue.filter('currency', value => {
	if (!value || isNaN(value)) return value;

	const decimalPart = value.toString().split('.')[1];
	const precision = decimalPart ? decimalPart.length : 0;

	return '' + value.toFixed(precision).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
});

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: h => h(App)
}).$mount('#app')
