import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        companies: [],
        company: null
    },

    actions:{
        create({ commit }, { companyId, beneficiaryName, identifier, bankName, address, accountNumber, swiftCode, iban, branch, country, intermediaryBank }){
            return window.axios.post(`/companies/${companyId}/bankaccounts`, {
                beneficiaryName,
                identifier,
                bankName,
                address,
                accountNumber,
                swiftCode,
                iban,
                branch,
                country,
                intermediaryBank
            })
        },

        update({ commit }, { companyId, bankAccountId, beneficiaryName, identifier, bankName, address, accountNumber, swiftCode, iban, branch, country, intermediaryBank }){
            return window.axios.put(`/companies/${companyId}/bankAccounts/${bankAccountId}`, {
                beneficiaryName,
                identifier,
                bankName,
                address,
                accountNumber,
                swiftCode,
                iban,
                branch,
                country,
                intermediaryBank
            })
        },

        delete({ commit }, { companyId, bankAccountId }){
            return window.axios.delete(`/companies/${companyId}/bankAccounts/${bankAccountId}`);
        }
    },

    mutations:{
    },

    getters:{
    }
}