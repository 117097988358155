export default {
    'customers-and-Suppliers': 'Customers & Suppliers',
    'add-a-customer': 'add a customer',
    'the-customer': 'the customer?',

    dialog: {
        'add-a-customer': 'Add a customer',
        'customer-or-supplier-name': 'Customer or supplier name',
        'tax-number': 'Tax number',
        'box-number': 'PO Box',
        address: 'Address',
        'phone-numbers': 'Phone numbers',
        'phone-number': 'Phone number',
        emails: 'Emails',
        email: 'Email',
        'favorite-items': 'Customer favorite items'
    }
}