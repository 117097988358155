export default {
    users: 'المستخدمون',
    'add-a-user': 'إضافة مستخدم ',
    'the-user': 'المستخدم؟',
    'edit-user': 'تعديل المستخدم',
    'add-user': 'إضافة مستخدم ',

    'username': 'اسم المستخدم ',
    'password': 'كلمة المرور',
    'confirm-password': 'تأكيد كلمة المرور ',
    'not-matched-password': 'كلمات المرور غير متطابقة ',
    'user-permissions': 'صلاحيات المستخدم',
    'read-only': 'قراءة فقط',
    'read-and-write': 'قراءة وكتابة',

    'leave-it-empty-if-you-do-not-want-to-change-password': 'اترك الحقل فارغاً إن كنت لا تريد تغيير كلمة المرور '
}