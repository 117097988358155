<template>
    <div style="position: relative;">
        <span>{{ label }}</span>
        <div class="d-flex">
            <v-text-field
                v-model="sequence.firstPart"
                @change="$emit('input', sequence)"
                :disabled="disabled"
                hide-details
                outlined
                dense
                :label="$t('fixed-part')"
                class="mt-2"
                :class="{'rounded-br-0': loading}"
            ></v-text-field>
            <v-text-field
                v-model.number="sequence.secondPart"
                @change="$emit('input', sequence)"
                :disabled="disabled"
                hide-details
                type="number"
                min="0"
                outlined
                dense
                :label="$t('dynamic-part')"
                class="mt-2"
                :class="{'rounded-b-0': loading}"
            ></v-text-field>
            <v-text-field
                v-model="sequence.thirdPart"
                @change="$emit('input', sequence)"
                :disabled="disabled"
                hide-details
                outlined
                dense
                :label="$t('fixed-part')"
                class="mt-2"
                :class="{'rounded-bl-0': loading}"
            ></v-text-field>
        </div>
        <v-progress-linear
          v-if="loading"
          indeterminate
          rounded
          height="2px"
          style="position: absolute; bottom:0;"
          class="rounded-tr-0 rounded-tl-0"
        />
    </div>
</template>

<script>
    export default {
        props: ['value', 'label', 'loading', 'disabled'],

        computed: {
            sequence() {
                return this.value ? this.value : {
                    firstPart: null,
                    secondPart: null,
                    thirdPart: null,
                }
            }
        }
    }
</script>
